import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_APPLICANT_JOBPOST_REFERENCE_VIA_API,
  ADD_APPLICANT_NOTE_VIA_API,
  ADD_APPLICANT_REFENCE_JOB_POSTING_VIA_API,
  ADD_APPLICANT_VIA_API,
  ADD_BLOCKLIST_VIA_API,
  ADD_DEGREES_VIA_API,
  ADD_RESUMESOURCES_VIA_API,
  AWS_RESUME_URL,
  AWS_RESUME_URL_VIA_API,
  CHANGE_APPLICANT_STATUS_VIA_API,
  CHANGE_JOB_STATUS_VIA_API,
  CHANGE_STATUS_OF_APPLICANT_VIA_API,
  GET_ALL_APPLICANTS_VIA_API,
  GET_ALL_DEGREES_VIA_API,
  GET_ALL_RESUMESOURCES_VIA_API,
  GET_PAGINATION_APPLICANT_VIA_API,
  GET_TOTAL_APPLICANTS_COUNT_VIA_API,
  GLOBAL_SEARCH_JOB_POSTING_SUBMISSIONS,
  JOB_CLOSE_REQUEST_ADMIN_VIA_API,
  JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
 JOB_POST_RECRUITER_VIA_API,
 PARSE_RESUME_VIA_API,
 SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
 SEARCH_JOB_POST_RECRUITER_VIA_API,
 SUBMIT_NOTE_REQUEST,
 SUCCESS_GET_ALL_REFRENCES_NOTES,
 SUCCESS_GET_ALL_REFRENCES_NOTES_VIA_API,
 TAG_AND_SUBMIT_APPLICANTS_API,
 TAG_APPLICANT_TO_JOB_VIA_API,
} from "./actionType";
import { addApplicantReferenceJobPostingViaApi, globalSearchApplicantsPagination, searchDateJobPostingDataViaApi, successAddApplicant, successAddApplicantJobPostReference, successAddMigratedAppliants, successAllResumeSources, successApplicantReferenceJobPosting, successAwsResumeUrl, successDegree, successGetAllReferencesNotesViaApi, successHtmlResumeConvert, successjobPostRecruiter, successSearchDateJobPostingData, successTotalApplicantsCount, successUpdateNoteApplicants, sucessParseResume, tagAndSubmitApplicantViaApi } from "./action";
import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
import {loadingViaRedux} from "../reports/action"
import { successAddReferenceNoteInternalDbMatched, successApplicantReferenceAddNote, successIntegrateSearchInternalDBNoteUpdate, successInternalDBMatchedResumes, successInternalDBNoteUpdate } from "../actions";
const apiUrl = process.env.REACT_APP_API_URL;
const emailApiUrl=process.env.REACT_APP_API_URL_EMAILS;
function jobPostRecruiterViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(emailApiUrl + "/jobPostings/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function searchjobPostRecruiterViaApiSaga(payload) {
const bdmCompanyId=payload.id
const client=payload.client
  return fetch(`${apiUrl}/jobPostings/${bdmCompanyId}/${client}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function searchDateJobPostingDataViaApiSaga(payload) {
  const client=payload.client
  return fetch(apiUrl +"/jobPostings/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function jobPostAssignedToRecruiterViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl +"/jobPostings/assignToRecuiter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}



function addApplicantViaApiSaga(payload) {
return fetch(apiUrl + "/applicants/addApplicant", {
    method: "POST",
    body: payload, // Send the FormData directly
})
.then((response) => response.json())
.catch((error) => {
    throw error;
});
}

function getAllApplicantsViaApiSaga(payload) {
  const client=payload.client

  return fetch(apiUrl + "/applicants/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function getAllResumeSourceViaApiSaga(payload) {
  const client=payload.client
  return fetch(apiUrl + "/resumeSources/" +client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addResumeSourcesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/resumeSources/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addApplicantNoteViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/addNote", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function tagApplicantToJobViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(emailApiUrl + "/applicants/tagApplicant", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllDegreesViaApiSaga(client) {
  return fetch(apiUrl + "/degrees/"+client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addDegreeViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/degrees/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function awsResumeUrlViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/key", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function getPaginationApplicantViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/paginationApplicants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getTotalApplicantsCountViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/totalApplicants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function tagAndSubmitApplicantViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/tagAndSubmitApplicants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function changeJobStatusViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/jobPostings/changeJobStatus", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function changeApplicantStatusViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/changeApplicantStatus", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function jobCloseRequestAdminApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/jobPostings/changeJobStatusRequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function addNoteViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/jobPostings/addNote", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function changeStatusOfAplicantViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/changestatusOfApplicant", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function parseResumeViaApiSaga(formData) {
  return fetch(apiUrl + "/resumeSources/parseResume", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addBlockListViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/addBlockList", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addApplicantJobPostReferenceViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/notes/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function successGetAllReferencesNotesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/notes/getAllNotes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function* jobPostRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(jobPostRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      } else if (response.data) {
        yield put(successjobPostRecruiter(response.data));
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* searchjobPostRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(searchjobPostRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
        yield put(successjobPostRecruiter(response));
      
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* searchDateJobPostingDataViaSaga(action) {
  try {
    
    const { response, timeout } = yield race({
      response: call(searchDateJobPostingDataViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(loadingViaRedux(true))
      const allApplicantIds = [];
      const ApplicantsData = Array.isArray(response) ? response : [];
      // Map through job postings to get applicant IDs
      ApplicantsData.forEach(jobData => {
        if (jobData.submissions) {
          const applicantIds = jobData.submissions.map(submission => submission.applicantId);
          allApplicantIds.push(...applicantIds);
        }
      });
      const client=action.payload.client
      const page=action.payload.page
      if (allApplicantIds.length > 0&&client&&page&&page==='jobpage') {
        const action = {
          client: client,
          applicantIds: allApplicantIds
        };
        yield put(tagAndSubmitApplicantViaApi(action));
      }
      if(ApplicantsData){
        const filterIds = ApplicantsData.map((applicant) => applicant._id);
        const actionNote = {
          client: action.payload.client,
          jobPostIds: filterIds,
        };
        yield put(addApplicantReferenceJobPostingViaApi(actionNote));
      }

        yield put(successSearchDateJobPostingData(response));
      
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* jobPostAssignedToRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(jobPostAssignedToRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addApplicantViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addApplicantViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getAllApplicantsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllApplicantsViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* getAllResumeSourceViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllResumeSourceViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(successAllResumeSources(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addResumeSourcesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addResumeSourcesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addApplicantNoteViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addApplicantNoteViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      if(response.note){
        const filterIds = [response.note].map((applicant) => applicant._id);
        const actionNote = {
          client: action.payload.client,
          applicantIds: filterIds,
        };
   
        yield put(successInternalDBMatchedResumes([response.note]))
        yield put(successInternalDBNoteUpdate([response.note]))
        yield put(successIntegrateSearchInternalDBNoteUpdate([response.note]))
        yield put(successUpdateNoteApplicants([response.note]))
        yield put(successGetAllReferencesNotesViaApi(actionNote));
      }
    }
  } catch (error) {
  }
}
function* tagApplicantToJobViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(tagApplicantToJobViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        yield put(loadingViaRedux(true))
        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        yield put(loadingViaRedux(true))
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        yield put(loadingViaRedux(true))
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getAllDegreesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllDegreesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(successDegree(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addDegreeViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addDegreeViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* awsResumeUrlViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(awsResumeUrlViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      // if (response.message) {
      //   yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      
      // }
      yield put(successAwsResumeUrl(response.fileUrl))
    
      yield put(successHtmlResumeConvert(response.htmlContent))
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* getPaginationApplicantViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getPaginationApplicantViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getTotalApplicantsCountViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getTotalApplicantsCountViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put (successTotalApplicantsCount(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* tagAndSubmitApplicantViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(tagAndSubmitApplicantViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* changeJobStatusViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(changeJobStatusViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      
   
      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* changeApplicantStatusViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(changeApplicantStatusViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      
   
      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* jobCloseRequestAdminViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(jobCloseRequestAdminApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      
   
      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* addNoteViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addNoteViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      if(response.jobPosting){
        const filterIds = [response.jobPosting].map((applicant) => applicant._id);
        const actionNote = {
          client: action.payload.client,
          jobPostIds: filterIds,
        };
        yield put(addApplicantReferenceJobPostingViaApi(actionNote));
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* changeStatusOfApplicantViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(changeStatusOfAplicantViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      
   
      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}

function* parseResumeViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(parseResumeViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    if (response.message) {
      yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
    }
    
    yield put (sucessParseResume([response]))
    yield put(loadingViaRedux(true))
  }
}catch(error){

}
}
function* addBlockListViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(addBlockListViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    if (response.message) {
      yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
    }
    
    yield put (successUpdateNoteApplicants([response.data]))
    yield put(loadingViaRedux(true))
  }
}catch(error){

}
}
function* addApplicantJobPostReferenceViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(addApplicantJobPostReferenceViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    if (response.message) {
      yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
    }
    yield put (successAddApplicantJobPostReference([response.data]))
    yield put (successAddReferenceNoteInternalDbMatched([response.data]))
    yield put (successApplicantReferenceAddNote([response.data]))
    // yield put(loadingViaRedux(true))
  }
}catch(error){

}
}
function* successGetAllReferencesNotesViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(successGetAllReferencesNotesViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    if (response.message) {
      yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
    }
    
    yield put (successAddApplicantJobPostReference(response))
    yield put (successAddReferenceNoteInternalDbMatched(response))
    yield put (successApplicantReferenceAddNote(response))
  
    // yield put(loadingViaRedux(true))
  }
}catch(error){

}
}
function* addApplicantReferenceJobPostingViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(successGetAllReferencesNotesViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    if (response.message) {
      yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
    }
    yield put (successApplicantReferenceJobPosting(response))
    // yield put(loadingViaRedux(true))
  }
}catch(error){

}
}
function* globalSearchJobPostingSubmissionsViaSaga(action){
try{
  const { response, timeout } = yield race({
    response: call(tagAndSubmitApplicantViaApiSaga,action.payload), // Call the API function
    timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
  });

  if (response) {
    if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error"));
      return null;
    }
    if (response.error) {
      yield put(loadingViaRedux(true))
      yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      return null;
    }
    yield put (globalSearchApplicantsPagination(response))
    
  }
}catch(error){

}
}

// Watch for SETTLE_ITEMS action and run settleItemsSaga
function* watchSettleItems() {
  yield takeEvery(JOB_POST_RECRUITER_VIA_API, jobPostRecruiterViaSaga);
  yield takeEvery(SEARCH_JOB_POST_RECRUITER_VIA_API, searchjobPostRecruiterViaSaga);
  yield takeEvery(SEARCH_DATE_JOB_POSTING_DATA_VIA_API, searchDateJobPostingDataViaSaga);
  yield takeEvery(JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API, jobPostAssignedToRecruiterViaSaga);
  yield takeEvery(ADD_APPLICANT_VIA_API,addApplicantViaSaga );
  yield takeEvery(GET_ALL_APPLICANTS_VIA_API,getAllApplicantsViaSaga );
  yield takeEvery(GET_ALL_RESUMESOURCES_VIA_API,getAllResumeSourceViaSaga );
  yield takeEvery(ADD_RESUMESOURCES_VIA_API,addResumeSourcesViaSaga );
  yield takeEvery(ADD_APPLICANT_NOTE_VIA_API,addApplicantNoteViaSaga );
  yield takeEvery(TAG_APPLICANT_TO_JOB_VIA_API,tagApplicantToJobViaSaga );
  yield takeEvery(GET_ALL_DEGREES_VIA_API,getAllDegreesViaSaga );
  yield takeEvery(ADD_DEGREES_VIA_API,addDegreeViaSaga );
  yield takeEvery(AWS_RESUME_URL_VIA_API,awsResumeUrlViaSaga );
  yield takeEvery(GET_PAGINATION_APPLICANT_VIA_API,getPaginationApplicantViaSaga );
  yield takeEvery(GET_TOTAL_APPLICANTS_COUNT_VIA_API,getTotalApplicantsCountViaSaga );
  yield takeEvery(TAG_AND_SUBMIT_APPLICANTS_API,tagAndSubmitApplicantViaSaga );
  yield takeEvery(CHANGE_JOB_STATUS_VIA_API,changeJobStatusViaSaga);
  yield takeEvery(CHANGE_APPLICANT_STATUS_VIA_API,changeApplicantStatusViaSaga);
  yield takeEvery(JOB_CLOSE_REQUEST_ADMIN_VIA_API,jobCloseRequestAdminViaSaga)
  yield takeEvery(SUBMIT_NOTE_REQUEST, addNoteViaSaga); 
  yield takeEvery(CHANGE_STATUS_OF_APPLICANT_VIA_API,changeStatusOfApplicantViaSaga)
  yield takeEvery(PARSE_RESUME_VIA_API,parseResumeViaSaga)
  yield takeEvery(ADD_BLOCKLIST_VIA_API,addBlockListViaSaga)
  yield takeEvery(ADD_APPLICANT_JOBPOST_REFERENCE_VIA_API,addApplicantJobPostReferenceViaSaga)
  yield takeEvery(SUCCESS_GET_ALL_REFRENCES_NOTES_VIA_API,successGetAllReferencesNotesViaSaga)
  yield takeEvery(ADD_APPLICANT_REFENCE_JOB_POSTING_VIA_API,addApplicantReferenceJobPostingViaSaga)
  yield takeEvery(GLOBAL_SEARCH_JOB_POSTING_SUBMISSIONS,globalSearchJobPostingSubmissionsViaSaga)
  


  // yield takeEvery(SEARCH_DATE_JOB_POSTING_DATA_VIA_API, se);

 
  
}

export default function* Recruiter() {
  yield all([
    // ... other sagas ...

    fork(watchSettleItems),
  ]);
}
