import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";
  import {
    ADD_JOB_BOARDS_HISTORY_VIA_API,
    DELETE_JOB_BAORDS_HISTORY_VIA_API,
    FAILURE_VALIDATE_EMAIL,
    GET_APPLICANTS_SEARCH_LIST_VIA_API,
    GET_JOB_BOARDS_HISTORY_VIA_API,
    GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,
    GET_JOBPOSTINGS_SEARCH_LIST_VIA_API,
    GLOBAL_SEARCH_RESULT_VIA_API,
    INTERNAL_DB_MATCHED_RESUMES_VIA_API,
    REQUEST_VALIDATE_EMAIL,
    SAVE_JOB_BOARDS_HISTOTY_VIA_API,
    SUCCESS_VALIDATE_EMAIL,
  } from "./actionType";
  import {successjobPostingsSearchList, successApplicantsSearchList, successGlobalSearchResult, successInternalDBMatchedResumes, successJobboardsHistory} from "./action";
  import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
  import {loadingViaRedux} from "../reports/action"
import { globalSearchApplicantsPagination, globalSearchJobPostingsData, globalSearchJobPostingsSubmissions, successAddMigratedAppliants, successGetAllReferencesNotesViaApi, successUpdateNoteApplicants } from "../actions";
  const apiUrl = process.env.REACT_APP_API_URL;
  function addJobBoardsHistoryViaApiSag(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/historyStored", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/getHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function saveJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/saveHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function deleteJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/deleteHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getJobBoardsStatusHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/getStatusHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
 
  function emailValidationApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/emailLogs/validateEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function internalDBMatchedResumesViaApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/applicants/resumeIdMatchedResult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function globalSearchResultViaApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobPostings/globalSearchResult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getApplicantsSearchListViaApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/applicants/SearchResult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getjobPostingsSearchListViaApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobPostings/SearchResult", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }

// /-----------------------------------
  function* emailValidationSaga(action) {
    
    try {
      const { response, timeout } = yield race({
        response: call(emailValidationApiSaga, action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        yield put(loadingViaRedux(true))
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
  
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch success message
        }
        yield put({ type: SUCCESS_VALIDATE_EMAIL, payload: response });
        
      } else if (timeout) {
        yield put(setSnackbarMessage("Request timed out. Please try again.", "error"));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action if necessary
      yield put(setSnackbarMessage("An error occurred during validation.", "error"));
      yield put({ type: FAILURE_VALIDATE_EMAIL, error });
    }
  }




 
  function* addJobBoardsHistoryViaSag(action) {
    try {
      const { response, timeout } = yield race({
        response: call(addJobBoardsHistoryViaApiSag,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        // if (response.message) {
        //   yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        // }
        // yield put(loadingViaRedux(true))
        // yield put (successAddApplicant(response))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        // if (response.message) {
        //   yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        // }
        yield put (successJobboardsHistory(response))
        // yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* saveJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(saveJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          yield put(loadingViaRedux(true))
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        
        yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* deleteJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(deleteJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getJobBoardsStatusHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getJobBoardsStatusHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
        yield put(loadingViaRedux(true))
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(successJobboardsHistory(response))
        yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* internalDBMatchedResumesViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(internalDBMatchedResumesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
        yield put(loadingViaRedux(true))
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        if (response.error) {
        yield put(loadingViaRedux(true))
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.length > 0) {
          const filterIds = response.map((applicant) => applicant._id);
          const actionNote = {
            client: action.payload.client,
            applicantIds: filterIds,
          };
          yield put(successGetAllReferencesNotesViaApi(actionNote));
        }
        yield put(successInternalDBMatchedResumes(response))
        yield put(successAddMigratedAppliants(response))
        
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
function* globalSearchResultViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(globalSearchResultViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
         
     
       const {applicantsList,jobPostingsList,placementsList}=response
       const client=action.payload.client
       if(applicantsList.length>0){
        yield put(globalSearchApplicantsPagination(applicantsList))
       }
       if(jobPostingsList.length>0){
        const allApplicantIds = [];
      const ApplicantsData = Array.isArray(jobPostingsList) ? jobPostingsList : [];
      ApplicantsData.forEach(jobData => {
        if (jobData.submissions) {
          const applicantIds = jobData.submissions.map(submission => submission.applicantId);
          allApplicantIds.push(...applicantIds);
        }
      });
      if (allApplicantIds.length > 0&&client) {
        const action = {
          client: client,
          applicantIds: allApplicantIds
        };
        yield put(globalSearchJobPostingsSubmissions(action));
      }
      yield put(globalSearchJobPostingsData(jobPostingsList))
       }
       yield put(successGlobalSearchResult(response))
      yield put(loadingViaRedux(true))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getApplicantsSearchListViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(getApplicantsSearchListViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      const {applicantsList,jobPostingsList,placementsList}=response   
       if(applicantsList.length>0){
        yield put(globalSearchApplicantsPagination(applicantsList))
       }
      yield put(loadingViaRedux(true))
      yield put(successApplicantsSearchList(response))

    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getjobPostingsSearchListViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(getjobPostingsSearchListViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
      yield put(loadingViaRedux(true))
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      const {applicantsList,jobPostingsList,placementsList}=response   
       const client=action.payload.client
       if(jobPostingsList.length>0){
        const allApplicantIds = [];
      const ApplicantsData = Array.isArray(jobPostingsList) ? jobPostingsList : [];
      ApplicantsData.forEach(jobData => {
        if (jobData.submissions) {
          const applicantIds = jobData.submissions.map(submission => submission.applicantId);
          allApplicantIds.push(...applicantIds);
        }
      });
      if (allApplicantIds.length > 0&&client) {
        const action = {
          client: client,
          applicantIds: allApplicantIds
        };
        yield put(globalSearchJobPostingsSubmissions(action));
      }
       yield put(globalSearchJobPostingsData(jobPostingsList))
       }
       yield put(successjobPostingsSearchList(response))
      yield put(loadingViaRedux(true))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
  
  // Watch for SETTLE_ITEMS action and run settleItemsSaga
  function* watchSettleItems() {
    yield takeEvery(ADD_JOB_BOARDS_HISTORY_VIA_API, addJobBoardsHistoryViaSag); 
    yield takeEvery(GET_JOB_BOARDS_HISTORY_VIA_API, getJobBoardsHistoryViaSaga); 
    yield takeEvery(SAVE_JOB_BOARDS_HISTOTY_VIA_API, saveJobBoardsHistoryViaSaga);
    yield takeEvery(DELETE_JOB_BAORDS_HISTORY_VIA_API,deleteJobBoardsHistoryViaSaga);
    yield takeEvery(GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,getJobBoardsStatusHistoryViaSaga) 
    yield takeEvery(REQUEST_VALIDATE_EMAIL,emailValidationSaga) 
    yield takeEvery(INTERNAL_DB_MATCHED_RESUMES_VIA_API,internalDBMatchedResumesViaSaga)
    yield takeEvery(GLOBAL_SEARCH_RESULT_VIA_API,globalSearchResultViaSaga)
    yield takeEvery(GET_APPLICANTS_SEARCH_LIST_VIA_API,getApplicantsSearchListViaSaga)
    yield takeEvery(GET_JOBPOSTINGS_SEARCH_LIST_VIA_API,getjobPostingsSearchListViaSaga)
  }
  
  export default function* JobBoards() {
    yield all([
      // ... other sagas ...
  
      fork(watchSettleItems),
    ]);
  }
  