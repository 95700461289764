import{
    DELETE_RECRUITER,
    GLOBAL_SEARCH_APPLICANTS_PAGINATION,
    GLOBAL_SEARCH_JOB_POSTINGS_DATA,
    SUCCESS_ADD_APPLICANT,
    SUCCESS_ADD_APPLICANT_JOBPOST_REFERENCE,
    SUCCESS_ADD_MIGRATED_APPLICANTS,
    SUCCESS_ALL_RESUMESOURCES,
    SUCCESS_APPLICANT_REFERENCE_JOB_POSTING,
    SUCCESS_AWS_RESUME_URL,
    SUCCESS_DEGREE,
    SUCCESS_DEGREES,
    SUCCESS_HTML_RESUME_CONVERT,
    SUCCESS_JOB_POST_RECRUITER,
    SUCCESS_PARSE_RESUME,
    SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
    SUCCESS_TOTAL_APPLICANTS_COUNT,
    SUCCESS_UPDATE_NOTE_APPLICANTS
} from "./actionType"


const INIT_STATE = {
    jobPostings:[],
    applicants:[],
    resumeSources:[],
    degrees:[],
    awsResumeUrl:'',
    totalApplicants:0,
    htmlResume:'',
    parseResumeData:[]
  };
  const recruiters = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_JOB_POST_RECRUITER: {
        const JobPostingsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          jobPostings: JobPostingsData,
        };
      }
      case SUCCESS_SEARCH_DATE_JOB_POSTING_DATA: {
        const JobPostingsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          jobPostings: JobPostingsData,
        };
      }
      case SUCCESS_ADD_APPLICANT: {
        const ApplicantsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          applicants: ApplicantsData,
        };
      }
      case SUCCESS_ALL_RESUMESOURCES: {
        const ResumeSourcesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          resumeSources: ResumeSourcesData,
        };
      }
      case SUCCESS_DEGREES: {
        const degreesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          degrees: degreesData,
        };
      }
      case SUCCESS_AWS_RESUME_URL: {
        const awsResumeUrlLink = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          awsResumeUrl: awsResumeUrlLink,
        };
      }
      case SUCCESS_HTML_RESUME_CONVERT: {
        const htmlResumeData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          htmlResume: htmlResumeData,
        };
      }
      case SUCCESS_TOTAL_APPLICANTS_COUNT: {
        const totalApplicantsCount = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          totalApplicants: totalApplicantsCount,
        };
      }
     case SUCCESS_PARSE_RESUME:{
        return {
          ...state,
          parseResumeData: action.payload,
        };
     }
     case SUCCESS_UPDATE_NOTE_APPLICANTS: {
      const newData = action.payload; // Incoming updated data
      const oldData = state.applicants; // Existing data in state
      const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old]));
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem.applicantId);
        return matchingNewItem ? { ...oldItem, ...matchingNewItem } : oldItem; // Update if matched, else keep old
      });
      return {
        ...state,
        applicants: updatedData, // Preserve original records, only update matches
      };
    }
    
    case SUCCESS_ADD_APPLICANT_JOBPOST_REFERENCE: {
      const newData = action.payload;
      
      const oldData = state.applicants; // Existing data in state
    
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem._id);
        if (matchingNewItem) {
          const oldNote = oldItem?.note || []; // Existing notes or an empty array
          const updatedNote = matchingNewItem || {}; // Get the new note (or an empty object if not present)
          
          return { 
            ...oldItem, 
            note: [...oldNote, updatedNote] // Combine old and new notes
          };
        }
        return oldItem; // Return the old item if no match
      });
    
     
      return {
        ...state,
        applicants: updatedData, // Preserve original records, only update notes
      };
    }
    case SUCCESS_APPLICANT_REFERENCE_JOB_POSTING: {
      const newData = action.payload;
      const oldData = state.jobPostings;
    
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem.jobPostId === oldItem._id);
    
        if (matchingNewItem) {
          const oldNotes = oldItem?.notes || []; // Existing notes or an empty array
          const newNote = matchingNewItem || {}; // Get the new note (or an empty object if not present)
    
          // Check if the new note with the same 'addedBy', 'addedOn', 'note', and 'source' already exists
          const isNoteExists = oldNotes.some((note) => 
            note.addedBy === newNote.addedBy &&
            note.addedOn === newNote.addedOn &&
            note.note === newNote.note &&
            note.source === newNote.source
          );
    
          // If the note doesn't exist, add it
          if (!isNoteExists) {
            return {
              ...oldItem,
              notes: [...oldNotes, newNote] // Combine old and new notes
            };
          }
        }
        return oldItem; // Return the old item if no match or if the note already exists
      });
    
      return {
        ...state,
        jobPostings: updatedData, // Preserve original records, only update notes
      };
    }
    case SUCCESS_ADD_MIGRATED_APPLICANTS: {
      const newData = action.payload; // Incoming updated data
      const oldData = state.applicants; // Existing data in state
      const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old]));
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem.applicantId);
        return matchingNewItem ? { ...oldItem, ...matchingNewItem } : oldItem; // Update if matched, else keep old
      });
      const unmatchedNewData = newData.filter(
        (newItem) => !oldDataMap.has(newItem.applicantId)
      );
      return {
        ...state,
        applicants: [...updatedData, ...unmatchedNewData], // Preserve original records, update matches, and add new
      };
    }
    case GLOBAL_SEARCH_JOB_POSTINGS_DATA:{
      const newData = action.payload; // Incoming updated data
      const oldData = state.jobPostings; // Existing data in state
      const oldDataMap = new Map(oldData.map((old) => [old._id, old]));
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem._id === oldItem._id);
        return matchingNewItem ? { ...oldItem, ...matchingNewItem } : oldItem; // Update if matched, else keep old
      });
      const unmatchedNewData = newData.filter(
        (newItem) => !oldDataMap.has(newItem._id)
      );
      return {
        ...state,
        jobPostings: [...updatedData, ...unmatchedNewData], // Preserve original records, update matches, and add new
      };
    }
    case GLOBAL_SEARCH_APPLICANTS_PAGINATION:{
      const newData = action.payload; // Incoming updated data
      const oldData = state.applicants; // Existing data in state
      const oldDataMap = new Map(oldData.map((old) => [old._id, old]));
      const updatedData = newData.map((newItem) => {
          if (oldDataMap.has(newItem._id)) {
              return newItem;
          }
          return newItem;
      });
      const unmatchedOldData = oldData.filter(
          (oldItem) => !newData.some((newItem) => newItem._id === oldItem._id)
      );
  const mergedData = [...updatedData, ...unmatchedOldData];
  console.log('mergedData',mergedData);
      return{
        ...state,
        applicants:mergedData,
        
      }
    }
    
    
    

      case DELETE_RECRUITER: {
        
        return INIT_STATE;
      }
      default:
        return state;
    }
  };
  
  export default recruiters;
  