// import React, { useState } from "react";
// import * as XLSX from "xlsx";
// import Papa from "papaparse";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { Box, IconButton } from "@mui/material";
// import MyCompaniesListExcelView from "./Modals/MyCompaniesListExcelView";
// import { closeSnackbar } from "notistack";
// import CloseIcon from '@mui/icons-material/Close';
// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const FileUpload = ({
//   totalCompaniesList,
//   setTotalCompaniesList,
//   storeDataId,
//   handleOPenExcel,
//   enqueueSnackbar,
//   client,
// }) => {
//   const [previewData, setPreviewData] = useState([]);
//   // const sanitizeString = (str) =>str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
//   const sanitizeString = (str) => {
//     if (typeof str !== 'string') {
//         return ''; // Return an empty string for non-string inputs
//     }
//     return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
// };




//   const handleFileUpload = (event, setData) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       const binaryStr = e.target.result;
//       const workbook = XLSX.read(binaryStr, { type: "binary" });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
//       const jsonData = XLSX.utils.sheet_to_json(sheet);
//       // Limit the number of records processed
//       const limitedJsonData = jsonData.slice(0, 1000);

//       if (jsonData.length > 1000) {
//          enqueueSnackbar(
//           "Only the first 1000 records are processed. The rest are ignored.",
//           { variant: "warning",action: (key) => (
//             <IconButton  onClick={()=>closeSnackbar()} color="inherit">
//               <CloseIcon />
//             </IconButton>
//           ) }
//         );
//       }
      
        

//       if (limitedJsonData.length > 0) {
//         const updatedCompaniesList = totalCompaniesList.map((company) => {
//           // Create a copy of the company object
//           const updatedCompany = { ...company };
      
//           if (!updatedCompany.contactDetails) {
//             updatedCompany.contactDetails = [];
//           }
//           const existingEmails = new Set(updatedCompany.contactDetails.map((contact) => contact.emailId.toLowerCase()));
//           // Check if contactDetails length is less than 5
//           if (updatedCompany.contactDetails.length < 5) {
//             limitedJsonData.forEach((entry) => {
//               const companyName = entry["Company Name"];
//               const email = entry["Email 1"];
//               if (sanitizeString(companyName) === sanitizeString(updatedCompany.companyName)&&email&&!existingEmails.has(entry["Email 1"].toLowerCase())) {
//                 const contactDetail = {
//                   addedBy: storeDataId,
//                   firstName: entry["First Name"] ? entry["First Name"].trim() : '',
//                   lastName: entry["Last Name"] ? entry["Last Name"].trim() : '',
//                   designation: entry["Title"] ? entry["Title"].trim() : '',
//                   phoneNumber: entry["Contact Phone"] ? entry["Contact Phone"].trim() : '',
//                   emailId: email.trim(),
//                   contactAddedOn: new Date().toISOString(),
//                 };
//                 // Add new contact detail if less than 5
//                 if (updatedCompany.contactDetails.length < 5) {
//                   updatedCompany.contactDetails.push(contactDetail);
//                   existingEmails.add(entry["Email 1"].toLowerCase());
//                 }
//               }
//             });
//           }
//           return updatedCompany;
//         });
      
//         setPreviewData(updatedCompaniesList);
        
//       }
      
//     };

//     reader.readAsBinaryString(file);
//   };

//   const handleCSVUpload = (event, setData) => {
//     const file = event.target.files[0];
//     Papa.parse(file, {
//       header: true,
//       complete: (results) => {
//         const jsonData = results.data;
//         const limitedJsonData = jsonData.slice(0, 1000);

//         if (jsonData.length > 1000) {
//            enqueueSnackbar(
//             "Only the first 1000 records are processed. The rest are ignored.",
//             { variant: "warning",action: (key) => (
//               <IconButton  onClick={()=>closeSnackbar()} color="inherit">
//                 <CloseIcon />
//               </IconButton>
//             ) }
//           );
//         }
        
//         if (limitedJsonData.length > 0) {
//           const updatedCompaniesList = totalCompaniesList.map((company) => {
//             // Create a copy of the company object
//             const updatedCompany = { ...company };
        
//             if (!updatedCompany.contactDetails) {
//               updatedCompany.contactDetails = [];
//             }
//             const existingEmails = new Set(updatedCompany.contactDetails.map((contact) => contact.emailId.toLowerCase()));
//             // Check if contactDetails length is less than 5
//             if (updatedCompany.contactDetails.length < 5) {
//               limitedJsonData.forEach((entry) => {
//                 const companyName = entry["Company Name"];
//                 const email = entry["Email 1"];
//                 if (sanitizeString(companyName) === sanitizeString(updatedCompany.companyName)&&email&&!existingEmails.has(entry["Email 1"].toLowerCase())) {
//                   const contactDetail = {
//                     addedBy: storeDataId,
                   
//                     firstName: entry["First Name"] ? entry["First Name"].trim() : '',
//                     lastName: entry["Last Name"] ? entry["Last Name"].trim() : '',
//                     designation: entry["Title"] ? entry["Title"].trim() : '',
//                     phoneNumber: entry["Contact Phone"] ? entry["Contact Phone"].trim() : '',
//                     emailId: email.trim(),
//                     contactAddedOn: new Date().toISOString(),
//                   };
//                   // Add new contact detail if less than 5
//                   if (updatedCompany.contactDetails.length < 5) {
//                     updatedCompany.contactDetails.push(contactDetail);
//                     existingEmails.add(entry["Email 1"].toLowerCase());
//                   }
//                 }
//               });
//             }
//             return updatedCompany;
//           });
        
//           setPreviewData(updatedCompaniesList);
//         }
//       },
//     });
//   };

//   return (
//     <div>
     
//       <Box mb={2} sx={{display:'flex', gap:2}}>
//         <Button
//           component="label"
//           variant="contained"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload Excel File
//           <VisuallyHiddenInput
//             type="file"
//             accept=".xlsx"
//             onChange={(e) => handleFileUpload(e, previewData)}
//           />
//         </Button>
//         <Button
//           component="label"
//           variant="contained"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload CSV File
//           <VisuallyHiddenInput
//             type="file"
//             accept=".csv"
//             onChange={(e) => handleCSVUpload(e, previewData)}
//           />
//         </Button>
//       </Box>
//       <Box display="flex" justifyContent="center" mb={2}></Box>
//       {previewData.length > 0 && (
//         <MyCompaniesListExcelView
//           previewData={previewData}
//           handleOPenExcel={handleOPenExcel}
//           enqueueSnackbar={enqueueSnackbar}
//           setTotalCompaniesList={setTotalCompaniesList}
//           client={client}
//         />
//       )}
//     </div>
//   );
// };

// export default FileUpload;



import React, { useState } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, IconButton } from "@mui/material";
import MyCompaniesListExcelView from "./Modals/MyCompaniesListExcelView";
import { closeSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUpload = ({
  totalCompaniesList,
  setTotalCompaniesList,
  storeDataId,
  handleOPenExcel,
  enqueueSnackbar,
  client,
  setEmailError,
  emailError,
}) => {
  const [previewData, setPreviewData] = useState([]);

  // const sanitizeString = (str) =>str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const sanitizeString = (str) => {
    if (typeof str !== "string") {
      return ""; // Return an empty string for non-string inputs
    }
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^(?!.*@gmail\.com$)(?!.*\bgmail\b)([^@]+)@([^@]+\.[^@]+)$/;
    return emailRegex.test(email);
  };

  const handleFileUpload = (event, setData) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      // Limit the number of records processed
      const limitedJsonData = jsonData.slice(0, 1000);

      if (jsonData.length > 1000) {
        enqueueSnackbar(
          "Only the first 1000 records are processed. The rest are ignored.",
          {
            variant: "warning",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }

      if (limitedJsonData.length > 0) {
        const updatedCompaniesList = totalCompaniesList.map((company) => {
          // Create a copy of the company object
          const updatedCompany = { ...company };

          if (!updatedCompany.contactDetails) {
            updatedCompany.contactDetails = [];
          }
          const existingEmails = new Set(
            updatedCompany.contactDetails.map((contact) =>
              contact.emailId.toLowerCase()
            )
          );

          // Check if contactDetails length is less than 5
          if (updatedCompany.contactDetails.length < 5) {
            limitedJsonData.forEach((entry) => {
              const companyName = entry["Company Name"];
              const email = entry["Email 1"];
              const email2 = entry["Email 2"];

              if (
                sanitizeString(companyName) ===
                sanitizeString(updatedCompany.companyName)
              ) {
                if (email) {
                  if (!validateEmail(email)) {
                    setEmailError(true);
                    enqueueSnackbar(`Invalid email: ${email}`, {
                      variant: "error",
                      action: (key) => (
                        <IconButton
                          onClick={() => closeSnackbar(key)}
                          color="inherit"
                        >
                          <CloseIcon />
                        </IconButton>
                      ),
                    });
                  }
                }

                if (email2) {
                  if (!validateEmail(email2)) {
                    setEmailError(true);
                    enqueueSnackbar(`Invalid email: ${email2}`, {
                      variant: "error",
                      action: (key) => (
                        <IconButton
                          onClick={() => closeSnackbar(key)}
                          color="inherit"
                        >
                          <CloseIcon />
                        </IconButton>
                      ),
                    });
                  }
                }
              }

              if (
                sanitizeString(companyName) ===
                  sanitizeString(updatedCompany.companyName) &&
                email &&
                !existingEmails.has(entry["Email 1"].toLowerCase())
              ) {
                if (validateEmail(email) || validateEmail(email2) || email || email2) {
                  const contactDetail = {
                    addedBy: storeDataId,

                    firstName: entry["First Name"]
                      ? entry["First Name"].trim()
                      : "",
                    lastName: entry["Last Name"]
                      ? entry["Last Name"].trim()
                      : "",
                    designation: entry["Title"] ? entry["Title"].trim() : "",
                    // phoneNumber: entry["Contact Phone"]
                    //   ? entry["Contact Phone"]?.trim()
                    //   : "",
                    phoneNumber :entry["Contact Phone"]
  ? typeof entry["Contact Phone"] === 'string' 
    ? entry["Contact Phone"].trim() 
    : "" 
  : "",
                    emailId: email.trim(),
                    contactAddedOn: new Date().toISOString(),
                  };

                  if (updatedCompany.contactDetails.length < 5) {
                    updatedCompany.contactDetails.push(contactDetail);
                    existingEmails.add(entry["Email 1"].toLowerCase());
                  }
                }
              }
            });
          }
          return updatedCompany;
        });

        setPreviewData(updatedCompaniesList);
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleCSVUpload = (event, setData) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const jsonData = results.data;
        const limitedJsonData = jsonData.slice(0, 1000);

        if (jsonData.length > 1000) {
          enqueueSnackbar(
            "Only the first 1000 records are processed. The rest are ignored.",
            {
              variant: "warning",
              action: (key) => (
                <IconButton onClick={() => closeSnackbar()} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
            }
          );
        }

        if (limitedJsonData.length > 0) {
          const updatedCompaniesList = totalCompaniesList.map((company) => {
            // Create a copy of the company object
            const updatedCompany = { ...company };

            if (!updatedCompany.contactDetails) {
              updatedCompany.contactDetails = [];
            }
            const existingEmails = new Set(
              updatedCompany.contactDetails.map((contact) =>
                contact.emailId.toLowerCase()
              )
            );

            if (updatedCompany.contactDetails.length < 5) {
              limitedJsonData.forEach((entry) => {
                
                const companyName = entry["Company Name"];
                const email = entry["Email 1"];
                const email2 = entry["Email 2"];

                if (
                  sanitizeString(companyName) ===
                  sanitizeString(updatedCompany.companyName)
                ) {
                  if (email) {
                    if (!validateEmail(email)) {
                      setEmailError(true);
                      enqueueSnackbar(`Invalid email: ${email}`, {
                        variant: "error",
                        action: (key) => (
                          <IconButton
                            onClick={() => closeSnackbar(key)}
                            color="inherit"
                          >
                            <CloseIcon />
                          </IconButton>
                        ),
                      });
                    }
                  }

                  if (email2) {
                    if (!validateEmail(email2)) {
                      setEmailError(true);
                      enqueueSnackbar(`Invalid email: ${email2}`, {
                        variant: "error",
                        action: (key) => (
                          <IconButton
                            onClick={() => closeSnackbar(key)}
                            color="inherit"
                          >
                            <CloseIcon />
                          </IconButton>
                        ),
                      });
                    }
                  }
                }

                // Check if company name matches and email is not already existing
                if (
                  sanitizeString(companyName) ===
                    sanitizeString(updatedCompany.companyName) &&
                  !existingEmails.has(email.toLowerCase())
                ) {
                  if (validateEmail(email) || validateEmail(email2) || email || email2) {
                    const contactDetail = {
                      addedBy: storeDataId,
                      firstName: entry["First Name"]
                        ? entry["First Name"]?.trim()
                        : "",
                      lastName: entry["Last Name"]
                        ? entry["Last Name"].trim()
                        : "",
                      designation: entry["Title"] ? entry["Title"].trim() : "",
                      // phoneNumber: entry["Contact Phone"]
                      //   ? entry["Contact Phone"].trim()
                      //   : "",
                       phoneNumber :entry["Contact Phone"]
  ? typeof entry["Contact Phone"] === 'string' 
    ? entry["Contact Phone"].trim() 
    : "" 
  : "",
                      emailId: email.trim(),
                      contactAddedOn: new Date().toISOString(),
                    };

                    if (updatedCompany.contactDetails.length < 5) {
                      updatedCompany.contactDetails.push(contactDetail);
                      existingEmails.add(email.toLowerCase());
                    }
                  }
                }
              });
            }

            return updatedCompany;
          });

          setPreviewData(updatedCompaniesList);
        }
      },
    });
  };

  return (
    <div>
      <Box mb={2} sx={{ display: "flex", gap: 2 }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload Excel File
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx"
            onChange={(e) => handleFileUpload(e, previewData)}
          />
        </Button>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload CSV File
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={(e) => handleCSVUpload(e, previewData)}
          />
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}></Box>
      {previewData.length > 0 && (
        <MyCompaniesListExcelView
          previewData={previewData}
          handleOPenExcel={handleOPenExcel}
          enqueueSnackbar={enqueueSnackbar}
          setTotalCompaniesList={setTotalCompaniesList}
          client={client}
          setEmailError={setEmailError}
          emailError={emailError}
        />
      )}
    </div>
  );
};

export default FileUpload;