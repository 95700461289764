import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  CopyToClipboardButton,
  CustomFormField,
  JobBoardMigrationModal,
  JobBoardResultTable,
  JobBoardsAddNotes,
  JobBoardsQuickSubmitCheckAutharize,
  JobBoardsQuickSubmitConfirmModal,
  JobBoardsQuickSubmitSelectJob,
  KeyData,
  MainBackground,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import JobBoardMigration from "../../Components/JobBoardsResult/JobBoardMigration";
import {
  addApplicantJobPostReferenceViaApi,
  addApplicantNoteViaApi,
  awsResumeUrl,
  getAllDegreesViaApi,
  getAllTemplatesViaApi,
  getAllUsers,
  getIntegrationsViaApi,
  getMonsterResumeAndStoreInternalDbViaApi,
  getResumeAndStoreInternalDbViaApi,
  integrateCBAdvancedResumesSearchViaApi,
  integrateInternalDBSearchViaApi,
  integrateMonsterAdvancedResumesSearchViaApi,
  searchDateJobPostingDataViaApi,
  searchResumeIdInternalDbViaApi,
  stopLoadingRedux,
  successAwsResumeUrl,
  successHtmlResumeConvert,
  successSearchResumeIdInternalDb,
  tagApplicantToJobViaApi,
} from "../../store/actions";
import { ShimmerText } from "react-shimmer-effects";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import GroupsIcon from "@mui/icons-material/Groups";
import ShareIcon from "@mui/icons-material/Share";
import LabelIcon from "@mui/icons-material/Label";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import theme from "../../Theme";
import Highlighter from "react-highlight-words";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  optionsMonsterPostFrom,
  optionsMonsterTo,
  noteActionTexts,
} from "../../Data/JobPostingData";
import CommanFilters from "../../utils/CommanFilters";
const JobBoardsIntegrateResult = () => {
  const { getResumes, getYearsfromMonths,fixHtml } = CommanFilters;
  const apiUrl = process.env.REACT_APP_API_URL;
  const locationData = useLocation();
  const actionData = locationData.state;
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
  const internalDBMatchedResumesRedux = useSelector(
    (state) => state.JobBoards.internalDBMatchedResumes
  );

  const internalDBMatchedResumes = Array.isArray(internalDBMatchedResumesRedux)
    ? internalDBMatchedResumesRedux
    : [];

  const [keyWords, setKeyWords] = useState(actionData?.keyWords || "");
  const [sortFilter, setSortFilter] = useState("rank,distance,mdate");
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const degrees = useSelector((state) => state.Recruiters?.degrees);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const resumesFile = useSelector((state) => state.Resumes.resumesFile);
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const HtmlResume = useSelector((state) => state.Recruiters?.htmlResume);
  const integrationsList = useSelector((state) => state.Resumes.integrations);
  const client = storeData.clientDb;
  const navigate = useNavigate();
  const CbResumesList = useSelector(
    (state) => state.Resumes.integrateCBResumesList
  );
  const MonsterResumesList = useSelector(
    (state) => state.Resumes.integrateMonsterResumesList
  );
  const InternalResumesList = useSelector(
    (state) => state.Resumes.integrateInternalResumesList
  );
  const degreesNames = Array.isArray(degrees)
    ? degrees.map((item) => item.degreeName)
    : [];
  const internalDBCandidateList = Array.isArray(InternalResumesList)
    ? InternalResumesList.map((item, index) => ({
        id: index,
        ...item,
      }))
    : [];
  let matchedMonsterResult = 0;
  function parseMonsterCandidatedData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      MonsterResumesList,
      "application/xml"
    );
    const resumes = xmlDoc.getElementsByTagName("Resume");

    const candidates = Array.from(resumes).map((resume, index) => {
      const firstName = resume.querySelector("First")?.textContent || "";
      const lastName = resume.querySelector("Last")?.textContent || "";
      const middleName = resume.querySelector("Middle")?.textContent || "";
      const email =
        resume.querySelector("MD5-EmailAddress")?.textContent || "N/A";
      const board = resume.querySelector("Board");
      const city = resume.querySelector("City")?.textContent || "N/A";

      // Query Location inside each resume
      const locationElement = resume.querySelector("Location");
      let country = "N/A",
        state = "N/A",
        cityFromLocation = "N/A";
      if (locationElement) {
        const location = locationElement.textContent || "";
        [country, state, cityFromLocation] = location.split("-");
      }

      const relocation =
        resume.querySelector("Relocation")?.textContent || "N/A";
      const title = resume.querySelector("Title")?.textContent || "N/A";
      const description =
        resume.querySelector("Description")?.textContent || "N/A";
      const dateModified =
        resume.querySelector("DateModified")?.textContent || "N/A";
      const companyName =
        resume.querySelector("Company > Name")?.textContent || "N/A";

      // JobTypes
      const jobTypeElements = resume.getElementsByTagName("JobType");
      const jobTypes = Array.from(jobTypeElements).map(
        (jobType) => jobType.textContent
      );
      const desiredJobTypes = `${jobTypes.join(", ")}`;

      // Experience Calculation
      const experienceFromDate = resume.querySelector("Experience > DateFrom");
      const experienceToDate = resume.querySelector("Experience > DateTo");
      const educationElements = resume.getElementsByTagName("Education");

      const educationLevels = Array.from(educationElements)
        .map((education) => {
          const level = education.querySelector("Level");
          return level ? level.textContent : null;
        })
        .filter((level) => level !== null);

      const salaryElement = resume.querySelector("Salary");
      const minSalary = salaryElement?.querySelector("Min")?.textContent || "0";
      const maxSalary = salaryElement?.querySelector("Max")?.textContent || "0";
      const currency =
        salaryElement?.querySelector("Currency")?.textContent || "N/A";
      const type = salaryElement?.querySelector("Type")?.textContent || "N/A";

      // Calculate experience
      let years = 0,
        months = 0;
      if (experienceFromDate && experienceToDate) {
        const yearFrom = parseInt(experienceFromDate.getAttribute("Year"), 10);
        const monthFrom = parseInt(
          experienceFromDate.getAttribute("Month"),
          10
        );
        const yearTo = parseInt(experienceToDate.getAttribute("Year"), 10);
        const monthTo = parseInt(experienceToDate.getAttribute("Month"), 10);

        years = yearTo - yearFrom;
        months = monthTo - monthFrom;

        if (months < 0) {
          years--;
          months += 12;
        }
      }
      const isConfidential =
        resume.querySelector("Confidential")?.textContent === "1";
      const name = isConfidential
        ? "Confidential"
        : `${firstName || ""} ${middleName || ""} ${lastName || ""}`;
      const resumesNode = xmlDoc.getElementsByTagName("Resumes")[0];
      matchedMonsterResult = resumesNode.getAttribute("Found");

      // Extracting military experience data
      const militaryServiceFlag =
        resume.querySelector("MilitaryExperience > ServiceFlag")?.textContent ||
        "";
      const militaryInvolvement =
        resume.querySelector("MilitaryExperience > MilitaryInvolvement")
          ?.textContent || "";
      const militaryExperienceData = {
        ServiceFlag: militaryServiceFlag,
        MilitaryInvolvement: militaryInvolvement,
      };
      const resumeTitle =
        resume.querySelector("ResumeTitle")?.textContent || "";
      const experienceJobTitle =
        resume.querySelector("Experience Job Title")?.textContent || "";
      const experienceJobDescription =
        resume.querySelector("Experience Job Description")?.textContent || "";
      const experienceDateFrom =
        resume.querySelector("Experience DateFrom")?.getAttribute("Year") || "";
      const experienceDateTo =
        resume.querySelector("Experience DateTo")?.textContent || "";
      const experienceData = {
        Company: companyName,
        JobTitle: experienceJobTitle,
        JobDescription: experienceJobDescription,
        DateFrom: experienceDateFrom,
        DateTo: experienceDateTo,
      };

      return {
        id: index,
        ResumeId: resume.getAttribute("SID") || "N/A",
        ResumeBoardId: board?.getAttribute("ID") || "N/A",
        Name: name,
        Source: board?.textContent || "N/A",
        Email: email,
        Location: {
          country: country || "N/A",
          state: state || "N/A",
          city: cityFromLocation || "N/A",
        },
        Relocation: relocation,
        JobTitle: title,
        Description: description,
        ResumeUpdated: dateModified,
        DesiredJobType: desiredJobTypes,
        HighestEducation:
          educationLevels.length > 0 ? educationLevels[0] : "N/A",
        DesiredSalary: `${minSalary}-${maxSalary} ${currency}/${type}`,
        ExperienceYears: isNaN(years) ? 0 : years,
        ExperienceMonths: isNaN(months) ? 0 : months,
        companyName: companyName,
        experienceData: experienceData,
        resumeTitle: resumeTitle,
        militaryExperienceData: militaryExperienceData,
        middleName: middleName,
      };
    });
    
    return candidates;
  }

  // Call the function to parse candidate data
  const monsterCandidateList = parseMonsterCandidatedData();
  //CareerBuilder
  let matchedCBResult = 0;
  function parseCBCandidateData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      CbResumesList.V2_AdvancedResumeSearchResult,
      "application/xml"
    );
    const candidates = Array.from(
      xmlDoc.getElementsByTagName("ResumeResultItem_V3")
    );
    matchedCBResult = xmlDoc.getElementsByTagName("Hits")[0]?.textContent;

    return candidates.map((item, index) => {
      return {
        id: index, // Assuming id is simply the index + 1
        Name: item.getElementsByTagName("ContactName")[0].textContent || "N/A",
        Email:
          item.getElementsByTagName("ContactEmailMD5")[0].textContent || "N/A",
        phone: item.getElementsByTagName("RecentPay")[0].textContent || "N/A", // Replace with the actual phone field if available
        ResumeId: item.getElementsByTagName("ResumeID")[0].textContent || "N/A",
        ContactEmail:
          item.getElementsByTagName("ContactEmail")[0].textContent || "N/A",
        HomeLocation:
          item.getElementsByTagName("HomeLocation")[0].textContent || "N/A",
        UserDID: item.getElementsByTagName("UserDID")[0].textContent || "N/A",
        SFNmc: item.getElementsByTagName("SFNmc")[0].textContent || "N/A",
        source: "Career Builder",
        HasWordDoc:
          item.getElementsByTagName("HasWordDoc")[0].textContent || "N/A",
        title: item.getElementsByTagName("ResumeTitle")[0].textContent || "N/A",
        RecentJobTitle:
          item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        highestEducation:
          item.getElementsByTagName("HighestDegree")[0].textContent || "N/A",
        MonthsOfExperience:
          item.getElementsByTagName("MonthsOfExperience")[0].textContent ||
          "N/A",
        RecentEmployer:
          item.getElementsByTagName("RecentEmployer")[0].textContent || "N/A",
        lastUpdated:
          item.getElementsByTagName("LastUpdate")[0].textContent || "N/A",
        JobTitle: item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        resume: {
          LastActivity:
            item.getElementsByTagName("LastActivity")[0].textContent || "N/A",
          JobTitle:
            item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
          authorization: "US", // Assuming US as default
        },
        location: {
          city:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[2] || "N/A",
          state:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[1] || "N/A",
          country: "US", // Assuming US as default
        },
      };
    });
  }
  const cbCandidateList = parseCBCandidateData();
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [searchType, setSearchType] = useState("");

  const [openResultTabId, setOpenResultTabId] = useState("1");
  const handleResultTabChange = (event, newValue) => {
    setOpenResultTabId(newValue);
    setSelectedRows([]);
    const type =
      newValue === "1"
        ? "InternalDB"
        : newValue === "2"
        ? "CareerBuilder"
        : "Monster";
    setSearchType(type);
  };
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [revertHtml,setRevertHtml]=useState(false)
  const [dataList, setDataList] = useState([]);
  const handleClose = () => "";
  // Handle checkbox change (toggle selection state)
  const candidateList = searchType === "CareerBuilder" ? cbCandidateList : monsterCandidateList;
  const filterAlreadyMigratedProfiles =  candidateList.filter((data) =>
    internalDBMatchedResumes.some((dataMatch) => String(dataMatch.resumeId) === String(data.ResumeId))
  );
  const filterSelectedCandidateMigrated=internalDBMatchedResumes.filter((data) =>
    selectedRows.some((dataMatch) => String(dataMatch.ResumeId) === String(data.resumeId))
  );


  const handleCheckboxChange = (event, row, type) => {
    if (type === "Monster"||type === "CareerBuilder") {
      // const newSelectedRows = event.target.checked
      //   ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Check if the applicantId is not already selected
      //     ? [...selectedRows, row] // Add the row if it's not already selected
      //     : selectedRows // If already selected, keep it as is
      //   : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove the row if unchecked
      const filterPreviousData = selectedRows.filter((data) =>
        internalDBMatchedResumes.some(
          (dataMatch) => String(dataMatch.resumeId) === String(data.ResumeId)
        )
      );
      const findPresentData = internalDBMatchedResumes.find(
        (data) => String(data.resumeId) === String(row.ResumeId)
      );
  
      if (filterPreviousData.length === 0) {
        if (selectedRows.length === 0) {
          setSelectedRows([row]); // Set the selected rows state with the current row
        } else if (!findPresentData) {
          const newSelectedRows = event.target.checked
            ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Add if not already selected
              ? [...selectedRows, row]
              : selectedRows // Keep as is if already selected
            : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove if unchecked
          setSelectedRows(newSelectedRows); // Update the selected rows state
        }
      } else {
        if (!findPresentData) {
          setSelectedRows([row]); // Replace selected rows with the current row
        } else {
          const newSelectedRows = event.target.checked
            ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Add if not already selected
              ? [...selectedRows, row]
              : selectedRows // Keep as is if already selected
            : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove if unchecked
          setSelectedRows(newSelectedRows); // Update the selected rows state
        }
      }
    // } else if (type === "CareerBuilder") {
    //   const newSelectedRows = event.target.checked
    //     ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Check if the applicantId is not already selected
    //       ? [...selectedRows, row] // Add the row if it's not already selected
    //       : selectedRows // If already selected, keep it as is
    //     : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove the row if unchecked
    //   setSelectedRows(newSelectedRows); // Update the selected rows state
    } else if (type === "InternalDB") {
      const newSelectedRows = event.target.checked
        ? !selectedRows.some((r) => r.applicantId === row.applicantId) // Check if the applicantId is not already selected
          ? [...selectedRows, row] // Add the row if it's not already selected
          : selectedRows // If already selected, keep it as is
        : selectedRows.filter((r) => r.applicantId !== row.applicantId); // Remove the row if unchecked
      setSelectedRows(newSelectedRows); // Update the selected rows state
    }
  };
  const handleSelect = (key, type) => {
    setSearchType("");
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    if (type === "Monster") {
      setSearchType("Monster");
      setDataList(monsterCandidateList);
      const resumes =
        internalDBMatchedResumes.find(
          (internal) =>
            internal.resumeId === monsterCandidateList[key]?.ResumeId
        )?.resumes || [];
      const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
      const serverLocation =
        resumes?.[resumes.length - 1]?.serverLocation || "";
      if (serverLocation) {
        const action = {
          key: serverLocation,
        };
        dispatch(awsResumeUrl(action));
      }
    } else if (type === "CareerBuilder") {
      setDataList(cbCandidateList);
      setSearchType("CareerBuilder");
      const resumes =
        internalDBMatchedResumes.find(
          (internal) => internal.resumeId === cbCandidateList[key]?.ResumeId
        )?.resumes || [];
      const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
      const serverLocation =
        resumes?.[resumes.length - 1]?.serverLocation || "";

      if (serverLocation) {
        const action = {
          key: serverLocation,
        };
        dispatch(awsResumeUrl(action));
      }
    } else if (type === "InternalDB") {
      setSearchType("InternalDB");
      const resume =
        internalDBCandidateList[key]?.resumes?.[
          internalDBCandidateList[key]?.resumes.length - 1
        ];
      if (resume?.serverLocation) {
        const action = { key: resume.serverLocation };
        dispatch(awsResumeUrl(action));
      }
    }
    setSelectedCandidate(key);
    setDrawerOpen(true);
    setRevertHtml(false);
  };
  const closeDrawer = () => {
    setSelectedCandidate(null);
    setDrawerOpen(false);
    setRevertHtml(false);
    setDataList([]);
  };
  const [openTabId, setOpenTabId] = useState("1");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
  };
  const handleGetResume = () => {
    if (searchType === "Monster") {
      let totalAvailable = 0;
      let monsterAccountId = "";
      if (
        storeData.userType.includes("SuperAdmin") ||
        storeData.userType.includes("Developer")
      ) {
        if (Array.isArray(integrationsList)) {
          integrationsList.some((accountApi) => {
            totalAvailable = accountApi.totalAvailable; // Assuming totalAvailable is a single value you want
            monsterAccountId = accountApi._id;
            return totalAvailable > 0; // Exit early if totalAvailable is found
          });
        }
      } else {
        let allocatedCredits = 0;
        let usedCredits = 0;

        if (Array.isArray(integrationsList)) {
          integrationsList.forEach((accountApi) => {
            if (Array.isArray(accountApi.assignedTo)) {
              accountApi.assignedTo.forEach((account) => {
                if (account.accountId === storeData._id) {
                  allocatedCredits += account.allocatedCredits || 0; // Safely handle undefined values
                  usedCredits += account.usedCredits || 0; // Safely handle undefined values
                  totalAvailable = accountApi.totalAvailable; // Assume this is the correct place to get totalAvailable
                  monsterAccountId = accountApi._id;
                }
              });
            }
          });
        }
        if (allocatedCredits === 0) {
          enqueueSnackbar(
            `Your Monster account credits are not assigned please contact Admin!`,
            {
              variant: "error",
              action: (key) => (
                <IconButton onClick={() => closeSnackbar()} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
            }
          );
          return "";
        }
        if (allocatedCredits <= usedCredits) {
          enqueueSnackbar(`No Credits! please contact your manager`, {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          });
          return "";
        }
      }
      if (totalAvailable === 0) {
        enqueueSnackbar(`Your Monster Account Credits have reached zero.`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        return "";
      }

      const selectCandidateData = dataList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData.Name.split(" ");
      const firstName = fullName[0] || selectCandidateData.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData.Name; // In case the last name has multiple parts
      const middlename =
        fullName.slice(2).join(" ") || selectCandidateData.Name;
      const country = selectCandidateData.WorkAuths?.WorkAuth?.Country || "";
      const authType = selectCandidateData.WorkAuths?.WorkAuth?.AuthType || "";
      const workAuth = `${country} ${authType}`;

      const resumeTitle = selectCandidateData.ResumeTitle || "";
      const militaryExperience = {
        serviceFlag:
          selectCandidateData.PersonalData?.MilitaryExperience?.ServiceFlag ||
          "0",
        militaryInvolvement:
          selectCandidateData.PersonalData?.MilitaryExperience
            ?.MilitaryInvolvement || "0",
      };
      const experiences =
        selectCandidateData.Experiences?.Experience?.map((exp) => ({
          companyName: exp.Company?.Name || "",
          jobTitle: exp.Job?.Title || "",
          description: exp.Job?.Description || "",
          dateFrom: exp.DateFrom
            ? `${exp.DateFrom.Year}-${exp.DateFrom.Month || "01"}`
            : "",
          dateTo: exp.DateTo
            ? `${exp.DateTo.Year}-${exp.DateTo.Month || "01"}`
            : "Present",
        })) || [];
      const action = {
        client: client,
        resumeBoardId: selectCandidateData.ResumeBoardId,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        experienceMonths: selectCandidateData.ExperienceMonths, // Convert months to years
        experienceYears: selectCandidateData.ExperienceYears,
        jobTitle: selectCandidateData.JobTitle,
        source: selectCandidateData.Source,
        monsterAccountId: monsterAccountId,
        country: country,
        SFWillingToRelocate: selectCandidateData.Relocation,
        workHistory: selectCandidateData.experienceData,
        resumeTitle: selectCandidateData.resumeTitle,
        DesiredSalary: selectCandidateData.DesiredSalary,
        militaryExperience: selectCandidateData.militaryExperienceData,
      };
      dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
      // setTimeout(() => {
      //   dispatch(getIntegrationsViaApi(action));
      // }, 2000);
    } else if (searchType === "CareerBuilder") {
      const selectCandidateData = dataList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData.Name.split(" ");
      const firstName = fullName[0] || selectCandidateData.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData.Name; // In case the last name has multiple parts
      const action = {
        client: client,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        experienceMonths: selectCandidateData.MonthsOfExperience, // Convert months to years
        jobTitle: selectCandidateData.JobTitle,
        source: "CareerBuilder",
        HomeLocation: selectCandidateData.location,
        LastUpdate: selectCandidateData.lastUpdated,
        ResumeTitle: selectCandidateData.title,
        RecentEmployer: selectCandidateData.RecentEmployer,
        RecentJobTitle: selectCandidateData.resume.JobTitle,
        HighestDegree: selectCandidateData.highestEducation,
        LastActivity: selectCandidateData.resume?.LastActivity,
        UserDID: selectCandidateData.UserDID,
        SFNmc: selectCandidateData.SFNmc,
      };
      dispatch(getResumeAndStoreInternalDbViaApi(action));
    }
  };

  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));

    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  function extractKeywords(query) {
    const andClauses = query.replace(/[()]/g, "").split(/\s+and\s+/i);
    const keywords = [];
    andClauses.forEach((clause) => {
      clause.split(/\s+or\s+/i).forEach((keyword) => {
        // Remove any leading and trailing quotes from each keyword
        const cleanKeyword = keyword.trim().replace(/^["“”]|["“”]$/g, "");
        keywords.push(cleanKeyword);
      });
    });
    return keywords;
  }

  const extractedKeywords = keyWords ? extractKeywords(keyWords) : [];
  
  const handleClickRevertHtml = (data) => {
    const key = data?.resumes?.[data?.resumes?.length - 1]?.serverLocation || "";
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    if (key) { 
      const action = { key };
      dispatch(awsResumeUrl(action));
    } 
    setRevertHtml((prevState) => !prevState);
  };
  const { highlightedHtml, keywordCounts } = useMemo(() => {
    const resumes = getResumes({
      searchType,
      selectedCandidate,
      internalDBCandidateList,
      internalDBMatchedResumes,
      cbCandidateList,
      monsterCandidateList,
    });
    const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
    const content = latest || HtmlResume || "";
    let updatedHtml = revertHtml?HtmlResume:content;
    const counts = {};
    const sortedKeywords = [...extractedKeywords].sort(
      (a, b) => b.length - a.length
    );
    sortedKeywords.forEach((keyword) => {
      const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      const regex = new RegExp(`(${escapedKeyword})`, "gi");
      counts[keyword] = (content.match(regex) || []).length;
      updatedHtml = updatedHtml.replace(
        regex,
        `<span style="background-color: yellow;">$1</span>`
      );
    });
    return { highlightedHtml: updatedHtml, keywordCounts: counts };
  }, [HtmlResume, extractedKeywords, internalDBMatchedResumes]);
  const handlePageChange = (event, value, type) => {
    if (type === "Monster") {
      const action = actionData.Monster;
      action.page = value;
      action.client = client;
      action.sort = sortFilter;
      action.country = action?.rpcr ? "" : action?.country || 840;
      setPage(value);
      setLoading(true);
      dispatch(integrateMonsterAdvancedResumesSearchViaApi(action));
      setTimeout(() => {
        dispatch(getIntegrationsViaApi(action));
      }, 2000);
    } else if (type === "CareerBuilder") {
      const action = actionData.CareerBuilder;
      action.PageNumber = value;
      action.client = client;
      dispatch(integrateCBAdvancedResumesSearchViaApi(action));
      setPage(value);
      setLoading(true);
    } else if (type === "InternalDB") {
      const action = actionData.InternalDB;
      action.page = value;
      action.client = client;
      setPage(value);
      dispatch(integrateInternalDBSearchViaApi(action));
      setLoading(true);
    }
  };
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleNewWindow = (value) => {
    const resumes = value.resumes || [];
    const serverLocation = resumes?.[resumes.length - 1]?.serverLocation || "";
    if (serverLocation) {
      const action = {
        client: client,
        key: serverLocation,
      };
      dispatch(awsResumeUrl(action));
    }
    // const url = "/viewResume";
    // window.open(url, "_blank", "noopener,noreferrer");
    const url = `/viewResume?serverLocation=${encodeURIComponent(
      serverLocation
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [openQuickSubmitModal, setOpenQuickSubmitModal] = useState(false);
  const [selectCandidateQuickSubmit, setSelectCandidateQuickSubmit] = useState(
    []
  );
  const [selectJobQuickSubmit, setSelectJobQuickSubmit] = useState("");
  const [selectTagStatus, setSelectTagStatus] = useState("");
  const handleQuickClickSubmitModal = (applicant, tagStatus) => {
    setSelectTagStatus("");
    setSelectCandidateQuickSubmit("");
    setSelectJobQuickSubmit("");
    setSelectCandidateQuickSubmit([applicant]);
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
    setSelectTagStatus(tagStatus);
  };
  const handleCancelQuickSubmitModal = () => {
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    setSelectJobQuickSubmit("");
    setSelectCandidateQuickSubmit([]);
    const action = {
      client: client,
    };
    setSelectTagStatus("");
    dispatch(searchDateJobPostingDataViaApi(action));
  };
  const [openModalNotAutharized, setOpenModalNotAutharized] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const handleQuickSubmit = () => {
    const actionData = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(actionData));
    if (selectCandidateQuickSubmit === 0) {
      return enqueueSnackbar("Please select at least one candidate!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (!selectJobQuickSubmit) {
      return enqueueSnackbar("Please select a job!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleCloseConfirmModalOpen = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };
  const handleQuickSubmitConfirm = () => {
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }
    const submission = selectCandidateQuickSubmit.map((select) => ({
      addedBy: storeData._id,
      addedOn: new Date(),
      applicantId: select._id,
      applicantStatus: selectTagStatus,
    }));

    const jobSubmissionAction = {
      id: findId ? findId._id : "",
      submissions: submission,
    };

    let tagAction = [];

    selectCandidateQuickSubmit.forEach((selectedRow) => {
      const tagData = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobPostId: findId ? findId._id : "",
        applicantStatus: selectTagStatus,
      };
      const existingTagJobs = Array.isArray(selectedRow.tagJobs)
        ? selectedRow.tagJobs.filter(
            (tag) => tag.jobPostId !== tagData.jobPostId
          )
        : [];
      const updatedTagJobs = [...existingTagJobs, tagData];
      tagAction.push({
        id: selectedRow._id,
        tagJobs: updatedTagJobs,
      });
    });

    const action = {
      jobSubmissionAction,
      tagAction,
      addedBy: storeData._id,
      client: client,
    };

    setLoading(true);
    dispatch(tagApplicantToJobViaApi(action));
    setSelectJobQuickSubmit("");
    handleCancelQuickSubmitModal();
    handleCloseConfirmModalOpen();
    setTimeout(() => {
      dispatch(getAllDegreesViaApi(client));
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 2000);
  };
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
    dispatch(getAllTemplatesViaApi(action));
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [selectDestiny, setSelectDestiny] = useState("");
  const [jobPostReference, setJobPostReference] = useState("");
  const [noteAction, setNoteAction] = useState("");
  const [clickNoteApplicant, setClickNoteApplicant] = useState("");
  const [notePriority, setNotePriory] = useState("");
  const handleCloseNote = () => {
    setOpenNoteDrawer(false);
    setNote("");
    setClickNoteApplicant("");
    setSelectDestiny("");
    setJobPostReference("");
    setNoteAction("");
    setNotePriory("");
  };
  const handleClickNotes = (row, type, key = "") => {
    setClickNoteApplicant("");
    setClickNoteApplicant(row);
    setOpenNoteDrawer(!openNoteDrawer);
    setSelectDestiny(type);
  };

  const handleNote = (value) => {
    setNote(value);
  };
  // const handleNoteSubmit = () => {

  //   const newNote = {
  //     addedBy: storeData._id,
  //     addedOn: new Date(),
  //     note: note,
  //   };
  //   const existingNotes =
  //     openResultTabId === "1"
  //       ? internalDBCandidateList.find(
  //           (internal) =>
  //             internal.applicantId === clickNoteApplicant?.applicantId
  //         )?.note || []
  //       : internalDBMatchedResumes.find(
  //           (internal) =>
  //             internal.applicantId === clickNoteApplicant?.applicantId
  //         )?.note || [];
  //   const updatedNotes = [...existingNotes, newNote];
  //   const action = {
  //     id: clickNoteApplicant?._id,
  //     note: updatedNotes,
  //     client: client,
  //   };
  //   dispatch(addApplicantNoteViaApi(action));
  //   setLoading(true);
  //   setNote("");
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // };
  // Helper function to check for empty HTML content
  function isEmptyHtml(input) {
    // Remove all HTML tags and trim whitespace
    const strippedContent = input.replace(/<[^>]*>/g, "").trim();
    return strippedContent.length === 0;
  }
  const handleNoteSubmit = () => {
    if (!note || isEmptyHtml(note)) {
      return enqueueSnackbar("Please type note !", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (jobPostReference) {
      const takeJobCode = jobPostReference.value;
      const findId = JobPosting.find(
        (job) => job.jobDetails.jobCode === takeJobCode
      );
      const action = {
        client: client,
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
        notePriority: notePriority,
        noteAction: noteAction,
        jobPostReference: jobPostReference,
        applicantId: clickNoteApplicant?._id,
        jobPostId: findId?._id,
        source: "applicantReference",
      };
      dispatch(addApplicantJobPostReferenceViaApi(action));
      setLoading(true);
      setNote("");
      setTimeout(() => {
        setLoading(false);
        handleCloseNote();
      }, 3000);
    } else {
      const newNote = {
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
        notePriority: notePriority,
        noteAction: noteAction,
      };
      const existingNotes =
        searchType === "InternalDB"
          ? internalDBCandidateList.find(
              (internal) =>
                internal.applicantId === clickNoteApplicant?.applicantId
            )?.note || []
          : internalDBMatchedResumes.find(
              (internal) =>
                internal.applicantId === clickNoteApplicant?.applicantId
            )?.note || [];
      const updatedNotes = [...existingNotes, newNote];
      const action = {
        id: clickNoteApplicant?._id,
        note: updatedNotes,
        client: client,
      };
      dispatch(addApplicantNoteViaApi(action));
      setLoading(true);
      setNote("");
      setTimeout(() => {
        setLoading(false);
        handleCloseNote();
      }, 3000);
    }
  };

  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [totalResult, setTotalResult] = useState([]);
  const handleCloseMigrationModal = () => {
    setOpenMigrationModal(!openMigrationModal);
    setTotalResult([]);
  };
  // Helper to handle Monster account integration details
  const getMonsterAccountDetails = () => {
    let totalAvailable = 0;
    let monsterAccountId = "";

    if (
      storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Developer")
    ) {
      if (Array.isArray(integrationsList)) {
        integrationsList.some((accountApi) => {
          totalAvailable = accountApi.totalAvailable;
          monsterAccountId = accountApi._id;
          return totalAvailable > 0;
        });
      }
    } else {
      if (Array.isArray(integrationsList)) {
        integrationsList.forEach((accountApi) => {
          if (Array.isArray(accountApi.assignedTo)) {
            accountApi.assignedTo.forEach((account) => {
              if (account.accountId === storeData._id) {
                totalAvailable = accountApi.totalAvailable;
                monsterAccountId = accountApi._id;
              }
            });
          }
        });
      }
    }

    return { totalAvailable, monsterAccountId };
  };

  const handleOpenMigrationModal = () => {
    if (selectedRows.length === 0) {
      const typeofData =
        searchType === "Monster"
          ? monsterCandidateList
          : searchType === "CareerBuilder"
          ? cbCandidateList
          : [];
      if (typeofData.length > 0) {
        const filterSelectedData = typeofData.filter(
          (select) =>
            !internalDBMatchedResumes.some(
              (internal) => internal.resumeId === select.ResumeId
            )
        );
        setTotalResult(filterSelectedData.slice(0, 10));
        setOpenMigrationModal((prevState) => !prevState); // Toggle modal state
        return "";
      } else {
        // Handle case where dataList is empty if needed, or do nothing
        return;
      }
    }

    const filterSelectedData = selectedRows.filter(
      (select) =>
        !internalDBMatchedResumes.some(
          (internal) => internal.resumeId === select.ResumeId
        )
    );
    if (searchType === "CareerBuilder") {
      setLoading(true); // Set loading before processing
      filterSelectedData.forEach((item) => {
        const fullName = item.Name ? item.Name.split(" ") : []; // Safeguard against null/undefined
        const firstName = fullName[0] || item.Name; // Default to Name if firstName is missing
        const lastName = fullName.slice(1).join(" ") || ""; // Default to an empty string for lastName

        const action = {
          client: client,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.MonthsOfExperience, // Ensure this is properly handled
          jobTitle: item.JobTitle,
          source: "CareerBuilder",
        };
        console.log("action", action);
        dispatch(getResumeAndStoreInternalDbViaApi(action));
        // Dispatch the action for each item
        // dispatch(getResumeAndStoreInternalDbViaApi(action));
      });

      // dispatch(getResumeAndStoreInternalDbViaApi(action));
    }
    if (searchType === "Monster") {
      const { monsterAccountId } = getMonsterAccountDetails();
      setLoading(true);
      filterSelectedData.forEach((item) => {
        const fullName = item.Name.split(" ");
        const firstName = fullName[0] || item.Name;
        const lastName = fullName.slice(1).join(" ") || item.Name; // In case the last name has multiple parts
        const action = {
          client: client,
          resumeBoardId: item.ResumeBoardId,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.ExperienceMonths, // Convert months to years
          experienceYears: item.ExperienceYears,
          jobTitle: item.JobTitle,
          source: item.Source,
          monsterAccountId: monsterAccountId,
        };
        console.log("action", action);
        dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
      });
    }
  };

  const handleMigrationYES = () => {
    if (searchType === "CareerBuilder") {
      setLoading(true); // Set loading before processing
      totalResult.forEach((item) => {
        const fullName = item.Name ? item.Name.split(" ") : []; // Safeguard against null/undefined
        const firstName = fullName[0] || item.Name; // Default to Name if firstName is missing
        const lastName = fullName.slice(1).join(" ") || ""; // Default to an empty string for lastName
        const action = {
          client: client,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.MonthsOfExperience,
          jobTitle: item.JobTitle,
          source: "CareerBuilder",
        };
        console.log("Dispatching action:", action);
        dispatch(getResumeAndStoreInternalDbViaApi(action));
      });
      handleCloseMigrationModal(); // Close modal and clear results
    }
    if (searchType === "Monster") {
      const { monsterAccountId } = getMonsterAccountDetails();
      setLoading(true); // Set loading before processing
      totalResult.forEach((item) => {
        const fullName = item.Name.split(" ");
        const firstName = fullName[0] || item.Name;
        const lastName = fullName.slice(1).join(" ") || item.Name; // In case the last name has multiple parts
        const action = {
          client: client,
          resumeBoardId: item.ResumeBoardId,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.ExperienceMonths, // Convert months to years
          experienceYears: item.ExperienceYears,
          jobTitle: item.JobTitle,
          source: item.Source,
          monsterAccountId: monsterAccountId,
        };
        dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
        console.log("action", action);
      });
      // handleCloseMigrationModal(); // Close modal and clear results
    }
  };
 

  const handleDownloadResume = async (key, applicant) => {
    try {
      const parts = key.split(".");
      const firstName = applicant.firstName;
      const lastName = applicant.lastName;
      // const fileName = `mahee.${parts.pop()}`;
      const fileName = `${firstName || ""} ${lastName || ""}.${parts.pop()}`;
      const payload = {
        key: key, // S3 key to be passed to the backend
        fileName: fileName, // File name passed from frontend
        fileType: parts.pop(),
      };
      // Make the POST request to your backend API
      const response = await fetch(apiUrl + "/applicants/downloadFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to download the file");
      }
      // Trigger file download from response
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error triggering download:", error.message);
    }
  };
  const removeHtmlTags = (htmlString) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    // Set the HTML content
    tempDiv.innerHTML = htmlString;
    // Return the text content of the element
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleFilterMonster = (value) => {
    setSortFilter(value);
    const action = actionData.Monster;
    action.page = page;
    action.client = client;
    action.sort = value;
    action.country = action?.rpcr ? "" : action?.country || 840;
    setLoading(true);
    dispatch(integrateMonsterAdvancedResumesSearchViaApi(action));
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
    }, 2000);
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Boards Integrate Result"}>
          <Button
            size="small"
            color="error"
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{ textTransform: "inherit" }}
            // onClick={() => navigate(-1)}
            onClick={() => {
              const state = { tabId: "4" };
              navigate("/jobBoards", { state }); // Navigate to a specific route with state
            }}
          >
            Back
          </Button>
          <MainBackground>
            <TabContext value={openResultTabId}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleResultTabChange}
                  aria-label="Job Boards tabs"
                >
                  <Tab label="Internal DB" value="1" />
                  <Tab label={`Career Builder:-${matchedCBResult}`} value="2" />
                  <Tab label={`Monster:-${matchedMonsterResult} `} value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
                <JobBoardMigration
                  matchedMonsterResult={matchedMonsterResult}
                  matchedCBResult={matchedCBResult}
                  pageSize={pageSize}
                  searchType={"InternalDB"}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  searchTypeGrid={"IntegrateSearch"}
                  dataList={internalDBCandidateList}
                  handlePageChange={(event, value) =>
                    handlePageChange(event, value, "InternalDB")
                  }
                />
                <JobBoardResultTable
                  rows={internalDBCandidateList}
                  searchType={"InternalDB"}
                  handleSelect={(value) => handleSelect(value, "InternalDB")}
                  selectedRows={selectedRows}
                  handleCheckboxChange={handleCheckboxChange}
                  handleClickNotes={handleClickNotes}
                  resumesFile={resumesFile}
                  internalDBMatchedResumes={internalDBMatchedResumes}
                  allUsers={allUsers}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
                <JobBoardMigration
                  matchedMonsterResult={matchedMonsterResult}
                  matchedCBResult={matchedCBResult}
                  pageSize={pageSize}
                  searchType={"CareerBuilder"}
                  searchTypeGrid={"IntegrateSearch"}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  dataList={cbCandidateList}
                  handlePageChange={(event, value) =>
                    handlePageChange(event, value, "CareerBuilder")
                  }
                  handleOpenMigrationModal={handleOpenMigrationModal}
                  filterAlreadyMigratedProfiles={filterAlreadyMigratedProfiles}
                  filterSelectedCandidateMigrated={filterSelectedCandidateMigrated}
                />
                <JobBoardResultTable
                  rows={cbCandidateList}
                  searchType={"CareerBuilder"}
                  handleSelect={(value) => handleSelect(value, "CareerBuilder")}
                  selectedRows={selectedRows}
                  handleCheckboxChange={handleCheckboxChange}
                  internalDBMatchedResumes={internalDBMatchedResumes}
                  handleClickNotes={handleClickNotes}
                  allUsers={allUsers}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ p: 0, pt: 1 }}>
                <JobBoardMigration
                  matchedMonsterResult={matchedMonsterResult}
                  matchedCBResult={matchedCBResult}
                  pageSize={pageSize}
                  searchType={"Monster"}
                  searchTypeGrid={"IntegrateSearch"}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  dataList={monsterCandidateList}
                  handlePageChange={(event, value) =>
                    handlePageChange(event, value, "Monster")
                  }
                  handleOpenMigrationModal={handleOpenMigrationModal}
                  sortFilter={sortFilter}
                  handleFilterMonster={handleFilterMonster}
                  setSortFilter={setSortFilter}
                  filterAlreadyMigratedProfiles={filterAlreadyMigratedProfiles}
                  filterSelectedCandidateMigrated={filterSelectedCandidateMigrated}
                />
                <JobBoardResultTable
                  rows={monsterCandidateList}
                  searchType={"Monster"}
                  handleSelect={(value) => handleSelect(value, "Monster")}
                  selectedRows={selectedRows}
                  handleCheckboxChange={handleCheckboxChange}
                  internalDBMatchedResumes={internalDBMatchedResumes}
                  handleClickNotes={handleClickNotes}
                  allUsers={allUsers}
                />
              </TabPanel>
            </TabContext>
          </MainBackground>
        </LayoutSidebarMenu>
        {/* Drawer */}
        <Drawer open={drawerOpen} anchor="right" onClose={closeDrawer}>
          {(searchType === "Monster" || searchType === "CareerBuilder") && (
            <>
              <Box
                sx={{
                  width: 700,
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {loading ? (
                  <>
                    {" "}
                    <ShimmerText line={5} gap={10} />
                  </>
                ) : (
                  <>
                    {selectedCandidate !== null &&
                    selectedCandidate !== undefined ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                         <Box sx={{maxWidth:350}}>
                            {!internalDBMatchedResumes.some(
                              (applicant) =>
                                String(applicant.resumeId) ===
                                String(dataList[selectedCandidate]?.ResumeId)
                            ) ? (
                              <>
                                 <Typography  sx={{fontSize:18,fontWeight:700, color:theme.palette.primary.dark, textDecoration: "none",
                              "&:hover": { textDecoration: "underline" }}}>
                                 {dataList[selectedCandidate]?.Name || ""}
                                 </Typography>
                                <Typography noWrap sx={{fontSize:11, color:theme.palette.grey[700]}} >
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={extractedKeywords}
                                    autoEscape={true}
                                    textToHighlight={
                                      dataList[selectedCandidate]
                                        ?.JobTitle || "N/A"
                                    }
                                  />
                                </Typography>
                                </>
                            ) : (
                              
                              <>
                              <Typography component={Link}     to={`/applicantDetails/${
                                    internalDBMatchedResumes.find(
                                      (applicant) =>
                                        String(applicant?.resumeId) ===
                                        String(dataList[selectedCandidate]?.ResumeId)
                                    )?.applicantId || ""
                                  }`}// Assuming this is the intended link
                                  state={{
                                    applicant: dataList[selectedCandidate],
                                  }} // Passing the candidate object in state
                              sx={{fontSize:18,fontWeight:700, color:theme.palette.primary.dark, textDecoration: "none",
                                "&:hover": { textDecoration: "underline" }}}>
                 {internalDBMatchedResumes.find(
                                  (applicant) =>
                                    String(applicant.resumeId) ===
                                    String(
                                      dataList[selectedCandidate]
                                        ?.ResumeId
                                    )
                                )?.applicantId || ""}
                                {" - "}
                                {dataList[selectedCandidate]?.Name || ""}
                               </Typography>
                               <Typography noWrap sx={{fontSize:11, color:theme.palette.grey[700]}}> <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={extractedKeywords}
                            autoEscape={true}
                            textToHighlight={
                              dataList[selectedCandidate]
                                      ?.JobTitle || "N/A"
                            }
                          /> </Typography>
                               </>
                            )}
                          </Box>

                          <Box>
                            <ButtonGroup>
                              {internalDBMatchedResumes.some(
                                (applicant) =>
                                  applicant.resumeId ===
                                  dataList[selectedCandidate]?.ResumeId
                              ) &&
                                internalDBMatchedResumes.map((applicant) =>
                                  applicant.resumeId ===
                                  dataList[selectedCandidate]?.ResumeId ? (
                                    <IconButton
                                      size="small"
                                      key={applicant.resumeId}
                                    >
                                      <Tooltip title="Download">
                                        <FileDownloadIcon
                                          onClick={() =>
                                            handleDownloadResume(
                                              applicant?.resumes?.[
                                                applicant.resumes.length - 1
                                              ]?.serverLocation,
                                              applicant
                                            )
                                          }
                                          sx={{ fontSize: "17px" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  ) : (
                                    ""
                                  )
                                )}
                              <IconButton size="small">
                                <Tooltip title="Similar Applicants">
                                  <SupervisorAccountIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </Tooltip>
                              </IconButton>
                              {internalDBMatchedResumes.map(
                                (applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId && (
                                    <IconButton size="small">
                                      <Tooltip
                                        title="Open in New Tab"
                                        onClick={() =>
                                          handleNewWindow(applicant)
                                        }
                                      >
                                        <OpenInNewIcon
                                          sx={{ fontSize: "14px" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  )
                              )}
                              {internalDBMatchedResumes.some(
                                (applicant) =>
                                  applicant.resumeId ===
                                  dataList[selectedCandidate]?.ResumeId
                              ) ? (
                                internalDBMatchedResumes.map((applicant) =>
                                  applicant.resumeId ===
                                  dataList[selectedCandidate]?.ResumeId ? (
                                    <IconButton
                                      size="small"
                                      key={applicant.resumeId}
                                    >
                                      <Tooltip title="View the Resume">
                                        <VisibilityIcon
                                          onClick={() =>
                                            handleAxiosLink(
                                              applicant?.resumes?.[
                                                applicant.resumes.length - 1
                                              ]?.serverLocation
                                            )
                                          }
                                          sx={{ fontSize: "17px" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  ) : (
                                    ""
                                  )
                                )
                              ) : (
                                <IconButton size="small">
                                  <Tooltip title="Merge to Internal Database">
                                    <AddIcon
                                      onClick={handleGetResume}
                                      sx={{ fontSize: "17px" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                              <IconButton size="small">
                                <Tooltip title="Email">
                                  <MailOutlineIcon sx={{ fontSize: "17px" }} />
                                </Tooltip>
                              </IconButton>
                              {internalDBMatchedResumes.map(
                                (applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId && (
                                    <IconButton size="small">
                                      <Tooltip
                                        title="Add Notes"
                                        onClick={() =>
                                          handleClickNotes(
                                            applicant,
                                            "MonsterCB",
                                            ""
                                          )
                                        }
                                      >
                                        <NoteAddOutlinedIcon
                                          sx={{ fontSize: "17px" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  )
                              )}
                              <IconButton size="small">
                                <Tooltip title="Share the Profile">
                                  <ShortcutIcon sx={{ fontSize: "17px" }} />
                                </Tooltip>
                              </IconButton>
                              <IconButton size="small">
                                <Tooltip title="Add to Group">
                                  <GroupsIcon sx={{ fontSize: "17px" }} />
                                </Tooltip>
                              </IconButton>
                              <IconButton size="small">
                                <Tooltip title="Share Job">
                                  <ShareIcon sx={{ fontSize: "17px" }} />
                                </Tooltip>
                              </IconButton>

                              {internalDBMatchedResumes.map(
                                (applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId && (
                                    <>
                                      <IconButton size="small">
                                        <Tooltip
                                          title="Tag to Job"
                                          onClick={() =>
                                            handleQuickClickSubmitModal(
                                              applicant,
                                              "TagJob"
                                            )
                                          }
                                        >
                                          <LabelIcon
                                            sx={{ fontSize: "17px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                      <IconButton size="small">
                                        <Tooltip
                                          title="Quick Submit to Job"
                                          onClick={() =>
                                            handleQuickClickSubmitModal(
                                              applicant,
                                              "SubmitJob"
                                            )
                                          }
                                        >
                                          <PersonAddAlt1Icon
                                            sx={{ fontSize: "17px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    </>
                                  )
                              )}
                            </ButtonGroup>
                          </Box>
                        </Box>

                        <Box
                          bgcolor={theme.palette.grey[100]}
                          padding={2}
                          marginY={2}
                        >
                          <Grid container spacing={1}>
                            
                            <Grid item xs={4}>
                              <KeyData
                                title={"Job Title"}
                                value={
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={extractedKeywords}
                                    autoEscape={true}
                                    textToHighlight={
                                      dataList[selectedCandidate].JobTitle || ""
                                    }
                                  />
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KeyData
                                title={"Location"}
                                // value={dataList[selectedCandidate].HomeLocation ||''}
                                value={
                                  dataList[selectedCandidate]?.HomeLocation ||
                                  dataList[selectedCandidate]?.Location
                                    ?.country +
                                    " - " +
                                    dataList[selectedCandidate]?.Location
                                      ?.state +
                                    " - " +
                                    dataList[selectedCandidate]?.Location
                                      ?.city ||
                                  ""
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KeyData
                                title={"Recent Education"}
                                value={
                                  // dataList[selectedCandidate].HighestEducation||''
                                  dataList[selectedCandidate]
                                    ?.HighestEducation ||
                                  dataList[selectedCandidate]
                                    ?.highestEducation ||
                                  ""
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KeyData
                                title={"Experience"}
                                value={
                                  getYearsfromMonths(
                                    dataList[selectedCandidate]
                                      ?.MonthsOfExperience
                                  ) ||
                                  `${
                                    dataList[selectedCandidate]
                                      ?.ExperienceYears || ""
                                  }${"-"}${
                                    dataList[selectedCandidate]
                                      ?.ExperienceMonths || ""
                                  }` ||
                                  "-"
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                             
                            </Grid>
                            <Grid item xs={4}>
                              {internalDBMatchedResumes.map(
                                (applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId &&
                                  applicant?.mobileNumbers?.map(
                                    (mob, index) => (
                                      <KeyData
                                        key={index}
                                        title={`${mob.type}  Number`}
                                        value={mob.number || ""}
                                      />
                                    )
                                  )
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              {internalDBMatchedResumes.map(
                                (applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId &&
                                  applicant?.emailAddress?.map((mob, index) => (
                                    <>
                                    {mob?.email&&(
                                      <>
                                    <KeyData
                                      key={index}
                                      title={`${mob.type} Email`}
                                      value={mob.email || ""}
                                    />
                                      <CopyToClipboardButton text={mob.email} />
                                      </>
                                    )}
                                    </>
                                    
                                  ))
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <KeyData
                                title={"Work Authorization"}
                                value={
                                  dataList[selectedCandidate]?.Authorization ||
                                  "-"
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} flex={1}>
                          <TabContext value={openTabId}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="Job Boards tabs"
                                size="small"
                                variant="scrollable"
                              >
                                <Tab label="Resume" value="1" />
                                <Tab label="Personal Details" value="2" />
                                <Tab label="Job Applied" value="3" />
                                <Tab label="Notes" value="4" />
                                <Tab label="Work Experience" value="5" />
                                <Tab label="Email Merges" value="6" />
                              </TabList>
                            </Box>
                            <Box
                              sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <TabPanel
                                value="1"
                                sx={{
                                  p: 0,
                                  flex: 1,
                                }}
                              >
                                {internalDBMatchedResumes.some(
                                  (applicant) =>
                                    applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId
                                ) ? (
                                  <>
                                    <Box
                                      sx={{
                                        p: 2,
                                        display: "flex",
                                        gap: 1,
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {Object.entries(keywordCounts).map(
                                        ([keyword, count]) => (
                                          <Chip
                                            key={keyword} // Added a key for efficient rendering
                                            size="small"
                                            label={`${keyword} - ${count} `}
                                            variant="outlined"
                                          />
                                        )
                                      )}
                                    </Box>
                                    <Box>
                                      {(() => {
                                        const matchedApplicant =
                                          internalDBMatchedResumes.find(
                                            (applicant) =>
                                              applicant.resumeId ===
                                              dataList[selectedCandidate]
                                                ?.ResumeId
                                          );
                                        if (matchedApplicant) {
                                          const user = allUsers.find(
                                            (user) =>
                                              user._id ===
                                              matchedApplicant.addedBy
                                          );
                                          const userName =
                                            user?.userSudoFirstName ||
                                            "Unknown";
                                          const addedOnDate =
                                            matchedApplicant.addedOn
                                              ? new Date(
                                                  matchedApplicant.addedOn
                                                ).toLocaleString(undefined, {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  second: "2-digit",
                                                  hourCycle: "h23",
                                                })
                                              : "Unknown Date";
                                          return (
                                            <>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 0.5,
                                                py: 1,
                                              }}
                                            >
                                              <Typography>
                                                Migrated by
                                              </Typography>
                                              <Chip
                                                label={userName}
                                                size="small"
                                                icon={
                                                  <SentimentVerySatisfiedIcon />
                                                }
                                                variant="outlined"
                                                color="primary"
                                              />
                                              <Typography>from</Typography>
                                              <Chip
                                                label={
                                                  matchedApplicant?.source ||
                                                  "Unknown source"
                                                }
                                                size="small"
                                                icon={<CloudDownloadIcon />}
                                                variant="outlined"
                                                color="secondary"
                                              />
                                              <Typography>on</Typography>
                                              <Chip
                                                label={addedOnDate}
                                                size="small"
                                                icon={<CalendarMonthIcon />}
                                                variant="outlined"
                                                color="info"
                                              />
                                              
                                            </Box>
                                            <Box>
                                               Trouble viewing resume?
                                               <Button onClick={()=>handleClickRevertHtml(matchedApplicant)}> Click Here</Button>
                                            </Box>
                                            </>
                                          );
                                        }
                                        return "";
                                      })()}
                                    </Box>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: highlightedHtml,
                                      }}
                                      style={{
                                        padding: "20px",
                                        backgroundColor: "#f9f9f9",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 2,
                                        px: 10,
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        textAlign={"center"}
                                      >
                                        By clicking here, credits will be used,
                                        and you will unlock the resume.
                                      </Typography>

                                      <Button
                                        variant="outlined"
                                        sx={{ textTransform: "inherit" }}
                                        onClick={() => handleGetResume()}
                                      >
                                        Get the resume
                                      </Button>
                                    </Box>
                                  </Box>
                                )}
                              </TabPanel>
                              <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      dataList[selectedCandidate]?.ResumeId && (
                                      <Box
                                        bgcolor={theme.palette.grey[100]}
                                        padding={2}
                                        marginY={2}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"FirstName"}
                                              value={applicant?.firstName || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"Middle Name"}
                                              value={
                                                applicant?.middleName || ""
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"Last Name"}
                                              value={applicant?.lastName || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"Gender"}
                                              value={applicant?.gender || ""}
                                            />
                                          </Grid>

                                          <Grid item xs={4}>
                                            {applicant?.mobileNumbers?.map(
                                              (mob, index) => (
                                                <KeyData
                                                  key={index}
                                                  title={`${mob.type} Mobile Number`}
                                                  value={mob.number || ""}
                                                />
                                              )
                                            )}
                                          </Grid>
                                          <Grid item xs={4}>
                                            {applicant?.emailAddress?.map(
                                              (mob, index) => (
                                                <>
                                                {mob?.email&&(
                                                  <>
                                                <KeyData
                                                  key={index}
                                                  title={`${mob.type} Email Address`}
                                                  value={mob.email || ""}
                                                />
                                                  <CopyToClipboardButton text={mob.email} />
                                                  </>
                                                )}
                                                </>
                                                
                                              )
                                            )}
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"State"}
                                              value={applicant?.state || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"City"}
                                              value={applicant?.city || ""}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <KeyData
                                              title={"ZipCode"}
                                              value={applicant?.zipCode || ""}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )
                                )}
                              </TabPanel>
                              <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                                Job Applied
                              </TabPanel>
                              <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                                {internalDBMatchedResumes.map((applicant) =>
                                  applicant.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId &&
                                  applicant?.note?.length > 0
                                    ? applicant.note.map((noteItem, index) => (
                                        <MainBackground key={index}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: 1,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: 13,
                                                letterSpacing: 0.2,
                                                color: theme.palette.grey[800],
                                              }}
                                            >
                                              Note:{" "}
                                              {removeHtmlTags(noteItem.note)}
                                            </Typography>
                                            <Divider sx={{ py: 0.5 }} />
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: 0.5,
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: 0.2,
                                                  color:
                                                    theme.palette.grey[500],
                                                }}
                                              >
                                                User Name -{" "}
                                                {allUsers.find(
                                                  (user) =>
                                                    user._id ===
                                                    noteItem.addedBy
                                                )?.userSudoFirstName ||
                                                  "Unknown"}
                                              </Typography>

                                              <Typography
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: 0.2,
                                                  color:
                                                    theme.palette.grey[500],
                                                }}
                                              >
                                                {FormatDateTime(
                                                  noteItem.addedOn,
                                                  "full"
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </MainBackground>
                                      ))
                                    : ""
                                )}
                              </TabPanel>
                              <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                                Work Experience
                              </TabPanel>
                              <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                                Email Merges
                              </TabPanel>
                            </Box>
                          </TabContext>
                        </Box>
                      </>
                    ) : (
                      <Typography variant="body1">
                        No details available {selectedCandidate}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          {searchType === "InternalDB" && (
            <>
              <Box
                sx={{
                  width: 700,
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {selectedCandidate !== null &&
                selectedCandidate !== undefined ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{maxWidth:350}}>
                      <Typography component={Link} to={`/applicantDetails/${
                              internalDBCandidateList[selectedCandidate]
                                ?.applicantId || ""
                            }`} 
                            // Assuming this is the intended link
                            state={{
                              applicant:
                                internalDBCandidateList[selectedCandidate],
                            }} // Passing the candidate object in state  
                            sx={{fontSize:18,fontWeight:700, color:theme.palette.primary.dark, textDecoration: "none",
                              "&:hover": { textDecoration: "underline" },}}>
                              
                              {internalDBCandidateList[selectedCandidate]
                              ?.applicantId || ""}
                            {" - "}
                            {internalDBCandidateList[selectedCandidate]
                              ?.firstName || ""}{" "}
                            {internalDBCandidateList[selectedCandidate]
                              ?.lastName || ""}
                            </Typography>
                          <Typography noWrap sx={{fontSize:11, color:theme.palette.grey[700]}}> <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={extractedKeywords}
                              autoEscape={true}
                              textToHighlight={
                                internalDBCandidateList[selectedCandidate]
                                  ?.jobTitle || ""
                              }
                            /> </Typography>
                      </Box>

                      <Box></Box>
                      <Box>
                        <ButtonGroup>
                          {internalDBCandidateList[selectedCandidate].resumes &&
                            internalDBCandidateList[selectedCandidate].resumes
                              .length > 0 && (
                              <IconButton size="small">
                                <Tooltip title="Download">
                                  <FileDownloadIcon
                                    onClick={() =>
                                      handleDownloadResume(
                                        internalDBCandidateList[
                                          selectedCandidate
                                        ]?.resumes?.[
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.resumes?.length - 1
                                        ]?.serverLocation,
                                        internalDBCandidateList[
                                          selectedCandidate
                                        ]
                                      )
                                    }
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                          <IconButton size="small">
                            <Tooltip title="Similar Applicants">
                              <SupervisorAccountIcon
                                sx={{ fontSize: "14px" }}
                              />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip
                              title="Open in New Tab"
                              onClick={() =>
                                handleNewWindow(
                                  internalDBCandidateList[selectedCandidate]
                                )
                              }
                            >
                              <OpenInNewIcon sx={{ fontSize: "14px" }} />
                            </Tooltip>
                          </IconButton>
                          {internalDBCandidateList[selectedCandidate].resumes &&
                            internalDBCandidateList[selectedCandidate].resumes
                              .length > 0 && (
                              <IconButton size="small">
                                <Tooltip title="View the Resume">
                                  <VisibilityIcon
                                    onClick={() =>
                                      handleAxiosLink(
                                        internalDBCandidateList[
                                          selectedCandidate
                                        ]?.resumes?.[
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.resumes?.length - 1
                                        ]?.serverLocation
                                      )
                                    }
                                    title="View the resume"
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                          <IconButton size="small">
                            <Tooltip title="Email">
                              <MailOutlineIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip
                              title="Add Notes"
                              onClick={() =>
                                handleClickNotes(
                                  internalDBCandidateList[selectedCandidate],
                                  "InternalDB",
                                  ""
                                )
                              }
                            >
                              <NoteAddOutlinedIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip title="Share the Profile">
                              <ShortcutIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip title="Add to Group">
                              <GroupsIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip title="Share Job">
                              <ShareIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip
                              title="Tag to Job"
                              onClick={() =>
                                handleQuickClickSubmitModal(
                                  internalDBCandidateList[selectedCandidate],
                                  "TagJob"
                                )
                              }
                            >
                              <LabelIcon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                          <IconButton size="small">
                            <Tooltip
                              title="Quick Submit to Job"
                              onClick={() =>
                                handleQuickClickSubmitModal(
                                  internalDBCandidateList[selectedCandidate],
                                  "SubmitJob"
                                )
                              }
                            >
                              <PersonAddAlt1Icon sx={{ fontSize: "17px" }} />
                            </Tooltip>
                          </IconButton>
                        </ButtonGroup>
                      </Box>
                    </Box>

                    <Box
                      bgcolor={theme.palette.grey[100]}
                      padding={2}
                      marginY={2}
                    >
                      <Grid container spacing={1}>
                        {internalDBCandidateList[
                          selectedCandidate
                        ].mobileNumbers.map((number, index) => (
                          <Grid item xs={4} key={index}>
                            <KeyData
                              title={`mobile${number.type}`}
                              value={number.number}
                            />
                          </Grid>
                        ))}

                        <Grid item xs={4}>
                          <KeyData
                            title={"Job Title"}
                            value={
                              internalDBCandidateList[selectedCandidate]
                                ?.jobTitle || ""
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KeyData
                            title={"Location"}
                            value={`${
                              internalDBCandidateList[selectedCandidate]
                                ?.city || ""
                            } - ${
                              internalDBCandidateList[selectedCandidate]
                                ?.state || ""
                            } - ${
                              internalDBCandidateList[selectedCandidate]
                                ?.country || ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Array.isArray(
                            internalDBCandidateList[selectedCandidate]
                              ?.educationDetails
                          ) &&
                            internalDBCandidateList[
                              selectedCandidate
                            ].educationDetails.map((degree, index) => (
                              <KeyData
                                key={index}
                                title={`schoolName${degree.schoolName}`}
                                value={degree.degree}
                              />
                            ))}
                        </Grid>
                        <Grid item xs={4}>
                          <KeyData
                            title={"Experience(years)"}
                            value={
                              internalDBCandidateList[selectedCandidate]
                                .experienceYears || ""
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KeyData
                            title={"Email"}
                            value={
                              internalDBCandidateList[
                                selectedCandidate
                              ].emailAddress?.find(
                                (emails) => emails.type === "primary"
                              ).email || "-"
                            }
                          />
                            <CopyToClipboardButton text={internalDBCandidateList[
                                selectedCandidate
                              ].emailAddress?.find(
                                (emails) => emails.type === "primary"
                              ).email || "-"} />
                        </Grid>
                        <Grid item xs={4}>
                          <KeyData
                            title={"Work Authorization"}
                            value={
                              internalDBCandidateList[selectedCandidate]
                                .workAuthorization || "-"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} flex={1}>
                      <TabContext value={openTabId}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange}
                            aria-label="ob Boards tabs"
                            size="small"
                            variant="scrollable"
                          >
                            <Tab label="Resume" value="1" />
                            <Tab label="Personal Details" value="2" />
                            <Tab label="Job Applied" value="3" />
                            <Tab label="Notes" value="4" />
                            <Tab label="Skills" value={"5"} />
                            <Tab label="Work Experience" value="6" />
                            <Tab label="Email Merges" value="7" />
                          </TabList>
                        </Box>
                        <TabPanel
                          value="1"
                          sx={{
                            p: 0,
                            flex: 1,

                            width: "100%",
                            height: "100vh",
                          }}
                        >
                          {/* {AwsResumeUrl ? (
                      <ResumeText AwsResumeUrl={AwsResumeUrl} />
                    ) : (
                      <></>
                    )} */}
                          {HtmlResume ? (
                            <>
                              <Box
                                sx={{
                                  p: 2,
                                  display: "flex",
                                  gap: 1,
                                  flexWrap: "wrap",
                                }}
                              >
                                {Object.entries(keywordCounts).map(
                                  ([keyword, count]) => (
                                    <Chip
                                      size="small"
                                      label={`${keyword} - ${count} `}
                                      variant="outlined"
                                    />
                                  )
                                )}
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                    py: 1,
                                  }}
                                >
                                  <Typography>Migrated by</Typography>
                                  <Chip
                                    label={`${
                                      allUsers.find(
                                        (user) =>
                                          user._id ===
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.addedBy
                                      )?.userSudoFirstName || "Unknown"
                                    }`}
                                    size="small"
                                    icon={<SentimentVerySatisfiedIcon />}
                                    variant="outlined"
                                    color="primary"
                                  />
                                  <Typography>from</Typography>
                                  <Chip
                                    label={`${
                                      internalDBCandidateList[selectedCandidate]
                                        ?.source || "Unknown source"
                                    }`}
                                    size="small"
                                    icon={<CloudDownloadIcon />}
                                    variant="outlined"
                                    color="secondary"
                                  />
                                  <Typography>on</Typography>
                                  <Chip
                                    label={`${
                                      internalDBCandidateList[selectedCandidate]
                                        ?.addedOn
                                        ? new Date(
                                            internalDBCandidateList[
                                              selectedCandidate
                                            ].addedOn
                                          ).toLocaleString(undefined, {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hourCycle: "h23",
                                          })
                                        : "Unknown date"
                                    }`}
                                    size="small"
                                    icon={<CalendarMonthIcon />}
                                    variant="outlined"
                                    color="info"
                                  />
                                   
                                </Box>
                              </Box>
                              <Box>
                                               Trouble viewing resume?
                                               <Button onClick={()=>handleClickRevertHtml(internalDBCandidateList[selectedCandidate])}> Click Here</Button>
                                            </Box>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: highlightedHtml,
                                }}
                                style={{
                                  padding: "20px",
                                  backgroundColor: "#f9f9f9",
                                  borderRadius: "8px",
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                          <Box
                            bgcolor={theme.palette.grey[100]}
                            padding={2}
                            marginY={2}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"FirstName"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      ?.firstName || ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Middle Name"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      ?.middleName || ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Last Name"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      ?.lastName || ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Gender"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      ?.gender || ""
                                  }
                                />
                              </Grid>

                              <Grid item xs={4}>
                                {internalDBCandidateList[
                                  selectedCandidate
                                ].emailAddress.map((email, index) => (
                                  <>
                                  {email.email&&(
                                    <>
                                  <KeyData
                                    title={`email${email.type}`}
                                    value={email.email}
                                  /> 
                                    <CopyToClipboardButton text={email.email} />
                                    </>
                                  )}
                                  </>
                                  
                                ))}
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"State"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      .state || ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"City"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      .city || ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"ZipCode"}
                                  value={
                                    internalDBCandidateList[selectedCandidate]
                                      ?.zipCode || ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                          Job Applied
                        </TabPanel>
                        <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                          {internalDBCandidateList[
                            selectedCandidate
                          ]?.note?.map((noteItem, index) => (
                            <MainBackground key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 13,
                                    letterSpacing: 0.2,
                                    color: theme.palette.grey[800],
                                  }}
                                >
                                  Note:
                                  {removeHtmlTags(noteItem.note)}
                                </Typography>
                                <Divider sx={{ py: 0.5 }} />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 0.5,
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      letterSpacing: 0.2,
                                      color: theme.palette.grey[500],
                                    }}
                                  >
                                    User Name -{" "}
                                    {allUsers.find(
                                      (user) => user._id === noteItem.addedBy
                                    )?.userSudoFirstName || "Unknown"}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      letterSpacing: 0.2,
                                      color: theme.palette.grey[500],
                                    }}
                                  >
                                    {FormatDateTime(noteItem.addedOn, "full")}
                                  </Typography>
                                </Box>
                              </Box>
                            </MainBackground>
                          ))}
                        </TabPanel>
                        <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                          <Box
                            bgcolor={theme.palette.grey[100]}
                            padding={2}
                            marginY={2}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {Array.isArray(
                                    internalDBCandidateList[selectedCandidate]
                                      ?.skills
                                  ) &&
                                    internalDBCandidateList[
                                      selectedCandidate
                                    ]?.skills.map((skill, index) => (
                                      <Grid item xs={3} key={index}>
                                        {" "}
                                        {/* Adjust xs to control width */}
                                        <KeyData
                                          value={
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={extractedKeywords}
                                              autoEscape={true}
                                              textToHighlight={skill || ""}
                                            />
                                          }
                                        />
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </TabPanel>
                        <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                          Work Experience
                        </TabPanel>
                        <TabPanel value="7" sx={{ p: 0, pt: 5 }}>
                          Email Merges
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </>
                ) : (
                  <Typography variant="body1">
                    No details available {selectedCandidate}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Drawer>
        <Modal
          open={openResumeModal}
          onClose={handleCloseResumeModal}
          aria-labelledby="resume-modal-title"
          aria-describedby="resume-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleCloseResumeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* Conditional rendering based on file type */}
            {AwsResumeUrl ? (
              <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
            ) : (
              <Typography variant="h6" textAlign="center">
                No resume available.
              </Typography>
            )}
          </Box>
        </Modal>

        <JobBoardsQuickSubmitSelectJob
          open={openQuickSubmitModal}
          close={handleCancelQuickSubmitModal}
          submit={handleQuickSubmit}
          setSelectJobQuickSubmit={setSelectJobQuickSubmit}
          selectJobQuickSubmit={selectJobQuickSubmit}
          jobPostingOptions={jobPostingOptions}
          selectTagStatus={selectTagStatus}
        />
        <JobBoardsQuickSubmitCheckAutharize
          open={openModalNotAutharized}
          close={setOpenModalNotAutharized}
        />
        <JobBoardsQuickSubmitConfirmModal
          open={isConfirmModalOpen}
          close={handleCloseConfirmModalOpen}
          cofirm={handleQuickSubmitConfirm}
          jobId={selectJobQuickSubmit?.label}
          applicantName={`${
            selectCandidateQuickSubmit[0]?.firstName || ""
          }${" "}${selectCandidateQuickSubmit[0]?.middleName || ""}${" "}${
            selectCandidateQuickSubmit[0]?.lastName || ""
          }`}
          selectTagStatus={selectTagStatus}
        />
        <JobBoardsAddNotes
          open={openNoteDrawer}
          close={handleCloseNote}
          allUsers={allUsers}
          notes={
            searchType === "InternalDB"
              ? (
                  internalDBCandidateList.find(
                    (internal) =>
                      internal.applicantId === clickNoteApplicant?.applicantId
                  )?.note || []
                ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
              : (
                  internalDBMatchedResumes.find(
                    (internal) =>
                      internal.applicantId === clickNoteApplicant?.applicantId
                  )?.note || []
                ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
          }
          note={note}
          handleNote={handleNote}
          setNote={setNote}
          handleNoteSubmit={handleNoteSubmit}
          noteActionTexts={noteActionTexts}
          jobPostingOptions={jobPostingOptions}
          jobPostReference={jobPostReference}
          setJobPostReference={setJobPostReference}
          noteAction={noteAction}
          setNoteAction={setNoteAction}
          notePriority={notePriority}
          setNotePriory={setNotePriory}
        />

        <SnackbarComponent />
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" size="3rem" />
        </Backdrop>
        <JobBoardMigrationModal
          open={openMigrationModal}
          close={handleCloseMigrationModal}
          totalResult={totalResult}
          yes={handleMigrationYES}
        />
      </SnackbarProvider>
    </React.Fragment>
  );
};
export default JobBoardsIntegrateResult;
