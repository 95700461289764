import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";

import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";
import moment from "moment-timezone";
import { MainBackground } from "../../Components";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { getPaginationCompaniesViaApi, getTotalCompaniesCountViaSaga, stopLoadingRedux } from "../../store/actions";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");


const AllClients = () => {
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData) || [];
  const totalCompanies = useSelector(
    (state) => state.CompaniesList?.totalCompanies
  );
  const AllCompanies = useSelector(
    (state) => state.CompaniesList?.companies
  );
  const client = storeData.clientDb;
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitPage, setLimtPage] = useState(100);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const action = {
      client: client,
      pageSize: itemsPerPage,
      page: currentPage,
    };
    setLoading(true)
    dispatch(getTotalCompaniesCountViaSaga(action));
    dispatch(getPaginationCompaniesViaApi(action));
  
  }, []);
  const fetchCompanies = async (startIndex, limitSize) => {
    const action = {
      client: client,
      pageSize: limitSize,
      page: startIndex,
    };
    setCurrentStartIndex(startIndex);
    setLimtPage(limitSize);
    dispatch(getPaginationCompaniesViaApi(action));
    setLoading(true)
  };


  const handlePageChanges = (newPage) => {
    if (totalCompanies === 0) {
      return;
    }
    if (newPage < 1) {
      setCurrentPage(1);
      fetchCompanies(newPage, itemsPerPage);
    } else if (newPage > totalCompanies) {
      setCurrentPage(totalCompanies);
    } else {
      setCurrentPage(newPage);
      fetchCompanies(newPage, itemsPerPage);
    }
  };
  const handleChangeItemsPerPage = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    fetchCompanies(currentPage, newItemsPerPage);

  };

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };

  const CompanyIdData = Array.isArray(AllCompanies)
  ? AllCompanies.map((company, index) => {
      const recruiterData = allUsers.find((user) => user._id === company.addedBy);
      const recruiterName = company?.leadRAId
        ? recruiterData?.userSudoFirstName || ''
        : recruiterData?.userFirstName || '';
      return {
        ...company,
        addedBy: recruiterName,
        addedOn: formatDateForDisplay(company.addedOn) || '',
        status: company.status || [],
        createdAt: company?.createdAt
          ? formatDateForDisplay(company.createdAt)
          : '',
        updatedAt: company?.updatedAt
          ? formatDateForDisplay(company.updatedAt)
          : '',
        id: index, // Assign a unique identifier
      };
    })
  : [];


  const columns = [
    
    {
      field: "companyName",
      headerName: "Client Name",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "Contact Number",
      headerName: "Contact Number",
      width: 220,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;

       // Extract all non-empty phone numbers
    const contactNumbers = row?.contactDetails
      ?.map((contact) => contact.phoneNumber?.trim())
      .filter((number) => number) // Remove empty or undefined numbers
      .join(", "); // Join valid numbers with a comma
        return (
          <div>
            {contactNumbers || ""} {/* Show 'N/A' if no valid phone numbers */}
          </div>
        );
      },
    },
    {
      field: "jobWebsite",
      headerName: "Website",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;
        const website = row?.jobDetails?.[0]?.jobWebsite;
        return (
          <div>
            {website}
          </div>
        );
      },
    },
    {
      field: "jobIndustrieName",
      headerName: "Industry",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;

        const industry = row?.jobDetails?.[0]?.jobIndustrieName;

        return (
          <div>
            {industry}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "addedBy",
      headerName: "Created By",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "addedOn",
      headerName: "Created On",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Modified on",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "updatedAt",
      headerName: " Modified By",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "Country",
      headerName: "Country",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "State",
      headerName: "State",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;
  
        // Extract the state from the jobLocation field
        const jobLocation = row?.jobDetails?.[0]?.jobLocation || "";
        const state = jobLocation.split(", ")[1]; // Extracts the second part (State)
  
        return <div>{state || "N/A"}</div>;
      },
    },
    {
      field: "City",
      headerName: "City",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;
  
        // Extract the city from the jobLocation field
        const jobLocation = row?.jobDetails?.[0]?.jobLocation || "";
        const city = jobLocation.split(", ")[0]; // Extracts the first part (City)
  
        return <div>{city || "N/A"}</div>;
      },
    },
    {
      field: "Zip code",
      headerName: "Zip code",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  const handleClose = () => "";
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"All Clients"}>
          <Box>
            <Grid container spacing={2} height={"100%"}>
              <Grid item xs={12}>
                <MainBackground>
                  <DataGrid
                    rows={CompanyIdData}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnResize
                    disableColumnSort
                    hideFooter
                    getRowHeight={() => "auto"}
                    autoHeight
                    sx={{
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center !important",
                      },
                      [`& .MuiDataGrid-checkboxInput`]: {
                        color: theme.palette.primary.main,
                        p: 0,

                        "&.Mui-checked": {
                          color: theme.palette.primary.dark,
                        },
                      },
                      //Scroller
                      [`& .MuiDataGrid-scrollbar`]: {
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                        borderRadius: "10px", // Apply border radius
                      },

                      [`& .MuiDataGrid-scrollbar:hover`]: {
                        scrollbarColor: `${theme.palette.primary.dark}`,
                      },
                      [`& .MuiDataGrid-virtualScroller`]: {
                        maxHeight: "640px",
                        overflowY: "auto !important",
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                    }}
                    checkboxSelection={false}
                    // onRowSelectionModelChange={handleRowSelection}
                    // rowSelectionModel={selectedRows}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 150,
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      Row per page
                      <Select
                        size="small"
                        value={itemsPerPage}
                        onChange={(e) =>
                          handleChangeItemsPerPage(e.target.value)
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          hideBackdrop: true,
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          {(currentPage - 1) * itemsPerPage + 1} -{" "}
                          {itemsPerPage * currentPage} of
                        </Typography>
                        <Typography>{totalCompanies}</Typography>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => handlePageChanges(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handlePageChanges(currentPage + 1)}
                        disabled={currentPage * itemsPerPage >= totalCompanies}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </MainBackground>
              </Grid>
            </Grid>
          </Box>
          <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" size="3rem" />
        </Backdrop>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default AllClients;
