import { SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import {
  JobBoardInternalDb,
  JobBoardCareerBuilder,
  JobBoardMonster,
  IntegratedSearch,
  SnackbarComponent,
} from "../../Components";
import { clearSearchResults, getAllDegreesViaApi, getAllTemplatesViaApi, getAllUsers, getIntegrationsViaApi, getJobBoardsHistoryViaApi, searchDateJobPostingDataViaApi } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
const JobBoards = () => {
  const location = useLocation();
  const tabId = location?.state?.tabId || "1"; // Access tabId from state

  const dispatch=useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const [openTabId, setOpenTabId] = useState(tabId||"1");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
    const searchType = 
    newValue === "1" ? "InternalDB" :
    newValue === "2" ? "CareerBuilder" :
    newValue === "3" ? "MonsterClassicSearch" :
    "IntegrateSearch";
  const action = {
    client: client,
    addedBy: storeData._id,
    searchType: searchType,
  };
  dispatch(getAllDegreesViaApi(client));
  dispatch(getJobBoardsHistoryViaApi(action));
  };
  useEffect(()=>{
    const action = {
      client: client,
      addedBy: storeData._id,
      searchType: tabId==="1"?"InternalDB":tabId==="2"?"CareerBuilder":tabId==="3"?"MonsterClassicSearch":tabId==="4"?"IntegrateSearch":"InternalDB",
    };
    dispatch(clearSearchResults());
    dispatch(searchDateJobPostingDataViaApi(action));
    dispatch(getAllTemplatesViaApi(action));
    dispatch(getAllUsers(action));
    dispatch(getJobBoardsHistoryViaApi(action));
    dispatch(getIntegrationsViaApi(action))
  },[])
  
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Boards"}>
          <Box>
            <TabContext value={openTabId}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="Job Boards tabs">
                  <Tab label="Internal DB" value="1" />
                  <Tab label="Career Builder" value="2" />
                  <Tab label="Monster" value="3" />
                  <Tab label="Integrated Search" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
                <JobBoardInternalDb />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
                <JobBoardCareerBuilder />
              </TabPanel>
              <TabPanel value="3" sx={{ p: 0, pt: 1 }}>
                <JobBoardMonster />
              </TabPanel>
              <TabPanel value="4" sx={{ p: 0, pt: 1 }}>
                <IntegratedSearch />
              </TabPanel>
            </TabContext>
          </Box>
          <SnackbarComponent />
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default JobBoards;
