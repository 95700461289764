import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomFormField from "../Common/CustomFormField";
import theme from "../../Theme";
import ClearIcon from "@mui/icons-material/Clear";
const JobBoardMonsterPowerSearch = ({
  powerSearchType,
  setPowerSearchType,
  country,
  countryMonsterOptions,
  handleSelectCountry,
  MonsterPowerSearchRadiusOptions,
  radius,
  setRadius,
  MonsterPowerSearchSalaryRangeOptions,
  MonsterPowerSearchResumeUpdatedOptions,
  MonsterPowerSearchHourlySalaryRangeOptions,
  MonsterEducationLevel,
  MonsterPowerSearchTenureOptions,
  MonsterPowerSearchTravelRequirementsOptions,
  resumeUpdate,
  setResumeUpdate,
  minedulvid,
  setMinedulvid,
  salaryRangeOptions,
  setSalaryRangeOptions,
  salaryRangeType,
  setSalaryRangeType,
  setTsni,
  tsni,
  highEducation,
  setHighEducation,
  edumjr,
  setEdumjr,
  wtt,
  setWtt,
  ten,
  setTen,
  gsc,
  setGsc,
  setLstat,
  lstat,
  relo,
  setRelo,
  tjtid,
  setTjtid,
  bkeyword,
  setBkeyword,
  setExperience,
  experience,
  jt,
  setJt,
  skills,
  setSkills,
  locationSearch,
  setLocationSearch,
  locationRadius,
  setLocationRadius,
  handlePowerSearch,
  setSelectCityState,
  selectCityState,
  setSearchCityState,
  searchCityState,
  apiUrl,
  salaryRange,
  handleReset,
  setSalaryRange,
  filter=false
}) => {
  const [optionsCityZip, setOptionCityZip] = useState([]);
  const [advanceSearchOptions, setAdvanceSearchOptions] = useState(true);
  const toggleAdvanceSearchOptions = () => {
    setAdvanceSearchOptions(!advanceSearchOptions);
  };
  const handleChangeSearchType = (value) => {
    setPowerSearchType(value);
  };
  const handleChangeSalaryType = (value) => {
    setMinedulvid("");
    if (value === "Per Hour") {
      setSalaryRangeOptions(MonsterPowerSearchHourlySalaryRangeOptions);
    } else if (value === "Per Year") {
      setSalaryRangeOptions(MonsterPowerSearchSalaryRangeOptions);
    }
    setSalaryRangeType(value);
  };
  const handleAddField = (setter, fields) => {
    setter([...fields, { id: fields.length + 1, value: "" }]);
  };
  const handleFieldChange = (setter, fields, id, value) => {
    setter(fields.map((field) => (field.id === id ? { ...field, value } : field)));
  };
  const handleRemoveField = (setter, fields,id) => {
    setter(fields.filter((field) => field.id !== id));
  };
  const handleAddFieldLocation = () => {
    setLocationSearch([
      ...locationSearch,
      { id: locationSearch.length + 1, value: "" },
    ]);
    setLocationRadius([
      ...locationRadius,
      { id: locationRadius.length + 1, value: "" },
    ]);
    setSearchCityState([
      ...searchCityState,
      { id: searchCityState.length + 1, value: "" },
    ]);
    setSelectCityState([
      ...selectCityState,
      { id: searchCityState.length + 1, value: "" },
    ]);
  };
  const handleRemoveLocation = (id) => {
    setLocationSearch(locationSearch.filter((field) => field.id !== id));
    setLocationRadius(locationRadius.filter((field) => field.id !== id));
    setSelectCityState(selectCityState.filter((field) => field.id !== id));
    setSearchCityState(searchCityState.filter((field) => field.id !== id));
  };
  const handleSelectCityState = (id,value) => {
    setSelectCityState(selectCityState.map((field) => (field.id === id ? { ...field, value } : field)));
    setSearchCityState(searchCityState.map((field) => (field.id === id ? { ...field, value } : field)));
  };
  const handleCallCountriesWithApi = async (id,value) => {
    const passData=''
    // console.log('selectCityState.map((field) => (field.id === id ? { ...field, passData } : field))',selectCityState.map((field) => (field.id === id ? { ...field, passData } : field)));
   
    setSelectCityState(selectCityState.map((field) => (field.id === id ? { ...field, value:passData } : field)));
    setSearchCityState(searchCityState.map((field) => (field.id === id ? { ...field, value } : field)));
   
    if (!value) {
      setOptionCityZip([]);
      return;
    }
    // Only make the API call if the user has typed more than 2 characters
    if (value.length < 1) {
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/countries/getCitiesState `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: "tektree", 
          countryCode: country, 
          city: value,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }
      const data = await response.json();
      setOptionCityZip(data);
      // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap:filter?1: 2,
          alignItems: filter?"flex-start":"flex-end",
          flexDirection:filter?"column":"row"
        }}
      >
        <Typography>Power Resume Search</Typography>
        <Button sx={{ fontSize: 12 }} onClick={toggleAdvanceSearchOptions}>
          {advanceSearchOptions ? "Hide" : "Show"} Advance search options
        </Button>
      </Box>
      <Grid container  rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <CustomFormField
            // title={"Semantic "}
            // required
            components={[
              {
                type: "radio",
                props: {
                  title: "Semantic",
                  defaultValue: powerSearchType,
                  options: ["Semantic"],
                  onChange: (value) => handleChangeSearchType(value),
                },
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={2}>
          {jt.map((field, index) => (
            <Grid
              
              item
              xs={12}
              key={field.id}
              alignItems="center"
              
            >
              <Grid item xs={11}>
                <CustomFormField
                  title={"Job Title"}
                  titleInfo={"Entering  a job title  is highly recommended"}
                  sectionInfo={"(e.g., Sales, Accountant, Engineer)"}
                  colunm
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: `Job Title`,
                        defaultValue: field.value,
                        onChange: (value) => handleFieldChange(setJt,jt,field.id, value),
                      },
                    },
                  ]}
                />
              </Grid>
              {jt.length > 1 && (
                <Grid item xs={1}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveField(setJt,jt,field.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="" onClick={()=>handleAddField(setJt,jt)}>
              Add Another Title
            </Button>
          </Grid>
        </Grid></Grid>

        <Grid item xs={12}>
        <Grid container spacing={2}>
          {skills.map((field, index) => (
            <>
            <Grid
              container
              item
              xs={12}
              key={field.id}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={11}>
                <CustomFormField
                  title={"Skill/Keywords"}
                  titleInfo={
                    "Enter terms related to candidates industry, or expertise (e.g. java, telesales, call center, SOX)"
                  }
                  colunm
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: `Skills`,
                        defaultValue: field.value,
                        onChange: (value) =>
                          handleFieldChange(setSkills,skills, field.id, value),
                      },
                    },
                  ]}
                />

              </Grid>
              {skills.length > 1 && (
                <Grid item xs={1}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveField(setSkills,skills, field.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
            <CustomFormField
                  title={""}
                  colunm
                  components={[
                    {
                      type: "switch",
                      props: {
                        defaultValue: field.value,
                        options:["Required","Non-required"],
                        // onChange: (value) =>handleFieldChange(setSkills,skills, field.id, value),
                      },
                    },
                  ]}
                />
            </Grid>
              </>
          ))}
          <Grid item xs={12}>
            <Button variant="" onClick={()=>handleAddField(setSkills,skills)}>
              Add Another Skill or Keyword
            </Button>
          </Grid>
        </Grid>
        </Grid>


        <Grid item xs={12}>
          <Divider sx={{ borderStyle: "dashed" }} />
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{ color: theme.palette.primary.main, fontWeight: 600 }}
          >
            -OR-
          </Typography>
          <CustomFormField
            // title={"Semantic "}
            // required
            components={[
              {
                type: "radio",
                props: {
                  title: "Boolean Search",
                  options: ["Boolean Search"],
                  defaultValue: powerSearchType,
                  onChange: (value) => handleChangeSearchType(value),
                },
              },
            ]}
          />
        </Grid>
        <Grid item xs={filter?12:6}>
          <CustomFormField
            title={"Keywords"}
            components={[
              {
                type: "text",
                props: {
                  placeholder: `Keywords`,
                  defaultValue: bkeyword,
                  onChange: (value) => setBkeyword(value),
                },
              },
            ]}
          />
        </Grid>
        <Grid item xs={filter?12:6}>
          <CustomFormField
            title={"Years of Experience"}
            sectionInfo={"(e.g., 4, 2-3, 5+, <8)"}
            components={[
              {
                type: "text",
                props: {
                  placeholder: `Suggested 4, 2-3, 5+, <8`,
                  defaultValue: experience,
                  onChange: (value) => setExperience(value),
                },
              },
              {
                type: "switch",
                props: {
                  // defaultValue: field.value,
                  options:["Required","Non-required"],
                  // onChange: (value) =>handleFieldChange(setSkills,skills, field.id, value),
                },
              },
            ]}
          />
          
        </Grid>
       
        <Grid item xs={12}>
          <Divider sx={{ borderStyle: "dashed" }} />
        </Grid>
        <Grid item xs={12}>
          <CustomFormField
            title={"Locations"}
            titleInfo={"Enter city, state, country or zip code"}
            components={[
              {
                type: "selectLabel",
                props: {
                  title: "Select Country",
                  value: country,
                  options: countryMonsterOptions,
                  onChange: (value) => handleSelectCountry(value),
                },
              },
             
            ]}
          />
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={2}>
          {locationSearch.map((field, index) => (
            <Grid
              item
              xs={12}
              key={field.id}
              alignItems="center"
            >
              <Grid item xs={filter?12:11}>

                <CustomFormField
                  title={""}
                  colunm
                  components={[
                    {
                      type: "selectLabelMultipleFrom",
                      props: {
                        placeholder: ``,
                        options: optionsCityZip,
                        onChangeForm: (value) =>handleCallCountriesWithApi(field.id,value),
                        searchTerm:searchCityState[index]?.value||'',
                        selectCityZip: selectCityState[index]?.value||'',
                        onChange: (value) => handleSelectCityState(field.id,value)
                          // handleFieldChange(setLocationSearch,locationSearch, field.id, value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "within",
                      },
                    },
                    
                    {
                      type: "selectLabel",
                      props: {
                        title: "within",
                        value: locationRadius[index]?.value,
                        options: MonsterPowerSearchRadiusOptions,
                        onChange: (value) =>
                          handleFieldChange(setLocationRadius,locationRadius,field.id, value),
                      },
                    },
                  ]}
                />
              </Grid>
              {locationSearch.length > 1 && (
                <Grid item xs={1}>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveLocation(field.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="" onClick={handleAddFieldLocation}>
              Add Another Location
            </Button>
          </Grid>
        </Grid>
        </Grid>
        <Grid item xs={filter?12:4}>
          <CustomFormField
            title={"Resume Updated"}
            components={[
              {
                type: "selectLabel",
                props: {
                  title: "Resume Updated",
                  value: resumeUpdate,
                  options: MonsterPowerSearchResumeUpdatedOptions,
                  onChange: (value) => setResumeUpdate(value),
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      {advanceSearchOptions && (
        <Box mt={2}>
          <Grid container columns={12} rowSpacing={2} columnSpacing={1}>
            <Grid item xs={filter?12:8}>
              <CustomFormField
                title={"Education"}
                components={[
                  {
                    type: "selectLabel",
                    props: {
                      title: "Minimun Education Level",
                      value: highEducation,
                      options: MonsterEducationLevel,
                      onChange: (value) => setHighEducation(value),
                    },
                  },
                  {
                    type: "text",
                    props: {
                      typeInfo: "(e.g., MBA, MS Math, MSCS, English)",
                      placeholder: `Degree/Major`,
                      defaultValue: edumjr,
                      onChange: (value) => setEdumjr(value),
                    },
                  },
                  {
                    type: "switch",
                    props: {
                      // defaultValue: field.value,
                      options:["Required","Non-required"],
                      // onChange: (value) =>handleFieldChange(setSkills,skills, field.id, value),
                    },
                  },
                ]}
              />
              
            
         
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                title={"Maximun Salary"}
                colunm
                components={[
                  {
                    type: "radio",
                    props: {
                      title: "Salary Type",
                      options: ["Per Year", "Per Hour"],
                      defaultValue: salaryRangeType,
                      onChange: (value) => handleChangeSalaryType(value),
                    },
                  },
                  {
                    type: "selectLabel",
                    props: {
                      title: "Salary Level",
                      value: salaryRange,
                      options: salaryRangeOptions,
                      onChange: (value) => setSalaryRange(value),
                    },
                  },
                  {
                    type: "checkbox",
                    props: {
                      title: "Include candidates without salary information",
                      options: [
                        "Include candidates without salary information",
                      ],
                      size: "small",
                      defaultValue: tsni
                        ? "Include candidates without salary information"
                        : "",
                      onClick: (value) => setTsni(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                title={"Job Type"}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      show: "row",
                      title: "Job Type",
                      options: [
                        "Full Time",
                        "Part Time",
                        "Intern",
                        "Temporary/Contract",
                        "Seasonal",
                      ],
                      defaultValue: tjtid,
                      onClick: (value) => setTjtid(value),
                      multiple:true,
                    },
                  },
                  
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                title={"Desired Job Duration"}
                sectionInfo={"Select a desired average tenure"}
                components={[
                  {
                    type: "selectLabel",
                    props: {
                      title: "Desired Job Duration",
                      // defaultValue: ten,
                      value: ten,
                      options: MonsterPowerSearchTenureOptions,
                      onChange: (value) => setTen(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                title={"Willing  to Travel"}
                components={[
                  {
                    type: "selectLabel",
                    props: {
                      title: "Willing  to Travel",
                      // defaultValue: wtt,
                      value: wtt,
                      onChange: (value) => setWtt(value),
                      options: MonsterPowerSearchTravelRequirementsOptions,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>
            <Grid item xs={filter?12:6}>
              <CustomFormField
                title={"Relocation"}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      title: "Relocation",
                      defaultValue: relo,
                      options: [
                        "Limit result to job seekers who are willing to relocate",
                      ],
                      onChange: (value) => setRelo(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={filter?12:6}>
              <CustomFormField
                title={"Citizenship"}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      show: "row",
                      title: "Citizenship",
                      defaultValue: lstat,
                      options: ["US Citizen", "Parmanent Resident"],
                      onChange: (value) => setLstat(value),
                      multiple:true
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                title={"Security Clearance"}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      show: "row",
                      title: "Security Clearance",
                      defaultValue: gsc,
                      options: [
                        "Unspecified",
                        "Active Confodential",
                        "Active Secret",
                        "Active Top Secret",
                        "Active Top Secret/SCI",
                        "Active TS/SCI-CI Polygraph",
                        "Active TS/SCI-FS Polygraph",
                        "Other Active Clearance",
                      ],
                      onChange: (value) => setGsc(value),
                      multiple:true
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          p: 2,
        }}
      >
        <Button variant="text" color="error" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" onClick={handlePowerSearch}>Search</Button>
      </Box>
    </Box>
  );
};

export default JobBoardMonsterPowerSearch;
