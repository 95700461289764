import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  NativeSelect,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import theme from "../../Theme";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import ViewCompactRoundedIcon from "@mui/icons-material/ViewCompactRounded";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContactPageIcon from "@mui/icons-material/ContactPage";
//////////////
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CopyToClipboardButton,
  CustomFormField,
  FilterHeader,
  JobPostingForm,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
  AddNewJobPost,
  KeyData,
  JobBoardsAddNotes,
  BDMCustomeDateFilter,
} from "../../Components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNoteViaApi,
  awsResumeUrl,
  changeApplicantStatusViaApi,
  changeJobStatusViaApi,
  jobCloseRequestAdminViaApi,
  jobPostAssignedToRecruiterViaApi,
  searchDateJobPostingDataViaApi,
  successAwsResumeUrl,
  jobPostRecruiterViaApi,
} from "../../store/recruiter/action";
import { getAllUsers } from "../../store/auth/login/actions";
import dayjs from "dayjs";
import moment from "moment-timezone";

import {
  addIndustriesNamesViaApi,
  getAllIndustriesViaApi,
  getAllTemplatesViaApi,
  removeNewCompany,
  stopLoadingRedux,
} from "../../store/actions";
import { ShimmerTable } from "react-shimmer-effects";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { deepOrange } from "@mui/material/colors";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import applicantStatusData from "../../Components/StatusElements/applicantStatus";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import CandidateSendMail from "../../Components/Modals/CandidateSendMail";
import ReactQuill from "react-quill";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import { debounce } from "lodash";
import { GenerateJobString } from "../../utils/GenerateJobString";
import { TrimFields } from "../../utils/TrimFields";
import JobPostingSelectActionType from "../../Components/JobPostingSelectActionType";
import CommanFilters from "../../utils/CommanFilters";
const defaultJobDetails = {
  jobCode: "",
  jobTitle: "",
  jobWebsite: "",
  clientBillRateSalary: {
    currency: "USD",
    amount: "",
    paySchedule: "",
    jobType: "",
  },
  payRateSalary: {
    currency: "USD",
    minAmount: "",
    maxAmount: "",
    paySchedule: "",
    jobType: "",
  },
  jobStartDate: new Date(),
  jobEndDate: null,
  respondBy: "",
  remoteJob: "",
  expensesPaid: "",
  country: "USA",
  states: "",
  location: "",
  jobStatus: "Active",
  jobType: "",
  requiredHourWeek: "",
  client: "",
  clientManager: "",
  endClient: "",
  clientJobID: "",
  requiredDocuments: [],
  turnoutTime: {
    time: "",
    dates: "",
  },
  priority: "",
  clientCategory: "",
  duration: "",
  additionalDetails: "",
  areaCode: "",
  workAuthorization: [],
  interviewMode: "",
  applicationForm: "",
  clearance: "",
  address: "",
  employmentTestTemplate: "",
  employmentLevel: "",
};
const defaultSkills = {
  industry: "",
  degree: "",
  experience: {
    min: "",
    max: "",
  },
  evaluationTemplate: "",
  primarySkills: [],
  secondarySkills: "",
  languages: [],
};
const defaultOrganizationalInformation = {
  numberofPositions: "",
  maximumAllowedSubmission: "",
  taxTerms: [],
  salesManager: "",
  department: "",
  recruitmentManager: "",
  assignedTo: "",
  primaryRecruiter: "",
  comments: "",
  additionalNotifications: "",
  careerPortalPublishedDate: null,
  jobDescription: "",
};
const TableFilterHeader = ({
  params,
  type = "line", // select, multiselect
  options = [],
  selectedValue = type === "multiselect" ? [] : "",
  setter,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    if(setter){
      setter('')
    }
  };

  const SectionHeader = ({ title }) => (
    <InputLabel
      id={`${title}-label`}
      sx={{ fontSize: "12px", fontWeight: 600 }}
    >
      {title}
    </InputLabel>
  );
  const handleSearch=(event)=>{
    const searchValue = event.target.value; // Get the value from the input field
      if(setter){
        setter(searchValue)
      }
  }
  const handleSelectMultiple=(event)=>{
    const { value } = event.target;
    if(setter){
      setter(value)
    }
  }
  const handleDateChange=(value)=>{
    if(setter){
      setter(value)
    }  
  }
  return (
    <React.Fragment>
      {!showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box flex={1}>
            <Typography
              noWrap
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "15px",
                color: theme.palette.primary.main,
              }}
            >
              {params.headerName}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="primary">
              <FilterListIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
      {showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box flex={1}>
            <SectionHeader title={params.headerName} />
            {type==='date'?(
              <>
              <BDMCustomeDateFilter handleDateChange={handleDateChange} onlyTitle/>
              </>
            ):(
              <>
            {type !== "line" ? (
              <Select
                labelId={`${params.headerName}-label`}
                id={params.headerName}
                label={params.headerName}
                MenuProps={{}}
                variant="standard"
                fullWidth
                size="small"
                // multiple={type === "multiselect" ? true : false}
                multiple={type === "multiselect"}
                value={selectedValue || []}
                sx={{ fontSize: "12px", fontWeight: 600 }}
                onChange={handleSelectMultiple}
              >
                {options &&
                  options.map((item, key) => (
                    <MenuItem
                      value={item}
                      key={key}
                      sx={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <TextField
                variant="standard"
                //label={`${params.colDef.headerName} Filter`}
                placeholder={`${params.headerName} Filter`}
                fullWidth
                value={selectedValue}
                InputProps={{
                  style: { fontSize: "12px" }, // Set font size for the input element
                }}
                onChange={handleSearch}
              />
            )}
            </>
          )}
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="error">
              <FilterListOffIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

const JobPosts = () => {
  const {findUserName}=CommanFilters
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
 
  const currentCompany = useSelector((state) => state.Search.newCompany);
  const { applicantStatus, jobStatus } = applicantStatusData;
  const Applicants = useSelector((state) => state.Recruiters?.applicants);
  const ApplicantsData = Array.isArray(Applicants) ? Applicants : [];
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = industries.map((item) => item.industryName) || [];
  const client = storeData.clientDb;
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
    dispatch(getAllTemplatesViaApi(action));
    dispatch(getAllIndustriesViaApi(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

// Get all `userSudoFirstName` values for users not in the RATeam, removing empty or undefined names
const filteredUserFirstNames = allUsers
  .filter(user => !user.userType.includes("RATeam")) // Exclude users in RATeam
  .map(user => user.userSudoFirstName)              // Extract the names
  .filter(name => name);

  const [openRows, setOpenRows] = useState({});
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [selectedJodCode, setSelectedJodCode] = useState(""); // Add this line
  const [selectOptionJobPost, setSelectOptionJobPost] = useState("Submit");
  const [filterJobPostChange, setFilterJobPostChange] = useState([]);
  const [selectedJobStatus, setSelectedJobStatus] = useState("");
  const [bdmCompanyId, setBdmCompanyId] = useState("");
  const [takeJobTitle, setTakeJobTitle] = useState("");

  const [modalAssignOpenApplicantStatus, setModalAssignOpenApplicantStatus] =
    useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState("");
  const [selectedApplicantStatus, setSelectedApplicantStatus] = useState("");


  ///jobPostingFilters
  const [jobPostingJobCode,setJobPostingJobCode]=useState('')
  const [jobPostingJobTitle,setJobPostingJobTitle]=useState('')
  const [jobPostingCompanyName,setJobPostingCompanyName]=useState('')
  const [jobPostingLocation,setJobPostingLocation]=useState('')
  const [jobPostingJobStatus,setJobPostingJobStatus]=useState([])
  const [jobPostingClientBill,setJobPostingClientBill]=useState('') 
  const [jobPostingPayBill,setJobPostingPayBill]=useState('') 
  const [jobPostingDeliveryManger,setJobPostingDeliveryManger]=useState([]) 
  const [jobPostingRecruiter,setJobPostingRecruiter]=useState([])
  const [jobPostingCreatedBy,setJobPostingCreatedBy]=useState([])
  const [jobPostingCreatedOn,setJobPostingCreatedOn]=useState([])
  const [jobPostingMinSal,setJobPostingMinSal]=useState('')
  const [jobPostingMaxSal,setJobPostingMaxSal]=useState('')




  //// handle new job add modal
  const [isNewJobModalOpen, setIsNewJobModalOpen] = useState(false);
  const handleNewJobModalOpen = () => {
    setIsNewJobModalOpen(true);
  };
  const handleNewJobModalClose = () => {
    setIsNewJobModalOpen(false);
  };


  
  ///////
  //// job close request to Admin
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closeReason, setCloseReason] = useState("");
  ///
  const quillRef = useRef(null);
  const handleSelectJobStatus = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleModalClose = () => {
    setIsModalOpen(!isModalOpen);
    setCloseReason("");
  };

  const handleToggleCollapse = (rowId) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [rowId]: !prevOpenRows[rowId],
    }));
  };
  // const handleNoteDtawer = (rowId) => {
  //   setSelectedJodCode(rowId); // Set the selected job code
  //   setOpenNoteDrawer(!openNoteDrawer);
  // };

  const handleNoteDrawer = (rowId, jobId) => {
    setSelectedJodCode(rowId); // Set the selected job code
    setOpenNoteDrawer(!openNoteDrawer);
    setAssignJobPostId(jobId);
    setNote(""); // Clear any existing note
    setNoteError("");
  };
  const handleCloseNote=()=>{
    setOpenNoteDrawer(!openNoteDrawer);
    setNote(""); // Clear any existing note
  }
  const handleModalAssignOpenApplicantStatus = (params) => {
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId(params.row.jobPostingId);
    setSelectedApplicantStatus(params.row.applicantStatus);
    setSelectedApplicantId(params.row._id);
  };

  useEffect(() => {
    const action = {
      client: client,
      page: "jobpage",
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  // const checkRecruiter = storeData.userRole === "Recruiter";
  let filterRecruiter = Array.isArray(JobPosting)
    ? JobPosting.filter((job) => job.status === "Submit")
    : [];

  function getDisplayString(fullString) {
    // Split the string by the hyphen and remove the last part (timestamp)
    const parts = fullString.split("-");

    // Join the first two parts to get the display string
    const displayString = `${parts[0]}-${parts[1]}`;

    return displayString;
  }
  const handleChangeJobPost = (value) => {
    setSelectOptionJobPost(value);
    let filterRecruiterChange = [];
    filterRecruiterChange = Array.isArray(JobPosting)
      ? JobPosting.filter((job) => job.status === value)
      : [];
    if (storeData.userType.includes("BDM")) {
      filterRecruiterChange = Array.isArray(JobPosting)
        ? JobPosting.filter(
            (job) => job.status === value && job.addedBy === storeData._id
          )
        : [];
    }
    const changeJobPostingData = Array.isArray(filterRecruiterChange)
      ? filterRecruiterChange.map((data, index) => {
          const assignedToUser = allUsers.find(
            (user) => user._id === data.assignedTo
          );
          const createdByUser = allUsers.find(
            (user) => user._id === data.addedBy
          );
          const deliveryManager = allUsers.find(
            (user) => user._id === data.assignedBy
          );
          return {
            id: index,
            jobPostingId: data?._id || "",
            jobCode: data.jobDetails?.jobCode || "",
            companyName: data.jobDetails?.client || "",
            jobTitle: data.jobDetails?.jobTitle || "",
            jobWebsite: data.jobDetails?.jobWebsite || "",
            jobLocation: data.jobDetails?.location || "",
            jobStatus: data.jobStatus?.length
              ? data.jobStatus[data.jobStatus.length - 1].status
              : "",
            approvalStatus:
              data.jobStatus && data.jobStatus.length > 0
                ? data.jobStatus[data.jobStatus.length - 1].approvalStatus
                : "",

            assignedTo: assignedToUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
            assignedToRecruiters: data?.assignedToRecruiters || [],
            assignedToID: data?.assignedTo || "", // Adjust the property based on your user object structure
            jobCreatedBy: createdByUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
            jobCreatedOn: formatDateForDisplay(data.addedOn) || "",
            deliveryManager: deliveryManager?.userSudoFirstName || "",
            notes: data.notes||[],
            // Uncomment and adjust the following lines as needed
            clientBill: `${
              data.jobDetails?.clientBillRateSalary?.currency || ""
            }/${data.jobDetails?.clientBillRateSalary?.amount || ""}/${
              data.jobDetails?.clientBillRateSalary?.paySchedule || ""
            }/${data.jobDetails?.clientBillRateSalary?.jobType || ""}`,
            payBill: `${data.jobDetails?.payRateSalary?.currency || ""}/${
              data.jobDetails?.payRateSalary?.paySchedule || ""
            }/${data.jobDetails?.payRateSalary?.jobType || ""}`,
            minSalary: `${data.jobDetails?.payRateSalary?.minAmount || ""}/`,
            maxSalary: `${data.jobDetails?.payRateSalary?.maxAmount || ""}/`,
            jobDetails: data.jobDetails,
            skills: data.skills,
            submissions: data.submission || [],
            bdmCompanyId: data.bdmCompanyId,
            organizationalInformation: data.organizationalInformation,
            note:data?.note||[]
          };
        })
      : [];

    setFilterJobPostChange(changeJobPostingData);
  };

  const filterJobPostingData = Array.isArray(filterRecruiter)
    ? filterRecruiter.map((data, index) => {
        const assignedToUser = allUsers.find(
          (user) => user._id === data.assignedTo
        );
        const createdByUser = allUsers.find(
          (user) => user._id === data.addedBy
        );
        const deliveryManager = allUsers.find(
          (user) => user._id === data.assignedBy
        );
        return {
          id: index,
          jobPostingId: data?._id || "",
          jobCode: data.jobDetails?.jobCode || "",
          companyName: data.jobDetails?.client || "",
          jobTitle: data.jobDetails?.jobTitle || "",
          jobWebsite: data.jobDetails?.jobWebsite || "",
          jobLocation: data.jobDetails?.location || "",
          jobStatus: data.jobStatus?.length
            ? data.jobStatus[data.jobStatus.length - 1].status
            : "",
          approvalStatus:
            data.jobStatus && data.jobStatus.length > 0
              ? data.jobStatus[data.jobStatus.length - 1].approvalStatus
              : "",
          //adding these three to avoid breaking of existing codebase setup
          createdBy: createdByUser || {},
          createdDate: data.addedOn || "",
          jobStatusArray: data.jobStatus?.length ? data.jobStatus : [],
          notes:data?.notes || [],
          assignedTo: assignedToUser?.userSudoFirstName || "Not Assign", // Adjust the property based on your user object structure
          assignedToID: data?.assignedTo || "", // Adjust the property based on your user object structure
          assignedToRecruiters: data?.assignedToRecruiters || [],
          jobCreatedBy: createdByUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
          jobCreatedOn: formatDateForDisplay(data.addedOn) || "",
          deliveryManager: deliveryManager?.userSudoFirstName || "",
          note:data?.note||[],
          // Uncomment and adjust the following lines as needed
          clientBill: `${
            data.jobDetails?.clientBillRateSalary?.currency || ""
          }/${data.jobDetails?.clientBillRateSalary?.amount || ""}/${
            data.jobDetails?.clientBillRateSalary?.paySchedule || ""
          }/${data.jobDetails?.clientBillRateSalary?.jobType || ""}`,
          payBill: `${data.jobDetails?.payRateSalary?.currency || ""}/${
            data.jobDetails?.payRateSalary?.paySchedule || ""
          }/${data.jobDetails?.payRateSalary?.jobType || ""}`,
          minSalary: `${data.jobDetails?.payRateSalary?.minAmount || ""}/`,
          maxSalary: `${data.jobDetails?.payRateSalary?.maxAmount || ""}/`,
          jobDetails: data.jobDetails,
          skills: data.skills,
          submissions: data.submissions || [],
          bdmCompanyId: data.bdmCompanyId,
          organizationalInformation: data.organizationalInformation,
        };
      })
    : [];
  const JobPostingData = Array.isArray(JobPosting)
    ? JobPosting.filter((job) => job.status === "Submit")
    : [];

  const filterApplicantsByJob = (jobPosts, applicants) => {
    return jobPosts.map((job, index) => {
      // Get applicant IDs related to the
      const relevantApplicants = applicants.filter(
        (applicant) =>
          Array.isArray(applicant.tagJobs) &&
          applicant.tagJobs.some((tagJob) => tagJob.jobPostId === job._id)
      );
      return {
        jobId: job._id,
        submissions: relevantApplicants,
      };
    });
  };

  const filterApplicantByJobData = filterApplicantsByJob(
    JobPostingData,
    ApplicantsData
  );

  const getSubmissionCount = (jobId) => {
    const submission = filterApplicantByJobData.find(
      (item) => item.jobId === jobId
    );
    return submission ? submission.submissions.length : 0;
  };

  const recruiterIds = allUsers
    .filter(
      (user) =>
        user.userType.includes("RecruitmentTeam") ||
        user.userRole === "DeliveryManager"
    )
    .map((user) => user._id);

  const [modalAssignOpen, setModalAssignOpen] = useState(false);
  const [assignedRecruiter, setAssignedRecruiter] = useState([]);
  const [assignJobPostId, setAssignJobPostId] = useState("");
  const [modalStatusChange, setModalStatusChange] = useState(false);
  const [note, setNote] = useState("");
  const [noteTask,setNoteTask]=useState('')
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [noteError, setNoteError] = useState("");

  const handleModalAssignOpen = (data, params) => {
    setModalAssignOpen(!modalAssignOpen);
    setAssignedRecruiter(data);
    setAssignJobPostId(params.row.jobPostingId);
  };
  const handleModalAssignCancel = () => {
    setModalAssignOpen(!modalAssignOpen);
    setAssignedRecruiter([]);
    setAssignJobPostId("");
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // `value` is an array of selected recruiterIDs
    setAssignedRecruiter(
      value.map((recruiterID) => {
        // Check if the recruiterID already exists in assignedRecruiter
        const existingRecruiter = assignedRecruiter.find(
          (recruiter) => recruiter.recruiterID === recruiterID
        );
        return (
          existingRecruiter || {
            recruiterID,
            assignedBy: storeData._id,
            status: "Assigned",
            addedOn: new Date(),
          }
        );
      })
    );
  };
  const handleAssignRecruiter = () => {
    // Add your logic to handle the recruiter assignment here
    if (assignJobPostId && assignedRecruiter.length > 0) {
      const action = {
        jobPostingId: assignJobPostId,
        assignedToRecruiters: assignedRecruiter,
        client: client,
      };
      setLoading(true);
      dispatch(jobPostAssignedToRecruiterViaApi(action));

      handleModalAssignCancel();
      setTimeout(() => {
        const action = {
          client,
          page: "jobpage",
        };
        dispatch(searchDateJobPostingDataViaApi(action));
      }, 3000);
    }
  };
  const handleModalStatusChange = (params) => {
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId(params.row.jobPostingId);
    setSelectedJobStatus(params.row.jobStatus);
    setTakeJobTitle(params.row.jobTitle);
    setBdmCompanyId(params.row.bdmCompanyId);
  };

  const handleModalAssignStatusCancel = () => {
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId("");
  };
  const handleSaveJobStatus = () => {
    if (selectedJobStatus === "Closed" || selectedJobStatus === "On Hold") {
      handleSelectJobStatus();
      return "";
    }
    const action = {
      client: client,
      addedBy: storeData._id,
      addedOn: new Date(),
      jobId: assignJobPostId,
      newStatus: selectedJobStatus,
      takeJobTitle: takeJobTitle,
      bdmCompanyId: bdmCompanyId,
      page: "jobpage",
    };
    dispatch(changeJobStatusViaApi(action));
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId("");
    setLoading(true);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3000);
  };
  const handleModalConfirm = () => {
    const action = {
      client: client,
      addedBy: storeData._id,
      addedOn: new Date(),
      jobId: assignJobPostId,
      newStatus: selectedJobStatus,
      takeJobTitle: takeJobTitle,
      bdmCompanyId: bdmCompanyId,
      page: "jobpage",
      bdmRequestDate: new Date(),
      closeReason: closeReason,
      statusAdvice:
        checkboxes.find((checkbox) => checkbox.isChecked)?.label || "",
    };
    dispatch(jobCloseRequestAdminViaApi(action));
    setModalStatusChange(!modalStatusChange);
    setIsModalOpen(!isModalOpen);
    setCloseReason("");
    setAssignJobPostId("");
    setLoading(true);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3000);
  };
  const handleModalAssignApplicantStatusCancel = () => {
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId("");
  };
  const handleSaveApplicantStatus = async () => {
    const action = {
      client: storeData.clientDb,
      addedBy: storeData._id,
      addedOn: new Date(),
      newStatus: selectedApplicantStatus,
      jobId: assignJobPostId,
      applicantId: selectedApplicantId,
      page: "jobpage",
    };
    dispatch(changeApplicantStatusViaApi(action));
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId("");
    setLoading(true);
    setOpenRows([]);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3500);
  };
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  const fileExtension = AwsResumeUrl?.split(".")
    .pop()
    .split("?")[0]
    .toLowerCase();

  const flattenedRows = filterApplicantByJobData.flatMap((job) =>
    job.submissions.map((submission) => {
      // Find the relevant tagJob for this submission
      const tagJob = submission.tagJobs.find(
        (tagjob) => tagjob.jobPostId === job.jobId
      );
      // Find the user who submitted this based on the tagJob  sub.statusChanges[0].addedBy
      const submitedBy = allUsers.find(
        (user) => tagJob?.statusChanges?.[0]?.addedBy === user._id
      )?.userSudoFirstName;

      return {
        id: submission.applicantId,
        jobPostingId: job.jobId,
        applicantName: `${submission?.firstName || ""} ${
          submission?.middleName || ""
        } ${submission?.lastName || ""}`.trim(),
        mobileNumber: submission.mobileNumbers
          ? submission.mobileNumbers
              .filter((item) => item.number) // Filter out objects without an email
              .map((item) => item.number)
              .join(", ")
          : "",
        city: submission?.city || "",
        country: submission?.state || "",
        experience: `${submission?.experienceYears || 0}/${
          submission?.experienceMonths || 0
        }`,
        source: submission?.source || "",
        resume: submission.resumes || "",
        workAuthorization: submission.workAuthorization || "",
        availability: submission?.availability || "",
        applicantStatus: tagJob?.applicantStatus || "",
        payRate: `${submission.payRateSalary?.currency || ""}/${
          submission.payRateSalary?.paySchedule || ""
        }/${submission?.taxTerm || ""}`,
        submitedBy: submitedBy || "Unknown",
        submitedOn: formatDateForDisplay(tagJob?.statusChanges?.[0]?.addedOn),
        ...submission, // Spread to include other properties
      };
    })
  );
  const getFilteredRows = (jobPostingId) => {
    const trimmedJobPostingId = jobPostingId.trim();
    const filteredRows = flattenedRows.filter(
      (row) => row.jobPostingId.trim() === trimmedJobPostingId
    );
    return filteredRows;
  };
  const filterData = (data) => {
    return data.filter((job) => {
      // const createdName=findUserName(job.addedBy)
      const recruiterIds = job?.assignedToRecruiters || [];
    const recruiterNames = recruiterIds.map((id) => ({
      ...id,
      recruiterID: findUserName(allUsers,id.recruiterID), // Resolve recruiterID to a name
    }));
    const jobCreatedOnDate = new Date(job.jobCreatedOn);
    const startDate = new Date(jobPostingCreatedOn[0]); // Start of range
    const endDate = new Date(jobPostingCreatedOn[1]); // End of range
      return (
        (jobPostingJobCode ? job.jobCode.toLowerCase().includes(jobPostingJobCode.toLowerCase()) : true) &&
        (jobPostingJobTitle ? job.jobTitle.toLowerCase().includes(jobPostingJobTitle.toLowerCase()) : true) &&
        (jobPostingCompanyName ? job.companyName.toLowerCase().includes(jobPostingCompanyName.toLowerCase()) : true) &&
        (jobPostingLocation ? job.jobLocation.toLowerCase().includes(jobPostingLocation.toLowerCase()) : true) &&
        (jobPostingJobStatus.length > 0 ? jobPostingJobStatus.includes(job.jobStatus) : true) &&
        (jobPostingCreatedBy.length > 0 ? jobPostingCreatedBy.includes(job.jobCreatedBy) : true) &&
        (jobPostingDeliveryManger.length > 0 ? jobPostingDeliveryManger.includes(job.assignedTo) : true) &&
        (jobPostingRecruiter.length > 0
          ? jobPostingRecruiter.some((recruiter) =>
            recruiterNames.some((r) => r.recruiterID === recruiter)
            )
          : true) &&
          (jobPostingClientBill ? job.clientBill.toLowerCase().includes(jobPostingClientBill.toLowerCase()) : true) &&
          (jobPostingPayBill ? job.payBill.toLowerCase().includes(jobPostingPayBill.toLowerCase()) : true) &&
          (jobPostingMinSal ? job.minSalary.toLowerCase().includes(jobPostingMinSal.toLowerCase()) : true) &&
          (jobPostingMaxSal ? job.maxSalary.toLowerCase().includes(jobPostingMaxSal.toLowerCase()) : true) &&
        (jobPostingCreatedOn.length > 0
          ? jobCreatedOnDate >= startDate && jobCreatedOnDate <= endDate
          : true)
      );
    });
  };

  const dataToRender =
    selectOptionJobPost === "Draft"
      ? filterData(filterJobPostChange)
      : filterData(filterJobPostingData);

  const [selectAction, setSelectAction] = useState("");
  const [addNote, setAddNote] = useState("");
  const [noteAction, setNoteAction] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const handleChangeAction = (value) => {
    setSelectAction(value);
  };
  // Helper function to check for empty HTML content
  function isEmptyHtml(input) {
    // Remove all HTML tags and trim whitespace
    const strippedContent = input.replace(/<[^>]*>/g, "").trim();
    return strippedContent.length === 0;
  }
  const handleApplyAction = () => {
    if (selectedRowData.length === 0) {
      return enqueueSnackbar("Please select at least one row!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    // Check if an action is selected
    if (!selectAction) {
      return enqueueSnackbar("Please select an action!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (selectAction === "Add Notes") {
      if (!addNote || isEmptyHtml(addNote)) {
        return enqueueSnackbar("Please type note !", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
        selectedRowData.forEach((selectedRow) => {
          const noteData = [
            {
              addedBy: storeData._id,
              addedOn: new Date(),
              note: addNote,
              noteAction:noteAction,
            },
          ];
          const action = {
            client: client,
            jobId: selectedRow.jobPostingId,
            note: noteData,
          };
          dispatch(addNoteViaApi(action));
          setLoading(true);
          setTimeout(() => {
            dispatch(searchDateJobPostingDataViaApi(action));
          }, 3000);
        });
        setAddNote("");
        setNoteAction("");
        setSelectAction('')
        setSelectedRowData([])
        setSelectedRows([])
    }
  };

  const handleDeleteSelectData = (row) => {
    const filterSelectedRowData = selectedRowData.filter(
      (data) => data._id !== row._id
    );
    const filterSelectedRows = selectedRows.filter(
      (id) => id.applicantId !== row.applicantId
    );
    setSelectedRows(filterSelectedRows);
    setSelectedRowData(filterSelectedRowData);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };
  // Handle header checkbox change to select/deselect all
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataToRender); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };
  const handleCheckboxChangeNote = (event, row) => {
    const newSelectedRows = event.target.checked
      ? !selectedRows.some((r) => r.jobPostingId === row.jobPostingId) // Check if the applicantId is not already selected
        ? [...selectedRows, row] // Add the row if it's not already selected
        : selectedRows // If already selected, keep it as is
      : selectedRows.filter((r) => r.jobPostingId !== row.jobPostingId); // Remove the row if unchecked
    if (newSelectedRows.length === 0) {
      // setSelectAction('')
    }
    setSelectedRowData(newSelectedRows);
    setSelectedRows(newSelectedRows); // Update the selected rows state
  };
  const tableColumns = [
    {
      field: "",
      headerName: "",
      minWidth: 180,
      headerName: (
        <input
          type="checkbox"
          checked={
            selectedRows.length === dataToRender.length &&
            dataToRender.length > 0
          } // Check if all rows are selected
          onChange={handleHeaderCheckboxChange} // Handle select all/deselect all
        />
      ),

      renderCell: (params) => {
        const {
          status,
          notes,
          jobCode,
          assignedTo,
          jobPostingId,
          assignedToRecruiters,
          submissions,
        } = params.row;
        const submissionCount = getSubmissionCount(jobPostingId);
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              checked={selectedRows.some(
                (r) => r.jobPostingId === params.row.jobPostingId
              )} // Check if the row is selected
              onChange={(e) => handleCheckboxChangeNote(e, params.row)} // Handle checkbox toggle
            />
            <PersonIcon
              sx={{ fontSize: 20 }}
              color={assignedToRecruiters.length > 0 ? "success" : "disabled"}
            />
            <CircleIcon
              sx={{ fontSize: 12 }}
              color={status === "active" ? "success" : "disabled"}
            />

            {submissionCount > 0 && (
              <Box display={"flex"} alignItems={"center"} gap={0.2}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleToggleCollapse(params.row.id)}
                >
                  {openRows[params.row.id] ? (
                    <RemoveIcon sx={{ fontSize: 14 }} color="primary" />
                  ) : (
                    <AddIcon sx={{ fontSize: 14 }} color="primary" />
                  )}
                </IconButton>

                <Typography
                  sx={{
                    background: theme.palette.primary.dark,
                    color: "primary.contrastText",
                    fontSize: 11,
                    px: 0.5,
                    py: 0.2,
                    borderRadius: 1,
                  }}
                >
                  {submissionCount}
                </Typography>
              </Box>
            )}

            { notes && Array.isArray(notes) && notes.length > 0 && (
              <IconButton
                size="small"
                //onClick={() => handleNoteDtawer(params.row.id)}
                // onClick={() => handleNoteDtawer(params.row.jobCode)}
                onClick={() =>
                  handleNoteDrawer(params.row.jobCode, params.row.jobPostingId)
                }
              >
                <SpeakerNotesIcon sx={{ fontSize: 14 }} color="warning" />
              </IconButton>
            )}
            {/* {submissions && Array.isArray(submissions) && submissions.length > 0 && (
                  <IconButton  size="small" onClick={()=>handleToggleSendMailModal(submissions,jobPostingId)}>
                  <MailOutlineIcon  sx={{ fontSize: 14 }} color="error"/>
                </IconButton>
                )} */}
          </Box>
        );
      },
    },
    {
      field: "jobCode",
      headerName: "Job Code",
      minWidth: 320,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingJobCode} 
        selectedValue={jobPostingJobCode}
        title={"jobPostingJobCode"} />
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.2,
            }}
          >
            <Tooltip title={params.value} arrow placement="top">
              {/* <Link to={"/jobPostDetails"}> */}
              <Link
                component="button" // Use `button` for a clickable element
                to="/jobPostDetails"
                state={{ company: params.row }}
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.primary.dark,
                    textDecoration: "none",
                  }}
                >
                  {getDisplayString(params.value)}
                </Typography>
              </Link>
            </Tooltip>
            <Tooltip title={"Quick View"} arrow placement="top">
              <IconButton size="small">
                <ViewCompactRoundedIcon sx={{ fontSize: 14 }} color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <CopyToClipboardButton text={params.value} />
        </Box>
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      minWidth: 250,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingJobTitle} 
        selectedValue={jobPostingJobTitle}
         />
      ),
      renderCell: (params) => (
        <Tooltip title={params.value} arrow placement="top">
          <Typography noWrap sx={{ fontSize: "14px" }}>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 250,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingCompanyName} 
        selectedValue={jobPostingCompanyName}
         />
      ),
      renderCell: (params) => (
        <Tooltip title={params.value} arrow placement="top">
          <Typography noWrap sx={{ fontSize: "14px" }}>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingLocation} 
        selectedValue={jobPostingLocation}
         />
      ),
    },
    {
      field: "jobStatus",
      headerName: "Job Status",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"multiselect"} 
        setter={setJobPostingJobStatus} 
        selectedValue={jobPostingJobStatus}
        options={jobStatus}
         />
      ),
      renderCell: (params) => {
        const approvalStatus = params.row.approvalStatus;
        const orignalStatus = params.value;
        const status =
          approvalStatus === false ? "Pending for Approval" : orignalStatus;

        return (
          <Box>
            <Chip
              label={status}
              color={
                status === "Active"
                  ? "success"
                  : status === "Draft"
                  ? "warning"
                  : "primary"
              }
              variant="outlined"
              size="small"
              sx={{ px: 0.5, lineHeight: 1 }}
              onClick={() => handleModalStatusChange(params)}
            />
          </Box>
        );
      },
    },
    {
      field: "clientBill",
      headerName: "Client Bill",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingClientBill} 
        selectedValue={jobPostingClientBill}
         />
      ),
    },
    {
      field: "payBill",
      headerName: "Pay Bill",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingPayBill} 
        selectedValue={jobPostingPayBill}
         />
      ),
    },
    {
      field: "assignedTo",
      headerName: "Delivery Manager",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} 
        type={"multiselect"} 
         options={filteredUserFirstNames} 
          setter={setJobPostingDeliveryManger} 
          selectedValue={jobPostingDeliveryManger} />
      ),
    },
    {
      field: "",
      headerName: "Recruiter",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} 
        type={"multiselect"} 
         options={filteredUserFirstNames} 
          setter={setJobPostingRecruiter} 
          selectedValue={jobPostingRecruiter} />
      ),
      renderCell: (params) => {
        const currentRecruiterIds = params.row.assignedToRecruiters || []; // Ensure it's an array

        if (
          (!Array.isArray(currentRecruiterIds) ||
            currentRecruiterIds.length === 0) &&
          (storeData.userType.includes("SuperAdmin") ||
            storeData.userType.includes("Admin") ||
            storeData.userType.includes("Developer") ||
            storeData.userLevel === "DeliveryManager")
        ) {
          return (
            <Box sx={{ flex: "1" }}>
              <Tooltip
                title={
                  currentRecruiterIds.length > 0
                    ? "Add more recruiters..."
                    : "Add recruiter"
                }
                placement="right-start"
                onClick={() =>
                  handleModalAssignOpen(currentRecruiterIds, params)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.2,
                    cursor: "pointer",
                  }}
                >
                  Add recruiter
                  <ControlPointIcon sx={{ fontSize: 16 }} />
                </Box>
              </Tooltip>
            </Box>
          );
        }
        const Unknown = "Unknown";
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.2,
              cursor: "pointer",
            }}
          >
            <AvatarGroup
              total={currentRecruiterIds.length}
              sx={{
                "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 12 },
              }}
            >
              {currentRecruiterIds.slice(0, 2).map((id) => {
                const recruiter = allUsers.find(
                  (user) => user._id === id.recruiterID
                );
                return recruiter ? (
                  <Avatar
                    key={id}
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={recruiter.userSudoFirstName}
                    title={`${recruiter.userSudoFirstName}${""}${
                      recruiter.userSudoLastName
                    }`}
                  >
                    {recruiter.userSudoFirstName.slice(0, 2).toUpperCase()}
                  </Avatar>
                ) : (
                  <Avatar
                    key={id}
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={Unknown}
                  >
                    {Unknown.slice(0, 2).toUpperCase()}
                  </Avatar>
                );
              })}
            </AvatarGroup>
            {currentRecruiterIds.length > 0 &&
              (storeData.userType.includes("SuperAdmin") ||
                storeData.userType.includes("Admin") ||
                storeData.userType.includes("Developer") ||
                storeData.userLevel === "DeliveryManager") && (
                <Tooltip
                  title={"Add more recruiters..."}
                  placement="right-start"
                  onClick={() =>
                    handleModalAssignOpen(currentRecruiterIds, params)
                  }
                >
                  <ControlPointIcon sx={{ fontSize: 16 }} />
                </Tooltip>
              )}
          </Box>
        );
      },
    },

    {
      field: "jobCreatedBy",
      headerName: "Job Created By",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} 
        type={"multiselect"} 
         options={filteredUserFirstNames} 
          setter={setJobPostingCreatedBy} 
          selectedValue={jobPostingCreatedBy} />
      ),
    },
    {
      field: "jobCreatedOn",
      headerName: "Job Created On",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"date"} 
        setter={setJobPostingCreatedOn} 
        selectedValue={jobPostingCreatedOn}
         />
      ),
    },
    {
      field: "minSalary",
      headerName: "Min Salary",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingMinSal} 
        selectedValue={jobPostingMinSal}
         />
      ),
    },
    {
      field: "maxSalary",
      headerName: "Max Salary",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} 
        setter={setJobPostingMaxSal} 
        selectedValue={jobPostingMaxSal}
         />
      ),
    },
  ];
  const nestedTableColunms = [
    {
      field: "id",
      headerName: "Submission Id",
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const applicantId = params?.row?.id;
        return (
          <Button
            component={Link}
            to={`/applicantDetails/${applicantId}`}
            target="_blank" // Opens in a new tab
            replace={true}
            variant="text"
            sx={{ p: 0, color: "text.main", fontSize: 14, fontWeight: "400" }}
          >
            {applicantId}
          </Button>
        );
      },
    },
    {
      field: "applicantName",
      headerName: "Applicant Name",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const applicantId = params?.row?.id;
        const applicantName = params?.value;
        return (
          <Button
            component={Link}
            to={`/applicantDetails/${applicantId}`}
            target="_blank" // Opens in a new tab
            replace={true}
            variant="text"
            sx={{ p: 0, color: "text.main", fontSize: 14, fontWeight: "400" }}
          >
            {applicantName}
          </Button>
        );
      },
    },
    {
      field: "workAuthorization",
      headerName: "Work Authorization",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        return <div>{params.value}</div>; // Render the cell value inside a div
      },
    },
    {
      field: "submissionScrore",
      headerName: "Submission Scrore",
      width: 190,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => (
        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 3 }}>
          <Box flex={1}>
            <LinearProgress
              variant="determinate"
              value={params.value}
              color={
                params.value < 50
                  ? "error"
                  : params.value >= 50 && params.value < 70
                  ? "warning"
                  : params.value >= 70 && params.value < 90
                  ? "info"
                  : params.value >= 90
                  ? "success"
                  : "primary"
              }
            />
          </Box>
          {params.value} %
        </Box>
      ),
    },
    {
      field: "city",
      headerName: "Location",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "source",
      headerName: "Source",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "resume",
      headerName: "Resume",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const lastDocument = params.value?.length ? params.value.at(-1) : {};
        return (
          <Box>
            <IconButton aria-label="expand row" size="small">
              <ContactPageIcon
                sx={{ fontSize: 16 }}
                onClick={() => handleAxiosLink(lastDocument?.serverLocation)}
              />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "revisionStatus",
      headerName: "Revision Status",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "applicantStatus",
      headerName: "Applicaton Status",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Chip
              label={params.value}
              color={params.value === "SubmitJob" ? "primary" : "success"}
              onClick={() => handleModalAssignOpenApplicantStatus(params)}
              variant="outlined"
              size="small"
              sx={{ px: 0.5, lineHeight: 1 }}
            />
          </Box>
        );
      },
    },
    {
      field: "outlookMSG",
      headerName: "Outlook MSG",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "billRate",
      headerName: "Bill Rate",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "payRate",
      headerName: "Pay Rate",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "employeerName",
      headerName: "Employeer Name",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "availability",
      headerName: "Availability",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "submitedBy",
      headerName: "Submited By",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "pwSubmissionType",
      headerName: "PW Submission Type",
      width: 220,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "noticePeriod",
      headerName: "Notice Period",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "currentCTC",
      headerName: "Current CTC",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "submitedOn",
      headerName: "Submited On",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <IconButton aria-label="expand row" size="small">
            <InsertDriveFileRoundedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ),
    },
  ];
  // const [isJobPostingModalOpen, setIsJobPostingModalOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState(defaultJobDetails);
  const [skills, setSkills] = useState(defaultSkills);
  const [jobStatusPost, setStatusPost] = useState([
    {
      addedBy: storeData._id,
      addedOn: new Date(),
      status: "Active",
    },
  ]);
  const [assignedTo, setAssignedTo] = useState("");
  const [jobNotifyTo,setJobNotifyTo]=useState([]);
  const [organizationalInformation, setOrganizationalInformation] = useState(
    defaultOrganizationalInformation
  );
  const [jobPostStatus, setJobPostStatus] = useState("");
  const [jobPostingId, setJobPostingId] = useState("");
  const [errors, setErrors] = useState({});

  const handleJobPostingCloseModal = () => {
    setJobDetails(defaultJobDetails);
    setSkills(defaultSkills);
    setOrganizationalInformation(defaultOrganizationalInformation);
    // setIsJobPostingModalOpen(!isJobPostingModalOpen);
    dispatch(removeNewCompany());
    setErrors({});
    setAssignedTo("");
    setJobNotifyTo([])
  };

  const updateJobPostingDetails = (jobData) => {
    if (!jobDetails.jobCode) {
      setJobDetails((job) => ({
        ...job,
        jobCode: GenerateJobString(storeData, jobData) || "",
      }));
    }
    if (!jobDetails.jobTitle) {
      setJobDetails((job) => ({
        ...job,
        jobTitle: jobData?.jobDetails[0]?.jobTitle || "",
      }));
    }
    if (!jobDetails.jobWebsite) {
      setJobDetails((job) => ({
        ...job,
        jobWebsite: jobData?.jobDetails[0]?.jobWebsite || "",
      }));
    }
    setOrganizationalInformation((organation) => ({
      ...organation,
      jobDescription: jobData?.jobDetails[0]?.jobDescription || "",
    }));

    const jobLocation = jobData?.jobDetails[0]?.jobLocation || "";
    // const [city = "", state = ""] = jobLocation.split(", ");
    const [city, state] = jobLocation.includes(",")
      ? jobLocation.split(",").map((str) => str.trim())
      : [jobLocation.trim(), jobLocation.trim()];
    const companyName = jobData?.companyName || "";
    setJobDetails((job) => ({
      ...job,
      states: state, // Note: "states" was used instead of "state"
      location: city,
      client: companyName,
    }));
  };

  useEffect(() => {
    //passing data through here to avoid stale closure
    if (currentCompany && currentCompany._id) {
      updateJobPostingDetails(currentCompany);
    }
    const action = {
      client: client,
      page: "jobpage",
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  }, [currentCompany && currentCompany._id]);
  const validateForm = () => {
    const newErrors = {};
    [
      { type: "jobCode", keyword: "Job Code" },
      { type: "jobTitle", keyword: "Job Title" },
      { type: "country", keyword: "Country" },
      { type: "remoteJob", keyword: "Remote Job status" },
      { type: "location", keyword: "Location" },
    ].some((list) => {
      if (!jobDetails[list.type]) {
        newErrors[list.type] = `${list.keyword} is required.`;
      }
    });

    // Validate duration
    if (jobDetails.jobType !== "Full Time" && !jobDetails?.duration) {
      newErrors.duration = "Duration is required";
    }
    [
      { type: "min", keyword: "Min", errType: "skillsExperienceMin" },
      { type: "max", keyword: "Max", errType: "skillsExperienceMax" },
    ].some((list) => {
      if (!skills?.experience[list.type]) {
        newErrors[list.errType] = `${list.keyword} is required.`;
      }
    });
    // Validate primarySkills
    if (!skills?.primarySkills || skills.primarySkills.length === 0) {
      newErrors.primarySkills = "At least one primary skill is required";
    }
    if (!jobNotifyTo || jobNotifyTo.length === 0) {
      newErrors.nofity = "At least one notify is required";
    }

    if (!organizationalInformation?.numberofPositions) {
      newErrors.numberofPositions = "NumberofPositions is required";
    }
    if (
      !organizationalInformation?.taxTerms ||
      organizationalInformation.taxTerms.length === 0
    ) {
      newErrors.taxTerms = "NumberofPositions is required";
    }

    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };
  const handleJobPostingSaveDraft = debounce(() => {
    const action = {
      addedBy: storeData._id,
      addedOn: new Date(),
      jobDetails: jobDetails,
      skills: skills,
      organizationalInformation: organizationalInformation,
      bdmCompanyId: currentCompany._id,
      status: "Draft",
      assignedTo: assignedTo,
      jobNotifyTo:jobNotifyTo,
      id: jobPostingId,
      industrieName: skills.industry,
      client: client,
      jobStatus: [
        { addedBy: storeData._id, addedOn: new Date(), status: "Active" },
      ],
    };
    dispatch(jobPostRecruiterViaApi(action));
    if (action.industrieName) {
      dispatch(addIndustriesNamesViaApi(action));
    }
    handleJobPostingCloseModal();
  }, 1000);
  const handleJobPostingSaveSubmit = debounce(() => {
    if (validateForm()) {
      // Trim spaces from jobDetails, skills, and organizationalInformation
      const trimmedJobDetails = TrimFields(jobDetails);
      const trimmedSkills = TrimFields(skills);
      const trimmedOrganizationalInformation = TrimFields(
        organizationalInformation
      );
      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobDetails: trimmedJobDetails,
        skills: trimmedSkills,
        organizationalInformation: trimmedOrganizationalInformation,
        bdmCompanyId: currentCompany._id,
        assignedTo: assignedTo,
        jobNotifyTo: jobNotifyTo,
        status: "Submit",
        id: jobPostingId,
        industrieName: skills.industry,
        client: client,
        jobStatus: [
          { addedBy: storeData._id, addedOn: new Date(), status: "Active" },
        ],
      };
      dispatch(jobPostRecruiterViaApi(action));
      if (action.industrieName) {
        dispatch(addIndustriesNamesViaApi(action));
      }

      handleJobPostingCloseModal();
    }
  }, 1000);

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [applicantsList, setApplicantsList] = useState([]);
  const [jobId, setJobId] = useState("");
  // const [applicantId,setAppl]
  const handleToggleSendMailModal = (row, jobPostingId) => {
    let listData = [];

    // Ensure row is an array
    const rowArray = Array.isArray(row) ? row : [];

    rowArray.forEach((applicant) => {
      const foundApplicant = ApplicantsData.find(
        (applicantData) => applicantData._id === applicant.applicantId
      );
      if (foundApplicant) {
        listData.push(foundApplicant);
      }
    });
    setJobId(jobPostingId);

    setApplicantsList(listData);
    setSendMailModal(!sendMailModal);
  };
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "DNC", isChecked: false },
    { id: 2, label: "Blocked", isChecked: false },
  ]);
  // Handler for checkbox change
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: checkbox.id === id,
    }));
    setCheckboxes(updatedCheckboxes);
  };
  const handleNote = (value) => {
    setNote(value);
  };
  const handleNoteSubmit = async () => {
    if (!note || isEmptyHtml(note)) {
      return enqueueSnackbar("Please type note !", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
      const noteData = [
        {
          addedBy: storeData._id,
          addedOn: new Date(),
          note: note,
        },
      ];
      const action = {
        client: client,
        jobId: assignJobPostId,
        note: noteData,
      };
      dispatch(addNoteViaApi(action));
      setLoading(true);
      setNote("");
      setOpenNoteDrawer(false)
      setTimeout(() => {
        dispatch(searchDateJobPostingDataViaApi(action));
        // dispatch(jobPostRecruiterViaApi(action))
      }, 3000);
   
  };

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Posts"}>
          <Box>
            {/* {(storeData.userType.includes("SuperAdmin") ||
              storeData.userType.includes("Admin") ||
              storeData.userType.includes("Developer") ||
              storeData.userType.includes("BDM")) && ( */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={() => handleChangeJobPost("Submit")}
                variant={
                  selectOptionJobPost === "Submit" ? "contained" : "outlined"
                }
              >
                Submit Job Posts
              </Button>
              <Button
                onClick={() => handleChangeJobPost("Draft")}
                color="error"
                variant={
                  selectOptionJobPost === "Draft" ? "contained" : "outlined"
                }
              >
                Draft Job Posts
              </Button>
              {/* <Button color="error" variant="contained" onClick={() => {setIsJobPostingModalOpen(true)}}> */}
              {/* <Button color="error" variant="contained"> */}
              <Button
                color="error"
                variant="contained"
                onClick={() => handleNewJobModalOpen()}
              >
                Add New Job Post
              </Button>
            </Box>
            {/* // )} */}

            {/* Add New Job Modal Component */}
            <AddNewJobPost
              open={isNewJobModalOpen}
              handleClose={handleNewJobModalClose}
              setLoading={setLoading}
            />

            {loading ? (
              <>
                <ShimmerTable row={5} col={5} />
              </>
            ) : (
              <>
                <Grid container spacing={2} height={"100%"}>
                  <Grid item xs={selectedRows.length > 0 ? 9 : 12}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        mt: 3,
                        overflowX: "auto",
                        maxHeight: "660px",
                        overflowY: "auto !important",
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      }}
                    >
                      <Table aria-label="spanning table" size="small">
                        <TableHead>
                          <TableRow sx={{ height: 60 }}>
                            {tableColumns.map((column) => (
                              <TableCell
                                key={column.field}
                                sx={{
                                  maxWidth: column.minWidth,
                                  minWidth: column.minWidth,
                                  width: "auto",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {column.renderHeader ? (
                                    column.renderHeader({
                                      headerName: column.headerName,
                                    }) // Pass the header name as params
                                  ) : (
                                    <Typography
                                      noWrap
                                      sx={{
                                        backgroundColor: "#FFFFFF",
                                        fontSize: "15px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      {column.headerName}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {dataToRender.map((row) => (
                            <React.Fragment key={row.id}>
                              <TableRow>
                                {tableColumns.map((column) => (
                                  <TableCell
                                    key={column.field}
                                    sx={{
                                      maxWidth: column.minWidth,
                                      minWidth: column.minWidth,
                                      width: "auto",
                                    }}
                                  >
                                    {column.renderCell ? (
                                      column.renderCell({
                                        value: row[column.field],
                                        row,
                                      })
                                    ) : (
                                      <Typography noWrap sx={{ fontSize: 14 }}>
                                        {row[column.field]}
                                      </Typography>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  colSpan={tableColumns.length}
                                  sx={{ padding: 0 }}
                                >
                                  <Collapse
                                    in={openRows[row.id]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: theme.palette.grey[100],
                                        p: 2,
                                      }}
                                    >
                                      <DataGrid
                                        rows={getFilteredRows(row.jobPostingId)}
                                        columns={nestedTableColunms}
                                        disableRowSelectionOnClick
                                        disableColumnMenu
                                        disableColumnSorting
                                        disableColumnSelector
                                        disableColumnFilter
                                        disableColumnReorder
                                        disableColumnResize
                                        disableColumnSort
                                        hideFooter
                                        initialState={{
                                          pagination: {
                                            paginationModel: {
                                              page: 0,
                                              pageSize: 100,
                                            },
                                          },
                                        }}
                                        pageSizeOptions={[100]}
                                        getRowHeight={() => "auto"}
                                        autoHeight
                                        sx={{
                                          "& .MuiDataGrid-cell": {
                                            display: "flex",
                                            alignItems: "center !important",
                                          },
                                          [`& .MuiDataGrid-checkboxInput`]: {
                                            color: theme.palette.primary.main,
                                            p: 0,

                                            "&.Mui-checked": {
                                              color: theme.palette.primary.dark,
                                            },
                                          },
                                          //Scroller
                                          [`& .MuiDataGrid-scrollbar`]: {
                                            scrollbarWidth: "thin",
                                            scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                                          },
                                          [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                            {
                                              borderRadius: "10px", // Apply border radius
                                            },

                                          [`& .MuiDataGrid-scrollbar:hover`]: {
                                            scrollbarColor: `${theme.palette.primary.dark}`,
                                          },
                                        }}
                                      />
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[100]}
                      component="div"
                      count={dataToRender.length}
                      rowsPerPage={100}
                      page={0}
                      // onPageChange={handleChangePage}
                      // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                  {selectedRows.length > 0 && (
                    <Grid
                      item
                      xs={3}
                      sx={{
                        mt:3,
                        position: "sticky", // Sticks within the container
                        top: -15, // Sticky from the top
                        height: "80vh", // Full viewport height
                        zIndex: 1, // Ensure it stays on top of other content
                        
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          height: "100%",
                          
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            width: "100%",
                            top: 0,
                            bottom: 0,
                            height: "100%",
                          }}
                        >
                          <MainBackground height="full">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                gap: 2,
                                
                              }}
                            >
                              <Box>
                                <CustomFormField
                                  title={"Select Action "}
                                  components={[
                                    {
                                      type: "select",
                                      props: {
                                        title: "Action",
                                        options: [
                                          "Add Notes",
                                          // "Add Tag",
                                          // "Add to Folder",
                                          // "Add to Bench",
                                          // "Mark as Favorite",
                                          // "Remove Favorite Tag",
                                          // "Send Email",
                                          // "Submit to Job",
                                          // "Delete",
                                          // "Change Ownership",
                                          // "Add to Snip List",
                                          // "Add to Group",
                                          // "Tag to Job",
                                          // "Add Blacklist",
                                          // "Macro",
                                          // "Share Profiles",
                                          // "Share a Job",
                                          // "Archive",
                                          // "Download Resumes",
                                        ],
                                        //multiple: true,
                                        defaultValue: selectAction,
                                        onChange: (value) =>
                                          handleChangeAction(value),
                                      },
                                    },
                                  ]}
                                />
                              </Box>
                              <Box flex={1}>
                          <ScrollView>
                          <Box pr={2}>
                              <JobPostingSelectActionType
                                selectAction={selectAction}
                                addNote={addNote}
                                setAddNote={setAddNote}
                                setNoteAction={setNoteAction}
                                noteAction={noteAction}
                              />

                              <Box sx={{ flex: 1, mb: 4, pb: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 0.5,
                                    alignItems: "center",
                                    mb: 1,
                                    mt: 2,
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 18,
                                      height: 18,
                                      fontSize: 12,
                                      background: theme.palette.primary.main,
                                    }}
                                  >
                                    {selectedRows.length}
                                  </Avatar>
                                  <Typography>
                                    {selectedRows.length > 1
                                      ? `JobPosts`
                                      : `JobPost`}
                                  </Typography>
                                </Box>
                                <Box>
                                  {selectedRowData.length > 0 ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                      }}
                                    >
                                      {selectedRowData.map((row) => (
                                        <Box
                                          key={row.id}
                                          sx={{
                                            borderRadius: 2,
                                            p: 1,
                                            mx: 0.5,
                                            background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                                            border: `1px solid ${theme.palette.grey[200]}`,
                                          }}
                                        >
                                          <Box>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                columnGap: 1,
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <Typography
                                                sx={{ fontWeight: "bold" }}
                                                noWrap
                                              >
                                                {row.jobCode || ""}
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                noWrap
                                              >
                                                {row.jobTitle || ""}
                                              </Typography>
                                            </Box>
                                            <Divider />

                                            <Divider />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: 1,
                                              my: 1,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                justifyContent: "space-between",
                                                gap: 1,
                                              }}
                                            >
                                              <KeyData
                                                title={"CompanyName:"}
                                                value={row.companyName || ""}
                                              />
                                              <Divider
                                                orientation="vertical"
                                                flexItem
                                              />
                                              <KeyData
                                                title={"Pay Rate:"}
                                                value={row.payBill}
                                              />
                                            </Box>
                                            <Divider />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 1,
                                              justifyContent: "end",
                                            }}
                                          >
                                            <Tooltip title="Delete">
                                              <IconButton
                                                aria-label="Delete"
                                                size="xsmall"
                                                color="error"
                                              >
                                                <DeleteIcon
                                                  fontSize="inherit"
                                                  onClick={() =>
                                                    handleDeleteSelectData(row)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                  ) : (
                                    <Typography>No rows selected</Typography>
                                  )}
                                </Box>
                              </Box>

                              <Box>
                                <Button
                                  variant="contained"
                                  onClick={handleApplyAction}
                                >
                                  {" "}
                                  {selectAction ? selectAction : "Apply"}
                                </Button>
                              </Box>
                              </Box>
                              </ScrollView>
                              </Box>


                            </Box>
                          </MainBackground>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
      <JobBoardsAddNotes
          open={openNoteDrawer}
          close={handleCloseNote}
          allUsers={allUsers}
          notes={(
            JobPosting?.find(
              (job) => job._id === assignJobPostId
            )?.notes||[]
            // Applicants?.find(
            //   (internal) =>
            //     internal.applicantId === clickNoteApplicant?.applicantId
            // )?.note || []
          ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))}
          note={note}
          setNoteTask={setNoteTask}
          noteTask={noteTask}
          setNote={setNote}
          handleNoteSubmit={handleNoteSubmit}
          dotShowReference={'yes'}
          pageProps={'JobPosting'}

        />


      <Modal
        open={modalAssignOpen}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to Assign
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Assign</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={assignedRecruiter.map((item) => item.recruiterID)} // Extract recruiterID from assignedRecruiter
                onChange={handleChange} // Handle change to update the array
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((recruiterID) => {
                      const recruiter = allUsers.find(
                        (user) => user._id === recruiterID
                      );
                      return recruiter ? (
                        <Chip
                          key={recruiter._id}
                          label={recruiter.userSudoFirstName}
                        />
                      ) : (
                        <Chip key={recruiterID} label="Unknown" />
                      );
                    })}
                  </Box>
                )}
              >
                {recruiterIds.map((id) => {
                  const recruiter = allUsers.find((user) => user._id === id);
                  return (
                    <MenuItem key={id} value={id}>
                      {recruiter ? recruiter.userSudoFirstName : "Unknown"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleAssignRecruiter()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={modalStatusChange}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to assign a job status?
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="job-status-label">Job Status</InputLabel>

              <Select
                labelId="demo-single-select-label"
                id="demo-single-select"
                value={selectedJobStatus} // This holds the single selected status
                onChange={(e) => setSelectedJobStatus(e.target.value)} // Function to handle the change
                input={<OutlinedInput label="Job Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selectedJobStatus} />
                  </Box>
                )}
              >
                {jobStatus.map((status, id) => (
                  <MenuItem key={id} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignStatusCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveJobStatus()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={modalAssignOpenApplicantStatus}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to change a Applicant status?
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="job-status-label">Applicant Status</InputLabel>

              <Select
                labelId="demo-single-select-label"
                id="demo-single-select"
                value={selectedApplicantStatus} // This holds the single selected status
                onChange={(e) => setSelectedApplicantStatus(e.target.value)} // Function to handle the change
                input={<OutlinedInput label="Applicant Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selected} />
                  </Box>
                )}
              >
                {applicantStatus.map((status, id) => {
                  return (
                    <MenuItem key={id} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignApplicantStatusCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveApplicantStatus()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={modalStatusChange}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <SectionTitle title="Are you sure you want to assign a job status?" />
            <CustomFormField
              title={"Job Status"}
              components={[
                {
                  type: "select",
                  props: {
                    placeholder: "Job Status",
                    required: true,
                    defaultValue: selectedJobStatus,
                    options: jobStatus,
                    onChange: (value) => setSelectedJobStatus(value),
                  },
                },
              ]}
            />
            <Box />
            <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <Button
                color="error"
                variant="text"
                onClick={() => handleModalAssignStatusCancel()}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={() => handleSaveJobStatus()}>
                Save
              </Button>
            </Box>

            {/* <FormControl>
              <InputLabel id="job-status-label">Job Status</InputLabel>

              <Select
                labelId="demo-single-select-label"
                id="demo-single-select"
                value={selectedJobStatus} // This holds the single selected status
                onChange={(e) => setSelectedJobStatus(e.target.value)} // Function to handle the change
                input={<OutlinedInput label="Job Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selectedJobStatus} />
                  </Box>
                )}
              >
                {jobStatus.map((status, id) => (
                  <MenuItem key={id} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
          {/* <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignStatusCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveJobStatus()}
            >
              Save
            </Button>
          </Box> */}
        </Box>
      </Modal>
      <Modal
        open={openResumeModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        // onClose={handleCloseResumeModal} // Function to close modal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseResumeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Resume iframe or fallback */}
          <Modal
            open={openResumeModal}
            onClose={handleCloseResumeModal}
            aria-labelledby="resume-modal-title"
            aria-describedby="resume-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={handleCloseResumeModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              {/* Conditional rendering based on file type */}
              {AwsResumeUrl ? (
                <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
              ) : (
                <Typography variant="h6" textAlign="center">
                  No resume available.
                </Typography>
              )}
            </Box>
          </Modal>
        </Box>
      </Modal>
      <Modal
        // open={isJobPostingModalOpen}
        open={Boolean(currentCompany && currentCompany._id)}
        onClose={handleJobPostingCloseModal}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
            height: "100vh",
          }}
        >
          <Box>
            <Paper
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
                px: 4,
              }}
            >
              <Typography variant="h5" fontWeight={600}>
                Job Posting
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* <Button color="error" onClick={handleJobPostingCloseModal}> */}
                <Button color="error" onClick={handleJobPostingSaveDraft}>
                  Close
                </Button>
                {/* <Button
                      color="inherit"
                      variant="outlined"
                      // onClick={handleJobPostingSaveDraft}
                      // disabled={jobPostStatus === "Submit"}
                      type="button"
                    >
                      Save as a Draft
                    </Button> */}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleJobPostingSaveSubmit}
                >
                  Save and Submit
                </Button>
              </Box>
            </Paper>
          </Box>

          <Box flex={1} overflow={"auto"} py={1}>
            <JobPostingForm
              jobDetails={jobDetails}
              setJobDetails={setJobDetails}
              skills={skills}
              setSkills={setSkills}
              organizationalInformation={organizationalInformation}
              setOrganizationalInformation={setOrganizationalInformation}
              errors={errors}
              allUsers={allUsers}
              setAssignedTo={setAssignedTo}
              setJobNotifyTo={setJobNotifyTo}
              jobNotifyTo={jobNotifyTo}
              assignedTo={assignedTo}
              industryNames={industryNames}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={sendMailModal}
        onClose={handleToggleSendMailModal}
        aria-labelledby="send email"
        aria-describedby="send email modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CandidateSendMail
            close={handleToggleSendMailModal}
            applicantsList={applicantsList}
            jobId={jobId}
          />
        </Box>
      </Modal>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason for {selectedJobStatus}
          </Typography>

          <Box
            sx={{
              maxHeight: 200,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px", // width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.primary[400],
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 5,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={closeReason}
              onChange={setCloseReason}
              placeholder="Paste Email response/thread here.. "
              modules={{
                toolbar: false, // Disable the toolbar
              }}
            />
          </Box>

          <Box>
            <FormGroup sx={{ display: "flex", flexDirection: "column" }}>
              {checkboxes.map((checkbox) => (
                <FormControlLabel
                  key={checkbox.id}
                  control={
                    <Checkbox
                      checked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox.id)}
                    />
                  }
                  label={checkbox.label}
                />
              ))}
            </FormGroup>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleModalClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => handleModalConfirm()}
              variant="contained"
              color="primary"
              disabled={!closeReason.trim()}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default JobPosts;
