import React, { useState, useEffect, useRef } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Autocomplete,
  Link,
} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import {
  SnackbarComponent,
  MyCompaniesListPreview,
  FileUpload,
  DataWidget,
  MainBackground,
  SectionTitle,
  KeyData,
  FilterHeader,
  CopyToClipboardButton,
} from "../../Components";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector, useDispatch } from "react-redux";
import {
  addJobDataViaApi,
  addJobDetailsViaApi,
  deleteCompanyRedux,
  deleteCompanyViaApi,
  getAllIndustriesViaApi,
  getCompanies,
  industriesNamesViaApi,
} from "../../store/search/action";
import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MyCompaniesListView from "../../Components/Modals/MyCompaniesListView";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import theme from "../../Theme";
import MyCompaniesListSave from "../../Components/Modals/MyCompaniesListSave";
import dayjs from "dayjs";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "baseline",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 172, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 2,
        pb: 0.5,
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <GridToolbarQuickFilter sx={{ width: "100%" }} />
    </Box>
  );
}

const ContactView = ({
  data,
  key,
  color = "primary", //primary, secondary, info, success, warning, error
  isSelected,
  onClick,
}) => {
  return (
    <React.Fragment>
      <Box
        key={key}
        sx={{
          borderLeft:
            ((color === "primary" || color === "success") &&
              data.status === "Company blocked") ||
            data.status === "DNC"
              ? `3px solid ${theme.palette.error.main}`
              : `3px solid ${theme.palette[color].main}`,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.4,
          overflow: "hidden",
          cursor:
            data.status === "Company blocked" || data.status === "Company DNC"
              ? "not-allowed"
              : "pointer",
          backgroundColor: isSelected
            ? theme.palette.action.selected
            : "inherit",
          opacity:
            data.status === "Company blocked" || data.status === "Company DNC"
              ? 0.8
              : 1,
        }}
        // onClick={() =>
        //   data.status !== "Company blocked" &&
        //   data.status !== "Company DNC" &&
        //   onClick(data)
        // }
      >
        <Box>
          <Typography
            noWrap
            sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1.2 }}
          >
            {data.firstName} {data.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.designation}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            {/* Used: 35 */}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.emailId}
            <CopyToClipboardButton text={data.emailId} />
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            {data.phoneNumber}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const MyCompaniesList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData) || [];
  const client = storeData.clientDb;
  const companiesList =
    useSelector((state) => state.Search.companiesList) || [];

  const [totalCompaniesList, setTotalCompaniesList] = useState(
    companiesList || []
  );
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = Array.isArray(industries)
    ? industries.map((item) => item.industryName)
    : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [searchListCompanyName, setSearchListCompanyName] = useState("");
  const [searchListJobTitle, setSearchListJobTitle] = useState("");
  const [searchListJobLocation, setSearchListJobLocation] = useState("");

  useEffect(() => {
    // Update totalCompaniesList whenever companiesList changes
    setTotalCompaniesList(companiesList);
  }, [companiesList]);
  const storeDataId = storeData._id;

  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllIndustriesViaApi(action));
  }, [dispatch, storeDataId]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchListCompanyName={setSearchListCompanyName}
          selectedValue={searchListCompanyName}
          title={"listCompanyName"}
        />
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          return row.jobDetails[0].jobTitle;
        } else {
          return "--";
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchListJobTitle={setSearchListJobTitle}
          selectedValue={searchListJobTitle}
          title={"listJobTitle"}
        />
      ),
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      width: 180,

      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          return row.jobDetails[0].jobLocation;
        } else {
          return "--";
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchListJobLocation={setSearchListJobLocation}
          selectedValue={searchListJobLocation}
          title={"listJobLocation"}
        />
      ),
    },
    {
      field: "jobDescription",
      headerName: "Job Description",
      sortable: false,
      width: 180,

      renderCell: (params) => {
        if (params.row.jobDetails && params.row.jobDetails.length > 0) {
          return (
            <Link
              href={params.row.jobDetails[0].jobDescription}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "error.main", textDecoration: "none" }} // Optional: Apply custom styles
            >
              Description Link
            </Link>
          );
        } else {
          return "--";
        }
      },
    },
    {
      field: "contactDetails",
      headerName: "Contact Details",
      width: 180,

      valueGetter: (value, row) => {
        if (row.contactDetails && row.contactDetails.length > 0) {
          return row.contactDetails.length;
        } else {
          return "--";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Add/Edit">
            <IconButton
              size="small"
              color="success"
              onClick={() => handleOpen(params.row.id)}
            >
              <EditIcon fontSize="12" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview">
            <IconButton
              size="small"
              color="warning"
              onClick={() => handleViewData(params.row.id)}
            >
              <VisibilityIcon fontSize="12" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              size="small"
              color="error"
              onClick={() => toggleDeleteOrderModal(params.row.id)}
            >
              <DeleteIcon fontSize="12" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  const [selectedRow, setSelectedRow] = useState("");

  const handleCellClick = (params, event) => {
    event.stopPropagation(); // Prevent default cell selection behavior
    setSelectedRow(params.row);
  };

  const [addedDateTime, setAddedDateTime] = useState(new Date());
  const [contactDetails, setContactDetails] = useState([
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
  ]);

  const [jobDetails, setJobDetails] = useState([
    {
      addedBy: storeData._id,
      jobTitle: "",
      jobLocation: "",
      jobDescription: "",
      jobAddedOn: addedDateTime,
      status: "Active",
    },
  ]);
  const [comment, setComment] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobWebsiteError, setJobWebsiteError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [firstNameIndex, setFirstNameIndex] = useState("");

  //excel paste
  const [excelData, setExcelData] = useState([
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
  ]);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    const rows = pastedText.split("\n");

    const newData = rows
      .map((row) => {
        const [
          firstName = "",
          lastName = "",
          designation = "",
          emailId = "",
          phoneNumber = "",
        ] = row.split("\t");
        // Remove carriage return (\r) characters from phone number
        const cleanedPhoneNumber = phoneNumber.replace(/\r/g, "");
        return {
          addedBy: storeData._id,
          firstName,
          lastName,
          designation,
          emailId,
          phoneNumber: cleanedPhoneNumber,
        };
      })
      .filter(
        (contact) =>
          contact.firstName.trim() ||
          contact.lastName.trim() ||
          contact.designation.trim() ||
          contact.emailId.trim() ||
          contact.phoneNumber.trim()
      );

    let toastShown = false; // Flag to track if toast message has been shown

    setExcelData((prevData) => {
      // Filter out empty previous rows
      const nonEmptyPrevData = prevData.filter(
        (contact) =>
          (contact.firstName && contact.firstName.trim()) ||
          (contact.lastName && contact.lastName.trim()) ||
          (contact.designation && contact.designation.trim()) ||
          (contact.emailId && contact.emailId.trim()) ||
          (contact.phoneNumber && contact.phoneNumber.trim())
      );

      const availableSlots = 5 - nonEmptyPrevData.length;
      const dataToAdd = newData.slice(0, availableSlots);

      if (newData.length > availableSlots && !toastShown) {
        enqueueSnackbar(`Not add contacts more than 5`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        toastShown = true; // Set flag to true after showing the toast message
      }

      return [...nonEmptyPrevData, ...dataToAdd];
    });
  };

  const handleClose = () => {
    setJobTitleError("");
    setJobLocationError("");
    setJobDescriptionError("");
    setFirstNameError("");
    setLastNameError("");
    setDesignationError("");
    setEmailIdError("");
    setComment("");
    setOpenCompanyId(null);
    setShowContact(false);
    setPhoneError("");
    setExcelData([
      {
        addedBy: storeData._id,
        firstName: "",
        lastName: "",
        designation: "",
        emailId: "",
        contactAddedOn: addedDateTime,
      },
      {
        addedBy: storeData._id,
        firstName: "",
        lastName: "",
        designation: "",
        emailId: "",
        contactAddedOn: addedDateTime,
      },
      {
        addedBy: storeData._id,
        firstName: "",
        lastName: "",
        designation: "",
        emailId: "",
        contactAddedOn: addedDateTime,
      },
    ]);

    setJobDetails([
      {
        addedBy: storeData._id,
        jobTitle: "",
        jobLocation: "",
        jobDescription: "",
        jobAddedOn: addedDateTime,
        status: "Active",
      },
    ]);
  };
  const handleAddContact = () => {
    if (excelData.length < 5) {
      setExcelData([
        ...excelData,
        {
          addedBy: storeData._id,
          firstName: "",
          lastName: "",
          designation: "",
          emailId: "",
          contactAddedOn: addedDateTime,
          phoneNumber: "",
        },
      ]);
    } else {
      enqueueSnackbar(`Not add contacts more than 5`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
  const handleJobChange = (index, field, value) => {
    const updatedContacts = [...jobDetails];
    updatedContacts[index][field] = value;
    setJobDetails(updatedContacts);
  };
  const handleChange = (index, field, value) => {
    const updatedContacts = [...excelData];
    updatedContacts[index][field] = value;
    // setContactDetails(updatedContacts);
    setExcelData(updatedContacts);
  };

  //delete contact
  {
    /**mahender update becuase delete contact 24-05-2024 */
  }
  const [deletedContactModal, setDeletedContactModal] = useState(false);
  const [delleteContactIndex, setDeleteContactIndex] = useState("");
  const deleteContactModal = (index) => {
    setDeletedContactModal(!deletedContactModal);
    setDeleteContactIndex(index);
  };
  const handleDeleteContact = () => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      updatedData.splice(delleteContactIndex, 1);
      setExcelData(updatedData);
      setDeletedContactModal(!deletedContactModal);
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    }
  };
  const handleDeleteDirectContact = (index) => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      const contact = updatedData[index];
      // Check if any of the specific fields are non-empty
      if (
        contact.firstName !== "" ||
        contact.lastName !== "" ||
        contact.designation !== "" ||
        contact.emailId !== ""
      ) {
        deleteContactModal(index);
      } else {
        updatedData.splice(index, 1);
        setExcelData(updatedData);
      }
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    }
  };
  {
    /**up to here  */
  }

  //Modal
  const [openCompanyId, setOpenCompanyId] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentCompany, setCurrentComapny] = useState("");
  const [emailError , setEmailError] = useState(false);
  const handleOpen = (id) => {
    setOpenCompanyId(id); // Set the ID of the currently open modal
    //setOpen(true);
    const idData = companiesListWithIds.find((company) => company.id === id);
    setCurrentComapny(idData);
    setExcelData(
      idData.contactDetails && idData.contactDetails.length > 0
        ? idData.contactDetails
        : excelData
    );
    setJobDetails(idData.jobDetails ? idData.jobDetails : jobDetails);
    setComment(idData.comment ? idData.comment : comment);
    if (idData && idData.jobDetails && idData.jobDetails.length > 0) {
      setShowContact(true);
    }
  };

  const filterSearchData = Array.isArray(totalCompaniesList)
    ? totalCompaniesList.filter((data) => {
        const lowerCaseCompanyName = data.companyName?.toLowerCase() || "";
        const lowerCaseJobPosition =
          data.jobDetails?.[0]?.jobTitle?.toLowerCase() || "";
        const lowerCaseJobLocation =
          data.jobDetails?.[0]?.jobLocation?.toLowerCase() || "";

        const lowerCaseSearchJobPosition =
          searchListJobTitle?.toLowerCase() || "";
        const lowerCaseSearchCompany =
          searchListCompanyName?.toLowerCase() || "";
        const lowerCaseSearchJobLocation =
          searchListJobLocation?.toLowerCase() || "";
        return (
          lowerCaseJobPosition.includes(lowerCaseSearchJobPosition) &&
          lowerCaseCompanyName.includes(lowerCaseSearchCompany) &&
          lowerCaseJobLocation.includes(lowerCaseSearchJobLocation)
        );
      })
    : [];
  // Get data of the currently open company

  const companiesListWithIds = Array.isArray(filterSearchData)
    ? filterSearchData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

  const getCompanyById = (id) => {
    return companiesListWithIds.find((company) => company.id === id);
  };

  ///////////////////////
  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      // const action = {
      //   searchFromDate: formattedDates[0],
      //   searchToDate: formattedDates[1],
      // };

      const action = {
        Id: storeData._id,
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client: client,
      };
      dispatch(getCompanies(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
  }, []);

  const handleSubmit = () => {
    // Resetting error states
    setJobTitleError("");
    setJobLocationError("");
    setJobDescriptionError("");
    setFirstNameError("");
    setLastNameError("");
    setDesignationError("");
    setEmailIdError("");
    setPhoneError("");
    // Check for invalid job details
    const invalidJobDetails = jobDetails.some((contact, index) => {
      if (!contact.jobTitle.trim()) {
        setJobTitleError(`Job title ${index + 1} is required.`);
        return true;
      }
      if (!contact.jobLocation.trim()) {
        setJobLocationError(`Job location ${index + 1} is required.`);
        return true;
      }
      if (!contact.jobDescription.trim()) {
        setJobDescriptionError(`Job description ${index + 1} is required.`);
        return true;
      }
      return false;
    });

    const phoneRegex = /^[+\d()-‑.,\s]+$/;
    const consecutiveSpacesRegex = /\s{2,}/;
    // Check for invalid contact details
    const encounteredEmails = {};
    const invalidContactDetails = excelData.some((contact, index) => {
      if (!contact.firstName.trim()) {
        setFirstNameIndex(index);
        setFirstNameError(`First name ${index + 1} is required.`);
        return true;
      }

      if (!contact.designation.trim()) {
        setFirstNameIndex(index);
        setDesignationError(`Designation ${index + 1} is required.`);
        return true;
      }

      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!contact.emailId.trim()) {
        setFirstNameIndex(index);
        setEmailIdError(`Email ID ${index + 1} is required.`);

        return true;
      } else if (!emailRegex.test(contact.emailId.trim())) {
        setEmailIdError(`Email ID ${index + 1} is not in a valid format.`);
        setFirstNameIndex(index);
        return true;
      } else if (contact.emailId.endsWith("@gmail.com")) {
        setFirstNameIndex(index);
        setEmailIdError(
          `Email ID ${
            index + 1
          } must not be a Gmail address write company email.`
        );
        return true;
      }
      // Check if email ID is already encountered
      const trimmedEmail = contact.emailId.trim().toLowerCase(); // Convert to lowercase for case-insensitive comparison
      if (encounteredEmails[trimmedEmail]) {
        setEmailIdError(`Email ID ${index + 1} is a duplicate.`);
        setFirstNameIndex(index);
        return true;
      }

      encounteredEmails[trimmedEmail] = true;
      if (contact.phoneNumber && contact.phoneNumber.trim()) {
        if (!phoneRegex.test(contact.phoneNumber.trim())) {
          setFirstNameIndex(index);
          setPhoneError(
            `Phone number ${index + 1} is not in a valid phone number format.`
          );
          return true;
        } else if (consecutiveSpacesRegex.test(contact.phoneNumber.trim())) {
          setFirstNameIndex(index);
          setPhoneError(
            `Phone number ${index + 1} contains consecutive spaces.`
          );
          return true;
        }
      }

      return false;
    });

    // Check if any required fields are empty or if email format is incorrect
    if (invalidJobDetails || invalidContactDetails) {
      return;
    }

    // Trim extra spaces from job details
    const trimmedJobDetails = jobDetails.map((contact) => ({
      ...contact,
      jobTitle: contact.jobTitle.trim(),
      jobLocation: contact.jobLocation.trim(),
      jobDescription: contact.jobDescription.trim(),
      jobWebsite: contact.jobWebsite ? contact.jobWebsite.trim() : "",
      jobIndustrieName: contact.jobIndustrieName
        ? contact.jobIndustrieName.trim()
        : "",
    }));

    // Trim extra spaces from contact details
    const trimmedContactDetails = excelData.map((contact) => ({
      ...contact,
      firstName: contact.firstName.trim(),
      lastName: contact.lastName ? contact.lastName.trim() : "",
      designation: contact.designation.trim(),
      emailId: contact.emailId.trim(),
      phoneNumber: contact.phoneNumber ? contact.phoneNumber.trim() : "",
    }));

    // All validations passed, proceed with submission
    const action = {
      _id: currentCompany._id,
      companyName: currentCompany.companyName,
      addedBy: currentCompany.addedBy,
      status: currentCompany.status,
      addedOn: currentCompany.addedOn,
      jobDetails: trimmedJobDetails,
      contactDetails: trimmedContactDetails,
      comment: comment ? comment.trim() : "",
      id: currentCompany._id,
      client: client,
    };
    dispatch(addJobDataViaApi(action));
    dispatch(industriesNamesViaApi(action));
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
    handleClose();
  };
  const handleSaveJobDetials = () => {
    setJobTitleError("");
    setJobLocationError("");
    setJobDescriptionError("");
    // Check for invalid job details
    const invalidJobDetails = jobDetails.some((contact, index) => {
      if (!contact.jobTitle.trim()) {
        setJobTitleError(`Job title  is required.`);
        return true;
      }
      if (!contact.jobLocation.trim()) {
        setJobLocationError(`Job location  is required.`);
        return true;
      }
      if (!contact.jobDescription.trim()) {
        setJobDescriptionError(`Job description  is required.`);
        return true;
      }
      return false;
    });
    if (invalidJobDetails) {
      return;
    }
    // Trim extra spaces from job details
    const trimmedJobDetails = jobDetails.map((contact) => ({
      ...contact,
      jobTitle: contact.jobTitle.trim(),
      jobLocation: contact.jobLocation.trim(),
      jobDescription: contact.jobDescription.trim(),
      jobWebsite: contact.jobWebsite ? contact.jobWebsite.trim() : "",
      jobIndustrieName: contact.jobIndustrieName
        ? contact.jobIndustrieName.trim()
        : "",
    }));
    // Trim extra spaces from contact details
    const trimmedContactDetails = excelData.map((contact) => ({
      ...contact,
      firstName: contact.firstName.trim(),
      lastName: contact.lastName ? contact.lastName.trim() : "",
      designation: contact.designation.trim(),
      emailId: contact.emailId.trim(),
      phoneNumber: contact.phoneNumber ? contact.phoneNumber.trim() : "",
    }));
    // All validations passed, proceed with submission
    const filteredContactDetails = trimmedContactDetails.filter(
      (contact) =>
        contact.firstName !== "" ||
        contact.designation !== "" ||
        contact.emailId !== ""
    );
    const action = {
      id: currentCompany._id,
      addedBy: currentCompany.addedBy,
      jobDetails: trimmedJobDetails,
      contactDetails: filteredContactDetails,
      client: client,
    };
    dispatch(addJobDetailsViaApi(action));

    dispatch(industriesNamesViaApi(action));

    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
    setShowContact(true);
  };

  let incompleteEntriesCount = 0;

  // Iterate over the data to count incomplete entries
  companiesListWithIds.forEach((entry) => {
    if (
      !entry.jobDetails ||
      !entry.contactDetails ||
      entry.contactDetails.length === 0
    ) {
      incompleteEntriesCount++;
    }
  });
  let completedEntriesCount = 0;

  // Iterate over the data to count completed entries
  companiesListWithIds.forEach((entry) => {
    if (
      entry.jobDetails &&
      entry.contactDetails &&
      entry.jobDetails.length > 0 &&
      entry.contactDetails.length > 0
    ) {
      completedEntriesCount++;
    }
  });
  //preview Modal
  const [preView, setPreView] = useState(false);
  const [clickView, setClickView] = useState(false);
  const [previewData, setPreViewData] = useState([]);
  const [viewIndex, setViewIndex] = useState("");
  useEffect(() => {
    const updatePreViewData = () => {
      if (companiesListWithIds) {
        setPreViewData(companiesListWithIds);
      }
    };

    updatePreViewData();
  }, [companiesListWithIds]); // Only depend on companiesListWithIds

  const previewModal = () => {
    setPreView(!preView);
  };
  const preViewCancel = () => {
    setPreView(!preView);
    setPreViewData([]);
  };
  const ViewCancel = () => {
    setClickView(!clickView);
    setViewIndex("");
  };
  //delete modal
  const [deleteCompanyModal, setdeleteCompanyModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});
  function toggleDeleteOrderModal(id) {
    const idData = companiesListWithIds.find((company) => company.id === id);
    setItemToDelete(idData);
    setdeleteCompanyModal(!deleteCompanyModal);
  }
  const deleteCompany = () => {
    const action = {
      id: itemToDelete._id,
      client: client,
    };
    dispatch(deleteCompanyViaApi(action));
    dispatch(deleteCompanyRedux(itemToDelete._id));
    setdeleteCompanyModal(!deleteCompanyModal);
  };
  const handleViewData = (id) => {
    const idData = companiesListWithIds.find((company) => company.id === id);
    // setPreViewData([idData]);
    setViewIndex(id);
    setClickView(!clickView);

    if (idData && idData.jobDetails && idData.jobDetails.length > 0) {
      setShowContact(true);
    }
  };

  const [excelOpen, setExcelOPen] = useState(false);
  const handleOPenExcel = () => {
    setExcelOPen(!excelOpen);
  };
  const [openCheckSave, setOPenCheckSave] = useState(false);
  const [checkSaveData, setCheckSaveData] = useState([]);

  const handleCheckEmpty = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      const filteredRecords = companiesListWithIds.filter((record) => {
        const hasEmptyJobDetails =
          record.jobDetails &&
          record.jobDetails.some(
            (job) => !job.jobTitle || !job.jobLocation || !job.jobDescription
          );

        const hasEmptyContactDetails =
          record.contactDetails &&
          record.contactDetails.some(
            (contact) =>
              !contact.firstName || !contact.designation || !contact.emailId
          );

        return (
          !record.contactDetails ||
          !record.contactDetails.length ||
          !record.jobDetails ||
          !record.jobDetails.length ||
          hasEmptyJobDetails ||
          hasEmptyContactDetails
        );
      });
      if (
        !filteredRecords ||
        (filteredRecords && filteredRecords.length === 0)
      ) {
        enqueueSnackbar(`All Your Data is Submitted`, {
          variant: "success",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        return;
      }
      setCheckSaveData(filteredRecords);
      setOPenCheckSave(!openCheckSave);
    }
  };
  const checkSaveDataCancel = () => {
    setOPenCheckSave(!openCheckSave);

    setCheckSaveData([]);
  };
  useEffect(() => {
    const updatePreViewData = () => {
      if (companiesListWithIds) {
        const filteredRecords = companiesListWithIds.filter((record) => {
          const hasEmptyJobDetails =
            record.jobDetails &&
            record.jobDetails.some(
              (job) => !job.jobTitle || !job.jobLocation || !job.jobDescription
            );

          const hasEmptyContactDetails =
            record.contactDetails &&
            record.contactDetails.some(
              (contact) =>
                !contact.firstName || !contact.designation || !contact.emailId
            );

          return (
            !record.contactDetails ||
            !record.contactDetails.length ||
            !record.jobDetails ||
            !record.jobDetails.length ||
            hasEmptyJobDetails ||
            hasEmptyContactDetails
          );
        });

        setCheckSaveData(filteredRecords);
      }
    };

    updatePreViewData();
  }, [companiesListWithIds]);

  const [jobDetailsCount, setJobDetailsCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (companiesListWithIds) {
      companiesListWithIds.forEach((company) => {
        if (company.jobDetails) {
          company.jobDetails.forEach((job) => {
            if (job.jobLocation && job.jobDescription && job.jobTitle) {
              count++;
            }
          });
        }
      });
    }
    setJobDetailsCount(count);
  }, [companiesListWithIds]);

  //////////////////
  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize); // Update width on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"My Companies List"}>
          <Grid
            container
            columnSpacing={2}
            columns={{ xs: 12, sm: 12, md: 15, lg: 15 }}
          >
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <DataWidget
                title="Total Companies"
                value={companiesListWithIds.length}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <DataWidget
                title="Completed Companies"
                value={completedEntriesCount}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <DataWidget
                title="Pending Companies"
                value={
                  parseInt(companiesListWithIds.length) -
                  parseInt(completedEntriesCount)
                }
                icon={<BusinessIcon />}
                color="error"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <DataWidget
                title="Total Job Details"
                value={jobDetailsCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <DataWidget
                title="Total Contact Details"
                value={
                  companiesListWithIds
                    ? companiesListWithIds.reduce((count, company) => {
                        return (
                          count +
                          (company.contactDetails
                            ? company.contactDetails.length
                            : 0)
                        );
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", overflowX: "auto", mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box sx={{ display: "flex", justifyContent: "end", mb: 1 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="medium"
                    startIcon={<CloudUploadIcon />}
                    onClick={handleOPenExcel}
                  >
                    Import from Excel
                  </Button>
                </Box>
                {loading ? (
                  <>
                    <ShimmerTable row={5} col={5} />
                  </>
                ) : (
                  <>
                    <DataGrid
                      rows={companiesListWithIds}
                      columns={columns}
                      slots={{ toolbar: QuickSearchToolbar }}
                      columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
                      onCellClick={handleCellClick} // Handle cell click event
                      disableColumnMenu
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      disableRowSelectionOnClick
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 100 },
                        },
                      }}
                      //hideFooter={companiesListWithIds.length < 100}
                      pageSizeOptions={[100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,
                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                  </>
                )}
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={previewModal}
                    color="secondary"
                  >
                    Preview
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleCheckEmpty()}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box mt={5.5} />
                <Box ref={parentRef} sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "fixed",
                      zIndex: 1,

                      width: parentWidth,
                      maxHeight: "65vh",
                      overflow: "auto",
                    }}
                  >
                    {selectedRow ? (
                      <MainBackground>
                        <SectionTitle title="Account Details" />
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <KeyData
                              title={"Company Name"}
                              value={
                                selectedRow && selectedRow.companyName
                                  ? selectedRow.companyName
                                  : "N/A"
                              }
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <KeyData
                              title={"Job Title"}
                              value={
                                selectedRow &&
                                selectedRow.jobDetails &&
                                selectedRow.jobDetails.length > 0 &&
                                selectedRow.jobDetails[0].jobTitle
                                  ? selectedRow.jobDetails[0].jobTitle
                                  : "N/A"
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            
                              <KeyData
                            title={"Website"}
                            link
                            linkTitle={
                              selectedRow &&
                              selectedRow.jobDetails &&
                              selectedRow.jobDetails.length > 0 &&
                              selectedRow.jobDetails[0].jobWebsite
                                ? selectedRow.jobDetails[0].jobWebsite
                                : "N/A"
                            }
                            value={
                              selectedRow &&
                              selectedRow.jobDetails &&
                              selectedRow.jobDetails.length > 0 &&
                              selectedRow.jobDetails[0].jobWebsite
                                ? selectedRow.jobDetails[0].jobWebsite
                                : "N/A"
                            }

                            //   "https://mui.com/material-ui/react-skeleton/"
                            // }
                          />
                          </Grid>
                          <Grid item xs={8}>
                            <KeyData
                              title={"Job Location"}
                              value={
                                selectedRow &&
                                selectedRow.jobDetails &&
                                selectedRow.jobDetails.length > 0 &&
                                selectedRow.jobDetails[0].jobLocation
                                  ? selectedRow.jobDetails[0].jobLocation
                                  : "N/A"
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Job Description"}
                              value={
                                selectedRow &&
                                selectedRow.jobDetails &&
                                selectedRow.jobDetails.length > 0 &&
                                selectedRow.jobDetails[0].jobDescription
                                  ? selectedRow.jobDetails[0].jobDescription
                                  : "N/A"
                              }
                              link
                            />
                          </Grid>
                        </Grid>
                        {selectedRow && selectedRow.contactDetails ? (
                          <>
                            <SectionTitle title="Contacts" size="sm" />
                            {selectedRow.contactDetails.map(
                              (contact, index) => (
                                <Grid item xs={12}>
                                  <ContactView key={index} data={contact} />
                                </Grid>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </MainBackground>
                    ) : (
                      <>
                        <MainBackground>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 2,
                            }}
                          >
                            Please select any Contact
                          </Box>{" "}
                        </MainBackground>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </LayoutSidebarMenu>

        <Modal
          open={Boolean(openCompanyId)}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "1200px",
              width: "80%",
              maxHeight: "80vh",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              overflowY: "auto",
              p: {
                xs: 1.5,
                md: 3,
              },
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.dark,
                fontWeight: "bold",
                mb: 1,
              }}
            >
              {currentCompany ? `${currentCompany.companyName}` : ""}
            </Typography>
            <Divider />

            <Box>
              <Box sx={{ my: 1 }}>
                <Typography sx={{ fontWeight: "bold" }}>Job Details</Typography>
              </Box>
              {jobDetails.map((contact, index) => (
                <FormRow>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Website"
                      placeholder="https://tektreeinc.com"
                      size="small"
                      fullWidth
                      value={contact.jobWebsite}
                      onChange={(e) =>
                        handleJobChange(index, "jobWebsite", e.target.value)
                      }
                      error={Boolean(jobWebsiteError)}
                      helperText={jobWebsiteError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Description"
                      placeholder="URL"
                      size="small"
                      fullWidth
                      value={contact.jobDescription}
                      onChange={(e) =>
                        handleJobChange(index, "jobDescription", e.target.value)
                      }
                      error={Boolean(jobDescriptionError)}
                      helperText={jobDescriptionError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Title"
                      placeholder="Application Engineer"
                      size="small"
                      fullWidth
                      value={contact.jobTitle}
                      onChange={(e) =>
                        handleJobChange(index, "jobTitle", e.target.value)
                      }
                      error={Boolean(jobTitleError)}
                      helperText={jobTitleError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Location"
                      placeholder="Location, State"
                      size="small"
                      fullWidth
                      value={contact.jobLocation}
                      onChange={(e) =>
                        handleJobChange(index, "jobLocation", e.target.value)
                      }
                      error={Boolean(jobLocationError)}
                      helperText={jobLocationError}
                    />
                  </FormCell>

                  <FormCell>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      id="combo-box-demo"
                      options={industryNames}
                      value={contact.jobIndustrieName}
                      onChange={(event, newValue) => {
                        handleJobChange(index, "jobIndustrieName", newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        // If you need to handle input changes separately, you can use this
                        handleJobChange(
                          index,
                          "jobIndustrieName",
                          newInputValue
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Industry"
                          variant="standard"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </FormCell>
                </FormRow>
              ))}
            </Box>

            <React.Fragment>
              <Box>
                <Box
                  sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Paste Excel Content Here:
                  </Typography>
                </Box>
                <TextField
                  type="text"
                  onPaste={handlePaste}
                  placeholder="Paste Excel content here..."
                  fullWidth
                  rows={3}
                />

                <Box
                  sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Contact Details:
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddContact}
                  >
                    Add more Contacts
                  </Button>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {excelData.map((row, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="First Name"
                        placeholder="Josh"
                        size="small"
                        fullWidth
                        value={row.firstName}
                        onChange={(e) =>
                          handleChange(rowIndex, "firstName", e.target.value)
                        }
                        error={
                          Boolean(firstNameError) &&
                          row.firstName.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.firstName.trim() === ""
                            ? firstNameError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Last Name"
                        placeholder="Morgan"
                        size="small"
                        fullWidth
                        value={row.lastName}
                        onChange={(e) =>
                          handleChange(rowIndex, "lastName", e.target.value)
                        }
                        error={
                          Boolean(lastNameError) &&
                          row.lastName.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.lastName.trim() === ""
                            ? lastNameError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Designation"
                        placeholder="HR Manager"
                        size="small"
                        fullWidth
                        value={row.designation}
                        onChange={(e) =>
                          handleChange(rowIndex, "designation", e.target.value)
                        }
                        error={
                          Boolean(designationError) &&
                          row.designation.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.designation.trim() === ""
                            ? designationError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Email Id"
                        placeholder="josh.m@company.com"
                        size="small"
                        fullWidth
                        value={row.emailId}
                        onChange={(e) =>
                          handleChange(rowIndex, "emailId", e.target.value)
                        }
                        error={
                          Boolean(emailIdError) && rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex ? emailIdError : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Phone"
                        placeholder="+1 (555) 555 555"
                        size="small"
                        fullWidth
                        value={row.phoneNumber}
                        onChange={(e) =>
                          handleChange(rowIndex, "phoneNumber", e.target.value)
                        }
                        error={
                          Boolean(phoneError) && rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex ? phoneError : ""
                        }
                      />
                      <FormCell width={10} flex={0}>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDeleteDirectContact(rowIndex)}
                        >
                          <CloseIcon fontSize="12" />
                        </IconButton>
                      </FormCell>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
                <TextField
                  type="text"
                  variant="standard"
                  fullWidth
                  label="Comment"
                  placeholder="Add if any"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  pt: 4,
                }}
              >
                <Button variant="text" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </Modal>
        <Modal open={preView}>
          <MyCompaniesListPreview
            preViewCancel={preViewCancel}
            previewData={previewData}
            toggleDeleteOrderModal={toggleDeleteOrderModal}
            handleOpen={handleOpen}
          />
        </Modal>

        <Modal open={clickView}>
          <MyCompaniesListView
            preViewCancel={ViewCancel}
            previewData={previewData}
            toggleDeleteOrderModal={toggleDeleteOrderModal}
            viewIndex={viewIndex}
            handleOpen={handleOpen}
          />
        </Modal>
        {/* delete*/}
        <Modal
          open={deleteCompanyModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: {
                xs: 1,
                md: 2,
              },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",

                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={"400"}>
                Are you sure you want to delete
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.dark,
                  textAlign: "center",
                }}
              >
                {itemToDelete ? `${itemToDelete.companyName}` : ""}
              </Typography>
              <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => setdeleteCompanyModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ textTransform: "inherit" }}
                onClick={() => deleteCompany()}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* delete Contact */}
        {/**mahender update becuase delete contact 24-05-2024 */}
        <Modal
          open={deletedContactModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: {
                xs: 1,
                md: 2,
              },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",

                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={"400"}>
                Are you sure you want to delete
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => setDeletedContactModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleDeleteContact()}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
        {/**up to here */}
        {/** mahender update here becuase of upload excel 27-05-2024 */}
        <Modal
          open={excelOpen}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: {
                xs: 1,
                md: 2,
              },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",

                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={"400"}>
                Upload any one file either excel or csv
              </Typography>
              <FileUpload
                totalCompaniesList={totalCompaniesList}
                setTotalCompaniesList={setTotalCompaniesList}
                storeDataId={storeDataId}
                handleOPenExcel={handleOPenExcel}
                enqueueSnackbar={enqueueSnackbar}
                client={client}
                setEmailError={setEmailError}
                emailError={emailError}
              />
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleOPenExcel()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        {/**up to here  */}
        {/**submit */}
        <Modal open={openCheckSave}>
          <MyCompaniesListSave
            preViewCancel={checkSaveDataCancel}
            previewData={checkSaveData}
            toggleDeleteOrderModal={toggleDeleteOrderModal}
            handleOpen={handleOpen}
          />
        </Modal>
      </SnackbarProvider>
      <SnackbarComponent />
    </React.Fragment>
  );
};

export default MyCompaniesList;
