function GenerateJobString(storeData, job) {
    // Remove spaces, truncate the company name to the first 2 characters, and convert to uppercase
    const companyNamePart =
      job?.companyName?.replace(/\s+/g, "").substring(0, 2).toUpperCase() || "";
    const BDMNamePart =
      storeData?.userSudoFirstName
        .replace(/\s+/g, "")
        .substring(0, 2)
        .toUpperCase() || "";
    // Get the current date in the format 'DD-MM-YY'
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).substring(2); // Get the last two digits of the year
    const formattedDate = `${day}${month}${year}`;
    // Get the current timestamp
    const timestamp = Date.now();
    // Combine parts into the desired format with no spaces
    return `${BDMNamePart}${companyNamePart}-${formattedDate}-${timestamp}`;
}
export {GenerateJobString};