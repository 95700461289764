import React, { useEffect } from 'react'
import ResumeViewer from '../../Components/Common/ResumeViewer';
import { Box, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { awsResumeUrl } from '../../store/actions';
import { useLocation } from 'react-router-dom';

export const ViewResume = () => {
    const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
    const { storeData } = useSelector((state) => ({
      storeData: state.Login.storeData,
    }));
    const client = storeData.clientDb;
    console.log('AwsResumeUrl',AwsResumeUrl);
    const { search } = useLocation();
    const dispatch=useDispatch();
    const queryParams = new URLSearchParams(search);
    const serverLocation = queryParams.get("serverLocation");

    // Use serverLocation to fetch data or dispatch an action
    useEffect(() => {
        if (serverLocation) {
          const action = {
            client: client,
            key: serverLocation,
          };
            dispatch(awsResumeUrl(action));
        }
    }, [serverLocation]);
  return (
    <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      overflow: "hidden",
    }}
  >
    {/* Close Button */}
    <IconButton
      aria-label="close"
      
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      {/* <CloseIcon /> */}
    </IconButton>
    {/* Conditional rendering based on file type */}
    {AwsResumeUrl ? (
      <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
    ) : (
      <Typography variant="h6" textAlign="center">
        No resume available.
      </Typography>
    )}
  </Box>
  )
}

export default ViewResume;