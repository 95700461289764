const apiUrl = process.env.REACT_APP_API_URL;
const  findUserIdByEmailSourceAndEmail=(emailSource, email,allUsers=[])=> {
    const user = allUsers.find(user =>
      user.emailsIntegration && 
      user.emailsIntegration.some(integration =>
        integration.type === emailSource && integration.emails.includes(email)
      )
    );
    return user ? user._id : '';
  }
  const filterUsersByEmailType=(users)=> {
    const filteredUsers = {};
    users.forEach((user) => {
      if (user.emailsIntegration) {
        user.emailsIntegration.forEach((integration) => {
          const type = integration.type;
          if (!filteredUsers[type]) {
            filteredUsers[type] = [];
          }
          filteredUsers[type].push(...integration.emails);
        });
      }
    });

    return filteredUsers;
  }
  const handleDownloadResume = async (key, applicant) => {
    try {
      const parts = key.split(".");
      const firstName = applicant.firstName;
      const lastName = applicant.lastName;
      const fileName = `${firstName || ""} ${lastName || ""}.${parts.pop()}`;
      const payload = {
        key: key, // S3 key to be passed to the backend
        fileName: fileName, // File name passed from frontend
        fileType: parts.pop(),
      };
      const response = await fetch(apiUrl + "/applicants/downloadFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to download the file");
      }
      // Trigger file download from response
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error triggering download:", error.message);
    }
  };
  // Assuming data is an array of contacts
const replacePlaceholders = (text, contact) => {
  return text.replace(/\${(\w+)}/g, (match, p1) => {
    switch (p1) {
      case "firstName":
        return contact.firstName;
      case "lastName":
        return contact.lastName;
      default:
        return match; // Return the placeholder itself if not recognized
    }
  });
};
const jobPostingReplacePlaceholders = (text, contact) => {
  return text.replace(/\${(\w+)}/g, (match, p1) => {
    switch (p1) {
      case "jobTitle":
        return contact.jobTitle;
      case "jobLocation":
        return contact.jobLocation || "Hyderabad"; // Default location
      case "jobDescription":
        return contact.jobDescription || ""; // Default location
      default:
        return match; // Return the placeholder itself if not recognized
    }
  });
};

function getResumes({
  searchType,
  selectedCandidate,
  internalDBCandidateList,
  internalDBMatchedResumes,
  cbCandidateList,
  monsterCandidateList,
}) {
  if (searchType === "InternalDB") {
    // Retrieve resumes from `internalDBCandidateList`
    return (
      internalDBCandidateList.find(
        (applicant) =>
          applicant.applicantId ===
          internalDBCandidateList[selectedCandidate]?.applicantId
      )?.resumes || []
    );
  } else {
    // Retrieve resumes from either `cbCandidateList` or `monsterCandidateList`
    const candidateList =
      searchType === "CareerBuilder"
        ? cbCandidateList
        : monsterCandidateList;

    return (
      internalDBMatchedResumes.find(
        (internal) =>
          internal.resumeId === candidateList[selectedCandidate]?.ResumeId
      )?.resumes || []
    );
  }
}
const getYearsfromMonths = (months) => {
  if (!months) return null;    
  let monthsNum = Number(months);
  let years = Math.floor(monthsNum / 12);
  let remainMonths = monthsNum % 12;
  return `${years}${remainMonths === 0 ? '' : ' - ' + remainMonths}`;
};
const findUserName = (users, userId) =>
  users.find((user) => user._id === userId)?.userSudoFirstName || "";
  const fileNameTake=(filename)=>filename.slice(filename.lastIndexOf("."))
  function getDisplayString(fullString) {
    if (!fullString) {
      return null;
    }
    // Split the string by the hyphen and remove the last part (timestamp)
    const parts = fullString.split("-");

    // Join the first two parts to get the display string
    const displayString = `${parts[0]}-${parts[1]}`;

    return displayString;
  }
  export default { getYearsfromMonths,findUserIdByEmailSourceAndEmail,filterUsersByEmailType,
    handleDownloadResume,replacePlaceholders,jobPostingReplacePlaceholders,
    getResumes,findUserName,fileNameTake,getDisplayString };
