
const GetDuplicateIds = (array, tag) => {
    let uniques = new Set();
    let duplicateIds = [];
    for (let i = 0; i < array.length; i++) {
      let tagValue = array[i][tag];
      if (uniques.has(tagValue)) {
        duplicateIds.push(tagValue);
      } else {
        uniques.add(tagValue);
      }
    }
    return Array.from(new Set(duplicateIds));
  }
  export default GetDuplicateIds;