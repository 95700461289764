import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Card,
  CardContent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomDetailViewSection,
  CustomSubmissionTilmeLine,
  JobPostingForm,
  KeyData,
  MainBackground,
  ScrollView,
  SectionTitle,
} from "../../Components";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjs from "dayjs";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";
import {
  addIndustriesNamesViaApi,
  addNoteViaApi,
  jobPostRecruiterViaApi,
  searchDateJobPostingDataViaApi,
} from "../../store/actions";
import { debounce } from "lodash";
import theme from "../../Theme";

import { timelineList } from "../../Data/timelineList";
import CustomTimeline from "../../Components/Common/CustomTimeline";
import CustomTaggedApplicants from "../../Components/Common/CustomTaggedApplicants";
import AddNoteJobBoards from "../../Components/Modals/JobBoardsAddNotes";
import RemoveHTMLTags from "../../utils/RemoveHTMLTags";
import CommanFilters from "../../utils/CommanFilters";
const TooltipIconButton = ({
  title,
  arrow = false,
  pointer = "right",
  icon = <ArrowBackIcon />,
}) => {
  const styledIcon = React.cloneElement(icon, {
    sx: { fontSize: 14 },
  });
  return (
    <Box>
      <Tooltip title={title} arrow={arrow} placement={pointer}>
        <IconButton>{styledIcon}</IconButton>
      </Tooltip>
    </Box>
  );
};
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const JobPostDetails = () => {
  const {findUserName}=CommanFilters
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const bdmCompanyId = queryParams.get("bdmCompanyId");
  const [tabShow, setTabShow] = React.useState(0);
  const [showJobDesc, setShowJobDesc] = React.useState(false);
  const [activeShortlistTab, setActiveShortlistTab] = useState(1);
  const [activeSnapshotNotesTab, setActiveSnapshotNotesTab] = useState(1);
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [note, setNote] = useState("");
  const [noteTask,setNoteTask]=useState('');
  const [assignJobPostId, setAssignJobPostId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedJodCode, setSelectedJodCode] = useState(""); // Add this line
  const [noteError, setNoteError] = useState('');
  const handleCloseNote=()=>{
    setOpenNoteDrawer(false);
    setNote(""); // Clear any existing note
  }
  function isEmptyHtml(input) {
    // Remove all HTML tags and trim whitespace
    const strippedContent = input.replace(/<[^>]*>/g, "").trim();
    return strippedContent.length === 0;
  }
  const handleNoteSubmit = async () => {
    if (!note || isEmptyHtml(note)) {
      return enqueueSnackbar("Please type note !", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
      const noteData = [
        {
          addedBy: storeData._id,
          addedOn: new Date(),
          note: note,
        },
      ];
      const action = {
        client: client,
        jobId: assignJobPostId,
        note: noteData,
      };
      dispatch(addNoteViaApi(action));
      setLoading(true);
      setNote("");
      setOpenNoteDrawer(false)
      setTimeout(() => {
        dispatch(searchDateJobPostingDataViaApi(action));
        // dispatch(jobPostRecruiterViaApi(action))
      }, 3000);
   
  };
  const handleOpenNoteDrawer = (rowId, jobId) => {
    setSelectedJodCode(rowId); // Set the selected job code
    setOpenNoteDrawer(!openNoteDrawer);
    setAssignJobPostId(jobId);
    setNote(""); // Clear any existing note
    setNoteError("");
  };

  const handleToggleJobDesc = () => {
    setShowJobDesc(!showJobDesc);

  };

  const handleTabChange = (event, newValue) => {
    setTabShow(newValue);
  };
  
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);

  const findJobPostingData = (location?.state?.company?.bdmCompanyId||bdmCompanyId)
    ? Array.isArray(JobPosting)
      ? JobPosting.find(
          (job) => String(job.bdmCompanyId) === String( (location?.state?.company?.bdmCompanyId||bdmCompanyId))
        )
      : {}
    : {};
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

  const [selectDocument, setSelectDocument] = useState(findJobPostingData?.bdmCompanyId||"");
  const defaultJobDetails = {
    jobCode: "",
    jobTitle: "",
    jobWebsite: "",
    clientBillRateSalary: {
      currency: "USD",
      amount: "",
      paySchedule: "",
      jobType: "",
    },
    payRateSalary: {
      currency: "USD",
      minAmount: "",
      maxAmount: "",
      paySchedule: "",
      jobType: "",
    },
    jobStartDate: new Date(),
    jobEndDate: null,
    respondBy: "",
    remoteJob: "",
    expensesPaid: "",
    country: "USA",
    states: "",
    location: "",
    jobStatus: "Active",
    jobType: "",
    requiredHourWeek: "",
    client: "",
    clientManager: "",
    endClient: "",
    clientJobID: "",
    requiredDocuments: [],
    turnoutTime: {
      time: "",
      dates: "",
    },
    priority: "",
    clientCategory: "",
    duration: "",
    additionalDetails: "",
    areaCode: "",
    workAuthorization: [],
    interviewMode: "",
    applicationForm: "",
    clearance: "",
    address: "",
    employmentTestTemplate: "",
    employmentLevel: "",
  };
  const defaultSkills = {
    industry: "",
    degree: "",
    experience: {
      min: "",
      max: "",
    },
    evaluationTemplate: "",
    primarySkills: [],
    secondarySkills: "",
    languages: [],
  };
  const defaultOrganizationalInformation = {
    numberofPositions: "",
    maximumAllowedSubmission: "",
    taxTerms: [],
    salesManager: "",
    department: "",
    recruitmentManager: "",
    assignedTo: "",
    primaryRecruiter: "",
    comments: "",
    additionalNotifications: "",
    careerPortalPublishedDate: null,
    jobDescription: "",
  };
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = industries.map((item) => item.industryName) || [];
  const [jobDetails, setJobDetails] = useState(findJobPostingData?.jobDetails || {});
  const [skills, setSkills] = useState(findJobPostingData?.skills||{});
  const [assignedTo, setAssignedTo] = useState("");
  const [jobNotifyTo,setJobNotifyTo]=useState([]);
  const [organizationalInformation, setOrganizationalInformation] = useState(
    findJobPostingData?.organizationalInformation || {}
  );
  const [jobPostStatus, setJobPostStatus] = useState(findJobPostingData?.status);
   const [jobStatus, setStatus] = useState([
    {
      addedBy: storeData._id,
      addedOn: new Date(),
      status: "Active",
    },
  ]);
  const [jobPostingId, setJobPostingId] = useState(findJobPostingData?._id||"");
  const [errors, setErrors] = useState({});
  const [isJobPostingModalOpen, setIsJobPostingModalOpen] = useState(false);
  const handleJobPostingCloseModal = () => {
    setJobDetails(defaultJobDetails);
    setSkills(defaultSkills);
    setOrganizationalInformation(defaultOrganizationalInformation);
    setIsJobPostingModalOpen(!isJobPostingModalOpen);
    setErrors({});
    setAssignedTo("");
    setJobNotifyTo([])
  };

  const trimFields = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        acc[key] = value;
      } else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimFields(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleJobPostingSaveDraft = debounce(() => {
    const action = {
      addedBy: storeData._id,
      addedOn: new Date(),
      jobDetails: jobDetails,
      skills: skills,
      organizationalInformation: organizationalInformation,
      bdmCompanyId: selectDocument,
      status: "Draft",
      assignedTo: assignedTo,
      jobNotifyTo:jobNotifyTo,
      id: jobPostingId,
      industrieName: skills.industry,
      client: client,
    };
    dispatch(jobPostRecruiterViaApi(action));
    if (action.industrieName) {
      dispatch(addIndustriesNamesViaApi(action));
    }
    handleJobPostingCloseModal();
  }, 1000);
  const validateForm = () => {
    const newErrors = {};

    // Check if jobCode is present and not empty inside jobDetails
    if (!jobDetails?.jobCode) {
      newErrors.jobCode = "Job Code is required in Job Details";
    }

    // Check if jobTitle is empty
    if (!jobDetails?.jobTitle) {
      newErrors.jobTitle = "Job Title is required";
    }

    // Validate country
    if (!jobDetails?.country) {
      newErrors.country = "Country is required";
    }

    // Validate remoteJob
    if (!jobDetails?.remoteJob) {
      newErrors.remoteJob = "Remote Job status is required";
    }

    // Validate location
    if (!jobDetails?.location) {
      newErrors.location = "Location is required";
    }
    if (!jobDetails?.jobStatus) {
      newErrors.jobStatus = "jobStatus is required";
    }

    // Validate duration
    if (jobDetails.jobType !== "Full Time" && !jobDetails?.duration) {
      newErrors.duration = "Duration is required";
    }
    if (!skills?.experience?.min) {
      newErrors.skillsExperienceMin = "min is required";
    }
    if (!skills?.experience?.max) {
      newErrors.skillsExperienceMax = "Max is required";
    }
    // Validate primarySkills
    if (!skills?.primarySkills || skills.primarySkills.length === 0) {
      newErrors.primarySkills = "At least one primary skill is required";
    }
    if (!jobNotifyTo || jobNotifyTo.length === 0) {
      newErrors.nofity = "At least one notify is required";
    }
    if (!organizationalInformation?.numberofPositions) {
      newErrors.numberofPositions = "NumberofPositions is required";
    }
    if (
      !organizationalInformation?.taxTerms ||
      organizationalInformation.taxTerms.length === 0
    ) {
      newErrors.taxTerms = "NumberofPositions is required";
    }

    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };

  const handleJobPostingSaveSubmit = debounce(() => {
    if (validateForm()) {
      // Trim spaces from jobDetails, skills, and organizationalInformation
      const trimmedJobDetails = trimFields(jobDetails);
      const trimmedSkills = trimFields(skills);
      const trimmedOrganizationalInformation = trimFields(
        organizationalInformation
      );

      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobDetails: trimmedJobDetails,
        skills: trimmedSkills,
        organizationalInformation: trimmedOrganizationalInformation,
        bdmCompanyId: selectDocument,
        assignedTo: assignedTo,
        jobNotifyTo:jobNotifyTo,
        status: "Submit",
        id: jobPostingId,
        industrieName: skills.industry,
        client: client,
      };
      dispatch(jobPostRecruiterViaApi(action));
      if (action.industrieName) {
        dispatch(addIndustriesNamesViaApi(action));
      }
      const today = dayjs().tz("America/New_York").startOf("day");

      handleJobPostingCloseModal();
    }
  }, 1000);
  

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const [checkBackURL, setCheckBackURL] = useState("jobPosts");
  function getDisplayString(fullString) {
    if (!fullString) {
      return null;
    }
    // Split the string by the hyphen and remove the last part (timestamp)
    const parts = fullString.split("-");

    // Join the first two parts to get the display string
    const displayString = `${parts[0]}-${parts[1]}`;

    return displayString;
  }
  
  
  const isObject = (value) =>
    value && typeof value === "object" && !Array.isArray(value);

  // Function to check if a value is an array
  const isArray = (value) => Array.isArray(value);

  // Function to convert objects or arrays into a formatted string
  const convertDynamically = (value) => {
    if (isObject(value)) {
      // If the value is an object, convert its values to a backslash-separated string
      return Object.values(value)
        .filter((val) => val !== undefined && val !== null)
        .join(",");
    } else if (isArray(value)) {
      // If the value is an array, join its elements with a backslash
      return value.join("");
    }
    return value; // If neither object nor array, return the value as it is
  };
// Assuming companyDetails is coming from props or state
const jobStartDate = findJobPostingData?.jobDetails?.jobStartDate; 
const jobEndDate = findJobPostingData?.jobDetails?.jobEndDate; 


// Log the formatted end date
// Format jobStartDate and jobEndDate as strings
// Assuming you want to format the job start and end dates to a readable format with timezone
const formattedJobStartDate = jobStartDate
  ? moment.tz(jobStartDate, 'America/New_York').format('YYYY-MM-DD HH:mm')
  : 'N/A';

const formattedJobEndDate = jobEndDate
  ? moment.tz(jobEndDate, 'America/New_York').format('YYYY-MM-DD HH:mm')
  : 'N/A';


  const filterDataJobDetails = [
    {
      jobCode: findJobPostingData?.jobDetails?.jobCode || "N/A",
      jobTitle: findJobPostingData?.jobDetails?.jobTitle || "N/A",
      jobWebsite: findJobPostingData?.jobDetails?.jobWebsite || "N/A",
      clientBillRateSalary: `${findJobPostingData?.jobDetails?.clientBillRateSalary?.currency}/${findJobPostingData?.jobDetails?.clientBillRateSalary?.amount}/${findJobPostingData?.jobDetails?.clientBillRateSalary?.paySchedule}/${findJobPostingData?.jobDetails?.clientBillRateSalary?.jobType}`,
      payRateSalary: `${findJobPostingData?.jobDetails?.payRateSalary?.currency}/${findJobPostingData?.jobDetails?.payRateSalary?.minAmount}/${findJobPostingData?.jobDetails?.payRateSalary?.maxAmount}/${findJobPostingData?.jobDetails?.payRateSalary?.paySchedule}/${findJobPostingData?.jobDetails?.payRateSalary?.jobType}`,
      jobStartDate: formattedJobStartDate,
    jobEndDate: formattedJobEndDate,
      remoteJob: findJobPostingData?.jobDetails?.remoteJob || "N/A",
      expensesPaid: findJobPostingData?.jobDetails?.expensesPaid || "N/A",
      country: findJobPostingData?.jobDetails?.country || "N/A",
      states: findJobPostingData?.jobDetails?.states || "N/A",
      location: findJobPostingData?.jobDetails?.location || "N/A",
      jobStatus: findJobPostingData?.jobDetails?.jobStatus || "N/A",
      jobType: findJobPostingData?.jobDetails?.jobType || "N/A",
      requiredHourWeek: findJobPostingData?.jobDetails?.requiredHourWeek || "N/A",
      client: findJobPostingData?.jobDetails?.client || "N/A",
      endClient: findJobPostingData?.endClient || "N/A",
      clientJobID: findJobPostingData?.jobDetails?.clientJobID || "N/A",
      requiredDocuments:
        Array.isArray(findJobPostingData?.jobDetails?.requiredDocuments) &&
        findJobPostingData?.jobDetails?.requiredDocuments.length > 0
          ? convertDynamically(findJobPostingData?.jobDetails?.requiredDocuments)
          : "N/A",
      turnoutTime:
        Array.isArray(findJobPostingData?.jobDetails?.turnoutTime) &&
        findJobPostingData?.jobDetails?.turnoutTime.length > 0
          ? convertDynamically(findJobPostingData?.jobDetails?.turnoutTime)
          : "N/A",
      priority: findJobPostingData?.jobDetails?.priority || "N/A",
      duration: findJobPostingData?.jobDetails?.duration || "N/A",
      areaCode: findJobPostingData?.jobDetails?.areaCode || "N/A",

      workAuthorization:
        Array.isArray(findJobPostingData?.jobDetails?.requiredDocuments) &&
        findJobPostingData?.jobDetails?.workAuthorization.length > 0
          ? convertDynamically(findJobPostingData?.jobDetails?.workAuthorization)
          : "N/A",
      clearance: findJobPostingData?.jobDetails?.clearance || "N/A",
      address: findJobPostingData?.jobDetails?.address || "N/A",
    },
  ];
  const filterSkilss = [
    {
      industry: findJobPostingData?.skills?.industry || "N/A",
      degree: findJobPostingData?.skills?.degree || "N/A",
      experience:
        `${findJobPostingData?.skills?.experience?.min}/${findJobPostingData?.skills?.experience?.max}` ||
        "N/A",
      primarySkills:
        Array.isArray(findJobPostingData?.skills?.primarySkills) &&
        findJobPostingData?.skills?.primarySkills.length > 0
          ? convertDynamically(findJobPostingData?.skills?.primarySkills)
          : "N/A",
      languages: findJobPostingData?.skills?.languages || "N/A",
    },
  ];

  const filterOrganizational = [
    {
      numberofPositions:
      findJobPostingData?.organizationalInformation?.numberofPositions || "N/A",
      taxTerms:
        Array.isArray(findJobPostingData?.organizationalInformation?.taxTerms) &&
        findJobPostingData?.organizationalInformation?.taxTerms.length > 0
          ? convertDynamically(
            findJobPostingData?.organizationalInformation?.taxTerms
            )
          : "N/A",
      comments: findJobPostingData?.organizationalInformation?.comments || "N/A",
      jobDescription:
        RemoveHTMLTags(
          findJobPostingData?.organizationalInformation?.jobDescription
        ) || "N/A",
    },
  ];
 

 
  const nameFind =
    allUsers.find((user) => user._id === storeData._id)?.userSudoFirstName ||
    "Unknown";
    function stripHtml(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
  }
  // const [openJobDescriptionModal,setOpenJobDescriptionModal]=useState(false)
  // const toggleOpenJobDesriptionModal=()=>{
  //   setOpenJobDescriptionModal(!openJobDescriptionModal)
  // }
  let taggedSubmissions = findJobPostingData?.submissions?.filter(list => list.applicantStatus === 'TagJob').length || 0;
  let jobPostingNotes = findJobPostingData?.notes?.filter(list => !list?.source || list?.source !== 'applicantReference') || [];
  let applicantRefNotes = findJobPostingData?.notes?.filter(list => list?.source === 'applicantReference') || []; 
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Post Details"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <MainBackground>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          component="button" // Use `button` for a clickable element
                          to={`/${checkBackURL}`}
                        >
                          <TooltipIconButton title="back" icon={<ArrowBackIcon />} />
                        </Link>
                        <Tooltip title={""}>
                          <CircleIcon
                            sx={{ fontSize: 12 }}
                            color={(findJobPostingData?.jobStatus[findJobPostingData?.jobStatus.length - 1]?.status || "")===
                               "Active"
                                ? "success"
                                : "disabled"
                            }
                          />
                        </Tooltip>
                        <Chip
                          label={(findJobPostingData?.jobStatus[findJobPostingData?.jobStatus.length - 1]?.status || "") || ""}
                          color="error"
                          size="small"
                          variant="outlined"
                          sx={{ px: 0.5, borderRadius: 1 }}
                        />
                      </Box>
                      <Box flex={1} bgcolor={"red"}></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <TooltipIconButton
                          title="left"
                          icon={<ChevronLeftIcon />}
                        />
                        <TooltipIconButton
                          title="right"
                          icon={<ChevronRightIcon />}
                        />
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {getDisplayString(findJobPostingData?.jobDetails?.jobCode)} -{" "}
                          {findJobPostingData?.jobDetails?.client}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <ButtonGroup
                            variant="outlined"
                            size="small"
                            color="error"
                          >
                            
                            <Button
                              startIcon={
                                <PersonSearchRoundedIcon fontSize="inherit" />
                              }
                              to={"/jobBoards"}
                              component={Link}
                            >
                              Find Applicant
                            </Button>
                            {findJobPostingData?.addedBy === storeData._id && (
                              <Button
                                startIcon={
                                  <EditIcon
                                    fontSize="inherit"
                                    onClick={() =>
                                      setIsJobPostingModalOpen(
                                        !isJobPostingModalOpen
                                      )
                                    }
                                  />
                                }
                              >
                                Edit
                              </Button>
                            )}
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box display={"flex"} gap={1}>
                          <KeyData
                            title={"Company Name"}
                            value={findJobPostingData?.jobDetails?.client}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Website"}
                            link
                            linkTitle={
                              findJobPostingData?.jobDetails?.jobWebsite
                                ? findJobPostingData?.jobDetails?.jobWebsite
                                : "NA"
                            }
                            value={
                              findJobPostingData?.jobDetails?.jobWebsite
                                ? findJobPostingData?.jobDetails?.jobWebsite
                                : ""
                            }

                            //   "https://mui.com/material-ui/react-skeleton/"
                            // }
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Job Title"}
                            value={findJobPostingData?.jobDetails?.jobTitle}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Location"}
                            value={`${findJobPostingData?.jobDetails?.states}-${findJobPostingData?.jobDetails?.location}`}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Assigned To"}
                            value={findUserName(allUsers,findJobPostingData?.organizationalInformation?.assignedTo)}
                          />
                          <Divider orientation="vertical" flexItem />
                          <Box flex={1} />
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box display={"flex"} gap={1}>
                          <KeyData
                            title={"Recruitment Manager"}
                            value={findUserName(allUsers,findJobPostingData?.addedBy)}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Client Bill Rate / Salary "}
                            value={`${
                              findJobPostingData.jobDetails?.clientBillRateSalary?.currency || ""
          }/${
            findJobPostingData.jobDetails?.clientBillRateSalary?.amount || ""
          }/${
            findJobPostingData.jobDetails?.clientBillRateSalary?.paySchedule ||
            ""
          }/${
            findJobPostingData.jobDetails?.clientBillRateSalary?.jobType || ""
          }`}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Pay Rate / Salary "}
                            value={`${
                              findJobPostingData.jobDetails?.payRateSalary?.currency || ""
          }/${findJobPostingData.jobDetails?.payRateSalary?.paySchedule || ""}/${
            findJobPostingData.jobDetails?.payRateSalary?.jobType || ""
          }`}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Created By & On "}
                            jobCreatedBy
                            value={findUserName(allUsers,findJobPostingData?.addedBy)}
                          />
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 0.2,
                          }}
                        >
                          <Box
                            onClick={handleToggleJobDesc}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              lineHeight: 1.2,
                              textTransform: "inherit",
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                          >
                            Job Description
                          </Box>

                          <IconButton
                            size="small"
                            onClick={handleToggleJobDesc}
                          >
                            {showJobDesc ? (
                              <VisibilityOffIcon fontSize="11px" />
                            ) : (
                              <VisibilityIcon fontSize="11px" />
                            )}
                          </IconButton>
                        </Box>
                        <ScrollView height={"150px"}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              height: showJobDesc ? "auto" : "40px",
                              overflow: "hidden",
                            }}
                          >
                            <Typography variant="caption">
                             
                            {RemoveHTMLTags(findJobPostingData?.organizationalInformation?.jobDescription)}
                            </Typography>
                            
                          </Box>
                        </ScrollView>
                      </Box>
                    </Box>
                  </Box>
                </MainBackground>

                <Tabs
                  value={tabShow}
                  onChange={handleTabChange}
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Tab label="Shortlist" />
                  <Tab label="Snapshot" />
                  <Tab label="Job Details" />
                  {/* <Tab label="Additional Details" /> */}
                  {/* <Tab label=" Task Manager" /> */}
                  <Tab label=" Activities" />
                  {/* <Tab label=" Email Merges" disabled /> */}
                </Tabs>
                <TabPanel value={tabShow} index={0}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Shortlist" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <ButtonGroup>
                          {[{title: 'Tagged', value: taggedSubmissions, index: 1}, {title: 'Emailed', value: 2, index: 2,}, {title: 'Under Review', value: 2, index: 3}].map(button => 
                            <CustomButton key={button.index} title={button.title} value={button.value} active={activeShortlistTab === button.index} onClick={()=> setActiveShortlistTab(button.index)} />
                            )}
                          </ButtonGroup>
                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      activeShortlistTab === 1 && <CustomTaggedApplicants submissions={findJobPostingData?.submissions||[]} />
                    )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={1}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Submissions" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <ButtonGroup>
                            <CustomButton title="Pipeline" />
                            <CustomButton title="All" value={2} btnActive />
                            <CustomButton
                              title="Client Submissions"
                              value={2}
                            />
                            <CustomButton title="Interviews" value={2} />
                            <CustomButton title="Confirmations" value={2} />
                            <CustomButton title="Placements" value={2} />
                            <CustomButton title="Not Joined" value={2} />
                          </ButtonGroup>
                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      <CustomSubmissionTilmeLine
                        submissions={findJobPostingData?.submissions}
                        jobPostingId={findJobPostingData?.jobDetails._id}
                      />
                    )}
                  />

                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 3,
                          }}
                        >
                          <SectionTitle title="Notes" />
                          <ButtonGroup>
                          {[{title: 'Job Posting', value: jobPostingNotes?.length || 0, index: 1}, {title: 'Applicant Reference', value: applicantRefNotes?.length || 0, index: 2,}].map(button =>                                 <CustomButton key={button.index} title={button.title} value={button.value} 
                                active={activeSnapshotNotesTab === button.index} onClick={()=> setActiveSnapshotNotesTab(button.index)} 
                            />)}
                          </ButtonGroup>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                         <CustomButton title="Add" onClick={() => handleOpenNoteDrawer(findJobPostingData?.jobDetails?.jobCode, findJobPostingData?._id)} />

                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      findJobPostingData?.notes?.length && <Grid container gap={2} >
                   {(activeSnapshotNotesTab === 2 ? applicantRefNotes : jobPostingNotes).map((note, index) => {
                     const matchedUser = allUsers?.find((user) => user._id === note.addedBy);
                     let noteCreator = matchedUser?.userSudoFirstName || matchedUser?.userSudoLastName || '';
                   return (
                       <Card key={index} variant="outlined" component={Grid} item xs={2.8}>
                       <CardContent sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'end'}}>
                         <Box>
                         <Typography sx={{ fontSize: 12 }} gutterBottom children={RemoveHTMLTags(note.note) || '-'} />
                         {noteCreator && <Typography sx={{ fontSize: 12 }}>Added By: {noteCreator}</Typography>}
                         </Box>
                         <Typography sx={{ fontSize: 12 }} gutterBottom children={new Date(note.addedOn).toDateString()} />
                       </CardContent>
                       </Card>
                   )})}
                 </Grid> 
                    )}
                  />
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Documents" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" active />
                        </Box>
                      </Box>
                    )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={2}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Job Details" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          {/* <CustomButton title="Edit" active /> */}
                        </Box>
                      </Box>
                    )}
                    title={"Job Details"}
                    data={filterDataJobDetails}
                  />
                  <CustomDetailViewSection
                    title={"Skills"}
                    data={filterSkilss}
                  />
                  <CustomDetailViewSection
                    title={"Organizational Information"}
                    data={filterOrganizational}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={3}>
                  <MainBackground>
                    <SectionTitle title="Timeline" />

                    <CustomTimeline data={findJobPostingData} />
                  </MainBackground>
                </TabPanel>
                {/* <TabPanel value={tabShow} index={4}>
                  <MainBackground>Task Manager</MainBackground>
                </TabPanel> */}
              </Box>
            </Grid>
            {/* <Grid item xs={3.5}>
              <MainBackground>adf</MainBackground>
            </Grid> */}
          </Grid>
          <AddNoteJobBoards
          open={openNoteDrawer}
          close={handleCloseNote}
          allUsers={allUsers}
          notes={(
            JobPosting?.find(
              (job) => job._id === assignJobPostId
            )?.notes||[]
          ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))}
          note={note}
          setNoteTask={setNoteTask}
          noteTask={noteTask}
          setNote={setNote}
          handleNoteSubmit={handleNoteSubmit}
          dotShowReference={'yes'}
          pageProps={'JobPosting'}
          />
          
          <Modal
            open={isJobPostingModalOpen}
            onClose={handleJobPostingCloseModal}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
                height: "100vh",
              }}
            >
              <Box>
                <Paper
                  elevation={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 1,
                    px: 4,
                  }}
                >
                  <Typography variant="h5" fontWeight={600}>
                    Job Posting
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button color="error" onClick={handleJobPostingCloseModal}>
                      Close
                    </Button>
                    <Button
                      color="inherit"
                      variant="outlined"
                      onClick={handleJobPostingSaveDraft}
                      disabled={jobPostStatus === "Submit"}
                      type="button"
                    >
                      Save as a Draft
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleJobPostingSaveSubmit}
                    >
                      Save and Submit
                    </Button>
                  </Box>
                </Paper>
              </Box>

              <Box flex={1} overflow={"auto"} py={1}>
                <JobPostingForm
                 selectDocument={selectDocument}
                  jobDetails={jobDetails}
                  setJobDetails={setJobDetails}
                  skills={skills}
                  setSkills={setSkills}
                  organizationalInformation={organizationalInformation}
                  setOrganizationalInformation={setOrganizationalInformation}
                  errors={errors}
                  allUsers={allUsers}
                  setAssignedTo={setAssignedTo}
                  assignedTo={assignedTo}
                  jobNotifyTo={jobNotifyTo}
                  setJobNotifyTo={setJobNotifyTo}
                  industryNames={industryNames}
                  editable={true}
                />
              </Box>
            </Box>
          </Modal>
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal open={showJobDesc} onClose={handleToggleJobDesc}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1400px",
            width: "80%",
            maxHeight: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 2,
              md: 2,
            },
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Box>
            <ScrollView height={"80vh"}>
              <Box
                sx={{
                  pl: 2,
                  pr: 4,
                  py: 2,
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", lineHeight: 1.7, fontWeight: 300 }}
                >
                 
                  <div
                      dangerouslySetInnerHTML={{
                        __html: findJobPostingData?.organizationalInformation?.jobDescription || "",
                      }}
                      style={{
                        padding: "20px",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                      />
                </Typography>
              </Box>
            </ScrollView>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default JobPostDetails;
