import { Box, Divider, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment-timezone";
import React, { useState } from "react";
import theme from "../../Theme";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
const SearchHistorySection = ({
  key,
  data,
  onClick,
  addedOn,
  onClickSave,
  status,
  onClickDelete,
  title = "",
  onClickEdit,
}) => {
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MM/DD/YY HH:mm:ss`);
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    // <div>
    //   <Box key={key}>
    //     {/* Displaying the query and country as a clickable link */}
    //     <Box
    //         href="#"
    //         sx={{
    //           color: "#4285F4",
    //           cursor: "pointer",
    //           textDecoration: "none",
    //         }}
    //         onClick={onClick}
    //       >
    //         {data}
    //       </Box>
    //     <Box>{formatDateForDisplay(addedOn)}</Box>
    //     <Divider sx={{ marginTop: 1 }} />
    //   </Box>
    // </div>
    <Box>
      <Box
        sx={{
          px: 2,
          py: 1,
          display: "flex",
          //flexDirection:"column",
          alignItems: "center",
          gap: 1,
          "&:hover": {
            bgcolor: theme.palette.secondary[200],
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box>
            {!title && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={onClick}
              >
                {data}
              </Typography>
            )}
            {title&&(
                <>
                 <Typography
                 variant="body2"
                 sx={{
                   color: theme.palette.secondary.main,
                   cursor: "pointer",
                   "&:hover": {
                     textDecoration: "underline",
                   },
                 }}
                 onClick={onClick}
               >
                 {title}
               </Typography>
               <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.grey[700],
                display: "flex",
                gap: 0.5,
                alignItems: "center",
              }}
            >
                {data}
                </Typography>
               </>
               
            )}
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.grey[700],
                display: "flex",
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <ScheduleIcon fontSize="small" />
              {formatDateForDisplay(addedOn)}
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ width: "30px" }}> */}
        <Box sx={{ display:'flex', flexDirection: 'row' }}>
        {isHovered && (
            <IconButton onClick={onClickEdit} color="secondary">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          {isHovered && status === "recent" && (
            <IconButton onClick={onClickSave} color="secondary">
              <SaveIcon fontSize="small" />
            </IconButton>
          )}
          {isHovered && status === "saved" && (
            <IconButton onClick={onClickDelete} color="secondary">
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default SearchHistorySection;
