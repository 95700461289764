import {
    Box,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  
  import FilterListIcon from "@mui/icons-material/FilterList";
  import FilterListOffIcon from "@mui/icons-material/FilterListOff";
  import React,{useState} from "react";
import BDMCustomeDateFilter from "./Common/BDMCustomeDateFilter";
const FilterHeader = ({
  params,
  type = "line", // select, multiselect
  options = [],
  setSearchJobPosition,
  setSearchCompany,
  setSearchStatus,
  setSearchBDmName,
  setSearchRAName,
  setSearchTeamCountRAName,
  setSearchTeamCountJobPosition,
  setSearchTeamCountEmailID,
  setSearchDownloadRAName,
  setSearchDownloadRAPsuedoName,
  setSearchDownloadEmailID,
  setSearchDownloadJobPosition,
  setEmailReportSenderAccount,
  setEmailReportBDMName,
  setEmailReportFromEmailId,
  setSearchDashboardCompanyName,
  setSearchDashboardBDMName,
  setSearchDashboardStatus,
  setSearchListCompanyName,
  setSearchListJobTitle,
  setSearchListJobLocation,
  setSearchRADashboardCompanyName,
  setSearchRADashboardjobPosition,
  setSearchRADashboardBDMName,
  setSearchRADashboardStatus,
  setSearchJobReportCompany,
  setSearchJobReportJobPosition,
  setSearchJobReportJobLocation,
  setSearchJobReportStatus,
  setSearchClientCompany,
  setSearchClientJobPosition,
  setSearchClientJobLocation,
  setSearchClientStatus,
  setSearchApprovalBDMName,
  setSearchApprovalCompanyName,
  setSearchApprovalJobTitle,
  setSearchApprovalJobLocation,
  setApprovalStatus,
  setSearchApprovalFromDate,
  setSearchApprovalToDate,
  setDashboardBDMName,
  setSearchDashboardRAName,
  setter,
  selectedValue= type === "multiselect" ? [] : "",
  title
}) => {
  // const [selectedValue, setSelectedValue] = React.useState(
  //   type === "multiselect" ? [] : ""
  // );

  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    if (title === 'jobTitle') {
      setSearchJobPosition(''); // Update the state with filtered jobs
    }else if(title==='companyName'){
      setSearchCompany('');
    }else if(title==='status'){
      setSearchStatus([])
    }else if(title==='bdmName'){
      setSearchBDmName([])
    }else if(title==='raName'){
      setSearchRAName([])
    }else if(title==='teamCountRAName'){
      setSearchTeamCountRAName([])
    
    }else if(title==='teamCountEmailId'){
      setSearchTeamCountEmailID([])
    
    }else if(title==='teamCountJobPosition'){
      setSearchTeamCountJobPosition('')
    }else if(title==='downloadRAName'){
      setSearchDownloadRAName([])
    
    }else if(title==='downloadRAPsuedoName'){
      setSearchDownloadRAPsuedoName([])
    }else if(title==='downloadEmailId'){
      setSearchDownloadEmailID([])
    }else if(title==='downloadJobPosition'){
      setSearchDownloadJobPosition('')
    
    }else if(title==='emailReportFromEmailId'){
      setEmailReportFromEmailId([])
    }else if(title==='emailReportBDMName'){
      setEmailReportBDMName([])
    }else if(title==='senderAccount'){
      setEmailReportSenderAccount([])
    
    }else if(title==='dashBoardCompanyName'){
      setSearchDashboardCompanyName('')
    
    }else if(title==='dashboardBdmName'){
      setSearchDashboardBDMName([])
    }else if(title==='dashboardStatus'){
      setSearchDashboardStatus([])
    }else if(title==='listCompanyName'){
      setSearchListCompanyName('')
    }else if(title==='listJobTitle'){
      setSearchListJobTitle('')
    }else if(title==='listJobLocation'){
      setSearchListJobLocation('')
    }else if(title==='RADashboardCompanyName'){
      setSearchRADashboardCompanyName('')
    }else if(title==='RADashboardJobPosition'){
      setSearchRADashboardjobPosition('')
    }else if(title==='RADashboardBDMName'){
      setSearchRADashboardBDMName([])
    }else if(title==='RADasboardStatus'){
      setSearchRADashboardStatus([])
    }else if(title==='jobReportCompanyName'){
      setSearchJobReportCompany('')
    }else if(title==='jobReportJobPosition'){
      setSearchJobReportJobPosition('')
    }else if(title==='jobReportJobLocation'){
      setSearchJobReportJobLocation('')
    }else if(title==='jobReportStatus'){
      setSearchJobReportStatus([])
    }else if(title==='clientCompanyName'){
      setSearchClientCompany('')
    }else if(title==='clientJobPosition'){
      setSearchClientJobPosition('')
    }else if(title==='clientJobLocation'){
      setSearchClientJobLocation('')
    }else if(title==='clientStatus'){
      setSearchClientStatus([])
    }else if(title=='approvalBDMName'){
      setSearchApprovalBDMName([])
    }else if(title==='approvalCompanyName'){
      setSearchApprovalCompanyName('')
    }else if(title==='approvaljobTitle'){
      setSearchApprovalJobTitle('')
    }else if(title==='approvaljobLocation'){
      setSearchApprovalJobLocation('')
    }else if(title==='approvalStatus'){
      setApprovalStatus([])
    }else if(title==='approvalBdmRequestDate'){
      setSearchApprovalToDate('')
      setSearchApprovalFromDate('')
    }else if(title==='bdmNameDasboard'){
      setDashboardBDMName([])
    }else if(title==='RANameDasboard'){
      setSearchDashboardRAName([])
    }else if(title==="jobPostingJobCode"){
      setter('')
    }else if(title==="jobPostingJobTitle"){
      setter('')
    }else if(title==="jobPostingCompanyName"){
      setter('')
    }else if(title==="jobPostingLocation"){
      setter('')
    }else if(title==="jobPostingLocation"){
      setter('')
    }
  };

  const SectionHeader = ({ title }) => (
    <InputLabel
      id={`${title}-label`}
      sx={{ fontSize: "12px", fontWeight: 600 }}
    >
      {title}
    </InputLabel>
  );
  const handleSearch = (event) => {
    const searchValue = event.target.value; // Get the value from the input field
  
    if (title === 'jobTitle') {
      setSearchJobPosition(searchValue); // Update the state with filtered jobs
    }else if(title==='companyName'){
      setSearchCompany(searchValue);
    }else if(title==='teamCountJobPosition'){
      setSearchTeamCountJobPosition(searchValue)
    }else if(title==='downloadJobPosition'){
      setSearchDownloadJobPosition(searchValue)
    }else if(title==='dashBoardCompanyName'){
      setSearchDashboardCompanyName(searchValue)
    }else if(title==='listCompanyName'){
      setSearchListCompanyName(searchValue)
    }else if(title==='listJobTitle'){
      setSearchListJobTitle(searchValue)
    }else if(title==='listJobLocation'){
      setSearchListJobLocation(searchValue)
    }else if(title==='RADashboardCompanyName'){
      setSearchRADashboardCompanyName(searchValue)
    }else if(title==='RADashboardJobPosition'){
      setSearchRADashboardjobPosition(searchValue)
    }else if(title==='jobReportCompanyName'){
      setSearchJobReportCompany(searchValue)
    }else if(title==='jobReportJobPosition'){
      setSearchJobReportJobPosition(searchValue)
    }else if(title==='jobReportJobLocation'){
      setSearchJobReportJobLocation(searchValue)
    }else if(title==='clientCompanyName'){
      setSearchClientCompany(searchValue)
    }else if(title==='clientJobPosition'){
      setSearchClientJobPosition(searchValue)
    }else if(title==='clientJobLocation'){
      setSearchClientJobLocation(searchValue)
    }else if(title==='approvalCompanyName'){
      setSearchApprovalCompanyName(searchValue)
    }else if(title==='approvaljobTitle'){
      setSearchApprovalJobTitle(searchValue)
    }else if(title==='approvaljobLocation'){
      setSearchApprovalJobLocation(searchValue)
    }else if(title==="jobPostingJobCode"){
      setter(searchValue)
    }else if(title==="jobPostingJobTitle"){
      setter(searchValue)
    }else if(title==="jobPostingCompanyName"){
      setter(searchValue)
    }else if(title==="jobPostingLocation"){
      setter(searchValue)
    }else if(title==="jobPostingJobStatus"){
      setter(searchValue)
    }
    else {
      console.log('No matching criteria for search.');
    }
  };
  const handleSelectMultiple = (event) => {
    const { value } = event.target;
    // setSelectedValue(value);
    if(title==='status'){
      setSearchStatus(value)
    }else if(title=='bdmName'){
      setSearchBDmName(value)
    }else if(title==='raName'){
      setSearchRAName(value)
    
    }else if(title==='teamCountRAName'){
      setSearchTeamCountRAName(value)
    }else if(title==='teamCountEmailId'){
      setSearchTeamCountEmailID(value)
    }else if(title==='downloadRAPsuedoName'){
      setSearchDownloadRAPsuedoName(value)
    }else if(title==='downloadRAName'){
      setSearchDownloadRAName(value)
    }
    else if(title==='downloadEmailId'){
      setSearchDownloadEmailID(value)
    }else if(title==='emailReportFromEmailId'){
      setEmailReportFromEmailId(value)
    }else if(title==='emailReportBDMName'){
      setEmailReportBDMName(value)
    }else if(title==='senderAccount'){
      setEmailReportSenderAccount(value)
    }else if(title==='dashboardBdmName'){
      setSearchDashboardBDMName(value)
    }else if(title==='dashboardStatus'){
      setSearchDashboardStatus(value)
    }else if(title==='RADashboardBDMName'){
      setSearchRADashboardBDMName(value)
    }else if(title==='RADasboardStatus'){
      setSearchRADashboardStatus(value)
    }else if(title==='jobReportStatus'){
      setSearchJobReportStatus(value)
    }else if(title==='clientStatus'){
      setSearchClientStatus(value)
    }else if(title=='approvalBDMName'){
      setSearchApprovalBDMName(value)
    }else if(title==='approvalStatus'){
      setApprovalStatus(value)
    }else if(title==='bdmNameDasboard'){
      setDashboardBDMName(value)
    }else if(title==='RANameDasboard'){
      setSearchDashboardRAName(value)
    }
  };
  if (!params || !params.colDef || !params.colDef.headerName) {
        console.error("Invalid params: ", params);
        return null; // Return null or some fallback UI if params are invalid
      }
      const handleDateChange = (selectedDates) => {
 
        setSearchApprovalFromDate("");
        setSearchApprovalToDate("");
        if (selectedDates.length === 2) {
          const formattedDates = selectedDates.map((date, index) => {
            return date.toISOString();
          });
    
          const [fromDate, toDate] = selectedDates;
          setSearchApprovalFromDate(formattedDates[0]);
          setSearchApprovalToDate(formattedDates[1]);
    
        }
      };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "1500px",
      }}
    >
      {!showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box flex={1}>{params.colDef.headerName}</Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="primary">
              <FilterListIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
      {showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box flex={1}>
            {type==='date'?(
              <>
              <BDMCustomeDateFilter handleDateChange={handleDateChange} onlyTitle/>
              </>
            ):(
              <>
               {type !== "line" ? (
              <>
                <SectionHeader title={params.colDef.headerName} />
                <Select
                  labelId={`${params.colDef.headerName}-label`}
                  id={params.colDef.headerName}
                  label={params.colDef.headerName}
                  MenuProps={{}}
                  variant="standard"
                  fullWidth
                  size="small"
                  // multiple={type === "multiselect" ? true : false}
                  multiple={type === "multiselect"}
                  value={selectedValue||[]}
                  sx={{ fontSize: "12px", fontWeight: 600 }}
                  onChange={handleSelectMultiple}
                >
                  {options &&
                    options.map((item, key) => (
                      <MenuItem
                        value={item}
                        key={key}
                        sx={{ fontSize: "12px", fontWeight: 600 }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </>
            ) : (
              <>
                <SectionHeader title={params.colDef.headerName} />
                <TextField
                  variant="standard"
                  //label={`${params.colDef.headerName} Filter`}
                  placeholder={`${params.colDef.headerName} Filter`}
                  fullWidth
                  InputProps={{
                    style: { fontSize: "12px" }, // Set font size for the input element
                  }}
                  onChange={handleSearch}
                />
              </>
            )}
              </>
            )}
           
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="error">
              <FilterListOffIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FilterHeader;