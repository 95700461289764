// import { Box, Typography } from "@mui/material";
// import React from "react";
// import theme from "../../Theme";

// const KeyData = ({ title, value, size = "small" }) => {
//   return (
//     <React.Fragment>
//       <Box sx={{ display: "flex", flexDirection: "column", p: 0.5, gap: 0.5 }}>
//         <Box>
//           <Typography
//             sx={{
//               fontSize: size == "small" ? 11 : size == "medium" ? 13 : 16,
//               fontWeight: size == "small" ? 400 : size == "medium" ? 500 : 600,
//               letterSpacing: 0.2,
//               color: theme.palette.primary.light,
//               lineHeight: 1.2,
//               whiteSpace: "nowrap",
//             }}
//           >
//             {title}
//           </Typography>
//         </Box>
//         <Box>
//           <Typography
//             sx={{
//               fontWeight: 600,
//               fontSize: size == "small" ? 12 : size == "medium" ? 16 : 20,
//               letterSpacing: 0.2,
//               color: theme.palette.grey[800],
//               lineHeight: 1.2,
//             }}
//           >
//             {value}
//           </Typography>
//         </Box>
//       </Box>
//     </React.Fragment>
//   );
// };

// export default KeyData;
// import { Box, Link, Menu, MenuItem, Typography } from "@mui/material";
// import React from "react";
// import theme from "../../Theme";

// const KeyData = ({
//   title,
//   value,
//   size = "small",
//   color = "primary", //primary, secondary, info, success,warning,error
//   link = false,
//   linkTitle="Link"
// }) => {
//   return (
//     <React.Fragment>
//       <Box sx={{ display: "flex", flexDirection: "column", p: 0.5, gap: 0.5 }}>
//         <Box>
//           <Typography
//             noWrap
//             sx={{
//               fontSize: size == "small" ? 11 : size == "medium" ? 13 : 16,
//               fontWeight: size == "small" ? 400 : size == "medium" ? 500 : 600,
//               letterSpacing: 0.2,
//               color: theme.palette[color].main,
//               lineHeight: 1.2,
//               whiteSpace: "nowrap",
//             }}
//           >
//             {title}
//           </Typography>
//         </Box>
//         <Box>
//           {!link ? (
//             <Typography
//               noWrap
//               sx={{
//                 fontWeight: 600,
//                 fontSize: size == "small" ? 12 : size == "medium" ? 16 : 20,
//                 letterSpacing: 0.2,
//                 color: theme.palette.grey[800],
//                 lineHeight: 1.2,
//               }}
//             >
//               {value}
//             </Typography>
//           ) : (
//             <Link
//               href={value}
//               target="_blank"
//               rel="noopener noreferrer"
//               sx={{ color: "error.main", textDecoration:'none' }} // Optional: Apply custom styles
//             >
//               {/* {value} */}
//               <Typography
//                 noWrap
//                 component={"span"}
//                 sx={{
//                   fontWeight: 600,
//                   fontSize: size == "small" ? 12 : size == "medium" ? 16 : 20,
//                   letterSpacing: 0.2,
//                   color: theme.palette.error.dark,
//                   lineHeight: 1.2,
//                 }}
//               >
//                 {linkTitle}
//               </Typography>
//             </Link>
//           )}
//         </Box>
//       </Box>
//     </React.Fragment>
//   );
// };

// export default KeyData;

import { Box, Link, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import theme from "../../Theme";
import HelpIcon from "@mui/icons-material/Help";
const KeyData = ({
  title,
  info,
  value,
  size = "small",
  color = "primary", //primary, secondary, info, success,warning,error
  link = false,
  linkTitle = "Link",
  fullContent = false,
  reverse = false,
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: reverse?"column-reverse":"column",
          p: 0.5,
          gap: 0.5,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            noWrap
            sx={{
              fontSize: size == "small" ? 11 : size == "medium" ? 13 : 16,
              fontWeight: size == "small" ? 400 : size == "medium" ? 500 : 600,
              letterSpacing: 0.2,
              color: theme.palette[color].main,
              lineHeight: 1.2,
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          {info && (
            <Tooltip title={info} arrow placement="top">
              <HelpIcon
                sx={{ color: "warning.main", fontSize: "12px", mx: 0.5 }}
              />
            </Tooltip>
          )}
        </Box>
        <Box>
          {!link ? (
            <Typography
              noWrap={!fullContent}
              sx={{
                fontWeight: 600,
                fontSize: size == "small" ? 12 : size == "medium" ? 16 : 20,
                letterSpacing: 0.2,
                color: theme.palette.grey[800],
                lineHeight: 1.2,
              }}
            >
              {value}
            </Typography>
          ) : (
            <Link
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "error.main",
                textDecoration: "none",
                p: 0,
              }} // Optional: Apply custom styles
            >
              {/* {value} */}
              <Typography
                noWrap
                sx={{
                  fontWeight: 600,
                  fontSize: size == "small" ? 12 : size == "medium" ? 16 : 20,
                  letterSpacing: 0.2,
                  color: theme.palette.error.dark,
                  lineHeight: 1.2,
                }}
              >
                {linkTitle}
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default KeyData;
