import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomTable from './CustomTable'
import { useSelector } from 'react-redux';

const CustomTaggedApplicants = ({submissions = []}) => {
    const Applicants = useSelector((state) => state.Recruiters?.applicants) || [];

    const taggedApplicants = submissions.map(sub => {
        let applicantData = Applicants.find(applicant => applicant._id === sub.applicantId && sub.applicantStatus === 'TagJob')
        if (applicantData) {
        return {id: applicantData.applicantId, fullName: `${applicantData.firstName ?? ''} ${applicantData.lastName ?? ''}`, ...applicantData}
        }
    }).filter(list => list !== undefined);

    const tableColumns = [
        { field: "fullName", headerName: "Full Name", minWidth: 100, clickable: true },
        { field: "jobTitle", headerName: "Designation/Job Title", minWidth: 200 },
      ];

  return (
    <Box>
        <CustomTable tableColumns={tableColumns} tableRows={taggedApplicants}/>
    </Box>
  )
}

export default CustomTaggedApplicants