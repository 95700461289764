import { combineReducers } from "redux";
// Authentication
import Login from "./auth/login/reducer";
import Search from "./search/reducer";
import CompaniesList from "./companiesList/reducer"
import Reports from "./reports/reducer";
import Recruiters from "./recruiter/reducer";
import Layouts from "./auth/layout/reducer";
import Resumes from "./resumes/reducer";
import JobBoards from "./jobBoards/reducer";
import BuildVersion from './buildVersion/reducer';
const rootReducer = combineReducers({
    // public
    Login,
    Search,
    CompaniesList,
    Reports,
    Recruiters,
    Layouts,
    Resumes,
    JobBoards,
    BuildVersion
});

export default rootReducer;