import { all, call, delay, fork, put, race, takeEvery } from "redux-saga/effects";
import { setSnackbarMessage } from "../actions";
import { GET_BUILDVERSION_NUM, LATEST_BUILD_VERSION_VIA_API } from "./actionType";

const apiUrl = process.env.REACT_APP_API_URL;

function getLatestBuildVersionViaApi() {

    return fetch(apiUrl + "/buildVersion/latestVersion", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
}

//---------------------------------------------------------------------------------------
// Fetch latest build version
function* getLatestBuildVersionViaSaga(action) {
    try {
        const { response, timeout } = yield race({
          response: call(getLatestBuildVersionViaApi), // Call the API function
          timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
        });
    
        if (response) {
          if (response.error === "An internal server error occurred") {
            yield put(setSnackbarMessage(response.error, "error"));
            return null;
          }
          if (response.error) {
            yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
            return null;
          }
          yield put({ type: GET_BUILDVERSION_NUM, payload: response.data});         
        }
      } catch (error) {
        // Handle errors here and dispatch a failure action
        // yield put(settleItems(error));
      }
  }

// Watcher saga
function* watchSettleItems() {
    yield takeEvery(LATEST_BUILD_VERSION_VIA_API, getLatestBuildVersionViaSaga);
};

// Root saga
export default function* BuildVersions() {
    yield all([fork(watchSettleItems)]);
  }