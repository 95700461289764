import { 
    ADD_JOB_BOARDS_HISTORY_VIA_API, DELETE_JOB_BAORDS_HISTORY_VIA_API, DELETE_JOB_BOARDS, FAILURE_VALIDATE_EMAIL, 
    GET_APPLICANTS_SEARCH_LIST_VIA_API, 
    GET_JOB_BOARDS_HISTORY_VIA_API, GET_JOB_BOARDS_STATUS_HISTORY_VIA_API, GET_JOBPOSTING_SEARCH_LIST_VIA_API, GET_JOBPOSTINGS_SEARCH_LIST_VIA_API, GET_PLACEMENTS_SEARCH_LIST_VIA_API, GLOBAL_SEARCH_RESULT_VIA_API, INTERNAL_DB_LOADING, INTERNAL_DB_MATCHED_RESUMES_VIA_API,
     REMOVE_OLD_GLOBAL_SEARCH_RESULT,
     REQUEST_VALIDATE_EMAIL, SAVE_JOB_BOARDS_HISTOTY_VIA_API, SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED, SUCCESS_APPLICANTS_SEARCH_LIST, SUCCESS_APPLICANTS_SEARCH_LIST_VIA_API, SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST, SUCCESS_GLOBAL_SEARCH_RESULT, SUCCESS_INTERNAL_DB_MATCHED_RESUMES, SUCCESS_JOB_BOARDS_HISTORY, SUCCESS_VALIDATE_EMAIL } from "./actionType"


export const addJobBoardsHistoryViaApi=(action)=>{
    return {
    type:ADD_JOB_BOARDS_HISTORY_VIA_API,
    payload:action
}
}
export const getJobBoardsHistoryViaApi=(action)=>({
    type:GET_JOB_BOARDS_HISTORY_VIA_API,
    payload:action
})
export const successJobboardsHistory=(reponse)=>({
    type:SUCCESS_JOB_BOARDS_HISTORY,
    payload:reponse
})

export const saveJobBoardsHistoryViaApi=(action)=>{
    return{
        type:SAVE_JOB_BOARDS_HISTOTY_VIA_API,
        payload:action
    }
}
export const deleteJobBoardsHistoryViaApi=(action)=>{
    return{
        type:DELETE_JOB_BAORDS_HISTORY_VIA_API,
        payload:action,
    }
}
export const getjobBoardsStatusHistoryViaApi=(action)=>{
    return{
        type:GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,
        payload:action
    }
}
export const requestValidateEmail = (emails) => {
    return{
    type: REQUEST_VALIDATE_EMAIL,
    payload: emails,
    
  }};
  
  export const successValidateEmail = (data) => ({
    type: SUCCESS_VALIDATE_EMAIL,
    payload: data,
  });
  
  export const failureValidateEmail = (error) => ({
    type: FAILURE_VALIDATE_EMAIL,
    payload: error,
  });

  export const internalDBMatchedResumesViaApi =(action)=>{
    return{
        type:INTERNAL_DB_MATCHED_RESUMES_VIA_API,
        payload:action
    }
  }
  export const successInternalDBMatchedResumes=(reponse)=>{
    return{
        type:SUCCESS_INTERNAL_DB_MATCHED_RESUMES,
        payload:reponse
    }
  }
  export const deleteJobBoards=()=>{
    return{
        type:DELETE_JOB_BOARDS,
        payload:{}
    }
  }
  export const successAddReferenceNoteInternalDbMatched=(reponse)=>{
    return {
        type:SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED,
        payload:reponse
    }
}
export const internalDBLoading=(action)=>{
    return{
        type:INTERNAL_DB_LOADING,
        payload:action
    }
}
export const globalSearchResultViaApi=(action)=>{
    return{
        type:GLOBAL_SEARCH_RESULT_VIA_API,
        payload:action
    }
}
export const successGlobalSearchResult=(reponse)=>{
    return{
        type:SUCCESS_GLOBAL_SEARCH_RESULT,
        payload:reponse
    }
}
export const removeOldGlobalSearchResult=(reponse)=>{
    return{
        type:REMOVE_OLD_GLOBAL_SEARCH_RESULT,
        payload:reponse
    }
}
export const getJobPostingsSearchListViaApi=(action)=>{
    return{
        type:GET_JOBPOSTINGS_SEARCH_LIST_VIA_API,
        payload:action
    }
}
export const successGetJobPostingsSearchList=(action)=>{
    return{
        type:SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST,
        payload:action
    }
}
export const getApplicantsSearchListViaApi=(action)=>{
    return{
        type:GET_APPLICANTS_SEARCH_LIST_VIA_API,
        payload:action
    }
}
export const successApplicantsSearchList=(action)=>{
    return{
        type:SUCCESS_APPLICANTS_SEARCH_LIST,
        payload:action
    }
}
export const getjobPostingsSearchListViaApi=(action)=>{
    return{
        type:GET_JOBPOSTINGS_SEARCH_LIST_VIA_API,
        payload:action,
    }
}
export const successjobPostingsSearchList=(action)=>{
    return{
        type:SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST,
        payload:action,
    }
}
