import { enqueueSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
export const GetMonsterAccountInfo = (storeData, integrationsList, closeSnackbar) => {
    let monsterAccountId = "";
    let totalAvailable = 0;
    const isSuperAdminOrDev =
      Array.isArray(storeData.userType) &&
      (storeData.userType.includes("SuperAdmin") || storeData.userType.includes("Developer"));
  
    if (isSuperAdminOrDev) {
      if (Array.isArray(integrationsList)) {
        integrationsList.some((accountApi) => {
          if (accountApi.totalAvailable > 0) {
            monsterAccountId = accountApi._id;
            totalAvailable = accountApi.totalAvailable;
            return true; // Break the loop
          }
          return false;
        });
      }
    } else {
      let access = false;
      if (Array.isArray(integrationsList)) {
        integrationsList.some((accountApi) => {
          if (Array.isArray(accountApi.assignedTo)) {
            const hasAccess = accountApi.assignedTo.some((account) => {
              if (account.accountId === storeData._id && account.access) {
                monsterAccountId = accountApi._id;
                totalAvailable = accountApi.totalAvailable;
                return true; // Break the loop
              }
              return false;
            });
            if (hasAccess) {
              access = true;
              return true; // Break the loop
            }
          }
          return false;
        });
      }
  
      if (!access) {
        enqueueSnackbar(
          `You have no access to Monster search. Please contact the admin.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
        // Stop the function completely if no access
        return;
      }
    }
    return { monsterAccountId, totalAvailable };
  };
  