export const ADD_JOB_BOARDS_HISTORY_VIA_API='ADD_JOB_BOARDS_HISTORY_VIA_API'
export const GET_JOB_BOARDS_HISTORY_VIA_API='GET_JOB_BOARDS_HISTORY_VIA_API'
export const SUCCESS_JOB_BOARDS_HISTORY='SUCCESS_JOB_BOARDS_HISTORY'
export const DELETE_JOB_BOARDS='DELETE_JOB_BOARDS'
export const SAVE_JOB_BOARDS_HISTOTY_VIA_API='SAVE_JOB_BOARDS_HISTOTY_VIA_API'
export const DELETE_JOB_BAORDS_HISTORY_VIA_API='DELETE_JOB_BAORDS_HISTORY_VIA_API'
export const GET_JOB_BOARDS_STATUS_HISTORY_VIA_API='GET_JOB_BOARDS_STATUS_HISTORY_VIA_API'

export const REQUEST_VALIDATE_EMAIL = 'REQUEST_VALIDATE_EMAIL';
export const SUCCESS_VALIDATE_EMAIL = 'SUCCESS_VALIDATE_EMAIL';
export const FAILURE_VALIDATE_EMAIL = 'FAILURE_VALIDATE_EMAIL';


export const INTERNAL_DB_MATCHED_RESUMES_VIA_API='INTERNAL_DB_MATCHED_RESUMES_VIA_API';
export const SUCCESS_INTERNAL_DB_MATCHED_RESUMES='SUCCESS_INTERNAL_DB_MATCHED_RESUMES';
export const SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED='SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED';

export const INTERNAL_DB_LOADING='INTERNAL_DB_LOADING';

export const GLOBAL_SEARCH_RESULT_VIA_API='GLOBAL_SEARCH_RESULT_VIA_API';
export const SUCCESS_GLOBAL_SEARCH_RESULT='SUCCESS_GLOBAL_SEARCH_RESULT';
export const REMOVE_OLD_GLOBAL_SEARCH_RESULT='REMOVE_OLD_GLOBAL_SEARCH_RESULT';

export const GET_JOBPOSTINGS_SEARCH_LIST_VIA_API='GET_JOBPOSTINGS_SEARCH_LIST_VIA_API';
export const SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST='SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST';

export const GET_APPLICANTS_SEARCH_LIST_VIA_API='GET_APPLICANTS_SEARCH_LIST_VIA_API';
export const SUCCESS_APPLICANTS_SEARCH_LIST='SUCCESS_APPLICANTS_SEARCH_LIST';

export const GET_PLACEMENTS_SEARCH_LIST_VIA_API='GET_PLACEMENTS_SEARCH_LIST_VIA_API';
export const SUCCESS_PLACEMENTS_SEARCH_LIST='SUCCESS_PLACEMENTS_SEARCH_LIST';


