// const FormatDateTime = (mailSent, type = "full") => {
//   const now = new Date();
//   const sentDate = new Date(mailSent);
//   const diffInMs = now - sentDate;
//   const diffInMinutes = Math.floor(diffInMs / 60000);

//   // Helper to format time as HH:MM
//   const formatTime = (date) => {
//     return date.toLocaleTimeString("en-GB", {
//       hour: "2-digit",
//       minute: "2-digit",
//     });
//   };

//   // Helper to calculate day difference
//   const getDayDifference = (startDate, endDate) => {
//     const start = new Date(
//       startDate.getFullYear(),
//       startDate.getMonth(),
//       startDate.getDate()
//     );
//     const end = new Date(
//       endDate.getFullYear(),
//       endDate.getMonth(),
//       endDate.getDate()
//     );
//     const diffInMs = end - start;
//     return Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Difference in days
//   };

//   const diffInHours = Math.floor(diffInMinutes / 60);
//   const isSameDay = now.toDateString() === sentDate.toDateString();
//   const yesterday = new Date();
//   yesterday.setDate(now.getDate() - 1);
//   const isYesterday = yesterday.toDateString() === sentDate.toDateString();
//   const diffInDays = getDayDifference(sentDate, now);

//   // Full Format
//   if (type === "full") {
//     if (diffInMinutes < 1) {
//       return "Now";
//     } else if (diffInMinutes < 60) {
//       return `${formatTime(sentDate)} (${diffInMinutes} min ago)`;
//     } else if (isSameDay) {
//       return `${formatTime(sentDate)} (${diffInHours} hour${
//         diffInHours > 1 ? "s" : ""
//       } ago)`;
//     } else if (isYesterday) {
//       const formattedDate = sentDate.toLocaleDateString("en-GB", {
//         weekday: "short",
//         day: "numeric",
//         month: "short",
//       });
//       return `${formattedDate}, ${formatTime(sentDate)} (${diffInHours} hour${
//         diffInHours > 1 ? "s" : ""
//       } ago)`;
//     } else {
//       const formattedDate = sentDate.toLocaleDateString("en-GB", {
//         weekday: "short",
//         day: "numeric",
//         month: "short",
//       });
//       return `${formattedDate}, ${formatTime(sentDate)} (${diffInDays} day${
//         diffInDays > 1 ? "s" : ""
//       } ago)`;
//     }
//   }

//   // Short Format
//   if (diffInMinutes < 1) {
//     return "Now";
//   } else if (diffInMinutes < 60) {
//     return `(${diffInMinutes} min ago)`;
//   } else if (isSameDay) {
//     return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
//   } else if (isYesterday) {
//     return sentDate.toLocaleDateString("en-GB", {
//       weekday: "short",
//       day: "numeric",
//       month: "short",
//     });
//   } else {
//     return sentDate.toLocaleDateString("en-GB", {
//       weekday: "short",
//       day: "numeric",
//       month: "short",
//     });
//   }
// };

// export default FormatDateTime;



const FormatDateTime = (mailSent, type = "full") => {
  const now = new Date();
  const sentDate = new Date(mailSent);
  const diffInMs = now - sentDate;
  const diffInMinutes = Math.floor(diffInMs / 60000);

  // Helper to format time as HH:MM
  const formatTime = (date) => {
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Helper to calculate day difference
  const getDayDifference = (startDate, endDate) => {
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const diffInMs = end - start;
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Difference in days
  };

  const diffInHours = Math.floor(diffInMinutes / 60);
  const isSameDay = now.toDateString() === sentDate.toDateString();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  const isYesterday = yesterday.toDateString() === sentDate.toDateString();
  const diffInDays = getDayDifference(sentDate, now);

  // Full Format
  if (type === "full") {
    if (diffInMinutes < 1) {
      return `Now, ${formatTime(now)}`;
    } else if (diffInMinutes < 60) {
      return `${sentDate.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
      })}, ${formatTime(sentDate)} (${diffInMinutes} min ago)`;
    } else if (isSameDay) {
      return `${sentDate.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
      })}, ${formatTime(sentDate)} (${diffInHours} hour${
        diffInHours > 1 ? "s" : ""
      } ago)`;
    } else if (isYesterday) {
      return `${sentDate.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
      })}, ${formatTime(sentDate)} (${diffInHours} hour${
        diffInHours > 1 ? "s" : ""
      } ago)`;
    } else {
      return `${sentDate.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
      })}, ${formatTime(sentDate)} (${diffInDays} day${
        diffInDays > 1 ? "s" : ""
      } ago)`;
    }
  }

  // Short Format
  if (diffInMinutes < 1) {
    return `Now, ${formatTime(now)}`;
  } else if (diffInMinutes < 60) {
    return `${sentDate.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })}, ${formatTime(sentDate)} (${diffInMinutes} min ago)`;
  } else if (isSameDay) {
    return `${sentDate.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })}, ${formatTime(sentDate)} (${diffInHours} hour${
      diffInHours > 1 ? "s" : ""
    } ago)`;
  } else if (isYesterday) {
    return `${sentDate.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })}, ${formatTime(sentDate)}`;
  } else {
    return `${sentDate.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })}, ${formatTime(sentDate)}`;
  }
};

export default FormatDateTime;
