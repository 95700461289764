import{
    DELETE_JOB_BOARDS,
    FAILURE_VALIDATE_EMAIL,
    INTERNAL_DB_LOADING,
    REMOVE_OLD_GLOBAL_SEARCH_RESULT,
    SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED,
    SUCCESS_APPLICANTS_SEARCH_LIST,
    SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST,
    SUCCESS_GLOBAL_SEARCH_RESULT,
    SUCCESS_INTERNAL_DB_MATCHED_RESUMES,
    SUCCESS_JOB_BOARDS_HISTORY,
    SUCCESS_VALIDATE_EMAIL
} from "./actionType"

const INIT_STATE = {
    jobBoardsHistory:[],
    validationResults: [],
    validationError: null,
    internalDBMatchedResumes:[],
    internalDBLoading:false,
    applicantsList:[],
    jobPostingsList:[],
    placementsList:[],
    countAllGlobalSearchList: {
      applicantsCount: 0,
      jobPostingsCount: 0,
      placementsCount: 0,
      moduleType:''

    },
  };
  const jobBoards = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_JOB_BOARDS_HISTORY: {
        const jobBoardsHistoryData = action.payload;
        return {
          ...state,
          jobBoardsHistory: jobBoardsHistoryData,
        };
      }
    
    
      case SUCCESS_VALIDATE_EMAIL: {
        const validationData = action.payload;
        return {
          ...state,
          validationResults: validationData,
          validationError: null,
        };
      }
      case FAILURE_VALIDATE_EMAIL: {
        const error = action.payload;
        return {
          ...state,
          validationError: error,
        };
      }
      case SUCCESS_INTERNAL_DB_MATCHED_RESUMES: {
        const matchedData = action.payload; // New data from action
        const oldData = state.internalDBMatchedResumes; // Existing data in state
        const oldDataMap = new Map(oldData.map((old) => [old.resumeId, old]));
        const updatedData = matchedData.map((newItem) => {
            if (oldDataMap.has(newItem.resumeId)) {
                return newItem;
            }
            return newItem;
        });
        const unmatchedOldData = oldData.filter(
            (oldItem) => !matchedData.some((newItem) => newItem.resumeId === oldItem.resumeId)
        );
        return {
            ...state,
            internalDBMatchedResumes: [...updatedData, ...unmatchedOldData],
        };
    }
    case SUCCESS_ADD_REFERENCE_NOTE_INTERNAL_DB_MATCHED:{
      const newData = action.payload;
      const oldData = state.internalDBMatchedResumes; // Existing data in state
      const updatedData = oldData.map((oldItem) => {
        const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem._id);
        if (matchingNewItem) {
          const oldNote = oldItem?.note || []; // Existing notes or an empty array
          const updatedNote = matchingNewItem || {}; // Get the new note (or an empty object if not present)
          return { 
            ...oldItem, 
            note: [...oldNote, updatedNote] // Combine old and new notes
          };
        }
        return oldItem; // Return the old item if no match
      });
      return {
        ...state,
        internalDBMatchedResumes: updatedData,
    };
    }
    case INTERNAL_DB_LOADING:
      return{
        ...state,
        internalDBLoading: action.payload,
      }
      case SUCCESS_GLOBAL_SEARCH_RESULT:{
        const {applicantsList,jobPostingsList,placementsList,applicantsCount,jobPostingsCount,moduleType}=action.payload;
        
        return{
          ...state,
          applicantsList:applicantsList,
          jobPostingsList:jobPostingsList,
          placementsList:placementsList,
          countAllGlobalSearchList: {
            applicantsCount:applicantsCount,
            jobPostingsCount:jobPostingsCount,
            placementsCount:0,
            moduleType:moduleType,
          },
        }
      }
      case SUCCESS_APPLICANTS_SEARCH_LIST:{
        const newData = action.payload.applicantsList;
        const oldData = state.applicantsList; // Existing data in state
        const oldDataMap = new Map(oldData.map((old) => [old._id, old]));
        const updatedData = newData.map((newItem) => {
            if (oldDataMap.has(newItem._id)) {
                return newItem;
            }
            return newItem;
        });
        const unmatchedOldData = oldData.filter(
            (oldItem) => !newData.some((newItem) => newItem._id === oldItem._id)
        );
    const mergedData = [...updatedData, ...unmatchedOldData];
        return{
          ...state,
          applicantsList:mergedData,
          countAllGlobalSearchList: {
            applicantsCount:action.payload.applicantsCount,
            placementsCount:0,
            moduleType:action.payload.moduleType,
          },
        }
      }
      case SUCCESS_GET_JOBPOSTINGS_SEARCH_LIST:{
        const newDataJobPostings = action.payload.jobPostingsList;
        const oldDataJobPostings = state.jobPostingsList; // Existing data in state
        const oldDataMapJobPostings = new Map(oldDataJobPostings.map((old) => [old._id, old]));
        const updatedDataJobPostings = newDataJobPostings.map((newItem) => {
            if (oldDataMapJobPostings.has(newItem._id)) {
                return newItem;
            }
            return newItem;
        });
        const unmatchedOldDataJobPostings = oldDataJobPostings.filter(
            (oldItem) => !newDataJobPostings.some((newItem) => newItem._id === oldItem._id)
        );
        return{
          ...state,
          jobPostingsList:[...updatedDataJobPostings, ...unmatchedOldDataJobPostings],
          countAllGlobalSearchList: {
            jobPostingsCount:action.payload.jobPostingsCount,
            placementsCount:0,
            moduleType:action.payload.moduleType,
          },
        }
      }
        case REMOVE_OLD_GLOBAL_SEARCH_RESULT:{
          return{
            ...state,
            applicantsList:[],
            jobPostingsList:[],
            placementsList:[],
            countAllGlobalSearchList: {
              applicantsCount:0,
              jobPostingsCount:0,
              placementsCount:0,
              moduleType:''
            },
          }
        }
      case DELETE_JOB_BOARDS: {
        
        return INIT_STATE;
      }

      default:
        return state;
    }
  };
  
  export default jobBoards;
  