import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import CustomFormField from "../Common/CustomFormField";
import MainBackground from "../Backgrounds/MainBackground";
import theme from "../../Theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyViaApi,
  getAllIndustriesViaApi,
  industriesNamesViaApi,
  removeNewCompany,
} from "../../store/actions";
import { debounce } from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const AddNewJobPost = ({ open, handleClose,setLoading }) => {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.Search.newCompany);
  const storeData = useSelector((state) => state.Login.storeData);
  const industriesRedux = useSelector((state) => state.Search.industries);
  const client = storeData.clientDb;
  const industries = Array.isArray(industriesRedux) ? industriesRedux : [];
  const searchResultsData =
    useSelector((state) => state.Search.searchData) || [];
  const latestResult = searchResultsData.sort(
    (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
  )[0];
  const industryNames = industries.map((item) => item.industryName) || [];
  let jobDetailsFormat = {
    addedBy: storeData._id,
    jobTitle: "",
    jobLocation: "",
    jobDescription: "",
    jobWebsite: "",
    jobIndustrieName: "",
    jobAddedOn: new Date(),
    status: "Active",
  };

  let excelDataFormat = {
    addedBy: storeData._id,
    firstName: "",
    lastName: "",
    designation: "",
    emailId: "",
    contactAddedOn: new Date(),
    phoneNumber: "",
  };

  const [company, setCompany] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
 
  const [errors, setErrors] = useState({});



  const [jobDetails, setJobDetails] = useState([jobDetailsFormat]);
  const [excelData, setExcelData] = useState([excelDataFormat]);
  const [deletedContactModal, setDeletedContactModal] = useState(false);
  const [delleteContactIndex, setDeleteContactIndex] = useState("");

  const handleResetState = () => {
    setJobDetails([jobDetailsFormat]);
    setExcelData([excelDataFormat]);
    setErrors({});
  };

  const handleCloseModal = () => {
    handleClose();
    setCompany("");
    handleResetState();
    setCompaniesList([]);
    currentCompany && currentCompany._id && dispatch(removeNewCompany());
  };

  const handleCompanyQuery = (value) => {
    // setCompaniesList([]);
    handleResetState();
    setCompany(value);
    handleCallCompanyWithApi(value);
  };

  const handleCallCompanyWithApi = async (value) => {
    try {
      if (value === "") {
        setCompaniesList([]);
        return;
      }
 // Only make the API call if the user has typed more than 2 characters
 if (value.length < 3) {
  return;
}
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/companyData/getRequestedCompanies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client: client, // Replace with the actual client value if dynamic
            company: value,
          }),
        }
      );

      if (!response.ok) {
        setCompaniesList([]);
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }
      const data = await response.json();
    const filterLatestCompanies = (data) => {
      const companyMap = new Map();
      data.forEach((company) => {
        const existing = companyMap.get(company.companyName);

        if (
          !existing || 
          new Date(company.addedOn) > new Date(existing.addedOn) // Check which is latest
        ) {
          companyMap.set(company.companyName, company);
        }
      });

      return Array.from(companyMap.values());
    };

    const latestCompanies = filterLatestCompanies(data);
    setCompaniesList(latestCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleFillCompanyData = (companyData, index) => {
    setCompany(companyData.companyName);

    let jobDetailsData = {};
    if (companyData.jobDetails[0]?.jobWebsite) {
      jobDetailsData.jobWebsite = companyData.jobDetails[0]?.jobWebsite;
    }
    if (companyData.jobDetails[0]?.jobIndustrieName) {
      jobDetailsData.jobIndustrieName =
        companyData.jobDetails[0]?.jobIndustrieName;
    }
    setJobDetails((prev) => [{ ...prev[index], ...jobDetailsData }]);
    setCompaniesList([]);
  };

  const handleDeleteContact = () => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      updatedData.splice(delleteContactIndex, 1);
      setExcelData(updatedData);
      setDeletedContactModal(!deletedContactModal);
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    }
  };

  const handleDeleteDirectContact = (index) => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      const contact = updatedData[index];
      // Check if any of the specific fields are non-empty
      if (
        contact.firstName !== "" ||
        contact.lastName !== "" ||
        contact.designation !== "" ||
        contact.emailId !== ""
      ) {
        ((index) => {
          setDeletedContactModal(!deletedContactModal);
          setDeleteContactIndex(index);
        })();
      } else {
        updatedData.splice(index, 1);
        setExcelData(updatedData);
      }
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    }
  };
  const validateForm = () => {
    const newErrors = {};
    const encounteredEmails = {};
  
    // Validate company
    if (!company?.trim()) {
      newErrors.company = "Company Name is required.";
    }
  
    // Validate job details
    jobDetails.forEach((contact, index) => {
      if (!contact.jobTitle?.trim()) {
        newErrors[`jobTitle_${index}`] = "Job title is required.";
      }
      if (!contact.jobLocation?.trim()) {
        newErrors[`jobLocation_${index}`] = "Job location is required.";
      }
      if (!contact.jobDescription?.trim()) {
        newErrors[`jobDescription_${index}`] = "Job description is required.";
      }
      if (!contact.jobWebsite?.trim()) {
        newErrors[`jobWebsite_${index}`] = "Job website is required.";
      }
    });
  
    // Validate Excel data (contact details)
  excelData.forEach((contact, index) => {
    // Check for missing fields
    ["firstName", "lastName", "designation"].forEach((field) => {
      if (!contact[field]?.trim()) {
        newErrors[`${field}Row_${index}`] = `${field} is required in row ${index + 1}.`;
      }
    });

    // Validate email directly here
    const email = contact.emailId?.trim();
    if (!email) {
      newErrors[`emailRow_${index}`] = `Email is required in row ${index + 1}.`;
    } else {
      // Email format validation
      const isValidEmail = /^(?!.*@gmail\.com$)(?!.*\bgmail\b)([^@]+)@([^@]+\.[^@]+)$/.test(email);
      if (!isValidEmail) {
        newErrors[`emailRow_${index}`] = `Email at row ${index + 1} is not valid.`;
      }
      // Check for duplicate emails
      if (encounteredEmails[email.toLowerCase()]) {
        newErrors[`emailRow_${index}`] = `Email at row ${index + 1} is a duplicate.`;
      } else {
        encounteredEmails[email.toLowerCase()] = true;
      }
    }
  });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  
  const handleJobChange = (index, field, value) => {
    const updatedContacts = [...jobDetails];
    updatedContacts[index][field] = value;
    setJobDetails(updatedContacts);
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...excelData];
    updatedContacts[index][field] = value;
    setExcelData(updatedContacts);
  };

  const handleSaveJobDetails = () => {
    setErrors({});

    if(validateForm()){
      
    const trimmedJobDetails = jobDetails.map((contact) => ({
      ...contact,
      jobTitle: contact.jobTitle.trim(),
      jobLocation: contact.jobLocation.trim(),
      jobDescription: contact.jobDescription.trim(),
      jobWebsite: contact.jobWebsite ? contact.jobWebsite.trim() : "",
      jobIndustrieName: contact.jobIndustrieName
        ? contact.jobIndustrieName.trim()
        : "",
    }));
    const trimmedContactDetails = excelData.map((contact) => ({
      ...contact,
      firstName: contact.firstName.trim(),
      lastName: contact.lastName ? contact.lastName.trim() : "",
      designation: contact.designation.trim(),
      emailId: contact.emailId.trim(),
      phoneNumber: contact.phoneNumber ? contact.phoneNumber.trim() : "",
    }));
    // const filteredContactDetails = trimmedContactDetails.filter(
    //   (contact) => contact.firstName !== "" || contact.designation !== ""
    // );

    const action = {
      companyName: company.trim(),
      addedBy: storeData._id,
      status: "On Process",
      addedOn: new Date(),
      client: client,
      jobDetails: trimmedJobDetails,
      contactDetails: trimmedContactDetails,
    };
    dispatch(addCompanyViaApi(action));
    dispatch(industriesNamesViaApi(action));
    dispatch(getAllIndustriesViaApi(action));
    handleClose();
    setLoading(true)
    setTimeout(() => {
      handleResetState();
      setCompaniesList([]);
      setCompany("");
    setLoading(false)
    }, 2000);
  }
  };

  const handleAddContact = () => {
    if (excelData.length >= 5) {
      enqueueSnackbar(`Not add contacts more than 5`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      return;
    }
    setExcelData([...excelData, excelDataFormat]);
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-add-new-job-post"
        aria-describedby="modal-add-new-job-post-description"
      >
        <Box
          sx={{
            // background:
            //   "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
            // width: "100vw",
            // height: "100vh",
            // display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 5,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SectionTitle title="Add new job post" />
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button color="error" onClick={handleCloseModal}>
                  Close
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveJobDetails}
                >
                  Next
                </Button>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Grid container spacing={3} sx={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <CustomFormField
                          title={"Company Name"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                required: true,
                                placeholder: "Company Name",
                                defaultValue: company,
                                onChange: (value) => handleCompanyQuery(value),
                                error: !!errors.company,
                                errorMessage: errors.company,
                              },
                            },
                          ]}
                        />
                      </Grid>

                      {jobDetails.map((contact, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={2}>
                            <CustomFormField
                              title={"Website"}
                              required
                              components={[
                                {
                                  type: "text",
                                  props: {
                                    required: true,
                                    placeholder: "Url",
                                    defaultValue: contact.jobWebsite,
                                    onChange: (value) =>
                                      handleJobChange(
                                        index,
                                        "jobWebsite",
                                        value
                                      ),
                                      error: !!errors[`jobWebsite_${index}`],
                                    errorMessage:errors[`jobWebsite_${index}`] || "" ,
                                  },
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <CustomFormField
                              title={"Job Description"}
                              required
                              components={[
                                {
                                  type: "text",
                                  props: {
                                    required: true,
                                    placeholder: "Url",
                                    defaultValue: contact.jobDescription,
                                    onChange: (value) =>
                                      handleJobChange(
                                        index,
                                        "jobDescription",
                                        value
                                      ),
                                      error: !!errors[`jobDescription_${index}`],
                                      errorMessage: errors[`jobDescription_${index}`] || "",
                                  },
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <CustomFormField
                              title={"Job Title"}
                              required
                              components={[
                                {
                                  type: "text",
                                  props: {
                                    required: true,
                                    placeholder: "Application Engineer",
                                    defaultValue: contact.jobTitle,
                                    onChange: (value) =>
                                      handleJobChange(index, "jobTitle", value),
                                    error: !!errors[`jobTitle_${index}`],
                                    errorMessage: errors[`jobTitle_${index}`] || "",
                      
                                  },
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <CustomFormField
                              title={"Job Location"}
                              required
                              components={[
                                {
                                  type: "text",
                                  props: {
                                    required: true,
                                    placeholder: "Location, State",
                                    defaultValue: contact.jobLocation,
                                    onChange: (value) =>
                                      handleJobChange(
                                        index,
                                        "jobLocation",
                                        value
                                      ),
                                      error: !!errors[`jobLocation_${index}`],
                                      errorMessage: errors[`jobLocation_${index}`] || "",
                        
                                  },
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <CustomFormField
                              title={"Select Industry"}
                              components={[
                                {
                                  type: "autocomplete",
                                  props: {
                                    placeholder: "Select Industry",
                                    options: industryNames,
                                    defaultValue: contact.jobIndustrieName,
                                    onChange: (value) =>
                                      handleJobChange(
                                        index,
                                        "jobIndustrieName",
                                        value
                                      ),
                                  },
                                },
                              ]}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            pt: 2,
                            pb: 0.5,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Job Poster Details:</Typography>
                          <Button onClick={handleAddContact}>
                            Add more Contacts
                          </Button>
                        </Box>
                        {excelData.map((row, rowIndex) => (
                          <Box key={rowIndex} sx={{ mb: 1.5 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={2.2}>
                                <CustomFormField
                                  title={"First Name"}
                                  required
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                        required: true,
                                        placeholder: "First Name",
                                        defaultValue: row.firstName,
                                        onChange: (value) =>
                                          handleContactChange(
                                            rowIndex,
                                            "firstName",
                                            value
                                          ),
                                          error: !!errors[`firstNameRow_${rowIndex}`],
                                          errorMessage: errors[`firstNameRow_${rowIndex}`] || "",
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={2.2}>
                                <CustomFormField
                                  title={"Last Name"}
                                  required
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                        required: true,
                                        placeholder: "Last Name",
                                        defaultValue: row.lastName,
                                        onChange: (value) =>
                                          handleContactChange(
                                            rowIndex,
                                            "lastName",
                                            value
                                          ),
                                          error: !!errors[`lastNameRow_${rowIndex}`],
                                          errorMessage: errors[`lastNameRow_${rowIndex}`] || "",
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={2.2}>
                                <CustomFormField
                                  title={"Designation"}
                                  required
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                        required: true,
                                        placeholder: "Designation",
                                        defaultValue: row.designation,
                                        onChange: (value) =>
                                          handleContactChange(
                                            rowIndex,
                                            "designation",
                                            value
                                          ),
                                          error: !!errors[`designationRow_${rowIndex}`],
                                          errorMessage: errors[`designationRow_${rowIndex}`] || "",
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={2.2}>
                                <CustomFormField
                                  title={"Email Id"}
                                  required
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                        required: true,
                                        placeholder: "Email Id",
                                        defaultValue: row.emailId,
                                        onChange: (value) => {
                                          handleContactChange(
                                            rowIndex,
                                            "emailId",
                                            value
                                          );
                                          // // Validate the email after each change
                                          // validateEmail(value.trim(), rowIndex);
                                        },
                                         error: !!errors[`emailRow_${rowIndex}`],
                                         errorMessage: errors[`emailRow_${rowIndex}`] || ""
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={2.8}>
                                <CustomFormField
                                  title={"Phone"}
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                       
                                        placeholder: "Phone",
                                        defaultValue: row.phoneNumber,
                                        onChange: (value) =>
                                          handleContactChange(
                                            rowIndex,
                                            "phoneNumber",
                                            value
                                          ),
                                       
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              {excelData.length > 1 && (
                                <Grid
                                  item
                                  xs={0.4}
                                  sx={{
                                    alignSelf: "flex-end",
                                    justifySelf: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleDeleteDirectContact(rowIndex)
                                    }
                                  >
                                    <CloseIcon fontSize="12" />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {companiesList.length > 0 && (
                    <Box>
                      <Box>
                        <Typography>List</Typography>
                      </Box>

                      {/* existing company names display  */}
                      <Box>
                        <Grid container spacing={2}>
                          {companiesList.map((companyData, index) => (
                            <Grid item xs={4} key={index}>
                              <MainBackground>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography noWrap variant="body1">
                                      {companyData.companyName}
                                    </Typography>
                                    <IconButton
                                      size="xsmall"
                                      onClick={() =>
                                        handleFillCompanyData(
                                          companyData,
                                          index
                                        )
                                      }
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                  <Divider sx={{ pt: 0.5 }} />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 0.5,
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      {companyData.jobDetails
                                        ? companyData.jobDetails[0]?.jobWebsite
                                        : ""}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      {companyData.jobDetails
                                        ? companyData.jobDetails[0]
                                            ?.jobIndustrieName
                                        : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </MainBackground>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deletedContactModal}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeletedContactModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleDeleteContact()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddNewJobPost;
