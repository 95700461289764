import React, { useRef } from 'react'
import CustomFormField from './Common/CustomFormField'
import { Box } from '@mui/material'

const JobPostingSelectActionType = 
({selectAction,addNote,setAddNote,
    noteAction,setNoteAction
}) => {
  const quillRef = useRef(null); // Initialize quillRef
  return (
    <Box>
    {selectAction === "Add Notes" && (
      <>
        {/* <CustomFormField
                  title={"Action"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Notes Priority",
                        defaultValue: noteAction,
                        options: ["Note from FG", "Note from iLabor", "Note from IQN"],
                        onChange:(value)=>setNoteAction(value)
                      },
                    },
                  ]}
                /> */}
               
        <CustomFormField
                  title={"Notes"}
                  components={[
                    {
                      type: "textEditor",
                      props: {
                        placeholder: "Add Notes",
                        defaultValue:addNote,
                        quillRef:quillRef,
                        onChange:(value)=>setAddNote(value),
                      },
                    },
                  ]}
                />
      </>
        
      )}
     
      </Box>
  )
}

export default JobPostingSelectActionType