import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../store/auth/login/actions";
import { deleteSearch } from "../../store/search/action";
import {deleteComapniesList} from "../../store/companiesList/action"
import {deleteReports} from "../../store/reports/action"
import login from "../../store/auth/login/reducer";
import { deleteJobBoards, deleteLayout, deleteRecruiter, initialStateResumeData } from "../../store/actions";
const Logout = (props) => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const [redirectCount, setRedirectCount] = useState(0);
  const navigate = useNavigate(); // Import useNavigate from react-router-dom

  const action={
    id:storeData._id,
    login:"Inactive"
  }
  useEffect(() => {
    dispatch(logoutUser(action));
    dispatch(deleteSearch());
    dispatch(deleteComapniesList());
    dispatch(deleteReports());
    dispatch(deleteRecruiter());
    dispatch(deleteLayout());
    dispatch(initialStateResumeData())
    dispatch(deleteJobBoards())
  }, [dispatch]);

  useEffect(() => {
    if (storeData === undefined || Object.keys(storeData).length === 0) {
      if (redirectCount < 3) {
        setRedirectCount((prevCount) => prevCount + 1);
      } else {
        // Redirect using useNavigate
        navigate("/", { replace: true });
      }
    }
  }, [storeData, redirectCount, navigate]);

  return null; // Return null or any other placeholder if needed
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
