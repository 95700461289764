import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomFormField, MainBackground, SectionTitle } from "..";

import theme from "../../Theme";
import CareerBuilder from "../JobBoardsResult/CareerBuilder";
import { addJobBoardsHistoryViaApi, advancedResumeSearchViaApi, clearSearchResults, deleteJobBoardsHistoryViaApi, getJobBoardsHistoryViaApi, getjobBoardsStatusHistoryViaApi, saveJobBoardsHistoryViaApi, stopLoadingRedux } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import {countries,workAuthorizationOptions,CBRadius,CareerBuilderRadius, countryMonsterOptions} from "../../Data/JobPostingData"
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import moment from "moment-timezone";
import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";
const data = {
  creditData: {
    remainingCredit: 99,
    currentMonthViews: 1,
    dailyLimit: 299,
    monthlyLimit: 138,
  },
};

const ChipSet = ({
  title,
  value,
  color = "default", // default,error,info,primary,secondary,success,warning
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <Typography fontSize={13}>{title} :</Typography>
      <Chip label={value} size="small" color={color} />
    </Box>
  );
};

const JobBoardCareerBuilder = () => {
  let navigate = useNavigate();
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const JobBoardHistory = useSelector((state) => state.JobBoards.jobBoardsHistory);
const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "CareerBuilder"&&job.status==="recent")
  : [];
  const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "CareerBuilder" && job.status === "saved")
  : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  
 
  const [keyWords, setKeyWords] = useState('');
  const dispatch =useDispatch();
  const [location, setLocation] = useState('');
  const [locationOptionsCb,setLocationOtionsCb]=useState([])
  const [JobTitle, setjobTitle] = useState('');
  const [excludeTerms, setExcludeTerms] = useState('');
  const [Names,setName]=useState('');
  const [errorKeyword,setErrorKeyword]=useState('')
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [employer,setEmployer]=useState('')
  const [radius,setRadius]=useState(50)
  const [selectCityZip,setSelectCityZip]=useState('')
  const handleSearchCarrerbuilder = () => {
    if(!keyWords){
      return setErrorKeyword('please type any keyword')
    }
    setLoading(true);
    dispatch(clearSearchResults());
    const action = {
      client: client,
      Keywords: keyWords,
      State: state,
      City: city,
      ZipCode:zipCode,
      Names:Names,
      JobTitle:JobTitle,
      ExcludeTerms:excludeTerms,
      Radius:radius,
      Employer:employer,
      PageNumber:page,
      RowsPerPage:pageSize,
      // SearchWithMostRecentJobTitle:true
    };
    dispatch(advancedResumeSearchViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "CareerBuilder",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      navigate("/jobBoardsResult", { state: { ...action,keyWords:keyWords, searchType: "CareerBuilder" } });
    }, 3000);
  };
  
 function parseLocationInput(input) {
  // Split input by commas and trim each part
  const parts = input.split(',').map(part => part.trim());
  if (parts.length === 1) {
    return {
      inputcity: '',           // No city provided
      inputstate: parts[0],     // State from single-part input
      inputzipCode: '',         // No ZIP code provided
    };
  }
  // Assign values based on available parts
  const inputcity = parts[0] || '';          // First part is city
  const inputstate = parts[1] || '';          // Second part is state (if available)
  const inputzipCode = parts[2] || '';        // Third part is ZIP code (if available)

  return {
    inputcity,
    inputstate,
    inputzipCode,
  };
}

let debounceTimeout;

const handleTypeLocations = async (value) => {
  setLocation(value);
  setSelectCityZip('')
  if(!value){
    setLocationOtionsCb([])
  }
  if (value.length < 2) {
    return; // Do nothing if less than 2 characters
  }


  // Clear the previous debounce timeout
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(async () => {
    try {
      const response = await fetch(`${apiUrl}/countries/getLocations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client: 'tektree', // Adjust if needed
          searchLocation: value,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setLocationOtionsCb(data); // Call the callback with the fetched options

    } catch (error) {
      console.error("Error fetching countries:", error.message);
    }
  }, 300); // Delay in ms, adjust as needed
};
const handleSelectCityZip=(value)=>{
  setSelectCityZip(value)
  setLocation(value)
  const parsedLocation = parseLocationInput(value);
  // Log the parsed results
  setCity(parsedLocation.inputcity);
  setState(parsedLocation.inputstate);
  setZipCode(parsedLocation.inputzipCode);
}

useEffect(() => {
  if (loadingRedux) {
    dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
    setLoading(false);

    // Set a timer to stop loading after 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }
}, [loadingRedux, dispatch]);
const [openTabId, setOpenTabId] = useState("recentHistory");
  const handleChange = (event, newValue) => {
    // reset all fields
    [setCity,setEmployer,setExcludeTerms,setjobTitle,setKeyWords,setName,
      setPage,setRadius,setState,setZipCode,setPageSize,handleSelectCityZip].forEach(setter => setter(''))
    setOpenTabId(newValue);
    if(newValue==="recentHistory"){
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'CareerBuilder',
        status:'recent'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }else {
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'CareerBuilder',
        status:'saved'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }
  };
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MM/DD/YY HH:mm:ss`);
  };
const handleClose = () => '';
const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
const handleClickHistory = (value) => {
  const action = value.query;
  action.client=client;
  setLoading(true);
  dispatch(advancedResumeSearchViaApi(action));
  const historyAction = {
    id: value._id,
    query: action,
    addedBy: storeData._id,
    addedOn: new Date(),
    client: client,
    status: value.status,
    searchType: "CareerBuilder",
  };
  dispatch(addJobBoardsHistoryViaApi(historyAction));
  setTimeout(() => {
    dispatch(getJobBoardsHistoryViaApi(historyAction));
    navigate("/jobBoardsResult", { state: { ...action,keyWords:action.Keywords, searchType: "CareerBuilder" } });
  }, 3000);
};
const handleClickEditHistory = (searchData) => {
  const QueryData = searchData?.query;
  const updateStates = { City: setCity, Employer: setEmployer, ExcludeTerms: setExcludeTerms, JobTitle: setjobTitle, 
    Keywords: setKeyWords, Names: setName, PageNumber: setPage, Radius: setRadius, State: setState, ZipCode: setZipCode, RowsPerPage: setPageSize };
  Object.entries(updateStates).forEach(([key, setter]) => { setter(QueryData[key]) });
  const {City, State, ZipCode} = QueryData;
  let address = `${City ? City : ''}${City && State ? ', ' : ''}${State ? State : ''}${(City || State) && ZipCode ? ', ' : ''}${ZipCode ? ZipCode : ''}`;
  handleSelectCityZip(address);
};
const handleClickSaveHistory=(value)=>{
  setHistoryTitle("");
  setErrTitle("");
  setSelectHistory("");
  if (value) {
    setAddNoteModal(!addNoteModal);
    setSelectHistory(value);
  }

}
const handleClickDeleteHistory=(value)=>{
  if (value) {
    setSelectHistory(value);
    setDeleteHistoryModal(!deleteHistoryModal);
  }
}
const handleConfirmHistorySave = () => {
  setErrTitle("");
  if (!selectHistory && !historyTitle) {
    setErrTitle("please type title");
    return "";
  }
  const action = {
    id: selectHistory._id,
    client: client,
    title: historyTitle,
  };
  dispatch(saveJobBoardsHistoryViaApi(action));
  setLoading(true);
  setAddNoteModal(!addNoteModal);
  setTimeout(() => {
    const action = {
      addedBy: storeData._id,
      searchType: 'CareerBuilder',
      status: "recent",
      client: client,
    };
    dispatch(getjobBoardsStatusHistoryViaApi(action));
  }, 2000);
};
const handleConfirmHistoryDelete = () => {
  const action = {
    id: selectHistory._id,
    client: client,
  };
  dispatch(deleteJobBoardsHistoryViaApi(action));
  setLoading(true);
  setDeleteHistoryModal(!deleteHistoryModal);
  setTimeout(() => {
    const fetchAction = {
      client: client,
      addedBy: storeData._id,
      searchType: 'CareerBuilder',
      status: "saved",
    };
    dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
  }, 2000);
};
  return (
    <Box>
      <MainBackground p={0}>
      <Grid container>
      <Grid item xs={8}>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <CustomFormField
                title={"Job Title or Skills"}
                // title={"Job Title or Keywords"}
                required
                // components={[
                //   {
                //     type: "text",
                //   },
                // ]}
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,    
                    defaultValue: keyWords,
                      placeholder: "",
                      onChange: (value) => setKeyWords (value),
                      error:!!errorKeyword,
                      errorMessage:errorKeyword 
                    },
                  },
                ]}
               
               
              />
            </Grid>
            <Grid item xs={8}>
              <CustomFormField
                title={"City,State or Zip"}
                required
                components={[
                  {
                    type: "selectLabelMultipleFrom",
                    props:{
                      onChangeForm: (value) => handleTypeLocations( value), 
                      options: locationOptionsCb ,
                      searchTerm:location,
                      selectCityZip:selectCityZip,
                      onChange: (value)=>handleSelectCityZip(value)
                    }
                  },
                ]}
                
              />
            </Grid>
            <Grid item xs={4} sx={{ alignSelf: "flex-end" }}>
              <Button fullWidth variant="contained" size="large" onClick={handleSearchCarrerbuilder}>
                Search Candidate
              </Button>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <ChipSet
                title="Remaining Credit"
                value={data.creditData.remainingCredit}
                color="success"
              />
              <Divider orientation="vertical" flexItem />
              <ChipSet
                title="Current Month Views"
                value={data.creditData.currentMonthViews}
                color="warning"
              />
              <Divider orientation="vertical" flexItem />
              <ChipSet
                title="Daily Limit"
                value={data.creditData.dailyLimit}
                color="info"
              />
              <Divider orientation="vertical" flexItem />
              <ChipSet
                title="Monthly Limit"
                value={data.creditData.monthlyLimit}
                color="error"
              />
            </Box>
            <Box>
              <Button onClick={() => setShowAdvanceSearch(!showAdvanceSearch)}>
                {showAdvanceSearch ? "Hide" : "Show"} Advance Search
              </Button>
            </Box>
          </Box>
          {showAdvanceSearch && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Search by Name"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "Search by Name",
                          defaultValue: Names,
                          onChange: (value) => setName (value),
                        },
                      },
                    ]}
                   
                  />
                </Grid>
                <Grid item xs={2.4}>
                 
                  <CustomFormField
                    title={"Exclude Skills"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "Exclude Skills...",
                          defaultValue: excludeTerms,
                       
                          onChange: (value) => setExcludeTerms (value),
                        },
                      },
                    ]}
                  
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Search by Job title"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "Search by Job title...",
                          defaultValue: JobTitle,
                          onChange: (value) => setjobTitle (value),
                          onEnter:handleSearchCarrerbuilder,
                        },
                      },
                    ]}
                    multiline={true}
                    
                  />
                 
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Search by Employer"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "Search by Employer...",
                          defaultValue:employer,
                          onChange:(value)=>setEmployer(value)
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Select ( Miles to Location)"}
                    components={[
                      {
                        type: "selectLabel",
                        props: {
                          title: "Select",
                          options: CareerBuilderRadius,
                          value:radius,
                          onChange:(value)=>setRadius(value)
                        },
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              bgcolor: theme.palette.grey[50],
              px: 3,
              py: 1,
              height: "100%",
            }}
          >
            <TabContext value={openTabId}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="ob Boards tabs">
                  <Tab label="Recent History" value="recentHistory" />
                  <Tab label="Saved History" value="savedHistory" />
                </TabList>
              </Box>

              <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                {JobBoardsHistoryRecent.map((search) => (
                  <SearchHistorySection key={search._id} 
                  data={search.query.Keywords} 
                  onClick={() => handleClickHistory(search)} 
                  addedOn={search.addedOn} 
                  onClickSave={()=>handleClickSaveHistory(search)}
                  status="recent"
                  onClickEdit={()=> handleClickEditHistory(search)}  
                   />
                ))}
              </TabPanel>

              <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
               
                {JobBoardsHistorySaved.map((search) => (
                  <SearchHistorySection key={search._id} 
                  data={search.query.Keywords} 
                  onClick={() => handleClickHistory(search)} 
                  addedOn={search.addedOn}
                  onClickDelete={()=>handleClickDeleteHistory(search)}
                  status="saved"   
                  title={search.title}
                  onClickEdit={()=> handleClickEditHistory(search)}
                   />
                ))}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        </Grid>
      </MainBackground>
     
      <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
              {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
            </Typography>
            {/* <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography> */}
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={loading}
  onClick={handleClose}
>
  <CircularProgress color="inherit" size="3rem" />
</Backdrop>
    </Box>
  );
};

export default JobBoardCareerBuilder;
