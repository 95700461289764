import { Box, Button, Grid } from "@mui/material";
import React from "react";
import CustomFormField from "../Common/CustomFormField";

const JobBoardMonsterClassicSearch = ({
  keyWords,setKeyWords,errorKeyword,optionsMonsterPostFrom,
  fromDate,setFromDate,selectWithRadius,handleSelctOptionRadionsORStates,
  country,countryMonsterOptions,handleSelectCountry,radius,MonsterRadiusOptions,setRadius,handleCallCountriesWithApi,
  searchTerm,selectCityZip,handleSelectCityZip,selectedStates,statesArrayOptionMonster,handleStateChange,optionsCityZip,
  optionsMonsterTo,toDate,setToDate,twlid,setTwlid,wa,setWa,AddIcon,toggleOptions,height,contentRef,cn,setcn,jobTitle,
  setJobTitle,jobDescription,setJobDescription,schoolAttend,setSchoolAttend,tnsalmin,setTnsalmin,tnsalmax,setTnsalmax,
  tsaltyp,salaryTypeMonsterOptions,setTsaltyp,tsni,setTsni,highEducation,MonsterEducationLevel,setHighEducation,clv,
  careerLevelMonster,setClv,Lskill,LanguageskillsMonster,setLskill,LProf,setLProf,ProfessionalLevelMonsterOptions,tjtft,
  setTjtft,tjtpt,setTjtpt,tjtpd,setTjtpd,tjtp,setTjtp,tjti,setTjti,tjttc,setTjttc,tjts,setTjts,twindus,indusriesMonsterOptions,setTwindus,
  handleSearchMonster, handleReset
  })=> {
  return (
    <Box>
      <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
        <Grid item xs={7}>
          <CustomFormField
            title={"Keyword"}
            required
            components={[
              {
                type: "text",
                props: {
                  required: true,
                  defaultValue: keyWords,
                  onChange: (value) => setKeyWords(value),
                  error: !!errorKeyword,
                  errorMessage: errorKeyword,
                },
              },
            ]}
          />
          <Box mt={2} />
          <CustomFormField
            title={"Resume Posted"}
            //colunm={true}
            components={[
              // {
              //   type: "rtText",
              //   props: {
              //     // text: "from",
              //   },
              // },
              {
                type: "selectLabel",
                props: {
                  title: "From",
                  options: optionsMonsterPostFrom,
                  value: fromDate,
                  onChange: (value) => setFromDate(value),
                },
              },
              {
                type: "rtText",
                props: {
                  text: "-",
                },
              },
              {
                type: "selectLabel",
                props: {
                  title: "To",
                  options: optionsMonsterTo,
                  value: toDate,
                  onChange: (value) => setToDate(value),
                },
              },
            ]}
          />
        </Grid>
        <Grid item xs={5}>
          <CustomFormField
            title={"Location (Choose 1):"}
            colunm={true}
            components={[
              {
                type: "radio",
                props: {
                  title: "Search ZIP Code Radius",
                  defaultValue: selectWithRadius,
                  options: [
                    "Search ZIP Code Radius",
                    "Search within Selected Location",
                  ],
                  onChange: (value) => handleSelctOptionRadionsORStates(value),
                },
              },
              {
                type: "selectLabel",

                props: {
                  title: "Select Country",
                  value: country,
                  options: countryMonsterOptions,
                  onChange: (value) => handleSelectCountry(value),
                },
              },
            ]}
          />
          <Box>
            {selectWithRadius === "Search ZIP Code Radius" && (
              <Box id="radius">
                <CustomFormField
                  //title={"Location (Choose 1):"}
                  //colunm={true}
                  components={[
                    {
                      type: "selectLabel",
                      props: {
                        title: "Show jobseekers with in",
                        value: radius,
                        options: MonsterRadiusOptions,
                        onChange: (value) => setRadius(value),
                      },
                    },

                    {
                      type: "selectLabelMultipleFrom",
                      props: {
                        title: "of Zipcode/City",
                        defaultValue: "",
                        options: optionsCityZip,
                        onChangeForm: (value) =>
                          handleCallCountriesWithApi(value),
                        searchTerm: searchTerm,
                        selectCityZip: selectCityZip,
                        onChange: (value) => handleSelectCityZip(value),
                      },
                    },
                  ]}
                />
              </Box>
            )}

            {selectWithRadius === "Search within Selected Location" && (
              <Box id="selected-location">
                <CustomFormField
                  colunm={true}
                  components={[
                    {
                      type: "selectLabelMultiple",
                      props: {
                        title: "Select State",
                        value: selectedStates, // Should be a flat array
                        multiple: true,
                        options: statesArrayOptionMonster, // Your array of states
                        onChange: handleStateChange, // Use the handler here
                      },
                    },
                  ]}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CustomFormField
            colunm={true}
            components={[
              {
                type: "checkbox",
                props: {
                  title: "Search within Radius",
                  size: "small",
                  defaultValue: twlid
                    ? "Include Job Seekers Employable in Any of the Selected Locations."
                    : "",
                  options: [
                    "Include Job Seekers Employable in Any of the Selected Locations.",
                  ],
                  onClick: (value) => setTwlid(value),
                },
              },
              {
                type: "checkbox",
                props: {
                  title: "Search within Radius",
                  size: "small",
                  defaultValue: wa
                    ? "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations."
                    : "",
                  options: [
                    "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.",
                  ],
                  onClick: (value) => setWa(value),
                },
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            startIcon={<AddIcon />}
            size="small"
            onClick={toggleOptions}
          >
            Advanced Options Monster
          </Button>

          <Box
            sx={{
              height,
              overflow: "hidden",
              transition: "height 0.3s ease-in-out",

              mt: 2,
            }}
            ref={contentRef}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomFormField
                  title={"Resume Details"}
                  colunm={true}
                  components={[
                    {
                      type: "rtText",
                      props: {
                        text: "Enter Keywords to Search Areas of Job Seekers Resume",
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Most Recent Employer"}
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: "Most Recent Employer",
                        defaultValue: cn,
                        onChange: (value) => setcn(value),
                      },
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomFormField
                  title={"Most Recent Job Title"}
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: "Most Recent Job Title",
                        defaultValue: jobTitle,
                        onChange: (value) => setJobTitle(value),
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Most Recent Description"}
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: "Most Recent Description",
                        defaultValue: jobDescription,
                        onChange: (value) => setJobDescription(value),
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"School Attended"}
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: "School Attended",
                        defaultValue: schoolAttend,
                        onChange: (value) => setSchoolAttend(value),
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Desired Salary"}
                  components={[
                    {
                      type: "rtText",
                      props: {
                        text: "Min",
                      },
                    },
                    {
                      type: "text",
                      props: {
                        placeholder: "Min",
                        defaultValue: tnsalmin,
                        onChange: (value) => setTnsalmin(value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Max",
                      },
                    },
                    {
                      type: "text",
                      props: {
                        placeholder: "Max",
                        defaultValue: tnsalmax,
                        onChange: (value) => setTnsalmax(value),
                      },
                    },
                    {
                      type: "selectLabel",
                      props: {
                        title: "From",
                        value: tsaltyp,
                        options: salaryTypeMonsterOptions,
                        onChange: (value) => setTsaltyp(value),
                      },
                    },
                  ]}
                />
                <CustomFormField
                  //title={"Show Resumes without a Desire Salery"}
                  components={[
                    {
                      type: "checkbox",
                      props: {
                        title: "Show Resumes without a Desired Salary",
                        options: ["Show Resumes without a Desired Salary"],
                        size: "small",
                        defaultValue: tsni
                          ? "Show Resumes without a Desired Salary"
                          : "",
                        onClick: (value) => setTsni(value),
                      },
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomFormField
                  title={"Job Seeker Profile"}
                  colunm={true}
                  components={[
                    {
                      type: "selectLabel",
                      props: {
                        title: "Job Seeker Profile",
                        value: highEducation,
                        options: MonsterEducationLevel,
                        onChange: (value) => setHighEducation(value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Show Jobseekers with Selected Education Level or Higher",
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"More Profile Options"}
                  colunm={true}
                  components={[
                    {
                      type: "selectLabelMultiple",
                      multiple: true,
                      props: {
                        title: "Career Level",

                        value: clv ? clv : [],
                        multiple: true,
                        options: careerLevelMonster,
                        onChange: (value) => setClv(value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Career Level",
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Only show Job seekers with the selected career level",
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Language Proficiency"}
                  components={[
                    {
                      type: "selectLabel",
                      multiple: true,
                      props: {
                        title: "Select a Language",
                        value: Lskill,
                        options: LanguageskillsMonster,
                        onChange: (value) => setLskill(value),
                      },
                    },
                    {
                      type: "selectLabel",
                      multiple: true,
                      props: {
                        title: "Beginner",
                        value: LProf,
                        onChange: (value) => setLProf(value),
                        options: ProfessionalLevelMonsterOptions,
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Job Status"}
                  colunm={true}
                  components={[
                    {
                      type: "checkbox",
                      multiple: true,
                      props: {
                        title: "Job Seeker Profile",
                        options: ["Full-Time"],
                        defaultValue: tjtft ? "Full-Time" : "",
                        onClick: (value) => setTjtft(value),
                      },
                    },
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Seeker Profile",
                        options: ["Part-Time"],
                        faultValue: tjtpt ? "Part-Time" : "",
                        onClick: (value) => setTjtpt(value),
                      },
                    },
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Seeker Profile",
                        options: ["Contract"],
                        defaultValue: tjtpd ? "Contract" : "",
                        onClick: (value) => setTjtpd(value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Only show Job seekers interested in the selected job status",
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Job Type"}
                  colunm={true}
                  components={[
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Type",
                        options: ["Employee"],
                        defaultValue: tjtp ? "Employee" : "",
                        onClick: (value) => setTjtp(value),
                      },
                    },
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Type",
                        options: ["Intern"],
                        defaultValue: tjti ? "Intern" : "",
                        onClick: (value) => setTjti(value),
                      },
                    },
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Type",
                        options: ["Temporary/Contract/Permanent or Fulltime"],
                        defaultValue: tjttc
                          ? "Temporary/Contract/Permanent or Fulltime"
                          : "",
                        onClick: (value) => setTjttc(value),
                      },
                    },
                    {
                      type: "checkbox",
                      props: {
                        title: "Job Type",
                        options: ["Seasonal"],
                        defaultValue: tjts ? "Seasonal" : "",
                        onClick: (value) => setTjts(value),
                      },
                    },
                    {
                      type: "rtText",
                      props: {
                        text: "Only show Job seekers interested in the selected job type",
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomFormField
                  title={"Industries"}
                  colunm={true}
                  components={[
                    {
                      type: "selectLabelMultiple",
                      multiple: true,
                      props: {
                        title: "Select a Language",
                        value: twindus,
                        options: indusriesMonsterOptions,
                        onChange: (value) => setTwindus(value),
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={15} alignContent={"center"}>
          <Box display={"flex"} justifyContent={"center"} gap={2}>
            <Button variant="contained" onClick={() => handleSearchMonster()}>
              Search
            </Button>
            <Button variant="contained" onClick={handleReset}>Reset</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobBoardMonsterClassicSearch;
