import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import React from 'react'

 const JobBoardMigrationModal = ({open,close,totalResult,yes}) => {
  return (
<Modal
        open={open}
        aria-labelledby="modal-migrate -title"
        aria-describedby="modal-migrate-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <Typography
              variant="h6"
              lineHeight={1.4}
              fontWeight={200}
              textAlign={"center"}
            >
              The system will migrate the {totalResult.length} new profiles automatically. Please
              click YES to proceed.
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={close} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={yes}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

  )
}
export default JobBoardMigrationModal; 