
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Backdrop,
  CircularProgress,
  IconButton,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import {
  CustomFormField,
  KeyData,
  MainBackground,
  SectionTitle,
  InternalDBSearch,
  CareerBuilderSearch,
  MonsterSearch,
} from "../../Components";
import theme from "../../Theme";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { addJobBoardsHistoryViaApi, clearSearchResults, deleteJobBoardsHistoryViaApi, getIntegrationsViaApi, getJobBoardsHistoryViaApi, getjobBoardsStatusHistoryViaApi, integrateCBAdvancedResumesSearchViaApi, integrateInternalDBSearchViaApi, integrateMonsterAdvancedResumesSearchViaApi, integrateSearchInternalDbViaApi, internalDbSearchViaApi, monsterAdvancedResumeSearchViaApi, saveJobBoardsHistoryViaApi, searchResumeInternalDbViaApi, stopLoadingRedux } from "../../store/actions";
import {
  countries,countryOptionsIntegrationSearch,
  workAuthorizationOptions,radiusOptionsMonsterIntegrateSearch,
  lastUpdatedOptionsIntegrateSearch,optionsMonsterTo,countryMonsterOptions,
  statesArrayOptionMonster,MonsterEducationLevel,salaryTypeMonsterOptions,
  careerLevelMonster,resumeModifiedOptionsCB,resumeModifiedToOptionsCB,
  yearsOfExperienceOptionsCB,workStatusOptionsCB,
  workAuthorizationIntegrationSearch} from "../../Data/JobPostingData"
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment-timezone";
import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";

const IntegratedSearch = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const JobBoardHistory = useSelector((state) => state.JobBoards.jobBoardsHistory);
const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "IntegrateSearch"&&job.status==="recent")
  : [];
  const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "IntegrateSearch" && job.status === "saved")
  : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const degrees = useSelector((state) => state.Recruiters?.degrees);

  const integrationsList = useSelector((state) => state.Resumes.integrations);
  let navigate = useNavigate();
  const degreesNames = Array.isArray(degrees)
    ? degrees.map((item) => item.degreeName)
    : [];
    
  
    const [loading, setLoading] = useState(false);
    const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const client = storeData.clientDb;
  const dispatch=useDispatch();
  const [keyWords, setKeyWords] = useState('');
  const [country,setCountry]=useState(840);
  const [radius,setRadius]=useState(50);
  const [lastupdated,setLastupdated]=useState('')

  const [jobTitle,setJobTitle]=useState('');
  const [education,setEducation]=useState('');
  const [workAuthorization,setWorkAuthorization]=useState('')
  const [keyWordError,setKeyWordError]=useState('');
  const [openTabId, setOpenTabId] = useState("recentHistory");
  const [recentJobTitle,setRecentJobTitle]=useState('')
  const [location, setLocation] = useState('');
  const [locationOptionsCb,setLocationOtionsCb]=useState([])
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [selectCityZip,setSelectCityZip]=useState('')
  //monster
  const [monsterCountry,setMonsterCountry]=useState('')
  const [monsterStateOptions,setMonsterStateOptions]=useState([])
  const [monsterState,setMonsterState]=useState([])
  const [mdatemaxage,setMdatemaxage]=useState('')
  const [edulv,setEdulv]=useState('')
  const [qrcn,setQrcn]=useState('')
  const [qsn,setQsn]=useState('')
  const [tnsalmin,setTnsalmin]=useState('')
const [tnsalmax,setTnsalmax]=useState('')
const [tsaltyp,setTsaltyp]=useState('1')
const[tjtft,setTjtft]=useState('')
const[tjtpt,setTjtpt]=useState('')
const[tjtpd,setTjtpd]=useState('')

const [tjtp,setTjtp]=useState('')
const [tjti,setTjti]=useState('')
const [tjttc,setTjttc]=useState('')
const [tjts,setTjts]=useState('')
const[clv,setClv]=useState([])
const [wa,setWa]=useState('')
const [twlid,setTwlid]=useState('')

///// careerbuilder
const [ResumeModifiedFrom,setResumeModifiedFrom]=useState('')
const [ResumeModifiedTo,setResumeModifiedTo]=useState('')
const [yearofexpfrom,setYearofexpfrom]=useState('')
const [yearofexpto,setYearofexpto]=useState('')
const [WorkStatus,setWorkStatus]=useState([])
const [Names,setNames]=useState('')
const[ExcludeTerms,setExcludeTerms]=useState('')
const [Employer,setEmployer]=useState('')

///internal
const [internalDBCountry,setInternalDBCountry]=useState('')
const [internalDBStateOptions,setInternalDBStateOptions]=useState([])
const [internalDBState,setInternalDBState]=useState('')
const [minExperience,setMinExperience]=useState()
const [maxExperience,setMaxExperience]=useState()
const [internalDbName,setInternalDbName]=useState('')
const [relocate,setRelocate]=useState('')
const [clearance,setClearance]=useState('')
const [excludeBlockedList,setExcludeBlockedList]=useState('Exclude Blacklisted Applicants')
const [searchWithoutLocation,setSearchWithoutLocation]=useState('')
const [radiusSearch,setRadiusSearch]=useState('')

  const handleChange = (event, newValue) => {
    handleResetStates();
    setOpenTabId(newValue);
    if(newValue==="recentHistory"){
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'IntegrateSearch',
        status:'recent'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }else {
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'IntegrateSearch',
        status:'saved'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }
  };

  const [openResultTabId, setOpenResultTabId] = useState("1");
  const handleResultTabChange = (event, newValue) => {
    setOpenResultTabId(newValue);
  };

  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  ///career builder
  function getDateRange(lastupdated) {
    let fromDateaddedon = '';
    let toDateaddedOn = '';
    const today = new Date();
  
    if (lastupdated) {
      toDateaddedOn = today.toISOString().split('T')[0]; // Set to current date
  
      let fromDate = new Date(today);
      switch (lastupdated) {
        case 7:   // Last 7 days
          fromDate.setDate(today.getDate() - 6);
          break;
        case 30:  // Last 30 days
          fromDate.setDate(today.getDate() - 29);
          break;
        case 90:  // Last 90 days
          fromDate.setDate(today.getDate() - 89);
          break;
        case 999: // No date filter
        default:
          fromDateaddedon = '';
          toDateaddedOn = '';
          return { fromDateaddedon, toDateaddedOn };
      }
  
      fromDateaddedon = fromDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD
    }
  
    return { fromDateaddedon, toDateaddedOn };
  }
 
  function parseCityZip(select) {
    const parts = select ? select.split(',').map(part => part.trim()) : [];
   
    if (parts.length === 3) {
        const inputCity = parts[0] || '';       // Middle part is the city
        const inputZipCode = parts[2] || ''; 
        return { inputCity, inputZipCode };
    } else if (parts.length === 2) {
        const inputCity = parts[0] || '';       // First part is the city
        const inputZipCode = parts[1] || '';
        return { inputCity, inputZipCode };
    }
    
    // If the format is unexpected or parts length is not 2 or 3, return empty values
    return { inputCity: '', inputZipCode: '' };
}

  const buildInternalDBSearchAction=()=>{
    const { fromDateaddedon, toDateaddedOn } = getDateRange(lastupdated);
    return{
      keyWords:keyWords,
      client:client,
      jobTitle:jobTitle,
      education:education,
      workAuthorization:workAuthorization,
      state:state||internalDBState,
      city:city,
      zipCode:zipCode,
      blackList:excludeBlockedList,
      minExperience:minExperience,
      maxExperience:maxExperience,
      name:internalDbName,
      relocate:relocate,
      fromDateaddedon:fromDateaddedon,
      toDateaddedOn:toDateaddedOn,
      pageSize: pageSize,
      page: page,
    }
  }

  const handleSearchInternalDB=()=>{
    const action= buildInternalDBSearchAction()
    action.client=client
    dispatch(integrateInternalDBSearchViaApi(action))
  }
  const buildCareeerBuilderSearchAction=()=>{
    return{
      Keywords: keyWords,
      State: state,
      City: city,
      ZipCode:zipCode,
      Names:Names,
      JobTitle:jobTitle,
      WorkStatus:WorkStatus,
      Employer:Employer,
      ExcludeTerms:ExcludeTerms,
      CBMinimumExperience:yearofexpfrom,
      CBMaximumExperience:yearofexpto,
      Radius:radius,
      PageNumber:page,
      RowsPerPage:pageSize,
    }
  }
  const handleSearchCareerBuilder=()=>{
    const action =buildCareeerBuilderSearchAction();
    action.client=client
    dispatch(integrateCBAdvancedResumesSearchViaApi(action));
  }
  // Helper function to check user access
const getMonsterAccountId = () => {
  let monsterAccountId = '';

  if (storeData.userType.includes('SuperAdmin') || storeData.userType.includes('Developer')) {
    if (Array.isArray(integrationsList)) {
      integrationsList.some((accountApi) => (monsterAccountId = accountApi._id));
    }
  } else if (Array.isArray(integrationsList)) {
    const access = integrationsList.some((accountApi) =>
      Array.isArray(accountApi.assignedTo) &&
      accountApi.assignedTo.some((account) =>
        account.accountId === storeData._id && account.access && (monsterAccountId = accountApi._id)
      )
    );

    if (!access) {
      enqueueSnackbar(`You have no access to Monster search. Please contact the admin.`, {
        variant: 'error',
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      return '';
    }
  }
  return monsterAccountId;
};
const getMonsterAccountsId = () => {
  let monsterAccountId = '';

  if (storeData.userType.includes('SuperAdmin') || storeData.userType.includes('Developer')) {
    if (Array.isArray(integrationsList)) {
      integrationsList.some((accountApi) => (monsterAccountId = accountApi._id));
    }
  } else if (Array.isArray(integrationsList)) {
    integrationsList.some((accountApi) =>
      Array.isArray(accountApi.assignedTo) &&
      accountApi.assignedTo.some((account) =>
        account.accountId === storeData._id && account.access && (monsterAccountId = accountApi._id)
      )
    );
  }

  return monsterAccountId;
};


// Helper function to build the search action object
const buildMonsterSearchAction = (monsterAccountId) => {
  const takeanySelect=(selectCityZip?.trim() || location?.trim()||'')
  const { inputCity, inputZipCode } = parseCityZip(takeanySelect);
  const parts = takeanySelect ? takeanySelect.split(',').map(part => part.trim()) : [];
  let findstateCode=''
      
   findstateCode = statesArrayOptionMonster.find(
    (states) => states.label.toLocaleLowerCase() === state.toLocaleLowerCase()
  )?.value;
  if(parts.length===1){
    findstateCode = statesArrayOptionMonster.find(
      (states) => states.label.toLocaleLowerCase() === takeanySelect.toLocaleLowerCase()
    )?.value;
  }
  const checkEmptyCity = (city === '' && zipCode === '' && state !== '') ? findstateCode : '';
  return {
    sort: 'rank,distance,mdate',
    radius: radius || 30,
    query: keyWords,
    mdateminage: 0,
    mdatemaxage: mdatemaxage?mdatemaxage:lastupdated ? lastupdated === 999 ? 1576800 : lastupdated * 1440 : '43200',
    country: monsterState.length > 0 ?monsterCountry:inputZipCode ? '' :country || '',
    rlid: monsterState.length > 0 ? monsterState.join('+') : checkEmptyCity,
    zipcode: monsterState.length > 0 ?'':inputZipCode,
    rpcr: monsterState.length > 0 ? '' :inputZipCode ? `${inputZipCode}-${radius || 30}` : '',
    tsni: (tnsalmax || tnsalmin) ? 0 : 1,
    twlid: twlid ? 1 : '',
    wa: wa ? 164 - 1 : '',
    qrcn: qrcn,
    qrjt: jobTitle,
    qsn: qsn,
    tnsalmin: tnsalmin,
    tnsalmax: tnsalmax,
    tsaltyp: tsaltyp,
    tsalcur: (tnsalmin || tnsalmax) ? 1 : '',
    // clv: clv.length > 0 ? clv : '',
    clv: clv.length > 0 ? clv.join(",") : [],
    edulv: edulv,
    tjtft: tjtft ? 1 : '',
    tjtpt: tjtpt ? 1 : '',
    tjtpd: tjtpd ? 1 : '',
    tjtp: tjtp ? 1 : '',
    tjti: tjti ? 1 : '',
    tjttc: tjttc ? 1 : '',
    tjts: tjts ? 1 : '',
    page: page,
    pagesize: pageSize,
    client: client,
    monsterAccountId: monsterAccountId,
  };
};
  const handleSearchMonster=()=>{
    const monsterAccountId = getMonsterAccountId();
    if (monsterAccountId) {
      const action = buildMonsterSearchAction(monsterAccountId);
      dispatch(integrateMonsterAdvancedResumesSearchViaApi(action))
    }
  setTimeout(() => {
    // dispatch(getIntegrationsViaApi(action));
  }, 2000);
}
  const handleSearchAll=()=>{
    if(!keyWords){
      return setKeyWordError('please type any keyword')
    }
    setLoading(true)
    handleSearchInternalDB()
    handleSearchCareerBuilder()
    handleSearchMonster()
    const monsterAccountId = getMonsterAccountsId();
    const { fromDateaddedon, toDateaddedOn } = getDateRange(lastupdated);
    const takeanySelect=(selectCityZip?.trim() || location?.trim()||'')
    const historyAction = {
      query:{
        keyWords:keyWords,
        lastupdated:lastupdated,
        InternalDB:buildInternalDBSearchAction(),
        Monster:{...buildMonsterSearchAction(monsterAccountId),monsterState:monsterState,
          selectCityZip:takeanySelect,masterLastUpdate:mdatemaxage,
          monsterCountry:monsterCountry,allCountry:country,
        },
        CareerBuilder:buildCareeerBuilderSearchAction()
      },
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "IntegrateSearch",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      navigate("/jobBoardsIntegrateResult", { state: { InternalDB:buildInternalDBSearchAction(),
        Monster:buildMonsterSearchAction(monsterAccountId),
        CareerBuilder:buildCareeerBuilderSearchAction(),
        keyWords:keyWords
      }});
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 1500);
  }
  
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
  
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
  
      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  
  const handleClose = () => '';
 const handleChangeRecentJobTitle=()=>''
 const handleClickRecentJobTitle=(e)=>{
  setRecentJobTitle(e.target.checked)
 }
 const handleSelectCountryMonster=(value)=>{
  setMonsterCountry(value)
  if(value===840){
    setMonsterStateOptions(statesArrayOptionMonster)
  }else {
    setMonsterStateOptions([])
  }
 }
 const handleSelectCountryInternalDB=(value)=>{
  setInternalDBState('')
  setInternalDBCountry(value)
  if(value===840){
    setInternalDBStateOptions(statesArrayOptionMonster)
  }else {
    setInternalDBStateOptions([])
  }
 }
 // Function to identify input types
function parseLocationInput(input) {
  // Split input by commas and trim each part
  const parts = input.split(',').map(part => part.trim());
  if (parts.length === 1) {
    return {
      inputcity: '',           
      inputstate: parts[0],    
      inputzipCode: '',         
    };
  }
  const inputcity = parts[0] || '';          
  const inputstate = parts[1] || '';          
  const inputzipCode = parts[2] || '';        
  return {
    inputcity,
    inputstate,
    inputzipCode,
  };
}

let debounceTimeout;

const handleTypeLocations = async (value) => {
  setLocation(value);
  setSelectCityZip('')
   if(!value){
    setLocationOtionsCb([])
   }
  if (value.length < 2) {
    return;
  }

  // Clear the previous debounce timeout
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(async () => {
    try {
      const response = await fetch(`${apiUrl}/countries/getLocations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client: 'tektree', // Adjust if needed
          searchLocation: value,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setLocationOtionsCb(data); // Call the callback with the fetched options

    } catch (error) {
      console.error("Error fetching countries:", error.message);
    }
  }, 300); // Delay in ms, adjust as needed
};
const handleSelectCityZip=(value)=>{
  setSelectCityZip(value)
  setLocation(value)
  const parsedLocation = parseLocationInput(value);

  // Log the parsed results
  setCity(parsedLocation.inputcity);
  setState(parsedLocation.inputstate);
  setZipCode(parsedLocation.inputzipCode);
}

const handleClickHistory = (value) => {
  const monsterAccountId = getMonsterAccountId();
  const action = value.query;
  action.client=client;
  setLoading(true);
  action.InternalDB.client = client;
    dispatch(integrateInternalDBSearchViaApi(action.InternalDB));
    action.CareerBuilder.client = client;
    dispatch(integrateCBAdvancedResumesSearchViaApi(action.CareerBuilder));

    action.Monster.client = client;
    action.Monster.monsterAccountId = monsterAccountId;
     if(monsterAccountId){
      dispatch(integrateMonsterAdvancedResumesSearchViaApi(action.Monster))
     }
  const historyAction = {
    id: value._id,
    query: action,
    addedBy: storeData._id,
    addedOn: new Date(),
    client: client,
    status: value.status,
    searchType: "IntegrateSearch",
  };
  dispatch(addJobBoardsHistoryViaApi(historyAction));
  setTimeout(() => {
    navigate("/jobBoardsIntegrateResult", { state: { InternalDB:action.InternalDB,
      Monster:action.Monster,
      CareerBuilder:action.CareerBuilder,
      keyWords:action.keyWords
    }});
    dispatch(getJobBoardsHistoryViaApi(historyAction));
  }, 1500);
};

const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);

  let resetConfigs = [
    { setter: setJobTitle, value: "" },
    { setter: setKeyWords, value: "" },
    { setter: setCountry, value: 840 },
    { setter: setLocation, value: "" },
    { setter: setRadius, value: 50 },
    { setter: setInternalDBState, value: "" },
    { setter: setMonsterState, value: [] },
    { setter: setMinExperience, value: "" },
    { setter: setMaxExperience, value: "" },
    { setter: setEducation, value: "" },
    { setter: setWorkAuthorization, value: "" },
    { setter: setInternalDbName, value: "" },
    { setter: setRelocate, value: "" },
    { setter: setMonsterCountry, value: "" },
    { setter: setClv, value: [] },
    { setter: setTnsalmin, value: "" },
    { setter: setTnsalmax, value: "" },
    { setter: setTsaltyp, value: "1" },
    { setter: setTwlid, value: "" },
    { setter: setWa, value: "" },
    { setter: setTjtft, value: "" },
    { setter: setTjtpt, value: "" },
    { setter: setTjtpd, value: "" },
    { setter: setTjtp, value: "" },
    { setter: setTjti, value: "" },
    { setter: setTjttc, value: "" },
    { setter: setTjts, value: "" },
    { setter: setQrcn, value: "" },
    { setter: setQsn, value: "" },
    { setter: setEdulv, value: "" },
    { setter: setMdatemaxage, value: 43200 },
    { setter: setYearofexpto, value: "" },
    { setter: setYearofexpfrom, value: "" },
    { setter: setCity, value: "" },
    { setter: setEmployer, value: "" },
    { setter: setExcludeTerms, value: "" },
    { setter: setNames, value: "" },
    { setter: setPage, value: 1 },
    { setter: setPageSize, value: 30 },
    { setter: setState, value: "" },
    { setter: setWorkStatus, value: [] },
    { setter: setZipCode, value: "" },
    { setter: setSelectCityZip, value: "" },
  ];
  const handleResetStates = () => {
    resetConfigs.forEach(({setter, value}) => { setter(value) });
  }
  const handleClickEditHistory = (searchData) => {
    const QueryData = searchData?.query;
    handleResetStates();
    let clvArray = String(QueryData.Monster.clv).split(",");
    let locationInfo = (QueryData.CareerBuilder.City ? QueryData.CareerBuilder.City + ',' : '') + (QueryData.CareerBuilder.State ? QueryData.CareerBuilder.State + ',' : '') + QueryData.CareerBuilder.ZipCode;
    let getStateCodes = String(QueryData?.Monster?.rlid || '').split('+').map(code => Number(code));
    let monsterState= QueryData?.Monster?.monsterState||[]
    let lastupdatedData=QueryData?.lastupdated||''
    let mdatemaxageData=QueryData?.Monster?.masterLastUpdate||'';
    const updateStates = { qrjt: setJobTitle, query: setKeyWords, country : setCountry, radius: setRadius};    const updateIDBStates = { state: setInternalDBState, minExperience: setMinExperience, maxExperience: setMaxExperience, education: setEducation,
      workAuthorization: setWorkAuthorization, name: setInternalDbName, relocate: setRelocate,
      // country: handleSelectCountryInternalDB,keyWords: ,jobTitle: ,city: ,zipCode: ,fromDateaddedon: ,toDateaddedOn: ,
    };
    const updateMDBStates = {allCountry: setCountry,monsterCountry:setMonsterCountry,  tnsalmin: setTnsalmin, tnsalmax: setTnsalmax, tsaltyp: setTsaltyp, 
      twlid: setTwlid, wa: setWa, tjtft: setTjtft, tjtpt: setTjtpt, tjtpd: setTjtpd, tjtp: setTjtp, tjti: setTjti, tjttc: setTjttc, 
      tjts: setTjts, qrcn: setQrcn, qsn: setQsn, edulv: setEdulv, selectCityZip:setSelectCityZip,
      // mdateminage: ,rpcr: ,tsni: ,qrjt: ,tsalcur: ,zipcode: setZipCode,
    }
  
    const updateCBStates = {CBMaximumExperience: setYearofexpto, CBMinimumExperience: setYearofexpfrom, City: setCity, Employer: setEmployer, 
      ExcludeTerms: setExcludeTerms, JobTitle: setJobTitle, Keywords: setKeyWords, Names: setNames, PageNumber: setPage, Radius: setRadius, 
      RowsPerPage: setPageSize, State: setState, WorkStatus: setWorkStatus, ZipCode: setZipCode};
      if(monsterState.length>0){
        setMonsterStateOptions(statesArrayOptionMonster)
      }
      setMdatemaxage(mdatemaxageData)
      setLastupdated(lastupdatedData)
      setClv(clvArray);
      setLocation(locationInfo);
    Object.entries(updateStates).forEach(([key, setter]) => { setter(QueryData.Monster[key]) }); //using monster db object as base
    Object.entries(updateIDBStates).forEach(([key, setter]) => { setter(QueryData.InternalDB[key]) });
    Object.entries(updateCBStates).forEach(([key, setter]) => { setter(QueryData.CareerBuilder[key]) });
    Object.entries(updateMDBStates).forEach(([key, setter]) => { setter(QueryData.Monster[key]) });
    setMonsterState(monsterState);
  };

const handleClickSaveHistory=(value)=>{
  setHistoryTitle("");
  setErrTitle("");
  setSelectHistory("");
  if (value) {
    setAddNoteModal(!addNoteModal);
    setSelectHistory(value);
  }

}
const handleClickDeleteHistory=(value)=>{
  if (value) {
    setSelectHistory(value);
    setDeleteHistoryModal(!deleteHistoryModal);
  }
}
const handleConfirmHistorySave = () => {
  setErrTitle("");
  if (!selectHistory && !historyTitle) {
    setErrTitle("please type title");
    return "";
  }
  const action = {
    id: selectHistory._id,
    client: client,
    title: historyTitle,
  };
  dispatch(saveJobBoardsHistoryViaApi(action));
  setLoading(true);
  setAddNoteModal(!addNoteModal);
  setTimeout(() => {
    const action = {
      addedBy: storeData._id,
      searchType: 'CareerBuilder',
      status: "recent",
      client: client,
    };
    dispatch(getjobBoardsStatusHistoryViaApi(action));
  }, 2000);
};
const handleConfirmHistoryDelete = () => {
  const action = {
    id: selectHistory._id,
    client: client,
  };
  dispatch(deleteJobBoardsHistoryViaApi(action));
  setLoading(true);
  setDeleteHistoryModal(!deleteHistoryModal);
  setTimeout(() => {
    const fetchAction = {
      client: client,
      addedBy: storeData._id,
      searchType: 'CareerBuilder',
      status: "saved",
    };
    dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
  }, 2000);
};
  return (
    <Box>
      <MainBackground p={0}>
        <Grid container>
          <Grid item xs={7}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <SectionTitle
                  title="Integrated Search"
                  size="sm"
                  color="secondary"
                />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomFormField
                     title={"Keywords"}
                     required
                     components={[{ type: "text" ,
                       props: {
                         required: true,    
                       defaultValue: keyWords,
                         placeholder: "",
                         onChange: (value) => setKeyWords (value),
                         error:!!keyWordError,
                         errorMessage:keyWordError 
                       },
 
                     }]}
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <CustomFormField
                      title={"Job Title"}
                      required
                      components={[{ type: "text" ,
                        props: {
                          required: true,    
                        defaultValue: jobTitle,
                          placeholder: "",
                          onChange: (value) => setJobTitle (value),
                          // error:!!errorKeyword,
                          // errorMessage:errorKeyword 
                        },
                      }]}
                    />
                    <Box sx={{ display: "flex", justifyContent: "right" }}>
                      <FormControlLabel
                        control={<Checkbox size="small" checked={recentJobTitle} onChange={handleChangeRecentJobTitle} onClick={handleClickRecentJobTitle} />}
                        label="Recent Job Title"
                        componentsProps={{
                          typography: { sx: { fontSize: "12px" } },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2.4}>
                    <CustomFormField
                      title={"Country"}
                      components={[
                        {
                          type: "selectLabel",
                          props: {
                            title: "Country",
                            value: country,
                            options: countryOptionsIntegrationSearch,
                            onChange:(value)=>setCountry(value)
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <CustomFormField
                      title={"Location"}
                     
                          components={[
                            {
                              type: "selectLabelMultipleFrom",
                              props:{
                                onChangeForm: (value) => handleTypeLocations( value), 
                                options: locationOptionsCb ,
                                searchTerm:location,
                                selectCityZip:selectCityZip,
                                onChange: (value)=>handleSelectCityZip(value)
                              }
                            },
                          ]}
                      
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <CustomFormField
                      title={"Radius"}
                      components={[{ 
                        type: "selectLabel",
                        props: {
                        options:radiusOptionsMonsterIntegrateSearch,
                        value:radius,
                        onChange:(value)=>setRadius(value)
                        },
                       }]}
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <CustomFormField
                      title={"Last Updated"}
                      components={[
                        {
                          type: "selectLabel",
                          props: {
                            title: "select",
                            value: lastupdated,
                            options: lastUpdatedOptionsIntegrateSearch,
                            onChange:(value)=>setLastupdated(value)
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/*           */}
              <Accordion>
                <AccordionSummary>Internal DB</AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Country"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              title: "Country",
                              defaultValue: internalDBCountry,
                              onChange:(value)=>handleSelectCountryInternalDB(value) ,
                              options: countryOptionsIntegrationSearch,
                            },
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomFormField
                        title={"State"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              title: "State",
                              options: internalDBStateOptions,
                              value:internalDBState,
                              onChange:(value)=>setInternalDBState(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Total Experience"}
                        sectionInfo={"in years"}
                        components={[
                          {
                            type: "text",
                            props: {
                              type:'Number',
                              placeholder: "min",
                              defaultValue:minExperience,
                              onChange:(value)=>setMinExperience(value)
                            },
                          },
                          {
                            type: "text",
                            props: {
                              type:'Number',
                              placeholder: "max",
                              defaultValue:maxExperience,
                              onChange:(value)=>setMaxExperience(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Education Details"}
                        components={[
                          {
                            type: "select",
                            props: {
                              title: "Education Details",
                              defaultValue: education,
                              options: degreesNames,
                              onChange:(value)=>setEducation(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Work Authorization"}
                        components={[
                          {
                            type: "select",
                            props: {
                              title: "Education Details",
                              defaultValue: workAuthorization,
                              options: workAuthorizationIntegrationSearch,
                              onChange:(value)=>setWorkAuthorization(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Employer"}
                        components={[
                          {
                            type: "text",
                            props: {
                              placeholder: "Employer",
                              onChange:(value)=>setInternalDbName(value),
                              defaultValue:internalDbName
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Willing to Relocate"}
                        required
                        components={[
                          {
                            type: "radio",
                            props: {
                              title: "Willing to Relocate",
                              required: true,
                              options: ["Yes", "No"],
                              defaultValue: relocate,
                              onChange:(value)=>setRelocate(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Clearance"}
                        required
                        components={[
                          {
                            type: "radio",
                            props: {
                              title: "Clearance",
                              required: true,
                              options: ["Yes", "No"],
                              defaultValue: clearance,
                              onChange:(value)=>setClearance(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <FormControlLabel
                        control={<Checkbox size="small" />}
                        label="Exclude Blacklist applications"
                      /> */}
                      <CustomFormField
                      //title={"Show Resumes without a Desire Salery"}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: "Exclude Blacklisted Applicants",
                            options: ["Exclude Blacklisted Applicants"],
                            size: "small",
                            defaultValue:excludeBlockedList,
                            onClick:(value)=>setExcludeBlockedList(value)

                          },
                        },
                      ]}
                    />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <FormControlLabel
                        control={<Checkbox size="small" />}
                        label="Search Radius Within the state"
                      /> */}
                      <CustomFormField
                      //title={"Show Resumes without a Desire Salery"}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: " Search Radius Within the State",
                            options: [" Search Radius Within the State"],
                            size: "small",
                            defaultValue:radiusSearch,
                            onClick:(value)=>setRadiusSearch(value)

                          },
                        },
                      ]}
                    />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <FormControlLabel
                        control={<Checkbox size="small" />}
                        label="Include Applicants Without Country"
                      /> */}
                      <CustomFormField
                      //title={"Show Resumes without a Desire Salery"}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: "Include Applicants Without Country",
                            options: ["Include Applicants Without Country"],
                            size: "small",
                            defaultValue:searchWithoutLocation,
                            onClick:(value)=>setSearchWithoutLocation(value)

                          },
                        },
                      ]}
                    />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>Career Builder</AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Name"}
                        components={[
                          {
                            type: "text",
                            props: {
                              placeholder: "Name",
                              defaultValue:Names,
                              onChange:(value)=>setNames(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Exclude Skills"}
                        components={[
                          {
                            type: "text",
                            props: {
                              placeholder: "Exclude Skills",
                              defaultValue:ExcludeTerms,
                              onChange:(value)=>setExcludeTerms(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Employer"}
                        components={[
                          {
                            type: "text",
                            props: {
                              placeholder: "Employer",
                              defaultValue:Employer,
                              onChange:(value)=>setEmployer(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Work Status"}
                        components={[
                          {
                            type: "selectLabelMultiple",
                            props: {
                              title: "Work Status",
                              value: WorkStatus,
                              options: workStatusOptionsCB,
                              onChange:(value)=>setWorkStatus(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Total Experience"}
                        sectionInfo={"in years"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              placeholder: "min",
                              options:yearsOfExperienceOptionsCB,
                              value: yearofexpfrom,
                              onChange:(value)=>setYearofexpfrom(value)
                            },
                          },
                          {
                            type: "selectLabel",
                            props: {
                              placeholder: "max",
                              options:yearsOfExperienceOptionsCB,
                              value: yearofexpto,
                              onChange:(value)=>setYearofexpto(value)
                            },
                          },
                          {
                            type: "rtText",
                            props: {
                              text: "Years",
                             
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Last Modified"}
                        // sectionInfo={"in years"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              placeholder: "From",
                              value: ResumeModifiedFrom,
                              options: resumeModifiedOptionsCB,
                              onChange:(value)=>setResumeModifiedFrom(value)
                            },
                          },
                          {
                            type: "selectLabel",
                            props: {
                              placeholder: "To",
                              value: ResumeModifiedTo,
                              options: resumeModifiedToOptionsCB,
                              onChange:(value)=>setResumeModifiedTo(value)
                            },
                          },
                          {
                            type: "rtText",
                            props: {
                              text: "Years",
                             
                            },
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>Monster</AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Resume Posted"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              title: "Resume Posted",
                              value:mdatemaxage,
                              options: optionsMonsterTo,
                              onChange:(value)=>setMdatemaxage(value)
                            },
                          },
                        ]}
                      />
                      
                    </Grid>

                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Country"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              title: "Country",
                              defaultValue: "",
                              options: countryMonsterOptions,
                              value:monsterCountry,
                              onChange:(value)=>handleSelectCountryMonster(value) 
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"State"}
                        components={[
                          {
                            type: "selectLabelMultiple",
                            props: {
                              title: "State",
                              value: monsterState,
                              multiple: true,
                              options: monsterStateOptions,
                              onChange:(value)=>setMonsterState(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Job Seeker Profile"}
                        components={[
                          {
                            type: "selectLabel",
                            props: {
                              title: "Job Seeker Profile",
                              value: edulv,
                              options: MonsterEducationLevel,
                              onChange:(value)=>setEdulv(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Most Recent Employer"}
                        components={[
                          {
                            type: "text",
                            props: {
                              paceholder: "Most Recent Employer",
                              defaultValue:qrcn,
                              onChange:(value)=>setQrcn(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"School Attended"}
                        components={[
                          {
                            type: "text",
                            props: {
                              paceholder: "School Attended",
                              defaultValue:qsn,
                              onChange:(value)=>setQsn(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Desired Salary"}
                        components={[
                          {
                            type: "text",
                            props: {
                              placeholder: "Min",
                              defaultValue:tnsalmin,
                              onChange:(value)=>setTnsalmin(value)
                            },
                          },
                          {
                            type: "text",
                            props: {
                              placeholder: "Max",
                            defaultValue:tnsalmax,
                            onChange:(value)=>setTnsalmax(value)
                            },
                          },
                          {
                            type: "selectLabel",
                            props: {
                              title: "Yearly",
                              value: tsaltyp,
                              options: salaryTypeMonsterOptions,
                              onChange:(value)=>setTsaltyp(value)
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                    <CustomFormField
                      title={"Job Status"}
                      colunm={true}
                      components={[
                        {
                          type: "checkbox",
                          multiple: true,
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Full-Time"],
                            defaultValue:tjtft ? 'Full-Time': '',
                            onClick:(value)=>setTjtft(value)
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Part-Time"],
                            defaultValue:tjtpt ? 'Part-Time': '',
                            onClick:(value)=>setTjtp(value)
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Per Diem"],
                            defaultValue: tjtpd ? 'Per Diem':'',
                            onClick:(value)=>setTjtpd(value)
                          },
                        },
                        
                      ]}
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <CustomFormField
                      title={"Job Type"}
                      colunm={true}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ['Employee'],
                            defaultValue:tjtp ? 'Employee': '',
                            onClick:(value)=>setTjtp(value)
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ['Intern'],
                            defaultValue:tjti ? 'Intern': '',
                            onClick:(value)=>setTjti(value)
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ['Temporary/Contract/Permanent or Fulltime'],
                            defaultValue:tjttc ? 'Temporary/Contract/Permanent or Fulltime': '',
                            onClick:(value)=>setTjttc(value)
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ['Seasonal'],
                            defaultValue:tjts? 'Seasonal': '',
                            onClick:(value)=>setTjts(value)
                          },
                        },
                        
                      ]}
                    />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormField
                        title={"Career Level"}
                        components={[
                          {
                            type: "selectLabelMultiple",
                            props: {
                              title: "Career Level",
                              defaultValue: "",
                              value: clv?clv:[],
                              multiple: true,
                              options: careerLevelMonster,
                              onChange:(value)=> setClv(value),
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <CustomFormField
                colunm={true}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      title: "Search within Radius",
                      size:"small",
                      defaultValue: twlid ? "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.":'',
                      options: [
                        "Include Job Seekers Employable in Any of the Selected Locations.",
                      ],
                      onClick:(value)=>setTwlid(value)
                    },
                  },
                  {
                    type: "checkbox",
                    props: {
                      title: "Search within Radius",
                      size:"small",
                      defaultValue: wa ? "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.":'',
                      options: [
                        "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.",
                      ],
                      onClick:(value)=>setWa(value)
                    },
                  },
                ]}
              />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid item xs={12} alignContent={"center"}>
                <Box display={"flex"} gap={2} pt={3}>
                <Button variant="contained" onClick={handleSearchAll}>Search</Button>
                <Button variant="contained" onClick={handleResetStates}>Reset</Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                bgcolor: theme.palette.grey[50],
                px: 3,
                py: 1,
                height: "100%",
              }}
            >
              <TabContext value={openTabId}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="Job Boards tabs">
                    <Tab label="Recent History" value="recentHistory" />
                    <Tab label="Saved History" value="savedHistory" />
                  </TabList>
                </Box>

                <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                {JobBoardsHistoryRecent.map((search) => (
                  <SearchHistorySection key={search._id} 
                  data={search.query.keyWords} 
                  onClick={() => handleClickHistory(search)} 
                  addedOn={search.addedOn} 
                  onClickSave={()=>handleClickSaveHistory(search)}
                  status="recent"  
                  onClickEdit={()=> handleClickEditHistory(search)}
                   />
                ))}
                </TabPanel>
                <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
                {JobBoardsHistorySaved.map((search) => (
                  <SearchHistorySection key={search._id} 
                  data={search.query.keyWords} 
                  onClick={() => handleClickHistory(search)} 
                  addedOn={search.addedOn}
                  onClickDelete={()=>handleClickDeleteHistory(search)}
                  status="saved" 
                  title={search.title}  
                  onClickEdit={()=> handleClickEditHistory(search)}
                   />
                ))}
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </MainBackground>
      <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
            </Typography>
           
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={loading}
  onClick={handleClose}
>
  <CircularProgress color="inherit" size="3rem" />
</Backdrop>
    </Box>
  );
};

export default IntegratedSearch;