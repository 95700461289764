const TrimFields = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        acc[key] = value;
      } else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = TrimFields(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  export {TrimFields};
  