import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../../Theme";
import CustomFormField from "../Common/CustomFormField";
import { size, values } from "lodash";
import ClearIcon from "@mui/icons-material/Clear";
const JobBoardMonsterSearchMonster = ({
  mSearchType,
  setMSearchType,
  radius,
  setRadius,
  MonsterPowerSearchRadiusOptions,
  jobTitle,
  setJobTitle,
  bkeyword,
  setBkeyword,
  keyWords,
  setKeyWords,
  setRelo,
  relo,
  setVeteran,
  veteran,
  MonsterPowerSearchTenureOptions,
  setTen,
  ten,
  setExperience,
  experience,
  comp,
  setComp,
  setEdumjrMonster,
  edumjrMonster,
  setEdumjrTypeMonster,
  edumjrTypeMonster,
  setSch,
  sch,
  setCname,
  cname,
  wa,
  setWa,
  clvid,
  setClvid,
  handleMonsterSearch,
  setSelectCityZip,
  setSearchTerm,
  setOptionCityZip,
  country,
  searchTerm,
  selectCityZip,
  handleSelectCityZip,
  apiUrl,
  optionsCityZip,
  MonsterSearchResumeUpdatedOptions,
  resumeUpdate,
  setResumeUpdate,
  tjtid,
  setTjtid,
  lstat,
  setLstat,
  gsc,
  setGsc,
  setMinedulvid,
  setSalaryRangeType,
  setSalaryRangeOptions,
  MonsterPowerSearchHourlySalaryRangeOptions,
  MonsterPowerSearchSalaryRangeOptions,
  salaryRangeType,
  setSalaryRange,
  salaryRange,
  salaryRangeOptions,
  tsni,
  setTsni,
  wtt,
  setWtt,
  highEducation,
  setHighEducation,
  filter = false,
  handleReset
}) => {

  const [advanceSearchOptions, setAdvanceSearchOptions] = useState(true);
  const toggleAdvanceSearchOptions = () => {
    setAdvanceSearchOptions(!advanceSearchOptions);
  };
  const handleAddField = (setter, fields) => {
    setter([...fields, { id: fields.length + 1, value: "" }]);
  };
  const handleRemoveField = (setter, fields, id) => {
    setter(fields.filter((field) => field.id !== id));
  };
  const handleFieldChange = (setter, fields, id, value) => {
    setter(
      fields.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  const handleAddFieldEdumjrMonster = () => {
    setEdumjrMonster([
      ...edumjrMonster,
      { id: edumjrMonster.length + 1, value: "" },
    ]);
    setEdumjrTypeMonster([
      ...edumjrTypeMonster,
      { id: edumjrTypeMonster.length + 1, value: "" },
    ]);
  };

  const handleRemoveEdumjrMonster = (id) => {
    setEdumjrMonster(edumjrMonster.filter((field) => field.id !== id));
    setEdumjrTypeMonster(edumjrTypeMonster.filter((field) => field.id !== id));
  };
  const handleCallCountriesWithApi = async (value) => {
    setSelectCityZip("");
    setSearchTerm(value);
    if (!value) {
      setOptionCityZip([]);
      return;
    }
    // Only make the API call if the user has typed more than 2 characters
    if (value.length < 1) {
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/countries/getCitiesState `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: "tektree",
          countryCode: country,
          city: value,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }
      const data = await response.json();
      setOptionCityZip(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const handleChangeSalaryType = (value) => {
    setMinedulvid("");
    if (value === "Per Hour") {
      setSalaryRangeOptions(MonsterPowerSearchHourlySalaryRangeOptions);
    } else if (value === "Per Year") {
      setSalaryRangeOptions(MonsterPowerSearchSalaryRangeOptions);
    }
    setSalaryRangeType(value);
  };

  return (
    <Box sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
      <Box>
        <Typography textAlign={"center"} variant="h6">
          SearchMonster helps you connect with the right candidates - now.
        </Typography>
      </Box>
      <Box mt={4}>
        <Grid container rowSpacing={2} columnSpacing={1}>
          {mSearchType === "Semantic Search" ? (
            <>
              <Grid item xs={filter ? 12 : 6}>
                <CustomFormField
                  title={"Job Title"}
                  colunm
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: `Cook, Developer, Nurse`,
                        defaultValue: jobTitle,
                        onChange: (value) => setJobTitle(value),
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={filter ? 12 : 6}>
                <CustomFormField
                  title={"Skills/Keywords"}
                  colunm
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: `Javascript, Management, Finance`,
                        defaultValue: keyWords,
                        onChange: (value) => setKeyWords(value),
                      },
                    },
                  ]}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <CustomFormField
                title={"Boolean Search"}
                colunm
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: `Javascript, Management, Finance`,
                      defaultValue: bkeyword,
                      onChange: (value) => setBkeyword(value),
                    },
                  },
                ]}
              />
            </Grid>
          )}

          <Grid item xs={filter ? 12 : 5}>
            <CustomFormField
              title={"Location"}
              colunm
              components={[
                // {
                //   type: "text",
                //   props: {
                //     placeholder: `City, State or Zip`,
                //   },
                // },
                {
                  type: "selectLabelMultipleFrom",
                  props: {
                    title: "of Zipcode/City",
                    defaultValue: "",
                    options: optionsCityZip,
                    onChangeForm: (value) => handleCallCountriesWithApi(value),
                    searchTerm: searchTerm,
                    selectCityZip: selectCityZip,
                    onChange: (value) => handleSelectCityZip(value),
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={filter ? 12 : 5}>
            <CustomFormField
              title={"Radius"}
              components={[
                {
                  type: "selectLabel",
                  props: {
                    title: "within",
                    value: radius,
                    options: MonsterPowerSearchRadiusOptions,
                    onChange: (value) => setRadius(value),
                  },
                },
              ]}
            />
          </Grid>
          <Grid
            item
            xs={filter ? 12 : 2}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Button variant="text" color="error"  onClick={handleReset}>
              Reset
            </Button>
            <Button variant="contained" onClick={handleMonsterSearch}>
              Search
            </Button>
          </Grid>
          {filter&&(
            <Grid item xs={12}>
            <CustomFormField
              title={"Boolean Search"}
              colunm
              components={[
                {
                  type: "text",
                  props: {
                    placeholder: `Javascript, Management, Finance`,
                    defaultValue: bkeyword,
                    onChange: (value) => setBkeyword(value),
                  },
                },
              ]}
            />
          </Grid>
          )}
        {!filter&&(
          <Grid item xs={filter ? 12 : 6}>
            <CustomFormField
              components={[
                {
                  type: "radio",
                  props: {
                    show: "row",
                    title: "Semantic Search",
                    defaultValue: mSearchType,
                    onChange: (value) => setMSearchType(value),
                    options: ["Semantic Search", "Boolean Keyword Search"],
                  },
                },
              ]}
            />
          </Grid>
        )}
          
          <Grid
            item
            xs={filter ? 12 : 6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button size="small" onClick={toggleAdvanceSearchOptions}>
              Advanced Options
            </Button>
          </Grid>
        </Grid>
        {advanceSearchOptions && (
          <Box mt={4} p={2} bgcolor={"white"}>
            <Box py={2}>
              <Typography>Advanced Options</Typography>
            </Box>
            <Box>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={filter ? 12 : 6}>
                  <CustomFormField
                    title={"Select Candidate Types"}
                    colunm={filter ? "column" : "row"}
                    components={[
                      {
                        type: "checkbox",
                        props: {
                          
                          title: "Semantic Search",
                          defaultValue: relo,
                          options: [
                            "Limit results to candidates willing to relocate",
                          ],
                          onChange: (value) => setRelo(value),
                        },
                      },
                      {
                        type: "checkbox",
                        props: {
                          title: "Semantic Search",
                          options: [
                            "Limit results to candidates designated as veterans",
                          ],
                          defaultValue: veteran,
                          onChange: (value) => setVeteran(value),
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={filter ? 12 : 6}>
                  <CustomFormField
                    title={"Select Candidate Types"}
                    components={[
                      {
                        type: "radio",
                        props: {
                          title: "Semantic Search",
                          defaultValue: wa,
                          options: [
                            "Show all job seekers",
                            "Limit to job seekers authorized to work in this country for any employer",
                            "Limit to job seekers authorized to work in this country for their present employer only",
                            "ILimit to job seekers that require sponsorship to work in this country",
                          ],
                          onChange: (value) => setWa(value),
                        },
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <Accordion defaultExpanded>
                  <AccordionSummary>
                    <Typography>Resume Search Criteria</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Career Level"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                options: [
                                  "Executive Level",
                                  "Vice President",
                                  "Director",
                                  "Manager",
                                  "Representative",
                                ],
                                defaultValue: clvid,
                                onChange: (value) => setClvid(value),
                                multiple: true,
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Job Duration"}
                          components={[
                            {
                              type: "radio",
                              props: {
                                options: [
                                  "1 Year",
                                  "2 Years",
                                  "3 Years",
                                  "4 Years",
                                ],
                                defaultValue: ten,
                                onChange: (value) => setTen(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Years of Experience"}
                          sectionInfo={"(e.g., 4, 2-3, 5+, <8)"}
                          components={[
                            {
                              type: "text",
                              props: {
                                defaultValue: experience,
                                placeholder: `Suggested 4, 2-3, 5+, <8`,
                                onChange: (value) => setExperience(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {comp.map((field, index) => (
                            <Grid
                              item
                              xs={12}
                              key={field.id}
                              alignItems="center"
                            >

                              <Grid item xs={filter ? 12 : 11}>
                                <CustomFormField
                                  title={"Company"}
                                  colunm
                                  components={[
                                    {
                                      type: "text",
                                      props: {
                                        placeholder: `(e.g, , IHOP Corp, IBM Global Services)`,
                                        // value: field.value,
                                        defaultValue: field.value,
                                        onChange: (value) =>
                                          handleFieldChange(
                                            setComp,
                                            comp,
                                            field.id,
                                            value
                                          ),
                                      },
                                    },
                                  ]}
                                />
                              </Grid>
                              {comp.length > 1 && (
                                <Grid item xs={filter ? 12 :1}>
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleRemoveField(setComp, comp, field.id)
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <Button
                              variant=""
                              onClick={() => handleAddField(setComp, comp)}
                            >
                              add another
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>      
                      <Grid container spacing={2}>
                        {edumjrMonster.map((field, index) => (
                          <Grid
                            item
                            xs={12}
                            key={field.id}
                            alignItems="center"
                          >
                            <Grid item xs={11}>
                              <CustomFormField
                                title={"Degree/Major"}
                                colunm
                                components={[
                                  {
                                    type: "text",
                                    props: {
                                      placeholder: `(e.g., Computer Engineering)`,
                                      // value: field.value,
                                      defaultValue: field.value,
                                      onChange: (value) =>
                                        handleFieldChange(
                                          setEdumjrMonster,
                                          edumjrMonster,
                                          field.id,
                                          value
                                        ),
                                    },
                                  },
                                  {
                                    type: "checkbox",
                                    props: {
                                      title: "within",
                                      defaultValue:
                                        edumjrTypeMonster[field.id]?.value,
                                      options: ["Required"],
                                      onChange: (value) =>
                                        handleFieldChange(
                                          setEdumjrTypeMonster,
                                          edumjrTypeMonster,
                                          field.id,
                                          value
                                        ),
                                    },
                                  },
                                ]}
                              />
                            </Grid>
                            {edumjrMonster.length > 1 && (
                              <Grid item xs={1}>
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    handleRemoveEdumjrMonster(field.id)
                                  }
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            variant=""
                            onClick={handleAddFieldEdumjrMonster}
                          >
                            add another
                          </Button>
                        </Grid>
                      </Grid>
                      </Grid>
                      <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {sch.map((field, index) => (
                          <Grid
                            
                            item
                            xs={12}
                            key={field.id}
                            alignItems="center"
                            
                          >
                            <Grid item xs={filter ? 12 : 11}>
                              <CustomFormField
                                title={"School"}
                                colunm
                                components={[
                                  {
                                    type: "text",
                                    props: {
                                      placeholder: `(e.g., UCLA, Siena College, lowa State Univ)`,
                                      // value: field.value,
                                      defaultValue: field.value,
                                      onChange: (value) =>
                                        handleFieldChange(
                                          setSch,
                                          sch,
                                          field.id,
                                          value
                                        ),
                                    },
                                  },
                                ]}
                              />
                            </Grid>
                            {sch.length > 1 && (
                              <Grid item xs={filter ? 12 : 1}>
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    handleRemoveField(setSch, sch, field.id)
                                  }
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            variant=""
                            onClick={() => handleAddField(setSch, sch)}
                          >
                            add another
                          </Button>
                        </Grid>
                      </Grid>
                      </Grid>

                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary>
                    <Typography>Profile Search Criteria</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography mt={1} variant="body2">
                      Please be advised the search options in this search are
                      derived from the information that may or may not be
                      provided by the candidate as part of their profile. Use of
                      these search options could impact the quality of your
                      overall search results.
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Resume Updated"}
                          components={[
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select Country",
                                value: resumeUpdate,
                                options: MonsterSearchResumeUpdatedOptions,
                                onChange: (value) => setResumeUpdate(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Candidate Name"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "(e.g., Mark, John Wayne)",
                                defaultValue: cname,
                                onChange: (value) => setCname(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Job Type"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                show: "row",
                                options: [
                                  "Full Time",
                                  "Temporary/Contract",
                                  "Intern",
                                  "Part time",
                                  "Seasonal",
                                ],
                                defaultValue: tjtid,
                                onClick: (value) => setTjtid(value),
                                multiple: true,
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Citizenship"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                title: "Citizenship",

                                defaultValue: lstat,
                                options: ["US Citizen", "Parmanent Resident"],
                                onChange: (value) => setLstat(value),
                                multiple: true,
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Security Clearance"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                //show: "row",
                                title: "Security Clearance",
                                defaultValue: "",
                                options: [
                                  "Unspecified",
                                  "Active Confodential",
                                  "Active Secret",
                                  "Active Top Secret",
                                  "Active Top Secret/SCI",
                                  "Active TS/SCI-CI Polygraph",
                                  "Active TS/SCI-FS Polygraph",
                                  "Other Active Clearance",
                                ],
                                defaultValue: gsc,
                                onChange: (value) => setGsc(value),
                                multiple: true,
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Desired Candidate Salary"}
                          colunm
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Salary Type",
                                options: ["Per Year", "Per Hour"],
                                defaultValue: salaryRangeType,
                                onChange: (value) =>
                                  handleChangeSalaryType(value),
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "Salary Level",
                                value: salaryRange,
                                options: salaryRangeOptions,
                                onChange: (value) => setSalaryRange(value),
                              },
                            },
                            {
                              type: "checkbox",

                              props: {
                                title:
                                  "Include candidates without salary information",
                                options: [
                                  "Include candidates without salary information",
                                ],
                                size: "small",
                                defaultValue: tsni
                                  ? "Include candidates without salary information"
                                  : "",
                                onClick: (value) => setTsni(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Willing To Travel"}
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Willing To Travel",
                                defaultValue: wtt,
                                options: [
                                  "Up to 25% travel",
                                  "Up to 50% travel",
                                  "Up to 75% travel",
                                  "Up to 100%",
                                ],
                                onChange: (value) => setWtt(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={filter ? 12 : 4}>
                        <CustomFormField
                          title={"Education"}
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Education",
                                defaultValue: highEducation,
                                options: [
                                  "Some High School Coursework",
                                  "High School or equivalent",
                                  "Certification",
                                  "Vocational",
                                  "Some College Coursework Complented",
                                  "Associate Degree",
                                  "Bachelor's Degree",
                                  "Master's Degree",
                                  "Doctorate",
                                  "Professional",
                                ],
                                onChange: (value) => setHighEducation(value),
                              },
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default JobBoardMonsterSearchMonster;
