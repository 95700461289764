import {
  SnackbarProvider,
  closeSnackbar,
  enqueueSnackbar,
  useSnackbar,
} from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,

  Typography,
 
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { CustomBox, CustomDateFilter, DateTime, FilterHeader, PageHeader, Widget } from "../../Components";
import BusinessIcon from "@mui/icons-material/Business";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useSelector, useDispatch } from "react-redux";
import {
  dateSearchRaUsersCompaniesViaApi,
} from "../../store/search/action";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";


import JSZip from "jszip";
import { getAllUsers } from "../../store/auth/login/actions";
import dayjs from "dayjs";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
import theme from "../../Theme";
import CopyToClipboardButton from "../../Components/Common/CopyToClipboardButton";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const Download = () => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [searchDownloadJobPosition,setSearchDownloadJobPosition]=useState("");
  const [searchDownloadRAName,setSearchDownloadRAName]=useState([]);
  const [searchDownloadRAPsuedoName,setSearchDownloadRAPsuedoName]=useState([]);
  const [searchDownloadEmailID,setSearchDownloadEmailID]=useState([]);
  let retrictAllUsers =
    allUsers &&
    allUsers.filter(
      (user) =>
        user.userEmailId === "ratest@tektreeinc.com" ||
        user.userEmailId === "lilyra@tektreeinc.com" ||
        user.userEmailId === "oliviara@tektreeinc.com" ||
        user.userEmailId === "emmara@tektreeinc.com" ||
        user.userEmailId === "charlottera@tektreeinc.com" ||
        user.userEmailId === "danielra@tektreeinc.com" ||
        user.userEmailId === "miara@tektreeinc.com" ||
        user.userEmailId === "williamra@tektreellc.com" ||
        user.userType.includes("BDM") ||user.userType.includes('SuperAdmin') || 
        user.userType.includes('Admin') || user.userType.includes('Developer')
    );
    const isAuthorizedUser =
    (storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
    if(isAuthorizedUser){
       retrictAllUsers =
      allUsers &&
      allUsers.filter(
        (user) =>
       
        user.userType.includes("BDM") ||user.userType.includes('SuperAdmin') || user.userType.includes('Developer')
      );
    }
  const restrictUserIds = retrictAllUsers
    ? retrictAllUsers.map((user) => user._id)
    : [];

    const AllCompaniesListRedux= useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
    let AllCompaniesList =Array.isArray(AllCompaniesListRedux)?AllCompaniesListRedux:[]
  AllCompaniesList = AllCompaniesList.filter(
    (company) => !restrictUserIds.includes(company.addedBy)
  );
  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList.reduce((acc, companies) => {
        const useraddedBy = companies.addedBy;
        const existingGroupIndex = acc.findIndex(
          (group) => group.useraddedBy === useraddedBy
        );
        if (existingGroupIndex !== -1) {
          acc[existingGroupIndex].companies.push(companies);
        } else {
          acc.push({ useraddedBy, companies: [companies] });
        }
        return acc;
      }, []).map((group) => ({
        raName: group.useraddedBy,
        companies: group.companies,
      }))
    : [];
  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.raName);
    if (userData) {
      return {
        raName: userData.userFirstName,
        userSudoFirstName: userData.userSudoFirstName,
        emailid: userData.userEmailId,
        position: userData.userLevel,
        companies: group.companies.map((company) => {
          return {
            ...company,
            addedBy: userData.userFirstName, // Update addedBy to user's name
          };
        }),
      };
    } else {
      return group;
    }
  });

  const filterSearchData = Array.isArray(mergedData) 
  ? mergedData.filter((data) => {
      // Convert necessary fields to lower case for case-insensitive comparison
      const lowerCaseEmailid = data.emailid?.toLowerCase() || "";
      const lowerCaseJobPosition = data.position?.toLowerCase() || "";
      const lowerCaseLeadRaName = data.raName || "";
      const lowerCaseLeadRaSudoName = data.userSudoFirstName || "";
      
      // Search term inputs
      const lowerCaseSearchJobPosition = searchDownloadJobPosition?.toLowerCase() || "";
      const SearchDownloadRAName = searchDownloadRAName || [];
      const SearchDownloadEmailID = searchDownloadEmailID || [];
      const SearchDownloadPsuedoName=searchDownloadRAPsuedoName||[];
      // Filter logic
      return (
        lowerCaseJobPosition.includes(lowerCaseSearchJobPosition) &&
        (SearchDownloadRAName.length === 0 || SearchDownloadRAName.includes(lowerCaseLeadRaName)) &&
        (SearchDownloadPsuedoName.length === 0 || SearchDownloadPsuedoName.includes(lowerCaseLeadRaSudoName))&&
        (SearchDownloadEmailID.length === 0 || SearchDownloadEmailID.includes(lowerCaseEmailid))
      );
    })
  : [];

  const companiesListWithIds = Array.isArray(filterSearchData)
    ? filterSearchData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const [downloadModalOpen, setdownloadModalOpen] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState("");
  const [clickDownloadType, setClickDownloadType] = useState("");
  const [clickDownloadAction, setClickDownloadAction] = useState("");
  const handleDownloadModalOpen = (value, data) => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType(value);
    if (value === "actionDownload") {
      setClickDownloadAction(data);
    }
  };

  const handleRowDownload = (rowData) => {
    const csv = convertToCSV(rowData);

    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `${rowData.raName}_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function convertToCSV(data) {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";

    // Add data rows to CSV
    data.companies.forEach((company) => {
      // Add job details if available
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        // Add contact details if available
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            csv += `"${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","${contact.firstName || ""}","${
              contact.lastName || ""
            }","${contact.designation || ""}","${contact.emailId || ""}","${
              contact.phoneNumber || ""
            }"\n`;
          });
        } else {
          // Add a row with empty contact details if no contact details are available
          csv += `"${company.companyName || ""}","${company.comment || ""}","${
            job.jobTitle || ""
          }","${job.jobLocation || ""}","${job.jobDescription || ""}","${
            job.jobWebsite || ""
          }","","","","",""\n`;
        }
      } else {
        // Add a row with empty job details if no job details are available
        csv += `"${company.companyName || ""}","${
          company.comment || ""
        }","","","","","","","","",""\n`;
      }
    });

    return csv;
  }
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "raName",
      headerName: "RA Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (<FilterHeader params={params}  type="multiselect" setSearchDownloadRAName={setSearchDownloadRAName} selectedValue={searchDownloadRAName} title={'downloadRAName'}  options={mergedData.map((user) => user.raName)}/>),
    },
    {
      field: "userSudoFirstName",
      headerName: "Pseudo Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (<FilterHeader params={params}  type="multiselect" setSearchDownloadRAPsuedoName={setSearchDownloadRAPsuedoName} selectedValue={searchDownloadRAPsuedoName} title={'downloadRAPsuedoName'}  options={mergedData
        .filter(user => user.userSudoFirstName)
        .map(user => user.userSudoFirstName)}/>),
    },
    {
      field: "emailid",
      headerName: "Email Id",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (<FilterHeader params={params}  type="multiselect" setSearchDownloadEmailID={setSearchDownloadEmailID} selectedValue={searchDownloadEmailID} title={'downloadEmailId'}  options={mergedData.map((user) => user.emailid)}/>),
      renderCell:(params)=>(
        <>
        {params.value}
        <CopyToClipboardButton text={params.value} />
        </>
      )
    },
    {
      field: "position",
      headerName: "Position",
      width: 180,
      renderHeader: (params) => (<FilterHeader params={params}  setSearchDownloadJobPosition={setSearchDownloadJobPosition} selectedValue={searchDownloadJobPosition} title={'downloadJobPosition'}/>),
    },
    {
      field: "companies",
      headerName: "Total Companies",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          return row.companies.length;
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobDetails",
      headerName: "Total Job Details",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;
          return companies.reduce((count, company) => {
            // return company.jobDetails && company.jobDetails.length > 0
            //   ? count + 1
            //   : count;
            if (company.jobDetails && company.jobDetails.length > 0) {
              return count + company.jobDetails.filter(job => 
                job.jobLocation && job.jobDescription && job.jobTitle
              ).length;
            }
            return count;
          }, 0);
        } else {
          return "--";
        }
      },
    },
    {
      field: "contactDetails",
      headerName: "Total Contact Details",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;

          return companies.reduce((count, company) => {
            return (
              count +
              (company.contactDetails ? company.contactDetails.length : 0)
            );
          }, 0);
        } else {
          return "--";
        }
      },
    },

    {
      field: "actions",
      headerName: "Download",
      width: 88,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={() =>
              handleDownloadModalOpen("actionDownload", params.row)
            }
          >
            <GetAppIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const totalCompanies = AllCompaniesList.length;
  const totalUsers = AlluserComapnies.length;
  const AVG = (totalUsers !== 0 ? totalCompanies / totalUsers : 0).toFixed(2);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleDownloadAll = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const zip = new JSZip();
    if (selectedRows.length > 0) {
      selectedRows.forEach((company, index) => {
        const csv = convertToCSV(company);
        const fileName = `${company.raName}_${currentDate}.csv`;
        zip.file(fileName, csv);
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link for the ZIP file
        const zipFileName = `selectRATeamData_${currentDate}.zip`;
        const url = window.URL.createObjectURL(content);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", zipFileName);
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        document.body.removeChild(link);
      });
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        companiesListWithIds.forEach((company, index) => {
          const csv = convertToCSV(company);
          const fileName = `${company.raName}_${currentDate}.csv`;
          zip.file(fileName, csv);
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
          // Create a download link for the ZIP file
          const zipFileName = `AllRATeamData_${currentDate}.zip`;
          const url = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", zipFileName);
          document.body.appendChild(link);
          // Trigger the download
          link.click();
          document.body.removeChild(link);
        });
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownload = (rowData) => {
    const csv = ALLconvertToCSV(rowData);
    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `AllRATeamSheet_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function ALLconvertToCSV(company) {
    const headers = [
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";
    company.forEach((data) => {
      // Add data rows to CSV
      data.companies.forEach((company) => {
        // Add job details if available
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          // Add contact details if available
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              csv += `"${data.raName || ""}","${company.companyName || ""}","${
                company.comment || ""
              }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
                job.jobDescription || ""
              }","${job.jobWebsite || ""}","${contact.firstName || ""}","${
                contact.lastName || ""
              }","${contact.designation || ""}","${contact.emailId || ""}","${
                contact.phoneNumber || ""
              }"\n`;
            });
          } else {
            // Add a row with empty contact details if no contact details are available
            csv += `"${data.raName || ""}","${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","","","","",""\n`;
          }
        } else {
          // Add a row with empty job details if no job details are available
          csv += `"${data.raName || ""}","${company.companyName || ""}","${
            company.comment || ""
          }","","","","","","","","",""\n`;
        }
      });
    });
    return csv;
  }
  const handleDownloadAllOneFile = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownload(companiesListWithIds);
      // companiesListWithIds.forEach((company) => {
      //   handleAllRowDownload(company);
      // });
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = convertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `${rowData.raName}_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };
  const convertToJSON = (data) => {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    data.companies.forEach((company) => {
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            json.push({
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: contact.firstName || "",
              contactLastName: contact.lastName || "",
              contactDesignation: contact.designation || "",
              contactEmail: contact.emailId || "",
              contactPhoneNumber: contact.phoneNumber || "",
            });
          });
        } else {
          json.push({
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: job.jobTitle || "",
            jobLocation: job.jobLocation || "",
            jobDescription: job.jobDescription || "",
            jobWebsite: job.jobWebsite || "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      } else {
        json.push({
          companyName: company.companyName || "",
          comment: company.comment || "",
          jobTitle: "",
          jobLocation: "",
          jobDescription: "",
          jobWebsite: "",
          contactFirstName: "",
          contactLastName: "",
          contactDesignation: "",
          contactEmail: "",
          contactPhoneNumber: "",
        });
      }
    });
    return json;
  };

  const createExcelZipFile = (excelData) => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add each Excel file to the ZIP archive
    excelData.forEach((data, index) => {
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `${data.raName}_${currentDate}.xlsx`;

      // Create a worksheet from the formatted data
      const worksheet = XLSX.utils.json_to_sheet(data.json);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

      // Generate the file as a binary string
      const workbookBinary = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });

      // Convert the binary string to an array buffer
      const buffer = new ArrayBuffer(workbookBinary.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < workbookBinary.length; i++) {
        view[i] = workbookBinary.charCodeAt(i) & 0xff;
      }

      // Add the file to the ZIP archive
      zip.file(fileName, buffer);
    });

    // Generate the ZIP file asynchronously
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create a download link for the ZIP file
      const currentDate = new Date().toISOString().slice(0, 10);
      const zipFileName = `RATeamData_${currentDate}.zip`;
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipFileName);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      document.body.removeChild(link);
    });
  };
  const handleDownloadAllExcel = () => {
    if (selectedRows.length > 0) {
      const excelData = [];
      selectedRows.forEach((company) => {
        const formattedData = convertToJSON(company);
        excelData.push({ json: formattedData, raName: company.raName });
      });
      createExcelZipFile(excelData);
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        const excelData = [];
        companiesListWithIds.forEach((company) => {
          const formattedData = convertToJSON(company);
          excelData.push({ json: formattedData, raName: company.raName });
        });
        createExcelZipFile(excelData);
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = ALLconvertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `AllRATeamSheet_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };

  // Function to convert the data to the same format as the CSV function
  const ALLconvertToJSON = (company) => {
    const headers = [
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    company.forEach((data) => {
      data.companies.forEach((company) => {
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              json.push({
                addedByRaName: data.raName || "",
                companyName: company.companyName || "",
                comment: company.comment || "",
                jobTitle: job.jobTitle || "",
                jobLocation: job.jobLocation || "",
                jobDescription: job.jobDescription || "",
                jobWebsite: job.jobWebsite || "",
                contactFirstName: contact.firstName || "",
                contactLastName: contact.lastName || "",
                contactDesignation: contact.designation || "",
                contactEmail: contact.emailId || "",
                contactPhoneNumber: contact.phoneNumber || "",
              });
            });
          } else {
            json.push({
              addedByRaName: data.raName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: "",
              contactLastName: "",
              contactDesignation: "",
              contactEmail: "",
              contactPhoneNumber: "",
            });
          }
        } else {
          json.push({
            addedByRaName: data.raName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: "",
            jobLocation: "",
            jobDescription: "",
            jobWebsite: "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      });
    });
    return json;
  };

  const handleDownloadAllOneFileXlSX = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownloadExcel(companiesListWithIds);
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleSelectDownloadType = (value) => {
    setSelectDownloadType(value);
  };
  const handleDownload = () => {
    if (selectDownloadType === ".csv") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAll();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFile();
      } else if (clickDownloadType === "actionDownload") {
        handleRowDownload(clickDownloadAction);
      }
    } else if (selectDownloadType === ".xlsx") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAllExcel();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFileXlSX();
      } else if (clickDownloadType === "actionDownload") {
        handleRowDownloadExcel(clickDownloadAction);
      }
    } else if (selectDownloadType === "") {
      enqueueSnackbar(`Please select one atleast one option`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
  const handleCancelDownload = () => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType("");
    setSelectDownloadType("");
    setClickDownloadAction("");
  };

  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

     
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client
      };
   
   
      dispatch(dateSearchRaUsersCompaniesViaApi(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
   
    handleDateChange([today, today.endOf("day")]);
  }, []);


  const [jobDetailsCount, setJobDetailsCount] = useState(0);
  const [jobContactCount, setJobContactCount] = useState(0);
  useEffect(() => {
    let count = 0;
    if (AllCompaniesList) {
      AllCompaniesList.forEach((company) => {
        if (company.jobDetails) {
          company.jobDetails.forEach((job) => {
            if (job.jobLocation && job.jobDescription && job.jobTitle) {
              count++;
            }
          });
        }
      });
    }
    setJobDetailsCount(count);
  }, [AllCompaniesList]);
  useEffect(()=>{
    let count = 0;
    if (AllCompaniesList) {
      AllCompaniesList.forEach((company) => {
        if (company.contactDetails) {
          company.contactDetails.forEach((job) => {
            if (job.firstName && job.designation && job.emailId) {
              count++;
            }
          });
        }
      });
    }
    setJobContactCount(count);
  })
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Downloads"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              mb: 3,
            }}
          >
            <Box flex={1}></Box>

           
           <Box sx={{ mb: 2 }}>
            <CustomDateFilter  handleDateChange={handleDateChange}/>
          </Box>
          </Box>
          {loading?(
            <>
            <ShimmerTable row={5} col={5} />
            </>
          ):(
            <>
            <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Companies"
                value={AllCompaniesList.length}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Avg Companies/RA"
                value={AVG}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Job Details"
                value={
                  jobDetailsCount
                }
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Contact Details"
                value={
                  // AllCompaniesList
                  //   ? AllCompaniesList.reduce((count, company) => {
                  //       return (
                  //         count +
                  //         (company.contactDetails
                  //           ? company.contactDetails.length
                  //           : 0)
                  //       );
                  //     }, 0)
                  //   : 0
                  jobContactCount
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              rows={companiesListWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              checkboxSelection
              disableColumnMenu // Add this line to hide the column menu
              //onCellClick={handleCellClick} // Handle cell click event
              autoHeight
              columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = companiesListWithIds.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
              sx={{
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center !important",
                },
                [`& .MuiDataGrid-checkboxInput`]: {
                  color: theme.palette.primary.main,
                  p: 0,
                  "&.Mui-checked": {
                    color: theme.palette.primary.dark,
                  },
                },
                //Scroller
                [`& .MuiDataGrid-scrollbar`]: {
                  scrollbarWidth: "thin",
                  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                },
                [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                  borderRadius: "10px", // Apply border radius
                },

                [`& .MuiDataGrid-scrollbar:hover`]: {
                  scrollbarColor: `${theme.palette.primary.dark}`,
                },
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAll")}
            >
              Download All
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAllOneSheet")}
            >
              Download All In One Sheet
            </Button>
          </Box>
            </>
          )}
          
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal
        open={downloadModalOpen}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Select your preferred download format
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              color={selectDownloadType === ".xlsx" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".xlsx")}
            >
              .xlsx
            </Button>
            <Button
              variant="contained"
              color={selectDownloadType === ".csv" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".csv")}
            >
              .csv
            </Button>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2 }}>
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleCancelDownload()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleDownload()}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Download;
