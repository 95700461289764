import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  Typography,
} from "@mui/material";

import TableFilterHeader from "../TableFilterHeader";
import theme from "../../Theme";
import { Link } from "react-router-dom";

const CustomTable = ({
  tableColumns = [
    {
      field: "Header",
      headerName: "Header",
      minWidth: 200,
    },
  ],
  tableRows = [],
}) => {
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={{
          mt: 3,

          overflowX: "auto",
          "&::-webkit-scrollbar": {
            //width: "8px", // width of the scrollbar
            height: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.grey[300],
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light, // color of the thumb
            borderRadius: "10px", // roundness of the thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.dark, // color of the thumb on hover
          },
        }}
      >
        <Table aria-label="spanning table" size="small">
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    maxWidth: column.minWidth,
                    minWidth: column.minWidth,
                    width: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {column.renderHeader ? (
                      column.renderHeader({
                        headerName: column.headerName,
                      }) // Pass the header name as params
                    ) : (
                      <Typography
                        noWrap
                        sx={{
                          backgroundColor: "#FFFFFF",
                          fontSize: "15px",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {column.headerName}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length >= 1 ? (
              tableRows.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.field}
                        sx={{
                          maxWidth: column.minWidth,
                          minWidth: column.minWidth,
                          width: "auto",
                        }}
                      >
                        {column.renderCell ? (
                          column.renderCell({
                            value: row[column.field],
                            row,
                          })
                        ) : (
                          column?.clickable ? 
                          <Link component={Button} size="small"
                            state={{ applicant: row }}
                            to={`/applicantDetails/${row.id}`}>
                            <Typography noWrap sx={{ fontSize: 12 }}>
                            {row[column.field]}
                            </Typography>
                          </Link>: 
                          <Typography noWrap sx={{ fontSize: 12 }}>
                            {row[column.field]}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: "center", fontStyle: "italic", p: 2 }}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default CustomTable;
