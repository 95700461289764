import { Box, Button, Divider, Drawer, Grid } from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import ScrollView from "../Helper/ScrollView";
import CustomFormField from "../Common/CustomFormField";

const ApplicantSerchFilterDrawer = 
({ 
    open,close,email,
    setEMail,keyWords,
    setKeyWords,excludeKeyWords,
    setExcludeKeyWords,
    searchJobTitle,
    setSearchJobTitle,
    phoneNumber,
    setPhoneNumber,
    handleSearchInternalDB,
    handleResetSearchFilters,
 }) => {

  return (
    <Drawer
    open={open}
    onClose={close}
    anchor="right"
  >
    <Box
      sx={{
        width: 500,
        height: "100%",
        pl: 2,
        gap: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          pt: 2,
        }}
      >
        <SectionTitle title="Refine your Search" />
        <Box sx={{ flex: 1, overflowY: "auto" }}>
          <ScrollView>
            <Box sx={{ mr: 2, py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Keywords"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: `Keywords`,
                          defaultValue:keyWords,
                          onChange:(value)=>setKeyWords(value)
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Exclude Keywords"}
                    components={[
                      {
                        type: "autoselect",
                        props: {
                          placeholder: `Exclude Keywords`,
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Job Title"}
                    colunm
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: `Job Title`,
                          default:searchJobTitle,
                          onChange:(value)=>setSearchJobTitle(value)
                        },
                      },
                      {
                        type: "checkbox",
                        props: {
                          title: "Recent Job Title",
                          defaultValue: "Recent Job Title",
                          options: ["Recent Job Title"],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                    <CustomFormField
                      title={"Email"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "Email",
                            defaultValue:email,
                            onChange:(value)=>setEMail(value)
                          },
                        },
                      ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomFormField
                      title={"Mobile Number"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "Mobile Number",
                            defaultValue:phoneNumber,
                            onChange:(value)=>setPhoneNumber(value)
                          },
                        },
                      ]}
                    />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Years of Experience"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: `Min`,
                        },
                      },
                      {
                        type: "rtText",
                        props: {
                          text: "to",
                        },
                      },
                      {
                        type: "text",
                        props: {
                          placeholder: `Max`,
                        },
                      },
                      {
                        type: "rtText",
                        props: {
                          text: "/Years",
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Current CTC"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: `Min`,
                        },
                      },
                      {
                        type: "rtText",
                        props: {
                          text: "to",
                        },
                      },
                      {
                        type: "text",
                        props: {
                          placeholder: `Max`,
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Expected CTC"}
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: `Min`,
                        },
                      },
                      {
                        type: "rtText",
                        props: {
                          text: "to",
                        },
                      },
                      {
                        type: "text",
                        props: {
                          placeholder: `Max`,
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Submited On"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Resume Updated On"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Job Referance ID"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Location"}
                    components={[
                      {
                        type: "radio",
                        props: {
                          title: "Search within Radius",
                          options: ["Search within Radius"],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Country"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue: "",
                          options: [
                            "Option - 1",
                            "Option - 2",
                            "Option - 3",
                          ],
                        },
                      },
                    ]}
                  />
                  <Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <CustomFormField
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Zip Code",
                                defaultValue: "",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "With in",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                defaultValue: "",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomFormField
                          components={[
                            {
                              type: "checkbox",

                              props: {
                                title: "Search Radius",
                                required: true,
                                defaultValue: "",
                                options: [
                                  "Search Radius with in the state",
                                ],
                              },
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ScrollView>
        </Box>
      </Box>
      <Box
        sx={{ p: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
      >
        <Button color="inherit" onClick={handleResetSearchFilters}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSearchInternalDB} >Search</Button>
      </Box>
    </Box>
  </Drawer>
  );
};
export default ApplicantSerchFilterDrawer;
