import { Modal, Box, Typography, Alert, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AlertModal = ({ open, message = '', onClose }) => {
    const navigate=useNavigate()
  return (
    <Modal
      open={open}
      onClose={onClose}  // Correct prop to handle closing
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 0.5,
        }}
      >
        <Alert
  severity= {message==="No Email Template is available, kindly create one to send an email!"?"warning":"error"}
  action={
    message==="No Email Template is available, kindly create one to send an email!"?
    <Button variant='contained' color="warning" size="small" onClick={()=>navigate("/emailTemplates")}>
      Create Email Template
    </Button>:''
  }
>
{message}
</Alert>
      </Box>
    </Modal>
  );
};

export default AlertModal;
