// import React, { useEffect, useRef, useState } from "react";

// import {
//   Backdrop,
//   Box,
//   Button,
//   Checkbox,
//   Chip,
//   CircularProgress,
//   Divider,
//   FormControlLabel,
//   Grid,
//   IconButton,
//   Modal,
//   Tab,
//   Typography,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import { CustomFormField, MainBackground, SectionTitle } from "..";
// import theme from "../../Theme";
// import {
//   addJobBoardsHistoryViaApi,
//   clearSearchResults,
//   deleteJobBoardsHistoryViaApi,
//   getIntegrationsViaApi,
//   getJobBoardsHistoryViaApi,
//   getjobBoardsStatusHistoryViaApi,
//   monsterAdvancedResumeSearchViaApi,
//   saveJobBoardsHistoryViaApi,
//   stopLoadingRedux,
// } from "../../store/actions";
// import { useDispatch, useSelector } from "react-redux";
// import Monster from "../JobBoardsResult/Monster";
// import {
//   countries,
//   workAuthorizationOptions,
//   MonsterRadius,
//   optionsMonsterPostFrom,
//   optionsMonsterTo,
//   countryMonsterOptions,
//   MonsterRadiusOptions,
//   MonsterEducationLevel,
//   salaryTypeMonsterOptions,
//   LanguageskillsMonster,
//   ProfessionalLevelMonsterOptions,
//   careerLevelMonster,
//   indusriesMonsterOptions,
//   statesArrayOptionMonster,
// } from "../../Data/JobPostingData";
// import { Parser } from "papaparse";
// import { debounce, size, values } from "lodash";
// import { closeSnackbar, enqueueSnackbar } from "notistack";
// import CloseIcon from "@mui/icons-material/Close";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
// import dayjs from "dayjs";
// import moment from "moment-timezone";
// import { Link, useNavigate } from "react-router-dom";
// import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";
// const ChipSet = ({
//   title,
//   value,
//   color = "default", // default,error,info,primary,secondary,success,warning
// }) => {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
//       <Typography fontSize={13}>{title} :</Typography>
//       <Chip label={value} size="small" color={color} />
//     </Box>
//   );
// };
// const JobBoardCareerBuilder = () => {
//   const dispatch = useDispatch();
//   let navigate = useNavigate();
//   const apiUrl = process.env.REACT_APP_API_URL;
  
//   const JobBoardHistory = useSelector((state) => state.JobBoards.jobBoardsHistory);
// const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
//   ? JobBoardHistory.filter((job) => job.searchType === "Monster"&& job.status === "recent")
//   : [];
//   const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
//   ? JobBoardHistory.filter((job) => job.searchType === "Monster" && job.status === "saved")
//   : [];
//   const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
//   const [showOptions, setShowOptions] = useState(false);
//   const storeData = useSelector((state) => state.Login.storeData);
//   const integrationsList = useSelector((state) => state.Resumes.integrations);
//   const client = storeData.clientDb;
//   const [height, setHeight] = useState("0px");
//   const contentRef = useRef(null);
//   const [loading, setLoading] = useState(false);
//   const toggleOptions = () => {
//     setShowOptions((prev) => !prev);
//   };
  

//   function creditCalculations() {
//     let totalCredits = 0;
//     let availableCredits = 0;
//     let assignedCredits = 0;
//     let usedCredits = 0;

//     if (
//       storeData.userType.includes("SuperAdmin") ||
//       storeData.userType.includes("Developer")
//     ) {
//       if (Array.isArray(integrationsList)) {
//         integrationsList.forEach((accountApi) => {
//           // Assuming accountApi.totalAvailable is defined
//           totalCredits = accountApi.totalAvailable || 0; // Safeguard against undefined

//           if (Array.isArray(accountApi.assignedTo)) {
//             // Calculate allocatedCredits and usedCredits
//             accountApi.assignedTo.forEach((assignedUser) => {
//               assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
//               usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
//             });
//           }
//         });
//       }
//     } else {
//       if (Array.isArray(integrationsList)) {
//         integrationsList.forEach((accountApi) => {
//           if (Array.isArray(accountApi.assignedTo)) {
//             // Calculate allocatedCredits and usedCredits for the specific user
//             accountApi.assignedTo.forEach((assignedUser) => {
//               if (assignedUser.accountId === storeData._id) {
//                 assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
//                 usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
//               }
//             });
//           }
//         });
//       }
//     }

//     const subtractCredit = assignedCredits - usedCredits; // Calculate the difference
//     availableCredits = totalCredits - subtractCredit; // Calculate available credits

//     // Return an object with all calculated values
//     return {
//       totalCredits,
//       availableCredits,
//       assignedCredits,
//       usedCredits,
//     };
//   }

//   const creditData = creditCalculations();
//   const [openTabId, setOpenTabId] = useState("recentHistory");
//   const handleChange = (event, newValue) => {
//     handleResetStates();
//     setOpenTabId(newValue);
//     if(newValue==="recentHistory"){
//       const action={
//         client:client,
//         addedBy:storeData._id,
//         searchType:'Monster',
//         status:'recent'
//       }
//       dispatch(getjobBoardsStatusHistoryViaApi(action))
//     }else {
//       const action={
//         client:client,
//         addedBy:storeData._id,
//         searchType:'Monster',
//         status:'saved'
//       }
//       dispatch(getjobBoardsStatusHistoryViaApi(action))
//     }
//   };

//   const [pageSize, setPageSize] = useState(30);
//   const [page, setPage] = useState(1);
//   useEffect(() => {
//     // Dynamically set the height of the content when options are toggled
//     if (contentRef.current) {
//       setHeight(showOptions ? `${contentRef.current.scrollHeight}px` : "0px");
//     }
//   }, [showOptions]);
//   const [keyWords, setKeyWords] = useState("");
//   const [errorKeyword, setErrorKeyword] = useState("");
//   const [fromDate, setFromDate] = useState(0);
//   const [country, setCountry] = useState(840);
//   const [toDate, setToDate] = useState(43200);
//   const [radius, setRadius] = useState(30);
//   const [selectWithRadius, setSelectWithRadius] = useState(
//     "Search ZIP Code Radius"
//   );
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [optionsCityZip, setOptionCityZip] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectCityZip, setSelectCityZip] = useState("");
//   const [highEducation, setHighEducation] = useState("");
//   const [stateMonsterOptions, setStateMonsterOptions] = useState([]);

//   const [wa, setWa] = useState("");
//   const [twlid, setTwlid] = useState("");
//   const [cn, setcn] = useState("");
//   const [jobTitle, setJobTitle] = useState("");
//   const [jobDescription, setJobDescription] = useState("");
//   const [schoolAttend, setSchoolAttend] = useState("");
//   const [tnsalmin, setTnsalmin] = useState("");
//   const [tnsalmax, setTnsalmax] = useState("");
//   const [tsaltyp, setTsaltyp] = useState("1");
//   const [tsni, setTsni] = useState("");
//   const [clv, setClv] = useState([]);
//   const [Lskill, setLskill] = useState("");
//   const [LProf, setLProf] = useState("");

//   const [tjtft, setTjtft] = useState("");
//   const [tjtpt, setTjtpt] = useState("");
//   const [tjtpd, setTjtpd] = useState("");

//   const [tjtp, setTjtp] = useState("");
//   const [tjti, setTjti] = useState("");
//   const [tjttc, setTjttc] = useState("");
//   const [tjts, setTjts] = useState("");
//   const [twindus, setTwindus] = useState("");

//   const handleSearchMonster = () => {
//     if (!keyWords) {
//       return setErrorKeyword("please type any keyword");
//     }

//     let monsterAccountId = "";
//     if (
//       storeData.userType.includes("SuperAdmin") ||
//       storeData.userType.includes("Developer")
//     ) {
//       if (Array.isArray(integrationsList)) {
//         integrationsList.some(
//           (accountApi) => (monsterAccountId = accountApi._id)
//         );
//       }
//     }
//     if (
//       !Array.isArray(storeData.userType) ||
//       !(
//         storeData.userType.includes("SuperAdmin") ||
//         storeData.userType.includes("Developer")
//       )
//     ) {
//       let access = false;

//       if (Array.isArray(integrationsList)) {
//         access = integrationsList.some(
//           (accountApi) =>
//             Array.isArray(accountApi.assignedTo) &&
//             accountApi.assignedTo.some(
//               (account) =>
//                 account.accountId === storeData._id && account.access,
//               (monsterAccountId = accountApi._id)
//             )
//         );
//       }

//       if (!access) {
//         enqueueSnackbar(
//           `You have no access to Monster search. Please contact the admin.`,
//           {
//             variant: "error",
//             action: (key) => (
//               <IconButton onClick={() => closeSnackbar()} color="inherit">
//                 <CloseIcon />
//               </IconButton>
//             ),
//           }
//         );
//         return "";
//       }
//     }

//     const parts = selectCityZip
//       ? selectCityZip.split("-").map((part) => part.trim())
//       : [];

//     // Assign values based on available parts
//     const inputcity = parts[0] || ""; // First part is city
//     const inputZipCode = parts[1] ? parts[1] : "";
//     // Second part is zip code

//     const action = {
//       sort: "rank,distance,mdate",
//       radius: radius || 30,
//       query: keyWords,
//       mdateminage: fromDate,
//       mdatemaxage: toDate,
//       country: inputZipCode ? "" : country || 840,
//       rlid: selectedStates.length > 0 ? selectedStates.join("+") : 316,
//       zipcode: inputZipCode,
//       rpcr: inputZipCode ? `${inputZipCode}-${radius || 30}` : "",
//       tsni: tnsalmax || tnsalmin ? 0 : tsni ? tsni : "",
//       twlid: twlid ? 1 : "",
//       wa: wa ? 164 - 1 : "",
//       qrcn: cn,
//       qrjt: jobTitle,
//       qrjd: jobDescription,
//       qsn: schoolAttend,
//       tnsalmin: tnsalmin,
//       tnsalmax: tnsalmax,
//       tsaltyp: tsaltyp,
//       tsalcur: tnsalmin || tnsalmax ? 1 : "",
//       clv: clv.length > 0 ? clv.join(",") : [],
//       edulv: highEducation,
//       tjtft: tjtft ? 1 : "",
//       tjtpt: tjtpt ? 1 : "",
//       tjtpd: tjtpd ? 1 : "",
//       tjtp: tjtp ? 1 : "",
//       tjti: tjti ? 1 : "",
//       tjttc: tjttc ? 1 : "",
//       tjts: tjts ? 1 : "",
//       Lskill: `${Lskill}-${LProf}`,
//       twindus: twindus.length > 0 ? twindus : "",
//       page: page,
//       pagesize: pageSize,
//       client: client,
//       monsterAccountId: monsterAccountId,
//     };
//     setLoading(true);
//     dispatch(monsterAdvancedResumeSearchViaApi(action));
//     const historyAction = {
//       query: action,
//       addedBy: storeData._id,
//       addedOn: new Date(),
//       client: client,
//       status: "recent",
//       searchType: "Monster",
//     };
//     dispatch(addJobBoardsHistoryViaApi(historyAction));
//     setTimeout(() => {
     
//       navigate("/jobBoardsResult", { state: { ...action,keyWords:keyWords, searchType: "Monster" } });
//       dispatch(getJobBoardsHistoryViaApi(historyAction));
//     }, 1500);
//   };
  
//   useEffect(() => {
//     if (loadingRedux) {
//       dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
//       setLoading(false);
//       // Set a timer to stop loading after 5 seconds
//       const timer = setTimeout(() => {
//         setLoading(false);
//       }, 1000);
//       // Cleanup timer on unmount
//       return () => clearTimeout(timer);
//     }
//   }, [loadingRedux, dispatch]);
//   useEffect(() => {
//     const action = {
//       client: client,
//     };
//     const historyAction = {
//       query: action,
//       addedBy: storeData._id,
//       addedOn: new Date(),
//       client: client,
//       status: "recent",
//       searchType: "Monster",
//     };
//     setTimeout(() => {
//       // dispatch(getIntegrationsViaApi(action));
//       dispatch(getJobBoardsHistoryViaApi(historyAction));
//     }, 2000);
//   }, []);
//   const handleStateChange = (value) => {
//     setSelectedStates(value); // Ensure value is always a flat array
//   };

//   const handleCallCountriesWithApi = async (value) => {
//     setSelectCityZip("");
//     setSearchTerm(value);
//     if (!value) {
//       setOptionCityZip([]);
//       return;
//     }
//     // Only make the API call if the user has typed more than 2 characters
//     if (value.length < 3) {
//       return;
//     }

//     try {
//       const response = await fetch(
//         `${apiUrl}/countries/getCities `,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             client: "tektree", // Replace with the actual client value if dynamic
//             countryCode: country, // Replace with the actual countryCode value if dynamic
//             city: value,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
//       }

//       const data = await response.json();
//       setOptionCityZip(data);
//       // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
//     } catch (error) {
//       console.error("Error fetching countries:", error);
//     }
//   };

//   const handleSelectCityZip = (value) => {
//     setSearchTerm(value);
//     setSelectCityZip(value);
//   };
//   const handleSelectCountry = async (value) => {
//     setCountry(value);
//     try {
//       const response = await fetch(
//         `${apiUrl}/countries/getStates`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             client: "tektree", // Replace with the actual client value if dynamic
//             countryCode: value, // Replace with the actual countryCode value if dynamic
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
//       }

//       const data = await response.json();
//       setStateMonsterOptions(data);
//       // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
//     } catch (error) {
//       console.error("Error fetching countries:", error);
//     }
//   };
//   const handleSelctOptionRadionsORStates = (value) => {
//     setSelectWithRadius(value);
//     setCountry("");
//     setRadius("");
//     setSelectedStates([]);
//     setSelectCityZip("");
//     setSearchTerm("");
//   };

//   const handleClose = () => "";
//   const formatDateForDisplay = (date) => {
//     const dateInNY = dayjs(date).tz("America/New_York");
//     const isDST = moment.tz(date, "America/New_York").isDST();
//     const timeZoneAbbr = isDST ? "EDT" : "EST";
//     return dateInNY.format(`MM/DD/YY HH:mm:ss`);
//   };

//   const resetConfigs = [
//     { setter: setKeyWords, value: "" },
//     { setter: setErrorKeyword, value: "" },
//     { setter: setFromDate, value: 0 },
//     { setter: setCountry, value: 840 },
//     { setter: setToDate, value: 43200 },
//     { setter: setRadius, value: 30 },
//     { setter: setSelectWithRadius, value: "Search ZIP Code Radius" },
//     { setter: setSelectedStates, value: [] },
//     { setter: setOptionCityZip, value: [] },
//     { setter: setSearchTerm, value: "" },
//     { setter: setSelectCityZip, value: "" },
//     { setter: setHighEducation, value: "" },
//     { setter: setStateMonsterOptions, value: [] },
//     { setter: setWa, value: "" },
//     { setter: setTwlid, value: "" },
//     { setter: setcn, value: "" },
//     { setter: setJobTitle, value: "" },
//     { setter: setJobDescription, value: "" },
//     { setter: setSchoolAttend, value: "" },
//     { setter: setTnsalmin, value: "" },
//     { setter: setTnsalmax, value: "" },
//     { setter: setTsaltyp, value: "1" },
//     { setter: setTsni, value: "" },
//     { setter: setClv, value: [] },
//     { setter: setLskill, value: "" },
//     { setter: setLProf, value: "" },
//     { setter: setTjtft, value: "" },
//     { setter: setTjtpt, value: "" },
//     { setter: setTjtpd, value: "" },
//     { setter: setTjtp, value: "" },
//     { setter: setTjti, value: "" },
//     { setter: setTjttc, value: "" },
//     { setter: setTjts, value: "" },
//     { setter: setTwindus, value: "" },
//   ];
//     const handleResetStates = () => {
//       resetConfigs.forEach(({setter, value}) => { setter(value) });
//     }
//     const handleClickEditHistory = (searchData) => {
//       const QueryData = searchData?.query;
//       let skillNProf = String(QueryData.Lskill).split('-');
//       handleResetStates();
//       console.log({QueryData});
//       const updateStates = { qrjt: setJobTitle, query: setKeyWords, country : setCountry, 
//         radius: setRadius, mdateminage: setFromDate, mdatemaxage: setToDate, country: setCountry, tsni: setTsni, twlid: setTwlid, wa: setWa, qrcn: setcn, clv: setClv,
//         qrjd: setJobDescription, qsn: setSchoolAttend, tnsalmin: setTnsalmin, tnsalmax: setTnsalmax, tsaltyp: setTsaltyp, edulv: setHighEducation, tjtft: setTjtft, 
//         tjtpt: setTjtpt, tjtpd: setTjtpd, tjtp: setTjtp, tjti: setTjti, tjttc: setTjttc, tjts: setTjts, twindus: setTwindus, page: setPage, pagesize: setPageSize};
//         setLskill(skillNProf[0]);
//         setLProf(skillNProf[1]);
//         // rlid: selectedStates.length > 0 ? selectedStates.join("+") : 316,
//         // zipcode: inputZipCode,
//         // rpcr: inputZipCode ? `${inputZipCode}-${radius || 30}` : "",  
//         // tsalcur: tnsalmin || tnsalmax ? 1 : "",
//       Object.entries(updateStates).forEach(([key, setter]) => { setter(QueryData[key]) });
//     };
//   const handleClickHistory = (value) => {
//     const action = value.query;
//     action.country = action.rpcr ? "" : action.country;

//     setLoading(true);
//     dispatch(monsterAdvancedResumeSearchViaApi(action));
//     const historyAction = {
//       id: value._id,
//       query: action,
//       addedBy: storeData._id,
//       addedOn: new Date(),
//       client: client,
//       status: value.status,
//       searchType: "Monster",
//     };
//     dispatch(addJobBoardsHistoryViaApi(historyAction));
//     setTimeout(() => {
//       // dispatch(getIntegrationsViaApi(action));
//       dispatch(getJobBoardsHistoryViaApi(historyAction));
//       navigate("/jobBoardsResult", { state: { ...action,keyWords:action.query, searchType: "Monster" } });
//     }, 2000);
//   };
//   const [addNoteModal, setAddNoteModal] = useState(false);
//   const [historyTitle, setHistoryTitle] = useState("");
//   const [errTitle, setErrTitle] = useState("");
//   const [selectHistory, setSelectHistory] = useState("");
//   const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
//   const handleClickSaveHistory=(value)=>{
//     setHistoryTitle("");
//     setErrTitle("");
//     setSelectHistory("");
//     if (value) {
//       setAddNoteModal(!addNoteModal);
//       setSelectHistory(value);
//     }

//   }
//   const handleClickDeleteHistory=(value)=>{
//     if (value) {
//       setSelectHistory(value);
//       setDeleteHistoryModal(!deleteHistoryModal);
//     }
//   }
//   const handleConfirmHistorySave = () => {
//     setErrTitle("");
//     if (!selectHistory && !historyTitle) {
//       setErrTitle("please type title");
//       return "";
//     }
//     const action = {
//       id: selectHistory._id,
//       client: client,
//       title: historyTitle,
//     };
//     dispatch(saveJobBoardsHistoryViaApi(action));
//     setLoading(true);
//     setAddNoteModal(!addNoteModal);
//     setTimeout(() => {
//       const action = {
//         addedBy: storeData._id,
//         searchType: 'Monster',
//         status: "recent",
//         client: client,
//       };
//       dispatch(getjobBoardsStatusHistoryViaApi(action));
//     }, 2000);
//   };
//   const handleConfirmHistoryDelete = () => {
//     const action = {
//       id: selectHistory._id,
//       client: client,
//     };
//     dispatch(deleteJobBoardsHistoryViaApi(action));
//     setLoading(true);
//     setDeleteHistoryModal(!deleteHistoryModal);
//     setTimeout(() => {
//       const fetchAction = {
//         client: client,
//         addedBy: storeData._id,
//         searchType: 'Monster',
//         status: "saved",
//       };
//       dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
//     }, 2000);
//   };
//   return (
//     <Box>
//       <MainBackground  p={0}>
//       <Grid container>
//       <Grid item xs={8}>
//         <Box
//           sx={{
//             p: 2,
//             bgcolor: theme.palette.primary[100],
//           }}
//         >
//           <Box sx={{ mb: 3 }}>
//             <SectionTitle
//               title="Applicant Search In Monster"
//               size="sm"
//               color="secondary"
//             />
//           </Box>
//           <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
//             <Grid item xs={7}>
//               <CustomFormField
//                 title={"Keyword"}
//                 required
//                 components={[
//                   {
//                     type: "text",
//                     props: {
//                       required: true,
//                       defaultValue: keyWords,
//                       onChange: (value) => setKeyWords(value),
//                       error: !!errorKeyword,
//                       errorMessage: errorKeyword,
//                     },
//                   },
//                 ]}
//               />
//               <Box mt={2} />
//               <CustomFormField
//                 title={"Resume Posted"}
//                 //colunm={true}
//                 components={[
//                   // {
//                   //   type: "rtText",
//                   //   props: {
//                   //     // text: "from",
//                   //   },
//                   // },
//                   {
//                     type: "selectLabel",
//                     props: {
//                       title: "From",
//                       options: optionsMonsterPostFrom,
//                       value: fromDate,
//                       onChange: (value) => setFromDate(value),
//                     },
//                   },
//                   {
//                     type: "rtText",
//                     props: {
//                       text: "-",
//                     },
//                   },
//                   {
//                     type: "selectLabel",
//                     props: {
//                       title: "To",
//                       options: optionsMonsterTo,
//                       value: toDate,
//                       onChange: (value) => setToDate(value),
//                     },
//                   },
//                 ]}
//               />
//             </Grid>
//             <Grid item xs={5}>
//               <CustomFormField
//                 title={"Location (Choose 1):"}
//                 colunm={true}
//                 components={[
//                   {
//                     type: "radio",
//                     props: {
//                       title: "Search ZIP Code Radius",
//                       defaultValue: selectWithRadius,
//                       options: [
//                         "Search ZIP Code Radius",
//                         "Search within Selected Location",
//                       ],
//                       onChange: (value) =>
//                         handleSelctOptionRadionsORStates(value),
//                     },
//                   },
//                   {
//                     type: "selectLabel",

//                     props: {
//                       title: "Select Country",
//                       value: country,
//                       options: countryMonsterOptions,
//                       onChange: (value) => handleSelectCountry(value),
//                     },
//                   },
//                 ]}
//               />
//               <Box>
//                 {selectWithRadius === "Search ZIP Code Radius" && (
//                   <Box id="radius">
//                     <CustomFormField
//                       //title={"Location (Choose 1):"}
//                       //colunm={true}
//                       components={[
//                         {
//                           type: "selectLabel",
//                           props: {
//                             title: "Show jobseekers with in",
//                             value: radius,
//                             options: MonsterRadiusOptions,
//                             onChange: (value) => setRadius(value),
//                           },
//                         },

//                         {
//                           type: "selectLabelMultipleFrom",
//                           props: {
//                             title: "of Zipcode/City",
//                             defaultValue: "",
//                             options: optionsCityZip,
//                             onChangeForm: (value) =>
//                               handleCallCountriesWithApi(value),
//                             searchTerm: searchTerm,
//                             selectCityZip: selectCityZip,
//                             onChange: (value) => handleSelectCityZip(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Box>
//                 )}

//                 {selectWithRadius === "Search within Selected Location" && (
//                   <Box id="selected-location">
//                     <CustomFormField
//                       colunm={true}
//                       components={[
//                         {
//                           type: "selectLabelMultiple",
//                           props: {
//                             title: "Select State",
//                             value: selectedStates, // Should be a flat array
//                             multiple: true,
//                             options: statesArrayOptionMonster, // Your array of states
//                             onChange: handleStateChange, // Use the handler here
//                           },
//                         },
//                       ]}
//                     />
//                   </Box>
//                 )}
//               </Box>
//             </Grid>
//             <Grid item xs={12}>
//               <CustomFormField
//                 colunm={true}
//                 components={[
//                   {
//                     type: "checkbox",
//                     props: {
//                       title: "Search within Radius",
//                       size: "small",
//                       defaultValue: twlid ? "Include Job Seekers Employable in Any of the Selected Locations.": '',
//                       options: [
//                         "Include Job Seekers Employable in Any of the Selected Locations.",
//                       ],
//                       onClick: (value) => setTwlid(value),
//                     },
//                   },
//                   {
//                     type: "checkbox",
//                     props: {
//                       title: "Search within Radius",
//                       size: "small",
//                       defaultValue: wa ? "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations." : '',
//                       options: [
//                         "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.",
//                       ],
//                       onClick: (value) => setWa(value),
//                     },
//                   },
//                 ]}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               <Button
//                 color="secondary"
//                 startIcon={<AddIcon />}
//                 size="small"
//                 onClick={toggleOptions}
//               >
//                 Advanced Options Monster
//               </Button>

//               <Box
//                 sx={{
//                   height,
//                   overflow: "hidden",
//                   transition: "height 0.3s ease-in-out",

//                   mt: 2,
//                 }}
//                 ref={contentRef}
//               >
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <CustomFormField
//                       title={"Resume Details"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Enter Keywords to Search Areas of Job Seekers Resume",
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Most Recent Employer"}
//                       components={[
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "Most Recent Employer",
//                             defaultValue: cn,
//                             onChange: (value) => setcn(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>

//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Most Recent Job Title"}
//                       components={[
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "Most Recent Job Title",
//                             defaultValue: jobTitle,
//                             onChange: (value) => setJobTitle(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Most Recent Description"}
//                       components={[
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "Most Recent Description",
//                             defaultValue: jobDescription,
//                             onChange: (value) => setJobDescription(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"School Attended"}
//                       components={[
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "School Attended",
//                             defaultValue: schoolAttend,
//                             onChange: (value) => setSchoolAttend(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Desired Salary"}
//                       components={[
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Min",
//                           },
//                         },
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "Min",
//                             defaultValue: tnsalmin,
//                             onChange: (value) => setTnsalmin(value),
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Max",
//                           },
//                         },
//                         {
//                           type: "text",
//                           props: {
//                             placeholder: "Max",
//                             defaultValue: tnsalmax,
//                             onChange: (value) => setTnsalmax(value),
//                           },
//                         },
//                         {
//                           type: "selectLabel",
//                           props: {
//                             title: "From",
//                             value: tsaltyp,
//                             options: salaryTypeMonsterOptions,
//                             onChange: (value) => setTsaltyp(value),
//                           },
//                         },
//                       ]}
//                     />
//                     <CustomFormField
//                       //title={"Show Resumes without a Desire Salery"}
//                       components={[
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Show Resumes without a Desired Salary",
//                             options: ["Show Resumes without a Desired Salary"],
//                             size: "small",
//                             defaultValue: tsni ? 'Show Resumes without a Desired Salary':'',
//                             onClick: (value) => setTsni(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>

//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Job Seeker Profile"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "selectLabel",
//                           props: {
//                             title: "Job Seeker Profile",
//                             value: highEducation,
//                             options: MonsterEducationLevel,
//                             onChange: (value) => setHighEducation(value),
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Show Jobseekers with Selected Education Level or Higher",
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"More Profile Options"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "selectLabelMultiple",
//                           multiple: true,
//                           props: {
//                             title: "Career Level",

//                             value: clv?clv:[],
//                             multiple: true,
//                             options: careerLevelMonster,
//                             onChange: (value) => setClv(value),
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Career Level",
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Only show Job seekers with the selected career level",
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Language Proficiency"}
//                       components={[
//                         {
//                           type: "selectLabel",
//                           multiple: true,
//                           props: {
//                             title: "Select a Language",
//                             value: Lskill,
//                             options: LanguageskillsMonster,
//                             onChange: (value) => setLskill(value),
//                           },
//                         },
//                         {
//                           type: "selectLabel",
//                           multiple: true,
//                           props: {
//                             title: "Beginner",
//                             value: LProf,
//                             onChange: (value) => setLProf(value),
//                             options: ProfessionalLevelMonsterOptions,
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Job Status"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "checkbox",
//                           multiple: true,
//                           props: {
//                             title: "Job Seeker Profile",
//                             options: ["Full-Time"],
//                             defaultValue: tjtft ? 'Full-Time': '',
//                             onClick: (value) => setTjtft(value),
//                           },
//                         },
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Seeker Profile",
//                             options: ["Part-Time"],
//                             faultValue: tjtpt ? 'Part-Time': '',
//                             onClick: (value) => setTjtpt(value),
//                           },
//                         },
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Seeker Profile",
//                             options: ["Contract"],
//                             defaultValue: tjtpd ? 'Contract': '',
//                             onClick: (value) => setTjtpd(value),
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Only show Job seekers interested in the selected job status",
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Job Type"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Type",
//                             options: ["Employee"],
//                             defaultValue: tjtp ? 'Employee': '',
//                             onClick: (value) => setTjtp(value),
//                           },
//                         },
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Type",
//                             options: ["Intern"],
//                             defaultValue: tjti ? 'Intern': '',
//                             onClick: (value) => setTjti(value),
//                           },
//                         },
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Type",
//                             options: [
//                               "Temporary/Contract/Permanent or Fulltime",
//                             ],
//                             defaultValue: tjttc ? 'Temporary/Contract/Permanent or Fulltime': '',
//                             onClick: (value) => setTjttc(value),
//                           },
//                         },
//                         {
//                           type: "checkbox",
//                           props: {
//                             title: "Job Type",
//                             options: ["Seasonal"],
//                             defaultValue: tjts ? 'Seasonal': '',
//                             onClick: (value) => setTjts(value),
//                           },
//                         },
//                         {
//                           type: "rtText",
//                           props: {
//                             text: "Only show Job seekers interested in the selected job type",
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                   <Grid item xs={3}>
//                     <CustomFormField
//                       title={"Industries"}
//                       colunm={true}
//                       components={[
//                         {
//                           type: "selectLabelMultiple",
//                           multiple: true,
//                           props: {
//                             title: "Select a Language",
//                             value: twindus,
//                             options: indusriesMonsterOptions,
//                             onChange: (value) => setTwindus(value),
//                           },
//                         },
//                       ]}
//                     />
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Grid>
//             <Grid item xs={15} alignContent={"center"}>
//               <Box display={"flex"} justifyContent={"center"} gap={2}>
//                 <Button
//                   variant="contained"
//                   onClick={() => handleSearchMonster()}
//                 >
//                   Search
//                 </Button>
//                 <Button variant="contained">Reset</Button>
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: 2,
//             justifyContent: "space-between",
//             my: 2,
//           }}
//         >
//           <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
//             {(storeData.userType.includes("SuperAdmin") ||
//               storeData.userType.includes("Developer")) && (
//               <>
//                 <ChipSet
//                   title="Total Credits"
//                   value={creditData?.totalCredits || 0}
//                   color="success"
//                 />
//                 <Divider orientation="vertical" flexItem />
//                 <ChipSet
//                   title="Available Credits"
//                   value={creditData?.availableCredits || 0}
//                   color="warning"
//                 />
//               </>
//             )}

//             <Divider orientation="vertical" flexItem />
//             <ChipSet
//               title="Assigned Credits"
//               value={creditData?.assignedCredits || 0}
//               color="info"
//             />
//             <Divider orientation="vertical" flexItem />
//             <ChipSet
//               title="Used Credits"
//               value={creditData?.usedCredits || 0}
//               color="error"
//             />
//           </Box>
//           <Box></Box>
//         </Box>
//        </Grid>
//         <Grid item xs={4}>
//           <Box
//             sx={{
//               bgcolor: theme.palette.grey[50],
//               px: 3,
//               py: 1,
//               height: "100%",
//             }}
//           >
//             <TabContext value={openTabId}>
//               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//                 <TabList onChange={handleChange} aria-label="ob Boards tabs">
//                   <Tab label="Recent History" value="recentHistory" />
//                   <Tab label="Saved History" value="savedHistory" />
//                 </TabList>
//               </Box>

//               <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
//                 {JobBoardsHistoryRecent.map((search) => (
                  
//                   <SearchHistorySection 
//                   key={search._id} data={`${search.query.query} in ${countryMonsterOptions.find(
//                     (country) => country.value === search.query.country
//                   )?.label}`}  
//                   onClick={() => handleClickHistory(search)} addedOn={search.addedOn} 
//                   onClickSave={()=>handleClickSaveHistory(search)}
//                   status="recent"  
//                   onClickEdit={()=> handleClickEditHistory(search)}
//                    />
//                 ))}
//               </TabPanel>

//               <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
//               {JobBoardsHistorySaved.map((search) => (
                  
//                   <SearchHistorySection key={search._id} data={`${search.query.query} in ${countryMonsterOptions.find(
//                               (country) => country.value === search.query.country
//                             )?.label}`} 
//                   onClick={() => handleClickHistory(search)} 
//                   addedOn={search.addedOn} 
//                   onClickDelete={()=>handleClickDeleteHistory(search)}
//                   status="saved" 
//                   title={search.title}
//                   onClickEdit={()=> handleClickEditHistory(search)}
//                   />
//                 ))}
//               </TabPanel>
//             </TabContext>
//           </Box>
//         </Grid>
//         </Grid>
//       </MainBackground>

//     <Modal
//         open={addNoteModal}
//         aria-labelledby="child-modal-title"
//         aria-describedby="child-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             maxWidth: "400px",
//             width: "80%",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: {
//               xs: 1,
//               md: 2,
//             },
//             borderRadius: 2,
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               gap: 2,
//               flexWrap: "wrap",

//               mb: 3,
//             }}
//           >
//             <SectionTitle title=" Save Search" />
//             <Typography
//               variant="h5"
//               sx={{
//                 fontWeight: "bold",
//                 color: theme.palette.primary.dark,
//                 textAlign: "center",
//               }}
//             >
//               {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
//             </Typography>
//             {/* <Typography fontWeight={"400"}>
//                 from My Companies List?
//               </Typography> */}
//             <CustomFormField
//               title={"Search Title"}
//               required
//               components={[
//                 {
//                   type: "text",
//                   props: {
//                     required: true,
//                     defaultValue: historyTitle,
//                     onChange: (value) => setHistoryTitle(value),
//                     error: !!errTitle,
//                     errorMessage: errTitle,
//                   },
//                 },
//               ]}
//             />
//           </Box>
//           <Divider />
//           <Box
//             sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
//           >
//             <Button
//               color="inherit"
//               sx={{ textTransform: "inherit" }}
//               onClick={() => setAddNoteModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button
//               variant="contained"
//               color="success"
//               sx={{ textTransform: "inherit" }}
//               onClick={() => handleConfirmHistorySave()}
//             >
//               Save
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//       <Modal
//         open={deleteHistoryModal}
//         aria-labelledby="child-modal-title"
//         aria-describedby="child-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             maxWidth: "400px",
//             width: "80%",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: {
//               xs: 1,
//               md: 2,
//             },
//             borderRadius: 2,
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               columnGap: 1,
//               rowGap: 0.5,
//               flexWrap: "wrap",

//               mb: 3,
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Typography fontWeight={"400"}>
//               Are you sure you want to delete
//             </Typography>
//           </Box>
//           <Divider />
//           <Box
//             sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
//           >
//             <Button
//               color="inherit"
//               sx={{ textTransform: "inherit" }}
//               onClick={() => setDeleteHistoryModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button
//               variant="contained"
//               color="error"
//               sx={{ textTransform: "inherit" }}
//               onClick={() => handleConfirmHistoryDelete()}
//             >
//               Delete
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//       <Backdrop
//         sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
//         open={loading}
//         onClick={handleClose}
//       >
//         <CircularProgress color="inherit" size="3rem" />
//       </Backdrop>
//     </Box>
//   );
// };

// export default JobBoardCareerBuilder;







import React, { useEffect, useRef, useState,lazy, Suspense } from "react";

import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomFormField, MainBackground, SectionTitle } from "..";
import theme from "../../Theme";
import {
  addJobBoardsHistoryViaApi,
  clearSearchResults,
  deleteJobBoardsHistoryViaApi,
  getIntegrationsViaApi,
  getJobBoardsHistoryViaApi,
  getjobBoardsStatusHistoryViaApi,
  monsterAdvancedResumeSearchViaApi,
  monsterPowerSearchViaApi,
  saveJobBoardsHistoryViaApi,
  stopLoadingRedux,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  countries,
  workAuthorizationOptions,
  MonsterRadius,
  optionsMonsterPostFrom,
  optionsMonsterTo,
  countryMonsterOptions,
  MonsterRadiusOptions,
  MonsterEducationLevel,
  salaryTypeMonsterOptions,
  LanguageskillsMonster,
  ProfessionalLevelMonsterOptions,
  careerLevelMonster,
  indusriesMonsterOptions,
  statesArrayOptionMonster,
  MonsterPowerSearchRadiusOptions,
  MonsterPowerSearchSalaryRangeOptions,
  MonsterPowerSearchResumeUpdatedOptions,
  MonsterPowerSearchHourlySalaryRangeOptions,
  MonsterPowerSearchTenureOptions,
  MonsterPowerSearchTravelRequirementsOptions,
  MonsterSearchResumeUpdatedOptions,
  optionsValues,
  optionValuescitizen,
  ClearanceLeveloptions,
  workAuthorizeOptions,
  careerLevelOptions,
} from "../../Data/JobPostingData";
import { Parser } from "papaparse";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";

import JobBoardMonsterClassicSearch from "./JobBoardMonsterClassicSearch";
import JobBoardMonsterPowerSearch from "./JobBoardMonsterPowerSearch";
import JobBoardMonsterSearchMonster from "./JobBoardMonsterSearchMonster";
import { GetMonsterAccountInfo } from "../../utils/GetMonsterAccountInfo";
const ChipSet = ({
  title,
  value,
  color = "default", // default,error,info,primary,secondary,success,warning
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <Typography fontSize={13}>{title} :</Typography>
      <Chip label={value} size="small" color={color} />
    </Box>
  );
};
const JobBoardMonster = () => {
  const SearchHistorySection = lazy(() => import("../JobBoardsResult/SearchHistorySection")); // Adjust the path as per your project
  const location = useLocation();
  const tabsearchType = location?.state?.searchType || "1"; // Access tabId from state
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const JobBoardHistory = useSelector(
    (state) => state.JobBoards.jobBoardsHistory
  );
  
    
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [showOptions, setShowOptions] = useState(false);
  const storeData = useSelector((state) => state.Login.storeData);
  const integrationsList = useSelector((state) => state.Resumes.integrations);
  const client = storeData.clientDb;
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  function creditCalculations() {
    let totalCredits = 0;
    let availableCredits = 0;
    let assignedCredits = 0;
    let usedCredits = 0;

    if (
      storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Developer")
    ) {
      if (Array.isArray(integrationsList)) {
        integrationsList.forEach((accountApi) => {
          // Assuming accountApi.totalAvailable is defined
          totalCredits = accountApi.totalAvailable || 0; // Safeguard against undefined

          if (Array.isArray(accountApi.assignedTo)) {
            // Calculate allocatedCredits and usedCredits
            accountApi.assignedTo.forEach((assignedUser) => {
              assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
              usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
            });
          }
        });
      }
    } else {
      if (Array.isArray(integrationsList)) {
        integrationsList.forEach((accountApi) => {
          if (Array.isArray(accountApi.assignedTo)) {
            // Calculate allocatedCredits and usedCredits for the specific user
            accountApi.assignedTo.forEach((assignedUser) => {
              if (assignedUser.accountId === storeData._id) {
                assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
                usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
              }
            });
          }
        });
      }
    }

    const subtractCredit = assignedCredits - usedCredits; // Calculate the difference
    availableCredits = totalCredits - subtractCredit; // Calculate available credits

    // Return an object with all calculated values
    return {
      totalCredits,
      availableCredits,
      assignedCredits,
      usedCredits,
    };
  }

  const creditData = creditCalculations();
  const [openTabId, setOpenTabId] = useState("recentHistory");
  const handleChange = (event, newValue) => {
    handleResetStates();
    setOpenTabId(newValue);
    if (newValue === "recentHistory") {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: activeButton,
        status: "recent",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    } else {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: activeButton,
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }
  };

  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  useEffect(() => {
    // Dynamically set the height of the content when options are toggled
    if (contentRef.current) {
      setHeight(showOptions ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [showOptions]);
  const [keyWords, setKeyWords] = useState("");
  const [errorKeyword, setErrorKeyword] = useState("");

  const [fromDate, setFromDate] = useState(0);
  const [country, setCountry] = useState(840);
  const [toDate, setToDate] = useState(43200);
  const [radius, setRadius] = useState(30);
  const [selectWithRadius, setSelectWithRadius] = useState(
    "Search ZIP Code Radius"
  );
  const [selectedStates, setSelectedStates] = useState([]);
  const [optionsCityZip, setOptionCityZip] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectCityZip, setSelectCityZip] = useState("");
  const [highEducation, setHighEducation] = useState("");
  const [stateMonsterOptions, setStateMonsterOptions] = useState([]);

  const [wa, setWa] = useState("");
  const [twlid, setTwlid] = useState("");
  const [cn, setcn] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [schoolAttend, setSchoolAttend] = useState("");
  const [tnsalmin, setTnsalmin] = useState("");
  const [tnsalmax, setTnsalmax] = useState("");
  const [tsaltyp, setTsaltyp] = useState("1");
  const [tsni, setTsni] = useState("");
  const [clv, setClv] = useState([]);
  const [Lskill, setLskill] = useState("");
  const [LProf, setLProf] = useState("");

  const [tjtft, setTjtft] = useState("");
  const [tjtpt, setTjtpt] = useState("");
  const [tjtpd, setTjtpd] = useState("");

  const [tjtp, setTjtp] = useState("");
  const [tjti, setTjti] = useState("");
  const [tjttc, setTjttc] = useState("");
  const [tjts, setTjts] = useState("");
  const [twindus, setTwindus] = useState("");
 //power Search
 const [powerSearchType,setPowerSearchType]=useState('Semantic')
const [resumeUpdate,setResumeUpdate]=useState(0)
const [minedulvid,setMinedulvid]=useState('')
const [salaryRangeType,setSalaryRangeType]=useState('Per Year')
const [salaryRangeOptions,setSalaryRangeOptions]=useState(MonsterPowerSearchSalaryRangeOptions)
const[salaryRange,setSalaryRange]=useState('')
const [edumjr,setEdumjr]=useState('')
const [wtt,setWtt]=useState('')
const [ten,setTen]=useState('')
const [gsc,setGsc]=useState('')
const [lstat,setLstat]=useState('')
const [relo,setRelo]=useState('')
const [tjtid,setTjtid]=useState('')
const [bkeyword,setBkeyword]=useState('')
const [experience,setExperience]=useState('')
const [jt, setJt] = useState([{ id: 1, value: "" }]);
const [skills, setSkills] = useState([{ id: 1, value: "" }]);
const [locationSearch, setLocationSearch] = useState([{ id: 1, value: "" }]);
const [locationRadius,setLocationRadius]=useState([{ id: 1, value: 50 }]);
const [selectCityState, setSelectCityState] = useState([{ id: 1, value: "" }]);
const [searchCityState,setSearchCityState]=useState([{ id: 1, value: "" }])
//monsterSearch
const [mSearchType,setMSearchType]=useState('Semantic Search')
const [veteran,setVeteran]=useState('')
const [comp,setComp]=useState([{ id: 1, value: "" }]);
const [edumjrMonster,setEdumjrMonster]=useState([{ id: 1, value: "" }]);
const [edumjrTypeMonster,setEdumjrTypeMonster]=useState([{ id: 1, value: "" }]);
const [sch,setSch]=useState([{ id: 1, value: "" }]);
const [cname,setCname]=useState('')
const [clvid,setClvid]=useState('')


 /////////////////////////////////////////////////////////////////
 const [activeButton, setActiveButton] = useState( "MonsterClassicSearch");
 const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
 ? JobBoardHistory.filter(
     (job) => job.searchType === activeButton && job.status === "recent"
   )
 : [];
const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
 ? JobBoardHistory.filter(
     (job) => job.searchType === activeButton && job.status === "saved"
   )
 : [];
 const handleButtonClick = (buttonName) => {
   setActiveButton(buttonName);
   handleResetStates();
   const action = {
    client: client,
    addedBy: storeData._id,
    searchType: buttonName,
    status: "recent",
  };
  dispatch(getjobBoardsStatusHistoryViaApi(action));
   if(buttonName!=='MonsterClassicSearch'){
     setTsni('Include candidates without salary information')
   }
 };
  const handleSearchMonster = () => {
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }
    const {monsterAccountId,totalAvailable} = GetMonsterAccountInfo(storeData, integrationsList, closeSnackbar);
const takeanySelect=(selectCityZip?.trim() || searchTerm?.trim()||'')
    const parts = takeanySelect
      ? takeanySelect.split("-").map((part) => part.trim())
      : [];
    // Assign values based on available parts
    const inputcity = parts[0] || ""; // First part is city
    const inputZipCode = parts[1] ? parts[1] : "";
    // Second part is zip code

    const action = {
      sort: "rank,distance,mdate",
      radius: radius || 30,
      query: keyWords,
      mdateminage: fromDate,
      mdatemaxage: toDate,
      country: inputZipCode ? "" : country || 840,
      rlid: selectedStates.length > 0 ? selectedStates.join("+") : '',
      zipcode: inputZipCode,
      rpcr: inputZipCode ? `${inputZipCode}-${radius || 30}` : "",
      tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
      twlid: twlid ? 1 : "",
      wa: wa ? 164 - 1 : "",
      qrcn: cn,
      qrjt: jobTitle,
      qrjd: jobDescription,
      qsn: schoolAttend,
      tnsalmin: tnsalmin,
      tnsalmax: tnsalmax,
      tsaltyp: tsaltyp,
      tsalcur: tnsalmin || tnsalmax ? 1 : "",
      clv: clv.length > 0 ? clv.join(",") : [],
      edulv: highEducation,
      tjtft: tjtft ? 1 : "",
      tjtpt: tjtpt ? 1 : "",
      tjtpd: tjtpd ? 1 : "",
      tjtp: tjtp ? 1 : "",
      tjti: tjti ? 1 : "",
      tjttc: tjttc ? 1 : "",
      tjts: tjts ? 1 : "",
      Lskill: `${Lskill}-${LProf}`,
      twindus: twindus.length > 0 ? twindus : "",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    setLoading(true);
    console.log('action',action);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      // query: action,
      query: {...action, selectLocation: takeanySelect,selectWithRadius:selectWithRadius,selectedStates:selectedStates},
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: activeButton,
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      navigate("/jobBoardsResult", {
        state: { ...action, keyWords: keyWords,selectCityZip:takeanySelect,searchTerm:takeanySelect, searchType: activeButton },
      });
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 1500);
  };

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  const handleStateChange = (value) => {
    setSelectedStates(value); // Ensure value is always a flat array
  };

  const handleCallCountriesWithApi = async (value) => {
    setSelectCityZip("");
    setSearchTerm(value);
    if (!value) {
      setOptionCityZip([]);
      return;
    }
    // Only make the API call if the user has typed more than 2 characters
    if (value.length < 2) {
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/countries/getCities `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: "tektree", // Replace with the actual client value if dynamic
          countryCode: country, // Replace with the actual countryCode value if dynamic
          city: value,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }
      const data = await response.json();
      setOptionCityZip(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleSelectCityZip = (value) => {
    setSearchTerm(value);
    setSelectCityZip(value);
  };
  const handleSelectCountry = async (value) => {
    setCountry(value);
    try {
      const response = await fetch(`${apiUrl}/countries/getStates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: "tektree", // Replace with the actual client value if dynamic
          countryCode: value, // Replace with the actual countryCode value if dynamic
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }

      const data = await response.json();
      setStateMonsterOptions(data);
      // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const handleSelctOptionRadionsORStates = (value) => {
    setSelectWithRadius(value);
    setCountry("");
    setRadius("");
    setSelectedStates([]);
    setSelectCityZip("");
    setSearchTerm("");
  };

  const handleClose = () => "";
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MM/DD/YY HH:mm:ss`);
  };

  const resetConfigs = [
    { setter: setKeyWords, value: "" },
    { setter: setErrorKeyword, value: "" },
    { setter: setFromDate, value: 0 },
    { setter: setCountry, value: 840 },
    { setter: setToDate, value: 43200 },
    { setter: setRadius, value: 30 },
    { setter: setSelectWithRadius, value: "Search ZIP Code Radius" },
    { setter: setSelectedStates, value: [] },
    { setter: setOptionCityZip, value: [] },
    { setter: setSearchTerm, value: "" },
    { setter: setSelectCityZip, value: "" },
    { setter: setHighEducation, value: "" },
    { setter: setStateMonsterOptions, value: [] },
    { setter: setWa, value: "" },
    { setter: setTwlid, value: "" },
    { setter: setcn, value: "" },
    { setter: setJobTitle, value: "" },
    { setter: setJobDescription, value: "" },
    { setter: setSchoolAttend, value: "" },
    { setter: setTnsalmin, value: "" },
    { setter: setTnsalmax, value: "" },
    { setter: setTsaltyp, value: "1" },
    { setter: setTsni, value: "" },
    { setter: setClv, value: [] },
    { setter: setLskill, value: "" },
    { setter: setLProf, value: "" },
    { setter: setTjtft, value: "" },
    { setter: setTjtpt, value: "" },
    { setter: setTjtpd, value: "" },
    { setter: setTjtp, value: "" },
    { setter: setTjti, value: "" },
    { setter: setTjttc, value: "" },
    { setter: setTjts, value: "" },
    { setter: setTwindus, value: "" },
    { setter: setBkeyword, value: ""},
    { setter: setJt, value: [{ id: 1, value: "" }]},
    { setter: setSkills, value: [{ id: 1, value: "" }]},
    { setter: setExperience, value: ""},
    { setter: setSelectCityState, value: [{ id: 1, value: "" }]},
    { setter: setLocationRadius, value: [{ id: 1, value: 50 }]},
    { setter: setEdumjr, value: "" },
    { setter: setTjtid, value: ""},
    { setter: setResumeUpdate, value: 0},
    { setter: setTen, value: ""},
    { setter: setWtt, value: ""},
    { setter: setGsc, value: ""},
    { setter: setRelo, value: ""},
    { setter: setLstat, value: ""},
    // { setter: setPage, value: 1},
    // { setter: setPageSize, value: 30},
    { setter: setVeteran, value: ''},
    { setter: setClvid, value: ''},
    { setter: setComp, value: [{ id: 1, value: "" }]},
    { setter: setEdumjrMonster, value: [{ id: 1, value: "" }]},
    { setter: setSch, value: [{ id: 1, value: "" }]},
  ];
  const handleResetStates = () => {
    resetConfigs.forEach(({ setter, value }) => {
      setter(value);
    });
  };
  const handleClickEditHistory = (searchData) => {
    const QueryData = searchData?.query;
    handleResetStates();
    let updateStates;

    if(activeButton==="MonsterClassicSearch"){
    let skillNProf = String(QueryData.Lskill).split("-");
    let clvArray = String(QueryData.clv).split(",");
    let statesArray = String(QueryData.rlid).split(",");
    updateStates = {
      qrjt: setJobTitle,
      query: setKeyWords,
      country: setCountry,
      radius: setRadius,
       selectWithRadius:setSelectWithRadius,
       selectedStates:setSelectedStates,
      mdateminage: setFromDate,
      mdatemaxage: setToDate,
      country: setCountry,
      tsni: setTsni,
      twlid: setTwlid,
      mSearchType:setMSearchType,
      wa: setWa,
      qrcn: setcn,
      selectLocation: setSearchTerm,
      qrjd: setJobDescription,
      qsn: setSchoolAttend,
      tnsalmin: setTnsalmin,
      tnsalmax: setTnsalmax,
      tsaltyp: setTsaltyp,
      edulv: setHighEducation,
      tjtft: setTjtft,
      tjtpt: setTjtpt,
      tjtpd: setTjtpd,
      tjtp: setTjtp,
      tjti: setTjti,
      tjttc: setTjttc,
      tjts: setTjts,
      twindus: setTwindus,
      page: setPage,
      pagesize: setPageSize,
    };
    setLskill(skillNProf[0]);
    setLProf(skillNProf[1]);
    setClv(clvArray);
    setSelectedStates(statesArray);
  }
    if (activeButton==="MonsterPowerSearch") {
    updateStates = {bkeyword:setBkeyword,jt:setJt,skills:setSkills,experience:setExperience,
        selectCityState:setSelectCityState,selectCityState:setSearchCityState,locationRadius:setLocationRadius,highEducation:setHighEducation,
        edumjr:setEdumjr,tjtid:setTjtid,resumeUpdate:setResumeUpdate,ten:setTen,wtt:setWtt,tnsalmax:setTnsalmax,
        tsni:setTsni,gsc:setGsc,relo:setRelo,lstat:setLstat,country:setCountry,page:setPage,pagesize:setPageSize,
        }
    }

    if (activeButton==="MonsterSearch") {
      updateStates = {bkeyword:setBkeyword,jobTitle:setJobTitle,keyWords:setKeyWords,selectCityZip:setSelectCityZip,selectCityZip:setSearchTerm,radius:setRadius,relo:setRelo,
        veteran:setVeteran,clvid:setClvid,ten:setTen,experience:setExperience,comp:setComp,edumjrMonster:setEdumjrMonster,sch:setSch,resumeUpdate:setResumeUpdate,
        tjtid:setTjtid,lstat:setLstat,gsc:setGsc,wtt:setWtt,tsni:setTsni,highEducation:setHighEducation,country:setCountry,page:setPage,pagesize:setPageSize,wa:setWa,
        }
        // waData:[{"label":"Show all job seekers","value":""},{"label":"Limit to job seekers authorized to work in this country for any employer","value":"1"},{"label":"Limit to job seekers authorized to work in this country for their present employer only","value":"2"},{"label":"ILimit to job seekers that require sponsorship to work in this country","value":"3"}]
      }
      Object.entries(updateStates).forEach(([key, setter]) => {
        // Set default value for country if empty
        const value = QueryData[key];
        if (key === "country" &&  (!value || String(value).trim() === "")) {
          setter(840);
        }else if(key === "selectWithRadius" &&  (!value || String(value).trim() === "")){
          setSelectWithRadius("Search ZIP Code Radius")
        }else if(key === "mSearchType" &&  (!value || String(value).trim() === "")){
          setMSearchType('Semantic Search')
        }
         else {
          setter(value);
        }
      });
  };
  const handleClickHistory = (value) => {
    if(activeButton==="MonsterClassicSearch"){
    const action = value.query;
    action.country = action.rpcr ? "" : action.country;
    setLoading(true);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      id: value._id,
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: value.status,
      searchType: activeButton,
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      // dispatch(getIntegrationsViaApi(action));
      dispatch(getJobBoardsHistoryViaApi(historyAction));
      navigate("/jobBoardsResult", {
        state: { ...action, keyWords: action.query, searchType: activeButton },
      });
    }, 2000);

  }
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const handleClickSaveHistory = (value) => {
    setHistoryTitle("");
    setErrTitle("");
    setSelectHistory("");
    if (value) {
      setAddNoteModal(!addNoteModal);
      setSelectHistory(value);
    }
  };
  const handleClickDeleteHistory = (value) => {
    if (value) {
      setSelectHistory(value);
      setDeleteHistoryModal(!deleteHistoryModal);
    }
  };
  const handleConfirmHistorySave = () => {
    setErrTitle("");
    if (!selectHistory && !historyTitle) {
      setErrTitle("please type title");
      return "";
    }
    const action = {
      id: selectHistory._id,
      client: client,
      title: historyTitle,
    };
    dispatch(saveJobBoardsHistoryViaApi(action));
    setLoading(true);
    setAddNoteModal(!addNoteModal);
    setTimeout(() => {
      const action = {
        addedBy: storeData._id,
        searchType: activeButton,
        status: "recent",
        client: client,
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }, 2000);
  };
  const handleConfirmHistoryDelete = () => {
    const action = {
      id: selectHistory._id,
      client: client,
    };
    dispatch(deleteJobBoardsHistoryViaApi(action));
    setLoading(true);
    setDeleteHistoryModal(!deleteHistoryModal);
    setTimeout(() => {
      const fetchAction = {
        client: client,
        addedBy: storeData._id,
        searchType: activeButton,
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
    }, 2000);
  };
 
///powerSearch
const combineData = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.map((item, index) => `${item}-${array2[index]}`);
  }
  if (array1.length > array2.length) {
    return array1.map((item, index) => 
      array2[index] ? `${item}-${array2[index]}` : item
    );
  }
  if (array2.length > array1.length) {
    return array2.map((item, index) => 
      array1[index] ? `${array1[index]}-${item}` : item
    );
  }
  return [...array1, ...array2];
};

const handlePowerSearch=()=>{
  const {monsterAccountId,totalAvailable} = GetMonsterAccountInfo(storeData, integrationsList, closeSnackbar);
    const jobTileExtract=jt?.map(item => item.value)
    const skillsExtract=skills?.map(item=>item.value)
const selectCityStateExtract = selectCityState?.map(item => item.value).filter(value => typeof value === 'string' && value.trim() !== '');
const locationRadiusExtract = locationRadius?.map(item => item.value).filter(value => typeof value === 'string' && value.trim() !== '');
    const combinedData = selectCityStateExtract.length > 0
    ? combineData(selectCityStateExtract, locationRadiusExtract)
    : [];
    const matchedValues =tjtid&&tjtid.length>0&&optionsValues
  .filter(option => tjtid.includes(option.label))
  .map(option => option.value)
  const lstatMatches=lstat&&lstat.length>0&&optionValuescitizen
  .filter(option => lstat.includes(option.label))
  .map(option => option.value)
  
  const gscMatches=gsc&&gsc.length>0&&ClearanceLeveloptions
  .filter(option => gsc.includes(option.label))
  .map(option => option.value)
  const action = {
    bkeyword:bkeyword,
    sk:skillsExtract?skillsExtract.map(skill => `${skill} nth`).join(","):'',
    jt:jobTileExtract?jobTileExtract.join(","):'',
    experience:experience,
    location:selectCityStateExtract.length > 0? combinedData.join("+") === '-'? '': combinedData.join("+"): '' ,
    minedulvid:highEducation,
    edumjr:edumjr,
    tjtid:matchedValues.length>0?matchedValues.join(","):'',
    mdatemaxage:resumeUpdate===0?1576800:(1440*resumeUpdate),
    ten:ten||'',
    wtt:wtt?wtt:1,
    relo:relo?true:'',
    lstat:lstatMatches.length>0?lstatMatches.join(","):'',
    tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
    gsc:gscMatches.length>0?gscMatches.join(","):'',
    country:  'US',
    page: page,
    pagesize: pageSize,
    client: client,
    monsterAccountId: monsterAccountId,
  };
  const withoutAction={
    bkeyword:bkeyword,
    jt:jt,
    skills:skills,
    experience:experience,
    selectCityState:selectCityState,
    locationRadius:locationRadius,
    highEducation:highEducation,
    edumjr:edumjr,
    tjtid:tjtid,
    resumeUpdate:resumeUpdate,
    ten:ten,
    wtt:wtt,
    tnsalmax:tnsalmax,
    tsni:tsni,
    gsc:gsc,
    relo:relo,
    lstat:lstat,
    country:  'US',
    page: page,
    pagesize: pageSize,
    client: client,
    monsterAccountId: monsterAccountId,
  }
  setLoading(true);
  dispatch(monsterPowerSearchViaApi(action));
  const historyAction = {
    query: withoutAction,
    addedBy: storeData._id,
    addedOn: new Date(),
    client: client,
    status: "recent",
    searchType: activeButton,
  };
  dispatch(addJobBoardsHistoryViaApi(historyAction));
  setTimeout(() => {
    navigate("/jobBoardsResult", {
      state: { ...withoutAction,  searchType: "MonsterPowerSearch" },
    });
  }, 1500);
}

const handleMonsterSearch=()=>{
  const {monsterAccountId,totalAvailable} = GetMonsterAccountInfo(storeData, integrationsList, closeSnackbar);

  const tenExtract=ten&&MonsterPowerSearchTenureOptions
  .filter(option => ten===(option.label))
  .map(option => option.value)

  const waExtractt = workAuthorizeOptions
  ? workAuthorizeOptions.filter(option => wa === option.label).map(option => option.value)
  : []; // Default to an empty array if workAuthorizeOptions is undefined

const waData = Array.isArray(waExtractt) ? waExtractt.join(",") : "";

const clvidMatches=clvid&&clvid.length>0&&careerLevelOptions
  .filter(option => clvid.includes(option.label))
  .map(option => option.value)

  const compExtract = comp?.map(item => item.value).filter(value => typeof value === 'string' && value.trim() !== '');
  const schExtract = sch?.map(item => item.value).filter(value => typeof value === 'string' && value.trim() !== '');
  const matchedValues =tjtid&&tjtid.length>0&&optionsValues
  .filter(option => tjtid.includes(option.label))
  .map(option => option.value)
  const lstatMatches=lstat&&lstat.length>0&&optionValuescitizen
  .filter(option => lstat.includes(option.label))
  .map(option => option.value)
  const gscMatches=gsc&&gsc.length>0&&ClearanceLeveloptions
  .filter(option => gsc.includes(option.label))
  .map(option => option.value)
  const wttMatches=wtt&&MonsterPowerSearchTravelRequirementsOptions
  .filter(option => wtt===option.label)
  .map(option => option.value)

  const highEducationMatches=highEducation&&MonsterEducationLevel
  .filter(option => highEducation===option.label  )
  .map(option => option.value)
  const edumjrMonsterExtract = edumjrMonster?.map(item => item.value).filter(value => typeof value === 'string' && value.trim() !== '');
  const takeanySelect=(selectCityZip?.trim() || searchTerm?.trim()||'')
  const action = {
    bkeyword:bkeyword,
    jt:jobTitle,
    sk:keyWords ? `${keyWords} nth`.replace(/\s/g, "+") : "",
    location:takeanySelect?`${takeanySelect}-${radius}`:'',
    relo:relo?true:'',
    veteran:veteran?1:'',
    wa:waData?`${164}-${waData}`:'',
    clvid:clvidMatches.length>0?clvidMatches.join(","):'',
    ten:tenExtract.length>0?tenExtract.join(","):'',
    experience:experience,
    comp:compExtract.length>0?compExtract.join(","):'',
    edumjr:edumjrMonsterExtract.length>0?edumjrMonsterExtract.join(","):'',
    sch:schExtract.length>0?schExtract.join(","):'',
    mdatemaxage:resumeUpdate===0?1576800:(1440*resumeUpdate),
    tjtid:matchedValues.length>0?matchedValues.join(","):'',
    lstat:lstatMatches.length>0?lstatMatches.join(","):'',
    gsc:gscMatches.length>0?gscMatches.join(","):'',
    wtt:wtt?wttMatches.join(","):"",
    tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
    minedulvid:highEducation?highEducationMatches.join(","):'',
    country:  'US',
    page: page,
    pagesize: pageSize,
    client: client,
    monsterAccountId,
  }
  const withoutAction={
    mSearchType:mSearchType,
    bkeyword:bkeyword,
    jobTitle:jobTitle,
    keyWords:keyWords,
    selectCityZip:takeanySelect,
    radius:radius,
    relo:relo,
    veteran:veteran,
    waData:workAuthorizeOptions,
    wa:wa,
    clvid:clvid,
    ten:ten,
    experience:experience,
    comp:comp,
    edumjrMonster:edumjrMonster,
    sch:sch,
    resumeUpdate:resumeUpdate,
    tjtid:tjtid,
    lstat:lstat,
    gsc:gsc,
    wtt:wtt,
    tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
    highEducation:highEducation,
    country:'US',
    page: page,
    pagesize: pageSize,
    client: client,
    monsterAccountId,
  }
  setLoading(true);
  dispatch(monsterPowerSearchViaApi(action));
  const historyAction = {
    query: withoutAction,
    addedBy: storeData._id,
    addedOn: new Date(),
    client: client,
    status: "recent",
    searchType: activeButton,
  };
  dispatch(addJobBoardsHistoryViaApi(historyAction));
  setTimeout(() => {
    navigate("/jobBoardsResult", {
      state: { ...withoutAction,searchTerm:takeanySelect, searchType: "MonsterSearch" },
    });
    // dispatch(getJobBoardsHistoryViaApi(historyAction));
  }, 1500);
 }

  /////////////////////////////////////////////////////////////////
  return (
    <Box>
      <MainBackground p={0}>
        <Grid container>
          <Grid item xs={8}>
            <Box
              sx={{
                p: 2,
                bgcolor: theme.palette.primary[100],
              }}
            >
              <Box
                sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}
              >
                <SectionTitle
                  title="Applicant Search In Monster"
                  size="sm"
                  color="secondary"
                />
                <ButtonGroup size="small" aria-label="Small button group">
                  {activeButton !== "MonsterClassicSearch" && (
                    <Button onClick={() => handleButtonClick("MonsterClassicSearch")}>
                      Classic Search
                    </Button>
                  )}
                  {activeButton !== "MonsterPowerSearch" && (
                    <Button onClick={() => handleButtonClick("MonsterPowerSearch")}>
                      Power Search
                    </Button>
                  )}
                  {activeButton !== "MonsterSearch" && (
                    <Button onClick={() => handleButtonClick("MonsterSearch")}>
                      Search Monster
                    </Button>
                  )}
                </ButtonGroup>
              </Box>

              {activeButton === "MonsterClassicSearch" ? (
                <JobBoardMonsterClassicSearch
                keyWords={keyWords} setKeyWords={setKeyWords} errorKeyword={errorKeyword} optionsMonsterPostFrom={optionsMonsterPostFrom}
                fromDate= {fromDate} setFromDate={setFromDate} selectWithRadius={selectWithRadius} handleSelctOptionRadionsORStates={handleSelctOptionRadionsORStates}
                country={country} countryMonsterOptions={countryMonsterOptions} handleSelectCountry={handleSelectCountry} radius={radius}
                MonsterRadiusOptions={MonsterRadiusOptions} setRadius={setRadius} handleCallCountriesWithApi={handleCallCountriesWithApi} searchTerm={searchTerm}
                selectCityZip={selectCityZip} handleSelectCityZip={handleSelectCityZip} selectedStates={selectedStates} statesArrayOptionMonster={statesArrayOptionMonster}
                handleStateChange={handleStateChange} optionsCityZip={optionsCityZip} optionsMonsterTo={optionsMonsterTo} toDate={toDate}
                setToDate={setToDate} twlid={twlid} setTwlid={setTwlid} wa={wa} setWa={setWa} AddIcon={AddIcon} toggleOptions={toggleOptions} height={height}
                contentRef={contentRef} cn={cn} setcn={setcn} jobTitle={jobTitle} setJobTitle={setJobTitle}jobDescription={jobDescription}
                setJobDescription={setJobDescription}schoolAttend={schoolAttend}setSchoolAttend={setSchoolAttend}tnsalmin={tnsalmin}
                setTnsalmin={setTnsalmin} tnsalmax={tnsalmax} setTnsalmax={setTnsalmax} tsaltyp={tsaltyp}
                salaryTypeMonsterOptions={salaryTypeMonsterOptions} setTsaltyp={setTsaltyp} tsni={tsni} setTsni={setTsni}
                highEducation={highEducation} MonsterEducationLevel={MonsterEducationLevel} setHighEducation={setHighEducation}
                clv={clv} careerLevelMonster={careerLevelMonster} setClv={setClv} Lskill={Lskill} LanguageskillsMonster={LanguageskillsMonster}
                setLskill={setLskill} LProf={LProf} setLProf={setLProf} ProfessionalLevelMonsterOptions={ProfessionalLevelMonsterOptions} tjtft={tjtft}
                setTjtft={setTjtft} tjtpt={tjtpt} setTjtpt={setTjtpt} tjtpd={tjtpd} setTjtpd={setTjtpd} tjtp={tjtp} setTjtp={setTjtp} tjti={tjti}
                setTjti={setTjti} tjttc={tjttc} setTjttc={setTjttc} tjts={tjts} setTjts={setTjts} twindus={twindus} indusriesMonsterOptions={indusriesMonsterOptions}
                setTwindus={setTwindus} handleReset={handleResetStates}
                handleSearchMonster={handleSearchMonster}
                />
              ) : activeButton === "MonsterPowerSearch" ? (
                <JobBoardMonsterPowerSearch
                powerSearchType={powerSearchType}
                setPowerSearchType={setPowerSearchType}
                country={country}
                countryMonsterOptions={countryMonsterOptions}
                handleSelectCountry={handleSelectCountry}
                MonsterPowerSearchRadiusOptions={MonsterPowerSearchRadiusOptions}
                radius={radius}
                setRadius={setRadius}
                MonsterPowerSearchSalaryRangeOptions={MonsterPowerSearchSalaryRangeOptions}
                MonsterPowerSearchResumeUpdatedOptions={MonsterPowerSearchResumeUpdatedOptions}
                MonsterPowerSearchHourlySalaryRangeOptions={MonsterPowerSearchHourlySalaryRangeOptions}
                MonsterEducationLevel={MonsterEducationLevel}
                MonsterPowerSearchTenureOptions={MonsterPowerSearchTenureOptions}
                MonsterPowerSearchTravelRequirementsOptions={MonsterPowerSearchTravelRequirementsOptions}
                resumeUpdate={resumeUpdate}
                setResumeUpdate={setResumeUpdate}
                minedulvid={minedulvid}
                setMinedulvid={setMinedulvid}
                salaryRangeOptions={salaryRangeOptions}
                setSalaryRangeOptions={setSalaryRangeOptions}
                salaryRange={salaryRange}
                setSalaryRange={setSalaryRange}
                salaryRangeType={salaryRangeType}setSalaryRangeType={setSalaryRangeType}
                tsni={tsni}setTsni={setTsni}highEducation={highEducation} setHighEducation={setHighEducation}
                edumjr={edumjr} setEdumjr={setEdumjr} setWtt={setWtt} wtt={wtt} ten={ten}   setTen={setTen}
                gsc={gsc} setGsc={setGsc} setLstat={setLstat} lstat={lstat} relo={relo} setRelo={setRelo}
                tjtid={tjtid} setTjtid={setTjtid} bkeyword={bkeyword} setBkeyword={setBkeyword} 
                experience={experience} setExperience={setExperience} jt={jt} setJt={setJt}
                skills={skills} setSkills={setSkills}
                locationSearch={locationSearch} setLocationSearch={setLocationSearch} locationRadius={locationRadius} setLocationRadius={setLocationRadius}
                handlePowerSearch={handlePowerSearch}
                selectCityState={selectCityState} setSelectCityState={setSelectCityState} searchCityState={searchCityState} setSearchCityState={setSearchCityState}
                apiUrl={apiUrl} handleReset={handleResetStates}
                 />
              ) : (
                <JobBoardMonsterSearchMonster
                mSearchType={mSearchType}
                setMSearchType={setMSearchType}
                MonsterPowerSearchRadiusOptions={MonsterPowerSearchRadiusOptions}
                radius={radius}
                setRadius={setRadius}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                bkeyword={bkeyword}
                setBkeyword={setBkeyword}
                keyWords={keyWords}
                setKeyWords={setKeyWords}
                relo={relo}
                setRelo={setRelo}
                setVeteran={setVeteran}
                veteran={veteran}
                MonsterPowerSearchTenureOptions={MonsterPowerSearchTenureOptions}
                ten={ten}
                setTen={setTen} experience={experience} setExperience={setExperience} comp={comp} setComp={setComp}
                edumjrMonster={edumjrMonster} setEdumjrMonster={setEdumjrMonster} edumjrTypeMonster={edumjrTypeMonster}
                setEdumjrTypeMonster={setEdumjrTypeMonster} sch={sch} setSch={setSch} cname={cname} setCname={setCname}
                wa={wa} setWa={setWa} clvid={clvid} setClvid={setClvid} handleMonsterSearch={handleMonsterSearch}
                country={country} setSelectCityZip={setSelectCityZip} setSearchTerm={setSearchTerm} setOptionCityZip={setOptionCityZip}
                searchTerm={searchTerm} selectCityZip={selectCityZip} handleSelectCityZip={handleSelectCityZip} apiUrl={apiUrl}
                optionsCityZip={optionsCityZip} MonsterSearchResumeUpdatedOptions={MonsterSearchResumeUpdatedOptions}
                resumeUpdate={resumeUpdate} setResumeUpdate={setResumeUpdate} setTjtid={setTjtid} tjtid={tjtid} lstat={lstat}
                setLstat={setLstat} setGsc={setGsc} gsc={gsc} setMinedulvid={setMinedulvid} setSalaryRangeType={setSalaryRangeType}
                setSalaryRangeOptions={setSalaryRangeOptions} MonsterPowerSearchHourlySalaryRangeOptions={MonsterPowerSearchHourlySalaryRangeOptions}
                MonsterPowerSearchSalaryRangeOptions={MonsterPowerSearchSalaryRangeOptions} salaryRangeType={salaryRangeType} setSalaryRange={setSalaryRange}
                salaryRange={salaryRange} salaryRangeOptions={salaryRangeOptions} tsni={tsni} setTsni={setTsni} wtt={wtt} setWtt={setWtt}
                highEducation={highEducation} setHighEducation={setHighEducation} handleReset={handleResetStates}
                 /> 
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "space-between",
                my: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {(storeData.userType.includes("SuperAdmin") ||
                  storeData.userType.includes("Developer")) && (
                  <>
                    <ChipSet
                      title="Total Credits"
                      value={creditData?.totalCredits || 0}
                      color="success"
                    />
                    <Divider orientation="vertical" flexItem />
                    <ChipSet
                      title="Available Credits"
                      value={creditData?.availableCredits || 0}
                      color="warning"
                    />
                  </>
                )}

                <Divider orientation="vertical" flexItem />
                <ChipSet
                  title="Assigned Credits"
                  value={creditData?.assignedCredits || 0}
                  color="info"
                />
                <Divider orientation="vertical" flexItem />
                <ChipSet
                  title="Used Credits"
                  value={creditData?.usedCredits || 0}
                  color="error"
                />
              </Box>
              <Box></Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: theme.palette.grey[50],
                px: 3,
                py: 1,
                height: "100%",
              }}
            >
              <TabContext value={openTabId}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="ob Boards tabs">
                    <Tab label="Recent History" value="recentHistory" />
                    <Tab label="Saved History" value="savedHistory" />
                  </TabList>
                </Box>
                <Suspense fallback={<div>Loading Recent History...</div>}>
                <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistoryRecent.map((search) => (
                    
                    <SearchHistorySection
                      key={search._id}
                      data = {
                        activeButton === "MonsterClassicSearch"
                          ? `${search.query.query} in ${
                              countryMonsterOptions.find(
                                (country) => country.value === search.query.country
                              )?.label || "United States"
                            }`
                          : activeButton === "MonsterPowerSearch"
                          ? [
                              ...search.query?.jt?.filter((jTitle) => jTitle.value).map((j) => j.value),
                              ...search.query?.skills?.filter((skill) => skill.value).map((s) => s.value),
                              search.query?.bkeyword
                            ]
                              .filter(Boolean) // Filter out empty values
                              .join(", ")
                          : `${search.query.jobTitle} ${search.query.keyWords}`
                      }
                      
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickSave={() => handleClickSaveHistory(search)}
                      status="recent"
                      onClickEdit={() => handleClickEditHistory(search)}
                    />
                  ))}
                </TabPanel>
                  </Suspense>
                  <Suspense fallback={<div>Loading Saved History...</div>}>
                <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistorySaved.map((search) => (
                    
                    <SearchHistorySection
                      key={search._id}
                      data = {
                        activeButton === "MonsterClassicSearch"
                          ? `${search.query.query} in ${
                              countryMonsterOptions.find(
                                (country) => country.value === search.query.country
                              )?.label || "United States"
                            }`
                          : activeButton === "MonsterPowerSearch"
                          ? [
                              ...search.query?.jt?.filter((jTitle) => jTitle.value).map((j) => j.value),
                              ...search.query?.skills?.filter((skill) => skill.value).map((s) => s.value),
                              search.query?.bkeyword
                            ]
                              .filter(Boolean) // Filter out empty values
                              .join(", ")
                          : `${search.query.jobTitle} ${search.query.keyWords}`
                      }
                      
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickDelete={() => handleClickDeleteHistory(search)}
                      status="saved"
                      title={search.title}
                      onClickEdit={() => handleClickEditHistory(search)}
                    />
                  ))}
                </TabPanel>
                </Suspense>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </MainBackground>

      <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
              {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
            </Typography>
            {/* <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography> */}
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
    </Box>
  );
};

export default JobBoardMonster;
