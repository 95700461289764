// import { Box, Button, Divider, Drawer, Typography } from '@mui/material'
// import React from 'react'
// import FormatDateTime from '../Helper/FormatDateTime'
// import CustomFormField from '../Common/CustomFormField'
// import SectionTitle from '../Helper/SectionTitle'
// import theme from '../../Theme'
// import ScrollView from '../Helper/ScrollView'
// import MainBackground from '../Backgrounds/MainBackground'

//  const JobBoardsAddNotes = ({open,close,allUsers,notes=[],note,handleNote,handleNoteSubmit}) => {
//   return (
//     <Drawer
//     open={open}
//     onClose={close}
//     anchor="right"
//   >
//     <Box
//       sx={{
//         width: 500,
//         display: "flex",
//         flexDirection: "column",
//         gap: 2,
//         flex: 1,
//       }}
//     >
//       <Box p={2}>
//         <SectionTitle title="Add New Note" size="small" color="error" />
//         <Box mt={2} />
//         {/* Notes for Job Code: {selectedJodCode} */}
//         <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
//           <CustomFormField
//             //title={"Note"}
//             components={[
//               {
//                 type: "textArea",
//                 props: {
//                   defaultValue: note,
//                   placeholder: "Enter your Note",
//                   onChange:(value)=>handleNote(value),
//                 },
//               },
//             ]}
//           />
//           <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
//             <Button
//               variant="text"
//               size="small"
//               color="error"
//               onClick={close}
//             >
//               Cancel
//             </Button>
//             <Button variant="outlined" size="small" disabled={!note?.trim()}  onClick={handleNoteSubmit}>
//               Add Note
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//       <Box
//         sx={{
//           flex: 1,
//           display: "flex",
//           flexDirection: "column",
//           gap: 1,
//           pb: 2,
//           pl: 2,
//         }}
//       >
//         <Box>
//           <Typography
//             sx={{
//               fontSize: 12,
//               letterSpacing: 0.2,
//               color: theme.palette.primary.dark,
//             }}
//           >
//             Total Notes: {notes?.length ?? 0}
//           </Typography>
//         </Box>
//         <Box flex={1}>
//           <ScrollView>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 2,
//                 mr: 2,
//               }}
//             >
//               {/* {Array.from({ length: 15 }, (_, index) => ( */}
//                  {notes.map(
//                   (noteItem, index) => (
//                 <MainBackground key={index}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       gap: 1,
//                     }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 13,
//                         letterSpacing: 0.2,
//                         color: theme.palette.grey[800],
//                       }}
//                     >
//                       Note: {noteItem.note}
//                     </Typography>
//                     <Divider sx={{ py: 0.5 }} />
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         gap: 0.5,
//                         flexWrap: "wrap",
//                       }}
//                     >
//                       <Typography
//                         sx={{
//                           fontSize: 11,
//                           letterSpacing: 0.2,
//                           color: theme.palette.grey[500],
//                         }}
//                       >
//                         User Name -  {allUsers.find((user) => user._id === noteItem.addedBy)
//                   ?.userSudoFirstName || "Unknown"}
//                       </Typography>

//                       <Typography
//                         sx={{
//                           fontSize: 11,
//                           letterSpacing: 0.2,
//                           color: theme.palette.grey[500],
//                         }}
//                       >
//                         {FormatDateTime(noteItem.addedOn, "full")}
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </MainBackground>
//               ))}
//             </Box>
//           </ScrollView>
//         </Box>
//       </Box>
//     </Box>
//   </Drawer>
//   )
// }
// export default JobBoardsAddNotes

import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import CustomFormField from "../Common/CustomFormField";
import FormatDateTime from "../Helper/FormatDateTime";
import ScrollView from "../Helper/ScrollView";
import theme from "../../Theme";
import { useSelector } from "react-redux";

const dummyDataComments = [
  {
    id: 1,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },

  {
    id: 2,
    note: "Submission received for React Developer position.",
    category: "submissionNote",
    onDate: "2024-07-01T15:30:00.000Z",
  },
  {
    id: 3,
    note: "Candidate moved to the shortlisting stage.",
    category: "pipelineNote",
    onDate: "2024-07-02T12:45:00.000Z",
  },
  {
    id: 4,
    note: "Applicant is available for interviews next week.",
    category: "applicantNote",
    onDate: "2024-07-03T10:20:00.000Z",
  },
  {
    id: 5,
    note: "Pipeline updated with candidate's recent certifications.",
    category: "pipelineNote",
    onDate: "2024-07-04T11:15:00.000Z",
  },
  {
    id: 6,
    note: "Submission approved by hiring manager.",
    category: "submissionNote",
    onDate: "2024-07-05T13:00:00.000Z",
  },
  {
    id: 7,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },
  {
    id: 8,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },
  {
    id: 9,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },
  {
    id: 10,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },
  {
    id: 11,
    note: "Candidate has extensive experience in web development.",
    category: "applicantNote",
    onDate: "2024-06-30T09:00:00.000Z",
  },
];

const formatCategoryName = (category) => {
  switch (category) {
    case "applicantNote":
      return "Applicant Notes";
    case "submissionNote":
      return "Submission Notes";
    case "pipelineNote":
      return "Pipeline Notes";
    case "jobPostingNote":
      return "Job Posting Notes";
    case "applicantReference":
      return "Applicant Reference";
    default:
      return category; // Default case if no match
  }
};

const AddNoteJobBoards = ({
  open,
  close,
  noteActionTexts,
  jobPostingOptions,
  jobPostReference,
  setJobPostReference,
  noteAction,
  setNoteAction,
  notePriority,
  setNotePriory,
  note,
  handleNote,
  handleNoteSubmit,
  setNote,
  notes = [],
  showNotes,
  dotShowReference,
          
  pageProps=''
}) => {
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const quillRef = useRef(null); // Initialize quillRef
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  let filterNoteNormal = notes.filter(
    (note) => note.source !== "submission" && note.source !== "pipeline"
  );

  filterNoteNormal = filterNoteNormal.map((note) => ({
    ...note, // Spread the properties of the original note
    category: "applicantNote", // Add or overwrite the category field
  }));
   if(pageProps==="JobPosting"){
   const dataFilterNoteNormal = notes.filter(
      (note) => note.source !== "submission" && note.source !== "pipeline"&&note.source!=="applicantReference"
    );
    filterNoteNormal = dataFilterNoteNormal.map((note) => ({
      ...note, // Spread the properties of the original note
      category: "jobPostingNote", // Add or overwrite the category field
    }));
    let filterAppliacntRefere=notes.filter((note)=>note.source==="applicantReference")
    filterAppliacntRefere= filterAppliacntRefere.map((note)=>({
      ...note,
      category:'applicantReference'
    }));
    filterNoteNormal=[...filterNoteNormal,...filterAppliacntRefere]
   }
  const uniqueCategories = [
    ...new Set(filterNoteNormal.map((item) => item.category)),
  ];
  const removeHtmlTags = (htmlString) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    // Set the HTML content
    tempDiv.innerHTML = htmlString;
    // Return the text content of the element
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  return (
    <Drawer open={open} anchor="right">
      <Box
        sx={{
          width: 700,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
          }}
        >
          <SectionTitle
            title="Add New Note"
            size="small"
            color="error"
          />
          <Box>
            <Grid container spacing={2}>
              {!dotShowReference && (
                <Grid item xs={6}>
                  <CustomFormField
                    title={"Job Posting Referance"}
                    components={[
                      {
                        type: "autocomplete",
                        props: {
                          title: "Job Posting Referance",
                          defaultValue: jobPostReference,
                          options: jobPostingOptions,
                          onChange: (value) => setJobPostReference(value),
                        },
                      },
                    ]}
                  />
                </Grid>
              )}

              {/* <Grid item xs={6}>
                <CustomFormField
                  title={"Action"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Action",
                        defaultValue: noteAction,
                        options: noteActionTexts,
                        onChange:(value)=>setNoteAction(value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <CustomFormField
                  title={"Notify thse People"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Notify thse People",
                        defaultValue: "",
                        options: ["Option - 1", "Option - 2", "Option - 3"],
                      },
                    },
                  ]}
                />
              </Grid> */}
              {pageProps!=='JobPosting'&&(
                <Grid item xs={6}>
                <CustomFormField
                  title={"Notes Priority"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Notes Priority",
                        defaultValue: notePriority,
                        options: ["Critical", "High", "Medium", "Low"],
                        onChange: (value) => setNotePriory(value),
                      },
                    },
                  ]}
                />
              </Grid>
              )}
              

              <Grid item xs={12}>
                <CustomFormField
                  title={"Notes"}
                  components={[
                    {
                      type: "textEditor",
                      props: {
                        placeholder: "Add Notes",
                        defaultValue: note,
                        quillRef: quillRef,
                        onChange: (value) => setNote(value),
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button color="inherit" onClick={()=>close()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleNoteSubmit}>
              Add Note
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            px: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            pb: 0.2,
            pt: 2,
            bgcolor: theme.palette.grey[50],
          }}
        >
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {uniqueCategories.map((category, index) => (
                <Tab
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" textTransform={"initial"}>
                        {formatCategoryName(category)}
                      </Typography>
                      <Chip
                        size="small"
                        label={
                          filterNoteNormal.filter(
                            (note) => note.category === category
                          ).length
                        }
                        sx={{ ml: 1 }} // Optional: Adds some space between the category and the chip
                      />
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </Box>
          <Box flex={1}>
            <ScrollView>
              {uniqueCategories.map(
                (category, index) =>
                  activeTab === index && (
                    <Box key={category}>
                      {filterNoteNormal
                        .filter((note) => note.category === category)
                        .map((note) => (
                          <>
                            <Box
                              key={note.id}
                              sx={{
                                py: 2,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                }}
                              >
                                {removeHtmlTags(note?.note)}
                              </Typography>
                                <Box sx={{display:"flex",pt:1, justifyContent:"space-between"}}>
                              <Typography  sx={{
                                  fontSize: 12,
                                  color: theme.palette.grey[700],
                                }}>
                                Added by:{" "}
                                {allUsers.find(
                                  (user) => user._id === note.addedBy
                                )?.userSudoFirstName || "Unknown"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: theme.palette.grey[400],
                                }}
                              >
                                {FormatDateTime(note.addedOn, "full")}
                              </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </>
                        ))}
                    </Box>
                  )
              )}
            </ScrollView>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddNoteJobBoards;
