import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Chip, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, Typography } from '@mui/material';
import applicantStatusData from "../StatusElements/applicantStatus";
import { useDispatch, useSelector } from 'react-redux';
import { changeApplicantStatusViaApi, searchDateJobPostingDataViaApi, stopLoadingRedux } from '../../store/actions';


const ApplicantJobStatus = ({open, onClose, handleModalAssignApplicantStatusCancel, assignJobPostId, setAssignJobPostId, selectedApplicant, setSelectedApplicant}) => {
  const dispatch = useDispatch();
  const { applicantStatus } = applicantStatusData;
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const { storeData } = useSelector((state) => ({ storeData: state.Login.storeData }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  const handleSaveApplicantStatus = () => {
    const action = {
      client: storeData.clientDb,
      addedBy: storeData._id,
      addedOn: new Date(),
      newStatus: selectedApplicant.status,
      jobId: assignJobPostId,
      applicantId: selectedApplicant.id,
    };
    dispatch(changeApplicantStatusViaApi(action))
    onClose();
    setAssignJobPostId("");
    setLoading(true);
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const handleModalCancel = () => {
    handleModalAssignApplicantStatusCancel();
    setAssignJobPostId("");
  }

  return (<>
    <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="applicant-job-status"
        aria-describedby="applicant-job-status-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to change a Applicant status?
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="job-status-label">Applicant Status</InputLabel>

              <Select
                labelId="applicant-job-status-select-label"
                id="applicant-job-status-select"
                value={selectedApplicant.status} // This holds the single selected status
                onChange={(e) => setSelectedApplicant(prev => ({...prev, status: e.target.value}))} // Function to handle the change
                input={<OutlinedInput label="Applicant Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selected} />
                  </Box>
                )}
              >
                {applicantStatus.map((status, id) => {
                  return (
                    <MenuItem key={id} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={handleModalCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={handleSaveApplicantStatus}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
    open={loading}
  >
    <CircularProgress color="inherit" size="3rem" />
  </Backdrop>
  </>
  )
}

export default ApplicantJobStatus;