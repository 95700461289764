import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Collapse, Stack } from '@mui/material';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { useDispatch, useSelector } from 'react-redux';
import { BUILD_VERSION } from '../../configs/buildVersion';
import { useLocation } from 'react-router-dom';
import { fetchLatestVersionViaApi } from '../../store/actions';

const UpdateVersionAlert = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {versionNum, featuresList} = useSelector((state) => (state.BuildVersion));
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (versionNum != null &&
          versionNum !== '' && 
          BUILD_VERSION !== versionNum) {
          setShowAlert(true);
        }
        else { setShowAlert(false); }
    }, [versionNum]);
    
    const handleRefresh = () => {
      setLoading(true);
      dispatch(fetchLatestVersionViaApi());
      window.location.reload(true);
    };


  return (
    <Collapse orientation='vertical' in={location.pathname !== '/login' && showAlert}>
        <Alert variant="standard" severity={loading? 'info':"error"} sx={{alignItems:'center', fontWeight: featuresList.length > 0 ? 400 : 600}} icon={<SystemUpdateAltOutlinedIcon fontSize="inherit" color="inherit" />} action={
            <Stack direction={'row'} columnGap={2}>
              <Button color={loading?'secondary':"success"} variant="contained" size="small" children={loading ? 'Refreshing ...':'Refresh Now'} onClick={handleRefresh} />
            </Stack>
          } >
        {featuresList.length > 0 ? <Accordion sx={{background:'inherit', maxWidth: '50vw'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{background:'inherit', fontWeight: 600}}
        >
          ALERT - ATS application has a new update, refresh now to reflect changes.
        </AccordionSummary>
        <AccordionDetails id='panel1-content' sx={{maxHeight: '20vh', overflowY: 'scroll', scrollbarWidth: 'none'}}>
          {featuresList.map((list, index) => <li key={index}>{list}</li>)}
        </AccordionDetails>
        </Accordion> : 
          `ALERT - ATS application has a new update, refresh now to reflect changes.`
          }
        </Alert>
        </Collapse>
  )
}

export default UpdateVersionAlert;