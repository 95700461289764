// import {
//   Autocomplete,
//   Box,
//   Button,
//   Checkbox,
//   Chip,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   FormHelperText,
//   Grid,
//   InputLabel,
//   List,
//   ListItem,
//   ListItemText,
//   Menu,
//   MenuItem,
//   OutlinedInput,
//   Radio,
//   RadioGroup,
//   Select,
//   Switch,
//   TextField,
//   TextareaAutosize,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useRef, useState } from "react";
// import SectionTitle from "../Helper/SectionTitle";
// import HelpIcon from "@mui/icons-material/Help";
// import theme from "../../Theme";
// import { DatePicker } from "@mui/x-date-pickers";
// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import dayjs from "dayjs";

// const DefineStyle = {
//   fontSize: 14,
//   bgColor: "white",
//   //bgColor: `linear-gradient(0deg, rgba(${theme.palette.primary.default},.07) 0%, rgba(${theme.palette.primary.default},0.02) 100%)`,
//   boderColor: `rgba(${theme.palette.primary.default},.2)`,
//   textColor: theme.palette.text.primary,
//   placeholderColor: theme.palette.grey[900],
// };

// const FormSectionTitle = ({ title, info, required }) => {
//   return (
//     <React.Fragment>
//       <Box sx={{ display: "flex" }}>
//         <Typography
//           noWrap
//           sx={{
//             fontSize: 14,
//             fontWeight: 300,
//             lineHeight: 1.2,
//             letterSpacing: 0.5,
//             color: theme.palette.text.primary,
//           }}
//         >
//           {title}
//         </Typography>
//         {required && (
//           <Typography
//             noWrap
//             sx={{
//               fontSize: 12,
//               lineHeight: 0.8,
//               mx: 0.2,
//               color: theme.palette.error.dark,
//             }}
//           >
//             *
//           </Typography>
//         )}
//         {info && (
//           <Tooltip title={info} arrow placement="top">
//             <HelpIcon
//               sx={{ color: "warning.main", fontSize: "12px", mx: 0.5 }}
//             />
//           </Tooltip>
//         )}
//       </Box>
//     </React.Fragment>
//   );
// };

// const RtText = ({ text }) => {
//   return (
//     <React.Fragment>
//       <Typography sx={{ fontSize: 13, color: theme.palette.grey[400] }}>
//         {text}
//       </Typography>
//     </React.Fragment>
//   );
// };

// const CustomText = ({
//   placeholder,
//   defaultValue,
//   readOnly = false,
//   multiline = false,
//   onChange, // Generic onChange handle
//   disabled,
//   error = false, // Error state,
//   errorMessage = "", // Error message to display
//   type = "text",
// }) => {
//   const handleChangeText = (event) => {
//     if (onChange) {
//       onChange(event.target.value);
//     }
//   };

//   return (
//     <React.Fragment>
//       <TextField
//         placeholder={placeholder}
//         multiline={multiline}
//         rows={multiline && 3}
//         size="small"
//         fullWidth
//         type={type}
//         value={defaultValue}
//         InputProps={{
//           readOnly: readOnly,
//           sx: {
//             fontSize: DefineStyle.fontSize,
//             color: DefineStyle.textColor,
//             py: 0.5,
//           },
//         }}
//         sx={{
//           background: DefineStyle.bgColor,
//           "& .MuiInputBase-input::placeholder": {
//             color: DefineStyle.placeholderColor,
//             fontSize: DefineStyle.fontSize,
//           },
//           "& .MuiOutlinedInput-root": {
//             "& fieldset": {
//               borderColor: DefineStyle.boderColor, // Border color for the fieldset
//             },
//           },
//         }}
//         disabled={disabled}
//         onChange={handleChangeText}
//         error={error} // Highlight field if error
//         helperText={error ? errorMessage : ""} // Display error message
//       />
//     </React.Fragment>
//   );
// };

// const CustomTextArea = ({
//   placeholder,
//   defaultValue,
//   readOnly = false,
//   multiline = false,
//   onChange, // Generic onChange handle
//   disabled,
//   error = false, // Error state,
//   errorMessage = "", // Error message to display
//   type = "text",
//   onEnter,
// }) => {
//   const handleChangeText = (event) => {
//     if (onChange) {
//       onChange(event.target.value);
//     }
//   };
//   // const handleKeyPress = (event) => {
//   //   if (event.key === 'Enter') {
//   //     alert(event.target.value); // Alert the current value
//   //   }
//   // };
//   return (
//     <React.Fragment>
//       <TextField
//         placeholder={placeholder}
//         multiline
//         rows={4}
//         size="small"
//         fullWidth
//         type={type}
//         value={defaultValue}
//         InputProps={{
//           readOnly: readOnly,
//           sx: {
//             fontSize: DefineStyle.fontSize,
//             color: DefineStyle.textColor,
//             py: 0.5,
//           },
//         }}
//         sx={{
//           background: DefineStyle.bgColor,
//           "& .MuiInputBase-input::placeholder": {
//             color: DefineStyle.placeholderColor,
//             fontSize: DefineStyle.fontSize,
//           },
//           "& .MuiOutlinedInput-root": {
//             "& fieldset": {
//               borderColor: DefineStyle.boderColor, // Border color for the fieldset
//             },
//           },
//         }}
//         disabled={disabled}
//         onChange={handleChangeText}
//         // onKeyPress={handleKeyPress}
//         on
//         error={error} // Highlight field if error
//         helperText={error ? errorMessage : ""} // Display error message
//       />
//     </React.Fragment>
//   );
// };

// const CustomAutoComplete = ({
//   placeholder,
//   options = [],
//   defaultValue,
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const handleChange = (event, value) => {
//     // Pass only necessary data to the onChange callback
//     if (onChange) {
//       onChange(value);
//     }
//   };
//   return (
//     <React.Fragment>
//       <Autocomplete
//         disablePortal
//         freeSolo
//         id="combo-box-demo"
//         options={options}
//         value={defaultValue}
//         onChange={handleChange}
//         onInputChange={(event, newInputValue) => {
//           // If you need to handle input changes separately, you can use this
//           handleChange(event, newInputValue);
//         }}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             placeholder={placeholder}
//             size="small"
//             fullWidth
//             error={error}
//             helperText={error ? errorMessage : ""}
//             sx={{
//               background: DefineStyle.bgColor,
//               "& .MuiInputBase-input::placeholder": {
//                 color: DefineStyle.placeholderColor,
//                 fontSize: DefineStyle.fontSize,
//               },
//               // "& .MuiOutlinedInput-root": {
//               //   p: "3px",
//               //   "& fieldset": {
//               //     borderColor: DefineStyle.boderColor, // Border color for the fieldset
//               //   },
//               // },
//             }}
//           />
//         )}
//       />
//     </React.Fragment>
//   );
// };
// const CustomAutoSelect = ({
//   placeholder,
//   defaultValue,
//   readOnly = false,
//   multiline = false,
//   onChange, // Generic onChange handle
//   disabled,
//   error = false, // Error state,
//   errorMessage = "", // Error message to display
//   type = "text",
// }) => {
//   const handleChange = (event, newValue) => {
//     if (onChange) {
//       onChange(newValue);
//     }
//   };
//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Box>
//         <Autocomplete
//           placeholder={placeholder}
//           clearIcon={false}
//           value={defaultValue}
//           onChange={handleChange}
//           options={[]}
//           freeSolo
//           multiple
//           renderTags={(value, props) =>
//             value.map((option, index) => (
//               <Chip label={option} {...props({ index })} size="small" />
//             ))
//           }
//           renderInput={(params) => (
//             <TextField
//               placeholder={placeholder}
//               {...params}
//               sx={{
//                 background: DefineStyle.bgColor,
//                 "& .MuiInputBase-input::placeholder": {
//                   color: DefineStyle.placeholderColor,
//                   fontSize: DefineStyle.fontSize,
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: DefineStyle.boderColor, // Border color for the fieldset
//                   },
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   p: "3px",
//                   "& fieldset": {
//                     borderColor: DefineStyle.boderColor, // Border color for the fieldset
//                   },
//                 },
//               }}
//             />
//           )}
//         />
//       </Box>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };

// const CustomTextEditor = ({ placeholder, defaultValue,onChange,quillRef }) => {
  
  
//   return (
//     <React.Fragment>
//       <ReactQuill
//         ref={quillRef}
//         theme="snow"
//         value={defaultValue}
//         onChange={onChange}
//         placeholder={placeholder}
//         modules={{
//           toolbar: {
//             container: [
//               ["bold", "italic", "underline"],
//               [
//                 { list: "ordered" },
//                 { list: "bullet" },
//                 { indent: "-1" },
//                 { indent: "+1" },
//               ],
//               ["link"],
//               ["clean"],
//               [{ color: [] }, { background: [] }],
//             ],
//           },
//         }}
//       />
//     </React.Fragment>
//   );
// };

// const CustomSelect = ({
//   title,
//   defaultValue = [],
//   multiple = false,
//   options = [],
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   // Ensure defaultValue is always an array for multiple select
//   const value = multiple
//     ? Array.isArray(defaultValue)
//       ? defaultValue
//       : []
//     : defaultValue || "";

//   const handleChange = (event) => {
//     const newValue = event.target.value;
//     // For multi-select, newValue is an array
//     // For single-select, newValue is a single value
//     onChange(newValue);
//   };

//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Select
//         fullWidth
//         size="small"
//         value={value}
//         multiple={multiple}
//         displayEmpty
//         onChange={handleChange}
//         renderValue={(selected) => {
//           if (multiple) {
//             if (selected.length === 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray", // DefineStyle.placeholderColor or similar
//                     opacity: 0.4,
//                     fontSize: "0.875rem", // DefineStyle.fontSize or similar
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return (
//               <div>
//                 {selected.map((value) => (
//                   <Typography key={value} noWrap>
//                     {value}
//                   </Typography>
//                 ))}
//               </div>
//             );
//           } else {
//             if (selected === "") {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray", // DefineStyle.placeholderColor or similar
//                     opacity: 0.4,
//                     fontSize: "0.875rem", // DefineStyle.fontSize or similar
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return selected;
//           }
//         }}
//         sx={{
//           maxWidth: "100%",
//           background: "white", // DefineStyle.bgColor or similar
//           "& .MuiSelect-select": {
//             padding: "11px 8px 10px 8px",
//             fontSize: "0.875rem", // DefineStyle.fontSize or similar
//           },
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "gray", // DefineStyle.borderColor or similar
//           },
//         }}
//       >
//         <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
//           {" "}
//           {/* DefineStyle.fontSize or similar */}
//           <Typography noWrap>{title}</Typography>
//         </MenuItem>
//         {options.map((option, index) =>
//           typeof option === "object" ? (
//             <MenuItem key={index} value={option.value}>
//               <ListItemText primary={option.key} />
//             </MenuItem>
//           ) : (
//             <MenuItem
//               key={index}
//               value={option}
//               sx={{ fontSize: "12px", fontWeight: 600 }}
//             >
//               {option}
//             </MenuItem>
//           )
//         )}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };
// const CustomSelectLabel = ({
//   title,
//   value,
//   multiple = false,
//   options = [],
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const handleChange = (event) => {
//     const newValue = event.target.value;
    
//     onChange(newValue);
//   };

//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Select
//         fullWidth
//         size="small"
//         value={value}
//         multiple={multiple}
//         displayEmpty
//         onChange={handleChange}
//         renderValue={(selected) => {
//           if (multiple) {
//             if (selected.length === 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray",
//                     opacity: 0.4,
//                     fontSize: "0.875rem",
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return (
//               <div>
//                 {selected.map((val) => (
//                   <Typography key={val} noWrap>
//                     {options.find((opt) => opt.value === val)?.label || val}
//                   </Typography>
//                 ))}
//               </div>
//             );
//           } else {
//             if (selected === "" && selected !== 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray",
//                     opacity: 0.4,
//                     fontSize: "0.875rem",
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return options.find((opt) => opt.value === selected)?.label || selected;
//           }
//         }}
//         sx={{
//           maxWidth: "100%",
//           background: "white",
//           "& .MuiSelect-select": {
//             padding: "11px 8px 10px 8px",
//             fontSize: "0.875rem",
//           },
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "gray",
//           },
//         }}
//       >
//         <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
//           <Typography noWrap>{title}</Typography>
//         </MenuItem>
//         {options.map((option, index) => (
//           <MenuItem key={index} value={option.value}>
//             <ListItemText primary={option.label} />
//           </MenuItem>
//         ))}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };
// const CustomSelectLabelMultiple = ({
//   title,
//   value = [], // Default to an empty array
//   multiple = false,
//   options = [],
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const handleChange = (event) => {
//     const newValue = event.target.value;

//     // Handle the "Select All" option
//     if (newValue.includes("select-all")) {
//       if (value.length === options.length) {
//         // If all are selected, deselect all
//         onChange([]); // Pass a flat array
//       } else {
//         // Otherwise, select all options

//         onChange(options.map((option) => option.value)); // Ensure this is a flat array
//       }
//     } else {
//       // Pass a flat array to onChange
//       console.log('newValue',newValue);
//       onChange(newValue);
//     }
//   };

//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Select
//         fullWidth
//         size="small"
//         value={value} // Should be an array
//         multiple={multiple}
//         displayEmpty
//         onChange={handleChange}
//         renderValue={(selected) => {
//           if (multiple) {
//             if (selected.length === 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray",
//                     opacity: 0.4,
//                     fontSize: "0.875rem",
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return (
//               <div>
//                 {selected.map((val) => (
//                   <Typography key={val} noWrap>
//                     {options.find((opt) => opt.value === val)?.label || val}
//                   </Typography>
//                 ))}
//               </div>
//             );
//           } else {
//             return options.find((opt) => opt.value === selected)?.label || selected;
//           }
//         }}
//         sx={{
//           maxWidth: "100%",
//           background: "white",
//           "& .MuiSelect-select": {
//             padding: "11px 8px 10px 8px",
//             fontSize: "0.875rem",
//           },
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "gray",
//           },
//         }}
//       >
//         <MenuItem disabled value="">
//           <Typography noWrap>{title}</Typography>
//         </MenuItem>
//         <MenuItem value="select-all">
//           <ListItemText primary={value.length === options.length ? "Deselect All" : "Select All"} />
//         </MenuItem>
//         {options.map((option, index) => (
//           <MenuItem key={index} value={option.value}
//           sx={{
//             "&.Mui-selected": {
//               backgroundColor: theme.palette.primary[300], // Change the background color of selected item
//             },
//             "&.Mui-selected:hover": {
//               backgroundColor: theme.palette.primary[500], // Change hover effect for selected item
//             },
//           }}>
//             <ListItemText primary={option.label} />
//           </MenuItem>
//         ))}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };
// // const CustomSelectLabelMultipleForm = ({
// //   title,
// //   value = [], // Default to an empty array
// //   multiple = true,
// //   onChange,
// //   options = [],
// //   error = false,
// //   errorMessage = "",
// //   onChangeForm,
// // }) => {
// //   const handleChange = (event) => {
// //     const newValue = event.target.value;

// //     // Handle the "Select All" option
// //     if (newValue.includes("select-all")) {
// //       if (value.length === options.length) {
// //         // If all are selected, deselect all
// //         onChange([]); // Pass an empty array to deselect all
// //       } else {
// //         // Otherwise, select all options
// //         onChange(options.map(option => option.value)); // Pass a flat array of all option values
// //       }
// //     } else {
// //       // Handle individual selections
// //       const currentValue = newValue.filter(val => value.includes(val)); // Keep already selected values
// //       const newSelections = newValue.filter(val => !value.includes(val)); // Add new selections only
// //       onChange([...currentValue, ...newSelections]); // Update selected values
// //     }
// //   };
// //   const handleChangeForm=(value)=>{
// //     console.log('value',value);
// //     onChangeForm(value)
// //   }

// //   return (
// //     <FormControl fullWidth error={error} size="small">
// //       <InputLabel>{title}</InputLabel>
// //       <Select
// //         multiple={multiple}
// //         value={value}
// //         onChange={handleChange}
// //         renderValue={(selected) => selected.join(', ') || title}
// //       >
// //         <MenuItem disabled value="">
// //           <Typography>{title}</Typography>
// //         </MenuItem>
// //         <MenuItem value="select-all">
// //           <ListItemText primary={value.length === options.length ? "Deselect All" : "Select All"} />
// //         </MenuItem>
// //         <TextField
// //           variant="outlined"
// //           placeholder="Search..."
// //           onChange={(e) => handleChangeForm(e.target.value)}
// //           fullWidth
// //           sx={{ mb: 1 }} // Margin bottom for spacing
// //         />
// //         {options.map((option) => (
// //           <MenuItem key={option.value} value={option.value}>
// //             <ListItemText primary={option.label} />
// //           </MenuItem>
// //         ))}
// //       </Select>
// //       {error && <FormHelperText>{errorMessage}</FormHelperText>}
// //     </FormControl>
// //   );
// // };
// const CustomSelectLabelMultipleForm = ({
//   title,
//   value = [],
//   multiple = true,
//   onChange,
//   options = [],
//   error = false,
//   errorMessage = "",
//   onChangeForm,
//   searchTerm = "",
//   selectCityZip = "",
// }) => {
//   const handleSearchChange = (e) => {
//     const newSearchTerm = e.target.value;
//     if (onChangeForm) {
//       onChangeForm(newSearchTerm);
//     }
//   };

//   const handleMenuItemClick = (selectedOption) => {
//     if (onChange) {
//       onChange(selectedOption.value);
//     }
//   };
//   return (
//     <Box
//       sx={{
//         position: "relative",
//       }}
//     >
//       <FormControl fullWidth>
//         <InputLabel htmlFor="search-input" variant="standard">
//           Search
//         </InputLabel>
//         <OutlinedInput
//           id="search-input"
//           value={searchTerm}
//           onChange={handleSearchChange}
//           // onFocus={(e) => setAnchorEl(e.currentTarget)} // Open on focus
//           //label="Search"
//           size="small"
//           variant="standard"
//           sx={{
//             background: DefineStyle.bgColor,
//             p: 0.2,
//             mt: 0.2,
//             "& .MuiInputBase-input": {
//               "::placeholder": {
//                 color: DefineStyle.placeholderColor,
//                 fontSize: DefineStyle.fontSize,
//               },
//             },
//             "& .MuiOutlinedInput-root": {
//               "& fieldset": {
//                 borderColor: DefineStyle.borderColor, // Corrected spelling
//               },
//             },
//           }}
//           inputProps={{
//             placeholder: "Search...",
//           }}
//         />
//       </FormControl>

//       <Box
//         sx={{
//           //width: "100%",
//           maxHeight: 150,
//           overflowY: "auto",
//           position: "absolute",
//           zIndex: 999,
//           bgcolor:"white"
//         }}
//       >
//         {selectCityZip ? (
//           <></>
//         ) : (
//           <>
//             {options.length > 0 ? (
//               options.map((option) => (
//                 <MenuItem
//                   key={option.value}
//                   onClick={() => handleMenuItemClick(option)}
//                 >
//                   {option.label}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled></MenuItem>
//             )}
//           </>
//         )}
//       </Box>
//       {/* </Menu> */}
//     </Box>
//   );
// };



// const CustomSelectRef = ({
//   title,
//   multiple = false,
//   options = [],
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   // Ensure defaultValue is always an array for multiple select

//   const [value, setValue] = useState('');
//   const handleChange = (event) => {
//     const newValue = event.target.value;
//     onChange(newValue);
//     setValue(newValue)

//   };

//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Select
//         fullWidth
//         size="small"
//         value={value}
//         multiple={multiple}
//         displayEmpty
//         onChange={handleChange}
//         renderValue={(selected) => {
//           if (multiple) {
//             if (selected.length === 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray", // DefineStyle.placeholderColor or similar
//                     opacity: 0.4,
//                     fontSize: "0.875rem", // DefineStyle.fontSize or similar
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return (
//               <div>
//                 {selected.map((value) => (
//                   <Typography key={value} noWrap>
//                     {value}
//                   </Typography>
//                 ))}
//               </div>
//             );
//           } else {
//             if (selected === "") {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray", // DefineStyle.placeholderColor or similar
//                     opacity: 0.4,
//                     fontSize: "0.875rem", // DefineStyle.fontSize or similar
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return selected;
//           }
//         }}
//         sx={{
//           maxWidth: "100%",
//           background: "white", // DefineStyle.bgColor or similar
//           "& .MuiSelect-select": {
//             padding: "11px 8px 10px 8px",
//             fontSize: "0.875rem", // DefineStyle.fontSize or similar
//           },
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "gray", // DefineStyle.borderColor or similar
//           },
//         }}
//       >
//         <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
//           {" "}
//           {/* DefineStyle.fontSize or similar */}
//           <Typography noWrap>{title}</Typography>
//         </MenuItem>
//         {options.map((option, index) =>
//           typeof option === "object" ? (
//             <MenuItem key={index} value={option.value}>
//               <ListItemText primary={option.key} />
//             </MenuItem>
//           ) : (
//             <MenuItem
//               key={index}
//               value={option}
//               sx={{ fontSize: "12px", fontWeight: 600 }}
//             >
//               {option}
//             </MenuItem>
//           )
//         )}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };

// const CustomRadio = ({
//   title,
//   defaultValue,
//   options = [],
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const handleRadioChange = (event) => {
//     const newValue = event.target.value;
//     if (onChange) {
//       onChange(newValue); // Pass only the value to the handler
//     }
//   };
//   return (
//     <FormControl fullWidth error={error} size="small">
//       <RadioGroup
//         row
//         name={`${title}-radio-buttons-group`}
//         value={defaultValue} // Use value for controlled component
//         onChange={handleRadioChange} // Handle changes
//       >
//         {options.map((option, index) => {
//           const value = typeof option === "object" ? option.value : option;
//           const label = typeof option === "object" ? option.label : option;

//           return (
//             <FormControlLabel
//               key={index}
//               value={value}
//               control={
//                 <Radio
//                   size="small"
//                   sx={{
//                     color: DefineStyle.placeholderColor,
//                     opacity: 0.5,
//                     py: 0.7,
//                     "&.Mui-checked": {
//                       opacity: 1,
//                       color: theme.palette.primary.main,
//                     },
//                   }}
//                 />
//               }
//               label={label}
//               disabled={typeof option === "object" && option.disabled} // Handle disabled option
//             />
//           );
//         })}
//       </RadioGroup>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };

// const CustomCheckbox = ({
//   title,
//   defaultValue,
//   options = [],
//   onChange,
//   onClick,
//   error = false,
//   errorMessage = "",
//   size = "medium", //small
// }) => {
//   const handleCheckboxChange = (event) => {
//     const newValue = event.target.value;

//     if (onChange) {
//       onChange(newValue); // Pass only the value to the handler
//     }
//   };
//   const handleCheckboxClick=(event)=>{
//     const newValue = event.target.value;
//     if (onClick) {
//       if(newValue===defaultValue){
//       onClick(''); // Pass only the value to the handler
//       }else{
//         onClick(newValue); // Pass only the value to the handler
//       }
//     }
//   }
 
//   return (
//     <FormControl
//       fullWidth
//       error={error}
//       size="small"
//       component="fieldset"
//       variant="standard"
//     >
//       <FormGroup>
//         {options.map((option, index) => {
//           const value = typeof option === "object" ? option.value : option;
//           const label = typeof option === "object" ? option.label : option;

//           return (
//             <FormControlLabel
//               key={index}
//               value={value}
//               onChange={handleCheckboxChange}
//               onClick={handleCheckboxClick}
//               control={
//                 <Checkbox
//                   size="small"
//                   name={label}
//                   checked={defaultValue === label}
//                   sx={{
//                     color: DefineStyle.placeholderColor,
//                     opacity: 0.5,
//                     py: 0.7,
//                     "&.Mui-checked": {
//                       opacity: 1,
//                       color: theme.palette.primary.main,
//                     },
//                   }}
//                 />
//               }
//               label={label}
//               sx={{
//                 "& .MuiFormControlLabel-label": {
//                   fontSize: size === "small" ? 12 : "",
//                   color: size === "small" ? theme.palette.grey[500] : "",
//                   letterSpacing: size === "small" ? 0.5 : "",
//                 },
//               }}
//               disabled={typeof option === "object" && option.disabled} // Handle disabled option
//             />
//           );
//         })}
//       </FormGroup>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };

// const CustomSwitch = ({
//   title,
//   defaultValue,
//   onChange,
//   options = ["Yes", "No"],
//   error = false,
//   errorMessage = "",
// }) => {
//   // Manage the state of the switch
//   const [checked, setChecked] = useState(defaultValue);

//   const handleChange = (event) => {
//     // setChecked(event.target.checked);
//     if (onChange) {
//       onChange(event.target.checked);
//     }
//   };

//   return (
//     <Box display="flex" alignItems="center">
//       <Box display="flex" alignItems="center">
//         <Switch
//           checked={defaultValue}
//           onChange={handleChange}
//           sx={{
//             width: 50,
//             height: 26,
//             padding: 0,
//             "& .MuiSwitch-switchBase": {
//               padding: 0,
//               margin: "4px",
//               transitionDuration: "300ms",
//               color: DefineStyle.boderColor,
//               "&.Mui-checked": {
//                 transform: "translateX(24px)",
//                 color: theme.palette.primary.main,
//                 "& + .MuiSwitch-track": {
//                   backgroundColor: theme.palette.grey[200],
//                   opacity: 1,
//                   border: 0,
//                 },
//               },
//               "&.Mui-focusVisible .MuiSwitch-thumb": {
//                 color: "#33cf4d",
//                 border: "6px solid #fff",
//               },
//               "&.Mui-disabled .MuiSwitch-thumb": {
//                 color:
//                   theme.palette.mode === "light"
//                     ? "red"
//                     : theme.palette.grey[600],
//               },
//               "&.Mui-disabled + .MuiSwitch-track": {
//                 //opacity: theme.palette.mode === "light" ? 1 : 1,
//               },
//             },
//             "& .MuiSwitch-thumb": {
//               boxSizing: "border-box",
//               width: 18,
//               height: 18,
//             },
//             "& .MuiSwitch-track": {
//               borderRadius: 13,
//               backgroundColor: theme.palette.grey[200],
//               opacity: 1,
//             },
//           }}
//         />

//         <Typography
//           sx={{
//             marginLeft: 1,
//             fontSize: DefineStyle.fontSize,
//             color: checked
//               ? theme.palette.primary.main
//               : DefineStyle.placeholderColor,
//             opacity: checked ? 1 : 0.5,
//           }}
//         >
//           {checked ? options[0] : options[1]}
//         </Typography>
//       </Box>
//       {error && (
//         <Typography color="error" variant="caption" sx={{ marginTop: 0.5 }}>
//           {errorMessage}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// const CustomDatePicker = ({
//   defaultValue,
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const handleChangeDate = (date) => {
//     if (onChange) {
//       // Convert the selected date to ISO 8601 format
//       const isoDate = date ? dayjs(date).toISOString() : "";
//       onChange(isoDate);
//     }
//   };
//   return (
//     <React.Fragment>
//       <Box>
//         <DatePicker
//           sx={{
//             width: "100%",
//             borderRadius: "0px",
//             background: DefineStyle.bgColor,
//             "& .MuiOutlinedInput-root": {
//               fontSize: DefineStyle.fontSize,
//               "& fieldset": {
//                 borderColor: theme.palette.grey[500],
//                 borderColor: DefineStyle.boderColor,
//               },
//             },
//             "& .MuiInputBase-input": {
//               padding: "12px 8px 12px 8px", // Adjust padding as needed
//             },
//           }}
//           value={defaultValue ? dayjs(defaultValue) : null}
//           onChange={handleChangeDate}
//         />
//         {error && (
//           <Typography color="error" variant="caption">
//             {errorMessage}
//           </Typography>
//         )}
//       </Box>
//     </React.Fragment>
//   );
// };

// const CustomFileUploadField = ({
//   placeholder,
//   defaultValue,
//   onChange,
//   error = false,
//   errorMessage = "",
// }) => {
//   const [selectedFile, setSelectedFile] = useState();
//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedFile(file);
//       onChange(file);
//     }
//   };

//   const handleTextFieldClick = () => {
//     document.getElementById("file-input").click();
//   };
//   return (
//     <React.Fragment>
//       <Box sx={{ display: "flex" }}>
//         <input
//           id="file-input"
//           type="file"
//           style={{ display: "none" }}
//           // accept=".pdf,.doc,.docx,.txt"
//           accept=".pdf,.doc,.docx,.txt,.rtf,.html,.htm"
//           onChange={handleFileChange}
//         />

//         <TextField
//           value={selectedFile ? selectedFile.name : defaultValue}
//           onClick={handleTextFieldClick}
//           placeholder={placeholder}
//           size="small"
//           fullWidth
//           InputProps={{
//             readOnly: true,
//             sx: {
//               fontSize: DefineStyle.fontSize,
//               color: DefineStyle.textColor,
//               py: 0.5,
//               borderTopRightRadius: 0,
//               borderBottomRightRadius: 0,
//             },
//           }}
//           sx={{
//             background: DefineStyle.bgColor,
//             "& .MuiInputBase-input::placeholder": {
//               color: DefineStyle.placeholderColor,
//               fontSize: DefineStyle.fontSize,
//             },
//             "& .MuiOutlinedInput-root": {
//               "& fieldset": {
//                 borderColor: DefineStyle.boderColor, // Border color for the fieldset
//               },
//             },
//           }}
//           error={error} // Highlight field if error
//           helperText={error ? errorMessage : ""} // Display error message
//         />
//         <Button
//           onClick={handleTextFieldClick}
//           variant="contained"
//           sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
//         >
//           Upload
//         </Button>
//       </Box>
//     </React.Fragment>
//   );
// };
// const CustomSelectUser = ({
//   title,
//   defaultValue = [],
//   multiple = false,
//   options = [], // options will be ["123", "456"]
//   onChange,
//   error = false,
//   errorMessage = "",
//   allUsers = [], // list of users with { _id, userSudoFirstName }
// }) => {
//   // Ensure defaultValue is always an array for multiple select
//   const value = multiple
//     ? Array.isArray(defaultValue)
//       ? defaultValue
//       : []
//     : defaultValue || "";

//   const handleChange = (event) => {
//     const newValue = event.target.value;
//     onChange(newValue); // Return the ids when selected
//   };

//   // Debugging: Check if options and allUsers match


//   return (
//     <FormControl fullWidth error={error} size="small">
//       <Select
//         fullWidth
//         size="small"
//         value={value}
//         multiple={multiple}
//         displayEmpty
//         onChange={handleChange}
//         renderValue={(selected) => {
//           if (multiple) {
//             if (selected.length === 0) {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray",
//                     opacity: 0.4,
//                     fontSize: "0.875rem",
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             return (
//               <div>
//                 {selected.map((id) => {
//                   const user = allUsers.find((user) => user._id === id);
//                   return (
//                     <Typography key={id} noWrap>
//                       {user ? user.userSudoFirstName : "Unknown"}
//                     </Typography>
//                   );
//                 })}
//               </div>
//             );
//           } else {
//             if (selected === "") {
//               return (
//                 <Typography
//                   noWrap
//                   sx={{
//                     color: "gray",
//                     opacity: 0.4,
//                     fontSize: "0.875rem",
//                     py: 0.15,
//                     maxWidth: "60%",
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               );
//             }
//             const user = allUsers.find((user) => user._id === selected);
//             return user ? user.userSudoFirstName : "Unknown";
//           }
//         }}
//         sx={{
//           maxWidth: "100%",
//           background: "white",
//           "& .MuiSelect-select": {
//             padding: "11px 8px 10px 8px",
//             fontSize: "0.875rem",
//           },
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "gray",
//           },
//         }}
//       >
//         <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
//           <Typography noWrap>{title}</Typography>
//         </MenuItem>
//         {options.map((id, index) => {
//           const user = allUsers.find((user) => user._id === id);
//           return (
//             <MenuItem key={index} value={id}>
//               <ListItemText
//                 primary={user ? user.userSudoFirstName : "Unknown"}
//               />
//             </MenuItem>
//           );
//         })}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };


// const CustomFormField = ({
//   title,
//   info,
//   sectionInfo,
//   required,
//   components,
//   colunm = false,
// }) => {
//   return (
//     <React.Fragment>
//       <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
//         <Box>
//           <FormSectionTitle title={title} info={info} required={required} />
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             rowGap: 1,
//             columnGap: 1,
//             flexWrap: "wrap",
//             flexDirection: colunm ? "column" : "row",
//             alignItems: colunm ? "" : "center",
//           }}
//         >
//           {components.map((component, index) => {
//             const ComponentType = getComponentType(component.type);
//             return (
//               <Box
//                 key={index}
//                 sx={{
//                   flex: component.type !== "rtText" && !colunm && 1,
//                   flexBasis: component.type !== "rtText" && !colunm && "100px",
//                 }}
//               >
//                 <ComponentType {...component.props} sectionTitle={title} />
//               </Box>
//             );
//           })}
//         </Box>
//         {sectionInfo && (
//           <Box sx={{ px: 0.5, mt: -0.5 }}>
//             <Typography
//               noWrap
//               sx={{
//                 fontSize: 12,
//                 color: DefineStyle.placeholderColor,
//                 letterSpacing: 0.5,
//               }}
//             >
//               {sectionInfo}
//             </Typography>
//           </Box>
//         )}
//       </Box>
//     </React.Fragment>
//   );
// };

// // Helper function to get the correct component type
// const getComponentType = (type) => {
//   switch (type) {
//     case "select":
//       return CustomSelect;
//     case "selectLabel":
//       return CustomSelectLabel;
//     case "selectLabelMultiple":
//       return CustomSelectLabelMultiple;
//     case "selectLabelMultipleFrom":
//       return CustomSelectLabelMultipleForm;
//     case "selectRef":
//       return CustomSelectRef;
//     case "text":
//       return CustomText;
//     case "datepicker":
//       return CustomDatePicker;
//     case "radio":
//       return CustomRadio;

//     case "checkbox":
//       return CustomCheckbox;
//     case "switch":
//       return CustomSwitch;
//     case "textEditor":
//       return CustomTextEditor;
//     case "file":
//       return CustomFileUploadField;
//     case "autocomplete":
//       return CustomAutoComplete;
//     case "autoselect":
//       return CustomAutoSelect;
//     case "textArea":
//       return CustomTextArea;
//     case "rtText":
//       return RtText;
//     case "selectUser":
//       return CustomSelectUser;
//     default:
//       return null;
//   }
// };

// export default CustomFormField;






import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import HelpIcon from "@mui/icons-material/Help";
import theme from "../../Theme";
import { DatePicker } from "@mui/x-date-pickers";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

const DefineStyle = {
  fontSize: 14,
  bgColor: "white",
  //bgColor: `linear-gradient(0deg, rgba(${theme.palette.primary.default},.07) 0%, rgba(${theme.palette.primary.default},0.02) 100%)`,
  boderColor: `rgba(${theme.palette.primary.default},.2)`,
  textColor: theme.palette.text.primary,
  placeholderColor: theme.palette.grey[900],
};

const FormSectionTitle = ({ title, info, required }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Typography
          noWrap
          sx={{
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1.2,
            letterSpacing: 0.5,
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        {required && (
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              lineHeight: 0.8,
              mx: 0.2,
              color: theme.palette.error.dark,
            }}
          >
            *
          </Typography>
        )}
        {info && (
          <Tooltip title={info} arrow placement="top">
            <HelpIcon
              sx={{ color: "warning.main", fontSize: "12px", mx: 0.5 }}
            />
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
};

const RtText = ({ text }) => {
  return (
    <React.Fragment>
      <Typography sx={{ fontSize: 13, color: theme.palette.grey[400] }}>
        {text}
      </Typography>
    </React.Fragment>
  );
};

const CustomText = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type = "text",
  typeInfo,
}) => {
  const handleChangeText = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <TextField
        placeholder={placeholder}
        multiline={multiline}
        rows={multiline && 3}
        size="small"
        fullWidth
        type={type}
        value={defaultValue}
        InputProps={{
          readOnly: readOnly,
          sx: {
            fontSize: DefineStyle.fontSize,
            color: DefineStyle.textColor,
            py: 0.5,
          },
        }}
        sx={{
          background: DefineStyle.bgColor,
          "& .MuiInputBase-input::placeholder": {
            color: DefineStyle.placeholderColor,
            fontSize: DefineStyle.fontSize,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: DefineStyle.boderColor, // Border color for the fieldset
            },
          },
        }}
        disabled={disabled}
        onChange={handleChangeText}
        error={error} // Highlight field if error
        helperText={error ? errorMessage : ""} // Display error message
      />
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomTextArea = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type = "text",
  onEnter,
  typeInfo,
}) => {
  const handleChangeText = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     alert(event.target.value); // Alert the current value
  //   }
  // };
  return (
    <React.Fragment>
      <TextField
        placeholder={placeholder}
        multiline
        rows={4}
        size="small"
        fullWidth
        type={type}
        value={defaultValue}
        InputProps={{
          readOnly: readOnly,
          sx: {
            fontSize: DefineStyle.fontSize,
            color: DefineStyle.textColor,
            py: 0.5,
          },
        }}
        sx={{
          background: DefineStyle.bgColor,
          "& .MuiInputBase-input::placeholder": {
            color: DefineStyle.placeholderColor,
            fontSize: DefineStyle.fontSize,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: DefineStyle.boderColor, // Border color for the fieldset
            },
          },
        }}
        disabled={disabled}
        onChange={handleChangeText}
        // onKeyPress={handleKeyPress}
        on
        error={error} // Highlight field if error
        helperText={error ? errorMessage : ""} // Display error message
      />
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomAutoComplete = ({
  placeholder,
  options = [],
  defaultValue,
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  const handleChange = (event, value) => {
    // Pass only necessary data to the onChange callback
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        freeSolo
        id="combo-box-demo"
        options={options}
        value={defaultValue}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          // If you need to handle input changes separately, you can use this
          handleChange(event, newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size="small"
            fullWidth
            error={error}
            helperText={error ? errorMessage : ""}
            sx={{
              background: DefineStyle.bgColor,
              "& .MuiInputBase-input::placeholder": {
                color: DefineStyle.placeholderColor,
                fontSize: DefineStyle.fontSize,
              },
              // "& .MuiOutlinedInput-root": {
              //   p: "3px",
              //   "& fieldset": {
              //     borderColor: DefineStyle.boderColor, // Border color for the fieldset
              //   },
              // },
            }}
          />
        )}
      />
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomAutoSelect = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type = "text",
  typeInfo,
}) => {
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Box>
          <Autocomplete
            placeholder={placeholder}
            clearIcon={false}
            value={defaultValue}
            onChange={handleChange}
            options={[]}
            freeSolo
            multiple
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip label={option} {...props({ index })} size="small" />
              ))
            }
            renderInput={(params) => (
              <TextField
                placeholder={placeholder}
                {...params}
                sx={{
                  background: DefineStyle.bgColor,
                  "& .MuiInputBase-input::placeholder": {
                    color: DefineStyle.placeholderColor,
                    fontSize: DefineStyle.fontSize,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: DefineStyle.boderColor, // Border color for the fieldset
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    p: "3px",
                    "& fieldset": {
                      borderColor: DefineStyle.boderColor, // Border color for the fieldset
                    },
                  },
                }}
              />
            )}
          />
        </Box>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomTextEditor = ({ placeholder, defaultValue,onChange,quillRef,typeInfo}) => {
  return (
    <React.Fragment>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        modules={{
          toolbar: {
            container: [
              ["bold", "italic", "underline"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
              [{ color: [] }, { background: [] }],
            ],
          },
        }}
      />
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomSelect = ({
  title,
  defaultValue = [],
  multiple = false,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  // Ensure defaultValue is always an array for multiple select
  const value = multiple
    ? Array.isArray(defaultValue)
      ? defaultValue
      : []
    : defaultValue || "";

  const handleChange = (event) => {
    const newValue = event.target.value;
    // For multi-select, newValue is an array
    // For single-select, newValue is a single value
    onChange(newValue);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Select
          fullWidth
          size="small"
          value={value}
          multiple={multiple}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            if (multiple) {
              if (selected.length === 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray", // DefineStyle.placeholderColor or similar
                      opacity: 0.4,
                      fontSize: "0.875rem", // DefineStyle.fontSize or similar
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                <div>
                  {selected.map((value) => (
                    <Typography key={value} noWrap>
                      {value}
                    </Typography>
                  ))}
                </div>
              );
            } else {
              if (selected === "") {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray", // DefineStyle.placeholderColor or similar
                      opacity: 0.4,
                      fontSize: "0.875rem", // DefineStyle.fontSize or similar
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return selected;
            }
          }}
          sx={{
            maxWidth: "100%",
            background: "white", // DefineStyle.bgColor or similar
            "& .MuiSelect-select": {
              padding: "11px 8px 10px 8px",
              fontSize: "0.875rem", // DefineStyle.fontSize or similar
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray", // DefineStyle.borderColor or similar
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
            {" "}
            {/* DefineStyle.fontSize or similar */}
            <Typography noWrap>{title}</Typography>
          </MenuItem>
          {options.map((option, index) =>
            typeof option === "object" ? (
              <MenuItem key={index} value={option.value}>
                <ListItemText primary={option.key} />
              </MenuItem>
            ) : (
              <MenuItem
                key={index}
                value={option}
                sx={{ fontSize: "12px", fontWeight: 600 }}
              >
                {option}
              </MenuItem>
            )
          )}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomSelectLabel = ({
  title,
  value,
  multiple = false,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Select
          fullWidth
          size="small"
          value={value}
          multiple={multiple}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            if (multiple) {
              if (selected.length === 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray",
                      opacity: 0.4,
                      fontSize: "0.875rem",
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                <div>
                  {selected.map((val) => (
                    <Typography key={val} noWrap>
                      {options.find((opt) => opt.value === val)?.label || val}
                    </Typography>
                  ))}
                </div>
              );
            } else {
              if (selected === "" && selected !== 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray",
                      opacity: 0.4,
                      fontSize: "0.875rem",
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                options.find((opt) => opt.value === selected)?.label || selected
              );
            }
          }}
          sx={{
            maxWidth: "100%",
            background: "white",
            "& .MuiSelect-select": {
              padding: "11px 8px 10px 8px",
              fontSize: "0.875rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray",
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
            <Typography noWrap>{title}</Typography>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomSelectLabelMultiple = ({
  title,
  value = [], // Default to an empty array
  multiple = false,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value;

    // Handle the "Select All" option
    if (newValue.includes("select-all")) {
      if (value.length === options.length) {
        // If all are selected, deselect all
        onChange([]); // Pass a flat array
      } else {
        // Otherwise, select all options

        onChange(options.map((option) => option.value)); // Ensure this is a flat array
      }
    } else {
    
      onChange(newValue);
    }
  };

  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Select
          fullWidth
          size="small"
          value={value} // Should be an array
          multiple={multiple}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            if (multiple) {
              if (selected.length === 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray",
                      opacity: 0.4,
                      fontSize: "0.875rem",
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                <div>
                  {selected.map((val) => (
                    <Typography key={val} noWrap>
                      {options.find((opt) => opt.value === val)?.label || val}
                    </Typography>
                  ))}
                </div>
              );
            } else {
              return (
                options.find((opt) => opt.value === selected)?.label || selected
              );
            }
          }}
          sx={{
            maxWidth: "100%",
            background: "white",
            "& .MuiSelect-select": {
              padding: "11px 8px 10px 8px",
              fontSize: "0.875rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray",
            },
          }}
        >
          <MenuItem disabled value="">
            <Typography noWrap>{title}</Typography>
          </MenuItem>
          <MenuItem value="select-all">
            <ListItemText
              primary={
                value.length === options.length ? "Deselect All" : "Select All"
              }
            />
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary[300], // Change the background color of selected item
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.primary[500], // Change hover effect for selected item
                },
              }}
            >
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

// const CustomSelectLabelMultipleForm = ({
//   title,
//   value = [], // Default to an empty array
//   multiple = true,
//   onChange,
//   options = [],
//   error = false,
//   errorMessage = "",
//   onChangeForm,
// }) => {
//   const handleChange = (event) => {
//     const newValue = event.target.value;

//     // Handle the "Select All" option
//     if (newValue.includes("select-all")) {
//       if (value.length === options.length) {
//         // If all are selected, deselect all
//         onChange([]); // Pass an empty array to deselect all
//       } else {
//         // Otherwise, select all options
//         onChange(options.map(option => option.value)); // Pass a flat array of all option values
//       }
//     } else {
//       // Handle individual selections
//       const currentValue = newValue.filter(val => value.includes(val)); // Keep already selected values
//       const newSelections = newValue.filter(val => !value.includes(val)); // Add new selections only
//       onChange([...currentValue, ...newSelections]); // Update selected values
//     }
//   };
//   const handleChangeForm=(value)=>{
//     console.log('value',value);
//     onChangeForm(value)
//   }

//   return (
//     <FormControl fullWidth error={error} size="small">
//       <InputLabel>{title}</InputLabel>
//       <Select
//         multiple={multiple}
//         value={value}
//         onChange={handleChange}
//         renderValue={(selected) => selected.join(', ') || title}
//       >
//         <MenuItem disabled value="">
//           <Typography>{title}</Typography>
//         </MenuItem>
//         <MenuItem value="select-all">
//           <ListItemText primary={value.length === options.length ? "Deselect All" : "Select All"} />
//         </MenuItem>
//         <TextField
//           variant="outlined"
//           placeholder="Search..."
//           onChange={(e) => handleChangeForm(e.target.value)}
//           fullWidth
//           sx={{ mb: 1 }} // Margin bottom for spacing
//         />
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             <ListItemText primary={option.label} />
//           </MenuItem>
//         ))}
//       </Select>
//       {error && <FormHelperText>{errorMessage}</FormHelperText>}
//     </FormControl>
//   );
// };

const CustomSelectLabelMultipleForm = ({
  title,
  value = [],
  multiple = true,
  onChange,
  options = [],
  error = false,
  errorMessage = "",
  onChangeForm,
  searchTerm = "",
  selectCityZip = "",
  typeInfo,
}) => {
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    if (onChangeForm) {
      onChangeForm(newSearchTerm);
    }
  };

  const handleMenuItemClick = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption.value);
    }
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="search-input" variant="standard">
            Search
          </InputLabel>
          <OutlinedInput
            id="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
            // onFocus={(e) => setAnchorEl(e.currentTarget)} // Open on focus
            //label="Search"
            size="small"
            variant="standard"
            sx={{
              background: DefineStyle.bgColor,
              p: 0.2,
              mt: 0.2,
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: DefineStyle.placeholderColor,
                  fontSize: DefineStyle.fontSize,
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: DefineStyle.borderColor, // Corrected spelling
                },
              },
            }}
            inputProps={{
              placeholder: "Search...",
            }}
          />
        </FormControl>

        <Box
          sx={{
            //width: "100%",
            maxHeight: 150,
            overflowY: "auto",
            position: "absolute",
            zIndex: 999,
            bgcolor: "white",
          }}
        >
          {selectCityZip ? (
            <></>
          ) : (
            <>
              {options.length > 0 ? (
                options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleMenuItemClick(option)}
                  >
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled></MenuItem>
              )}
            </>
          )}
        </Box>
        {/* </Menu> */}
      </Box>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomSelectRef = ({
  title,
  multiple = false,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  // Ensure defaultValue is always an array for multiple select

  const [value, setValue] = useState("");
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Select
          fullWidth
          size="small"
          value={value}
          multiple={multiple}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            if (multiple) {
              if (selected.length === 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray", // DefineStyle.placeholderColor or similar
                      opacity: 0.4,
                      fontSize: "0.875rem", // DefineStyle.fontSize or similar
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                <div>
                  {selected.map((value) => (
                    <Typography key={value} noWrap>
                      {value}
                    </Typography>
                  ))}
                </div>
              );
            } else {
              if (selected === "") {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray", // DefineStyle.placeholderColor or similar
                      opacity: 0.4,
                      fontSize: "0.875rem", // DefineStyle.fontSize or similar
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return selected;
            }
          }}
          sx={{
            maxWidth: "100%",
            background: "white", // DefineStyle.bgColor or similar
            "& .MuiSelect-select": {
              padding: "11px 8px 10px 8px",
              fontSize: "0.875rem", // DefineStyle.fontSize or similar
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray", // DefineStyle.borderColor or similar
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
            {" "}
            {/* DefineStyle.fontSize or similar */}
            <Typography noWrap>{title}</Typography>
          </MenuItem>
          {options.map((option, index) =>
            typeof option === "object" ? (
              <MenuItem key={index} value={option.value}>
                <ListItemText primary={option.key} />
              </MenuItem>
            ) : (
              <MenuItem
                key={index}
                value={option}
                sx={{ fontSize: "12px", fontWeight: 600 }}
              >
                {option}
              </MenuItem>
            )
          )}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomRadio = ({
  title,
  defaultValue,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
  show = "column", //row
  typeInfo,
}) => {
  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue); // Pass only the value to the handler
    }
  };
  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <RadioGroup
          sx={{ gap: 1 }}
          row={show === "row" ? true : false}
          name={`${title}-radio-buttons-group`}
          value={defaultValue} // Use value for controlled component
          onChange={handleRadioChange} // Handle changes
        >
          {options.map((option, index) => {
            const value = typeof option === "object" ? option.value : option;
            const label = typeof option === "object" ? option.label : option;

            return (
              <FormControlLabel
                key={index}
                value={value}
                control={
                  <Radio
                    size="small"
                    sx={{
                      // color: theme.palette.grey[600],
                      // opacity: 0.5,
                      // py: 0.7,
                      // "&.Mui-checked": {
                      //   opacity: 1,
                      //   color: theme.palette.primary.main,
                      // },
                      color: DefineStyle.placeholderColor,
                      opacity: 0.5,
                      pr: 0.2,
                      py: 0.0,
                      border: "none",
                      "&.Mui-checked": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label={label}
                sx={{
                  alignItems: "flex-start",
                  gap: 0.5,
                  "& .MuiFormControlLabel-label": {
                    fontSize: 14,
                    fontWeight: 300,
                    color: theme.palette.grey[600],
                    letterSpacing: 0.5,
                  },
                }}
                disabled={typeof option === "object" && option.disabled} // Handle disabled option
              />
            );
          })}
        </RadioGroup>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

// const CustomCheckbox = ({
//   title,
//   defaultValue,
//   options = [],
//   onChange,
//   onClick,
//   error = false,
//   errorMessage = "",
//   size = "medium", //small
//   show = "column", //row
//   typeInfo,
// }) => {
//   const handleCheckboxChange = (event) => {
//     const newValue = event.target.value;
//     if (onChange) {
//       onChange(newValue); // Pass only the value to the handler
//     }
//   };
//   const handleCheckboxClick = (event) => {
//     const newValue = event.target.value;
//     if (onClick) {
//       if (newValue === defaultValue) {
//         onClick(""); // Pass only the value to the handler
//       } else {
//         onClick(newValue); // Pass only the value to the handler
//       }
//     }
//   };

//   return (
//     <React.Fragment>
//       <FormControl
//         fullWidth
//         error={error}
//         size="small"
//         component="fieldset"
//         variant="standard"
//       >
//         <FormGroup row={show === "row" ? true : false} sx={{ gap: 1 }}>
//           {options.map((option, index) => {
//             const value = typeof option === "object" ? option.value : option;
//             const label = typeof option === "object" ? option.label : option;

//             return (
//               <FormControlLabel
//                 key={index}
//                 value={value}
//                 onChange={handleCheckboxChange}
//                 onClick={handleCheckboxClick}
//                 control={
//                   <Checkbox
//                     size={size === "small" ? "xsmall" : "small"}
//                     name={label}
//                     checked={defaultValue === label}
//                     sx={{
//                       color: DefineStyle.placeholderColor,
//                       opacity: 0.5,
//                       pr: 0.2,
//                       py: 0.0,
//                       border: "none",
//                       "&.Mui-checked": {
//                         opacity: 1,
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   />
//                 }
//                 label={label}
//                 sx={{
//                   alignItems: "flex-start",
//                   gap: 0.5,
//                   "& .MuiFormControlLabel-label": {
//                     fontSize: size === "small" ? 12 : 14,
//                     lineHeight: size === "small" ? 1.4 : 1.5,
//                     fontWeight: 300,
//                     color: theme.palette.grey[600],
//                     letterSpacing: 0.5,
//                   },
//                 }}
//                 disabled={typeof option === "object" && option.disabled} // Handle disabled option
//               />
//             );
//           })}
//         </FormGroup>
//         {error && <FormHelperText>{errorMessage}</FormHelperText>}
//       </FormControl>
//       {typeInfo && (
//         <Box sx={{ px: 0.5, mt: 0.5 }}>
//           <Typography
//             noWrap
//             sx={{
//               fontSize: 12,
//               color: theme.palette.grey[700],
//               letterSpacing: 0.5,
//             }}
//           >
//             {typeInfo}
//           </Typography>
//         </Box>
//       )}
//     </React.Fragment>
//   );
// };

const CustomCheckbox = ({
  title,
  defaultValue,
  options = [],
  onChange,
  onClick,
  error = false,
  errorMessage = "",
  size = "medium", // small
  show = "column", // row
  typeInfo,
  multiple = false, // New prop to support single/multiple selection
}) => {
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    let newValue;

    if (multiple) {
      if (checked) {
        // Add to array
        newValue = [...(defaultValue || []), value];
      } else {
        // Remove from array
        newValue = defaultValue.filter((item) => item !== value);
      }
    } else {
      newValue = checked ? value : ""; // Single selection
    }

    if (onChange) {
      onChange(newValue); // Pass the updated value
    }
  };

  const handleCheckboxClick = (event) => {
    const value = event.target.value;

    if (onClick) {
      if (multiple) {
        if (defaultValue.includes(value)) {
          // Remove value if already selected
          onClick(defaultValue.filter((item) => item !== value));
        } else {
          // Add value to selected array
          onClick([...(defaultValue || []), value]);
        }
      } else {
        // Single selection
        onClick(value === defaultValue ? "" : value);
      }
    }
  };

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        error={error}
        size="small"
        component="fieldset"
        variant="standard"
      >
        <FormGroup row={show === "row"} sx={{ gap: 1 }}>
          {options.map((option, index) => {
            const value = typeof option === "object" ? option.value : option;
            const label = typeof option === "object" ? option.label : option;

            const isChecked = multiple
              ? defaultValue?.includes(value)
              : defaultValue === value;

            return (
              <FormControlLabel
                key={index}
                value={value}
                control={
                  <Checkbox
                    size={size === "small" ? "xsmall" : "small"}
                    name={label}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    onClick={handleCheckboxClick}
                    sx={{
                      color: DefineStyle.placeholderColor,
                      opacity: 0.5,
                      pr: 0.2,
                      py: 0.0,
                      border: "none",
                      "&.Mui-checked": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label={label}
                sx={{
                  alignItems: "flex-start",
                  gap: 0.5,
                  "& .MuiFormControlLabel-label": {
                    fontSize: size === "small" ? 12 : 14,
                    lineHeight: size === "small" ? 1.4 : 1.5,
                    fontWeight: 300,
                    color: theme.palette.grey[600],
                    letterSpacing: 0.5,
                  },
                }}
                disabled={typeof option === "object" && option.disabled} // Handle disabled option
              />
            );
          })}
        </FormGroup>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};



const CustomSwitch = ({
  title,
  defaultValue,
  onChange,
  options = ["Yes", "No"],
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  // Manage the state of the switch
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Switch
            checked={defaultValue}
            onChange={handleChange}
            sx={{
              width: 50,
              height: 26,
              padding: 0,
              "& .MuiSwitch-switchBase": {
                padding: 0,
                margin: "4px",
                transitionDuration: "300ms",
                color: DefineStyle.boderColor,
                "&.Mui-checked": {
                  transform: "translateX(24px)",
                  color: theme.palette.primary.main,
                  "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.grey[200],
                    opacity: 1,
                    border: 0,
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#33cf4d",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  color:
                    theme.palette.mode === "light"
                      ? "red"
                      : theme.palette.grey[600],
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  //opacity: theme.palette.mode === "light" ? 1 : 1,
                },
              },
              "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                width: 18,
                height: 18,
              },
              "& .MuiSwitch-track": {
                borderRadius: 13,
                backgroundColor: theme.palette.grey[200],
                opacity: 1,
              },
            }}
          />

          <Typography
            sx={{
              marginLeft: 1,
              fontSize: DefineStyle.fontSize,
              color: checked
                ? theme.palette.primary.main
                : DefineStyle.placeholderColor,
              opacity: checked ? 1 : 0.5,
            }}
          >
            {checked ? options[0] : options[1]}
          </Typography>
        </Box>
        {error && (
          <Typography color="error" variant="caption" sx={{ marginTop: 0.5 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomDatePicker = ({
  defaultValue,
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  const handleChangeDate = (date) => {
    if (onChange) {
      // Convert the selected date to ISO 8601 format
      const isoDate = date ? dayjs(date).toISOString() : "";
      onChange(isoDate);
    }
  };
  return (
    <React.Fragment>
      <Box>
        <DatePicker
          sx={{
            width: "100%",
            borderRadius: "0px",
            background: DefineStyle.bgColor,
            "& .MuiOutlinedInput-root": {
              fontSize: DefineStyle.fontSize,
              "& fieldset": {
                borderColor: theme.palette.grey[500],
                borderColor: DefineStyle.boderColor,
              },
            },
            "& .MuiInputBase-input": {
              padding: "12px 8px 12px 8px", // Adjust padding as needed
            },
          }}
          value={defaultValue ? dayjs(defaultValue) : null}
          onChange={handleChangeDate}
        />
        {error && (
          <Typography color="error" variant="caption">
            {errorMessage}
          </Typography>
        )}
      </Box>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomFileUploadField = ({
  placeholder,
  defaultValue,
  onChange,
  error = false,
  errorMessage = "",
  typeInfo,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      onChange(file);
    }
  };

  const handleTextFieldClick = () => {
    document.getElementById("file-input").click();
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          // accept=".pdf,.doc,.docx,.txt"
          accept=".pdf,.doc,.docx,.txt,.rtf,.html,.htm"
          onChange={handleFileChange}
        />

        <TextField
          value={selectedFile ? selectedFile.name : defaultValue}
          onClick={handleTextFieldClick}
          placeholder={placeholder}
          size="small"
          fullWidth
          InputProps={{
            readOnly: true,
            sx: {
              fontSize: DefineStyle.fontSize,
              color: DefineStyle.textColor,
              py: 0.5,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          sx={{
            background: DefineStyle.bgColor,
            "& .MuiInputBase-input::placeholder": {
              color: DefineStyle.placeholderColor,
              fontSize: DefineStyle.fontSize,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: DefineStyle.boderColor, // Border color for the fieldset
              },
            },
          }}
          error={error} // Highlight field if error
          helperText={error ? errorMessage : ""} // Display error message
        />
        <Button
          onClick={handleTextFieldClick}
          variant="contained"
          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          Upload
        </Button>
      </Box>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomSelectUser = ({
  title,
  defaultValue = [],
  multiple = false,
  options = [], // options will be ["123", "456"]
  onChange,
  error = false,
  errorMessage = "",
  allUsers = [], // list of users with { _id, userSudoFirstName }
  typeInfo,
}) => {
  // Ensure defaultValue is always an array for multiple select
  const value = multiple
    ? Array.isArray(defaultValue)
      ? defaultValue
      : []
    : defaultValue || "";

  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue); // Return the ids when selected
  };

  // Debugging: Check if options and allUsers match

  return (
    <React.Fragment>
      <FormControl fullWidth error={error} size="small">
        <Select
          fullWidth
          size="small"
          value={value}
          multiple={multiple}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            if (multiple) {
              if (selected.length === 0) {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray",
                      opacity: 0.4,
                      fontSize: "0.875rem",
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              return (
                <div>
                  {selected.map((id) => {
                    const user = allUsers.find((user) => user._id === id);
                    return (
                      <Typography key={id} noWrap>
                        {user ? user.userSudoFirstName : "Unknown"}
                      </Typography>
                    );
                  })}
                </div>
              );
            } else {
              if (selected === "") {
                return (
                  <Typography
                    noWrap
                    sx={{
                      color: "gray",
                      opacity: 0.4,
                      fontSize: "0.875rem",
                      py: 0.15,
                      maxWidth: "60%",
                    }}
                  >
                    {title}
                  </Typography>
                );
              }
              const user = allUsers.find((user) => user._id === selected);
              return user ? user.userSudoFirstName : "Unknown";
            }
          }}
          sx={{
            maxWidth: "100%",
            background: "white",
            "& .MuiSelect-select": {
              padding: "11px 8px 10px 8px",
              fontSize: "0.875rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "gray",
            },
          }}
        >
          <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
            <Typography noWrap>{title}</Typography>
          </MenuItem>
          {options.map((id, index) => {
            const user = allUsers.find((user) => user._id === id);
            return (
              <MenuItem key={index} value={id}>
                <ListItemText
                  primary={user ? user.userSudoFirstName : "Unknown"}
                />
              </MenuItem>
            );
          })}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
      {typeInfo && (
        <Box sx={{ px: 0.5, mt: 0.5 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[700],
              letterSpacing: 0.5,
            }}
          >
            {typeInfo}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const CustomFormField = ({
  title,
  info,
  titleInfo,
  sectionInfo,
  required,
  components,
  colunm = false,
}) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box>
          <FormSectionTitle title={title} info={info} required={required} />
          {titleInfo && (
            <Box>
              <Typography
                noWrap
                sx={{
                  fontSize: 11,
                  color: theme.palette.grey[400],
                  letterSpacing: 0.5,
                }}
              >
                {titleInfo}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            rowGap: 1,
            columnGap: 1,
            flexWrap: "wrap",
            flexDirection: colunm ? "column" : "row",
            alignItems: colunm ? "" : "flex-start",
          }}
        >
          {components.map((component, index) => {
            const ComponentType = getComponentType(component.type);
            return (
              <Box
                key={index}
                sx={{
                  flex: component.type !== "rtText" && !colunm && 1,
                  flexBasis: component.type !== "rtText" && !colunm && "100px",
                }}
              >
                <ComponentType {...component.props} sectionTitle={title} />
              </Box>
            );
          })}
        </Box>
        {sectionInfo && (
          <Box sx={{ px: 0.5, mt: -0.5 }}>
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: DefineStyle.placeholderColor,
                letterSpacing: 0.5,
              }}
            >
              {sectionInfo}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

// Helper function to get the correct component type
const getComponentType = (type) => {
  switch (type) {
    case "select":
      return CustomSelect;
    case "selectLabel":
      return CustomSelectLabel;
    case "selectLabelMultiple":
      return CustomSelectLabelMultiple;
    case "selectLabelMultipleFrom":
      return CustomSelectLabelMultipleForm;
    case "selectRef":
      return CustomSelectRef;
    case "text":
      return CustomText;
    case "datepicker":
      return CustomDatePicker;
    case "radio":
      return CustomRadio;

    case "checkbox":
      return CustomCheckbox;
    case "switch":
      return CustomSwitch;
    case "textEditor":
      return CustomTextEditor;
    case "file":
      return CustomFileUploadField;
    case "autocomplete":
      return CustomAutoComplete;
    case "autoselect":
      return CustomAutoSelect;
    case "textArea":
      return CustomTextArea;
    case "rtText":
      return RtText;
    case "selectUser":
      return CustomSelectUser;
    default:
      return null;
  }
};

export default CustomFormField;
