import { Box, Tooltip } from "@mui/material";
import React from "react";
import theme from "../../Theme";

const CustomBadge = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 10,
        right: 0,
        bgcolor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
        px: 2,
        py: 0.5,
        borderTopLeftRadius: 50, // Applies border-radius to the top-left corner
        borderBottomLeftRadius: 50, // Applies border-radius to the bottom-left corner
        fontSize: 12,
        lineHeight: 1.5,
        right: -5,
        "&::after": {
          content: '""', // Required for pseudo-elements
          position: "absolute",
          bottom: -5, // Adjust position as needed
          right: 0, // Adjust position as needed
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "5px 5px 0 0", // Top, right, bottom, left
          borderColor: `${theme.palette.error.dark} transparent transparent transparent`, // Adjust colors for the triangle
        },
      }}
    >
        <Tooltip>
      Profile Migrated
      </Tooltip>
    </Box>
  );
};

export default CustomBadge;
