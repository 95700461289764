import { GET_BUILDVERSION_NUM, LATEST_BUILD_VERSION_VIA_API } from "./actionType"

export const getbuildVersionNumViaApi=(action)=>{
    return{
        type: GET_BUILDVERSION_NUM,
        payload: action,
    }
}

export const fetchLatestVersionViaApi=(action)=>{
    return{
        type: LATEST_BUILD_VERSION_VIA_API,
        payload: action,
    }
}