import {
  CLEAR_SEARCH_RESULTS,
  INITIALSTATE_RESUME_DATA,
  SUCCESS_ADVANCED_RESUME_SEARCH,
  SUCCESS_APPLICANT_REFERENCE_ADD_NOTE,
  SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH,
  SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH,
  SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH,
  SUCCESS_INTEGRATE_SEARCH_INTERNALDB_NOTE_UPDATE,
  SUCCESS_INTEGRATIONS,
  SUCCESS_INTERNAL_DB_SEARCH,
  SUCCESS_INTERNALDB_NOTE_UPDATE,
  SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH,
  SUCCESS_SEARCH_RESUME_INTERNAL_DB,
  SUCCESS_SEARCH_RESUMEID_INTERNALDB,
} from "./actionType";

const INIT_STATE = {
  cbResumesList: [],
  resumesFile: [],
  monsterResumesList: [],
  internalResumesList: [],
  internalDBResumes: [],
  integrations: [],
  integrateMonsterResumesList: [],
  integrateCBResumesList: [],
  integrateInternalResumesList: [],
};

const resumes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        cbResumesList: [], // Clear previous results
        monsterResumesList: [],
        internalResumesList: [],
        internalDBResumes: [],
        integrateMonsterResumesList: [],
        integrateCBResumesList: [],
        integrateInternalResumesList: [],
      };

    case SUCCESS_ADVANCED_RESUME_SEARCH:
      return {
        ...state,
        cbResumesList: action.payload,
      };

    case SUCCESS_SEARCH_RESUMEID_INTERNALDB:
      return {
        ...state,
        resumesFile: action.payload,
      };
    case SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH:
      return {
        ...state,
        monsterResumesList: action.payload,
      };

    case SUCCESS_SEARCH_RESUME_INTERNAL_DB:
      return {
        ...state,
        internalResumesList: action.payload,
      };
    case SUCCESS_INTERNAL_DB_SEARCH:
      return {
        ...state,
        internalDBResumes: action.payload,
      };
    case SUCCESS_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
      };
    case SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH:
      return {
        ...state,
        integrateMonsterResumesList: action.payload,
      };
    case SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH:
      return {
        ...state,
        integrateCBResumesList: action.payload,
      };
    case SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH:
      return {
        ...state,
        integrateInternalResumesList: action.payload,
      };

      // case SUCCESS_INTERNALDB_NOTE_UPDATE: {
      //   const newData = action.payload; // Incoming updated data
      //   const oldData = state.internalResumesList; // Existing data in state for internalResumesList
      //   const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old])); // Map for oldData by applicantId
      //   const updatedData = newData
      //     .filter((newItem) => oldDataMap.has(newItem.applicantId)) // Only keep items that already exist in oldData
      //     .map((newItem) => {
      //       return { ...oldDataMap.get(newItem.applicantId), ...newItem }; 
      //     });
      //  console.log('updatedData',updatedData);
      //   return {
      //     ...state,
      //     internalResumesList: updatedData,
      //   };
      // }
      // case SUCCESS_INTEGRATE_SEARCH_INTERNALDB_NOTE_UPDATE: {
      //   const newData = action.payload; // Incoming updated data
      //   const oldData = state.integrateInternalResumesList; // Existing data in state for internalResumesList
      //   const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old])); // Map for oldData by applicantId
      //   const updatedData = newData
      //     .filter((newItem) => oldDataMap.has(newItem.applicantId)) // Only keep items that already exist in oldData
      //     .map((newItem) => {
      //       return { ...oldDataMap.get(newItem.applicantId), ...newItem }; 
      //     });
      //   return {
      //     ...state,
      //     integrateInternalResumesList: updatedData,
      //   };
      // }
      case SUCCESS_INTERNALDB_NOTE_UPDATE: {
        const newData = action.payload; // Incoming updated data
        const oldData = state.internalResumesList; // Existing data in state
        const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old])); // Map oldData by applicantId
      
        const updatedData = oldData.map((oldItem) => {
          // Update if there's a match, otherwise keep the old item
          const newItem = newData.find((newItem) => newItem.applicantId === oldItem.applicantId);
          return newItem ? { ...oldItem, ...newItem } : oldItem;
        });
      
        // Add any new records from newData that don't exist in oldData
        newData.forEach((newItem) => {
          if (!oldDataMap.has(newItem.applicantId)) {
            updatedData.push(newItem);
          }
        });
      
      
        return {
          ...state,
          internalResumesList: updatedData,
        };
      }
      
      case SUCCESS_INTEGRATE_SEARCH_INTERNALDB_NOTE_UPDATE: {
        const newData = action.payload; // Incoming updated data
        const oldData = state.integrateInternalResumesList; // Existing data in state
        const oldDataMap = new Map(oldData.map((old) => [old.applicantId, old])); // Map oldData by applicantId
      
        const updatedData = oldData.map((oldItem) => {
          // Update if there's a match, otherwise keep the old item
          const newItem = newData.find((newItem) => newItem.applicantId === oldItem.applicantId);
          return newItem ? { ...oldItem, ...newItem } : oldItem;
        });
      
        // Add any new records from newData that don't exist in oldData
        newData.forEach((newItem) => {
          if (!oldDataMap.has(newItem.applicantId)) {
            updatedData.push(newItem);
          }
        });
      
        return {
          ...state,
          integrateInternalResumesList: updatedData,
        };
      }
      
      case SUCCESS_APPLICANT_REFERENCE_ADD_NOTE:{
        const newData = action.payload; // Incoming updated data
        const oldData = state.internalResumesList; // Existing data in state for internalResumesList
        const oldDataSecond=state.integrateInternalResumesList
        const updatedData = oldData.map((oldItem) => {
          const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem._id);
          if (matchingNewItem) {
            const oldNote = oldItem?.note || []; // Existing notes or an empty array
            const updatedNote = matchingNewItem || {}; // Get the new note (or an empty object if not present)
            return { 
              ...oldItem, 
              note: [...oldNote, updatedNote] // Combine old and new notes
            };
          }
          return oldItem; // Return the old item if no match
        });
        const updatedDataSecond = oldDataSecond.map((oldItem) => {
          const matchingNewItem = newData.find((newItem) => newItem.applicantId === oldItem._id);
          if (matchingNewItem) {
            const oldNote = oldItem?.note || []; // Existing notes or an empty array
            const updatedNote = matchingNewItem || {}; // Get the new note (or an empty object if not present)
            return { 
              ...oldItem, 
              note: [...oldNote, updatedNote] // Combine old and new notes
            };
          }
          return oldItem; // Return the old item if no match
        });
        return{
          ...state,
          internalResumesList:updatedData,
          integrateInternalResumesList:updatedDataSecond,
        }
      }
      
      
      
    case INITIALSTATE_RESUME_DATA: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default resumes;
