import React, { useRef } from 'react'
import CustomFormField from './Common/CustomFormField'
import { Box } from '@mui/material'

const ApplicantSelectActionType = 
({selectAction,addNote,setAddNote,jobPostingOptions,selectJobTag,
    handleJobSelect,blacklistReasons,blackListReason,
    setBlackListReason,blackListCommit,setBlackListCommit,
    notePriority,setNotePriory
}) => {
  const quillRef = useRef(null); // Initialize quillRef
  return (
    <Box>
    {selectAction === "Add Notes" && (
      <>
        <CustomFormField
                  title={"Notes Priority"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Notes Priority",
                        defaultValue: notePriority,
                        options: ["Critical", "High", "Medium", "Low"],
                        onChange:(value)=>setNotePriory(value)
                      },
                    },
                  ]}
                />
                {/* <CustomFormField
          title={"Add Notes"}
          components={[
            {
              type: "textArea",
              props: {
                title: "Action",

                //multiple: true,
                defaultValue: addNote,
                onChange: (value) => setAddNote(value),
              },
            },
          ]}
        /> */}
        <CustomFormField
                  title={"Notes"}
                  components={[
                    {
                      type: "textEditor",
                      props: {
                        placeholder: "Add Notes",
                        defaultValue:addNote,
                        quillRef:quillRef,
                        onChange:(value)=>setAddNote(value),
                      },
                    },
                  ]}
                />
      </>
        
      )}
      {(selectAction === "Submit to Job" ||
        selectAction === "Tag to Job") && (
        <CustomFormField
          title={selectAction}
          components={[
            {
              type: "autocomplete",
              props: {
                placeholder: `select the job`,
                options: jobPostingOptions,
                defaultValue: selectJobTag,
                onChange: (value) => handleJobSelect(value),
                // error: !!errors.sourceOfResume,
                // errorMessage: errors.sourceOfResume,
              },
            },
          ]}
        />
      )}
      {selectAction==="Add Blacklist"&&(
        <>
        <CustomFormField
        title={'Reason'}
        components={[
          {
            type:'select',
            props:{
              placeholder:"select",
              options:blacklistReasons,
              defaultValue:blackListReason,
              onChange:(value)=>setBlackListReason(value)
            }
          }
        ]}
        />
        <CustomFormField
        title={"Comment"}
        components={[
          {
            type: "textArea",
            props: {
              title: "Action",

              //multiple: true,
              defaultValue: blackListCommit,
              onChange: (value) => setBlackListCommit(value),
            },
          },
        ]}
        />
        </>
      )}
      </Box>
  )
}

export default ApplicantSelectActionType