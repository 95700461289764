const initial_state = {
    versionNum: null,
    featuresList: []
}

const buildVersionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case 'GET_BUILDVERSION_NUM': {
            return {...state, 
                versionNum : action.payload.versionNum,
                featuresList : action.payload.featuresList
            };
        }
        default:
            return state;
    }
};

export default buildVersionReducer;