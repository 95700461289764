import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Tab,
  Typography,
  Modal,
  Tooltip,
  Avatar,
  CircularProgress,
  Backdrop,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import theme from "../../Theme";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { pink } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import GroupsIcon from "@mui/icons-material/Groups";
import ShareIcon from "@mui/icons-material/Share";
import LabelIcon from "@mui/icons-material/Label";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { KeyboardArrowUp } from "@mui/icons-material";
import {
  addApplicantJobPostReferenceViaApi,
  addApplicantNoteViaApi,
  addJobBoardsHistoryViaApi,
  advancedResumeSearchViaApi,
  awsResumeUrl,
  getAllDegreesViaApi,
  getAllTemplatesViaApi,
  getAllUsers,
  getIntegrationsViaApi,
  getJobBoardsHistoryViaApi,
  getMonsterResumeAndStoreInternalDbViaApi,
  getResumeAndStoreInternalDbViaApi,
  internalDBLoading,
  monsterAdvancedResumeSearchViaApi,
  monsterPowerSearchViaApi,
  searchDateJobPostingDataViaApi,
  searchResumeInternalDbViaApi,
  stopLoadingRedux,
  successAwsResumeUrl,
  successHtmlResumeConvert,
  tagApplicantToJobViaApi,
} from "../../store/actions";
import { ShimmerText } from "react-shimmer-effects";

import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import Highlighter from "react-highlight-words";
import {
  AlertModal,
  CopyToClipboardButton,
  CustomBadge,
  CustomFormField,
  JobBoardMigrationModal,
  JobBoardResultTable,
  JobBoardsAddNotes,
  JobBoardsQuickSubmitCheckAutharize,
  JobBoardsQuickSubmitConfirmModal,
  JobBoardsQuickSubmitSelectJob,
  KeyData,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
  JobBoardResultSideFilters,
} from "../../Components";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  optionsMonsterPostFrom,
  optionsMonsterTo,
  noteActionTexts,
  monsterSort,
  MonsterPowerSearchSalaryRangeOptions,
  countryMonsterOptions,
  MonsterPowerSearchRadiusOptions,
  MonsterPowerSearchResumeUpdatedOptions,
  MonsterPowerSearchHourlySalaryRangeOptions,
  MonsterPowerSearchTravelRequirementsOptions,
  MonsterPowerSearchTenureOptions,
  MonsterEducationLevel,
  MonsterSearchResumeUpdatedOptions,
  optionsValues,
  optionValuescitizen,
  ClearanceLeveloptions,
  workAuthorizeOptions,
  careerLevelOptions,
  excludeViewedByMeOptionsCareerBuilder,
  yearsOfExperienceOptionsCareerBuilder,
  resumeModifiedToOptionsCareerBuilder,
  resumeModifiedFromOptionsCarrerBuilder,
} from "../../Data/JobPostingData";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import CandidateSendMail from "../../Components/Modals/CandidateSendMail";
import CandidatesMailMerge from "../../Components/Modals/CandidatesMailMerge";
import JobBoardMonsterPowerSearch from "../../Components/Tabs/JobBoardMonsterPowerSearch";
import JobBoardMonsterSearchMonster from "../../Components/Tabs/JobBoardMonsterSearchMonster";
import { GetMonsterAccountInfo } from "../../utils/GetMonsterAccountInfo";
import CommanFilters from "../../utils/CommanFilters";
import { fontSize } from "@mui/system";
const JobBoardsResult = () => {
  const { getYearsfromMonths, jobPostingReplacePlaceholders, getResumes } =
    CommanFilters;
  const apiUrl = process.env.REACT_APP_API_URL;
  const locationData = useLocation();
  const navigate = useNavigate();
  const actionData = locationData.state || {};
  const dispatch = useDispatch();
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const resumesFile = useSelector((state) => state.Resumes.resumesFile);

  const internalDBLoadingRedux = useSelector(
    (state) => state.JobBoards?.internalDBLoading
  );
  const internalDBMatchedResumesRedux = useSelector(
    (state) => state.JobBoards.internalDBMatchedResumes
  );
  const internalDBMatchedResumes = Array.isArray(internalDBMatchedResumesRedux)
    ? internalDBMatchedResumesRedux
    : [];
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client = storeData.clientDb;
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const HtmlResume = useSelector((state) => state.Recruiters?.htmlResume);
  const integrationsList = useSelector((state) => state.Resumes.integrations);
  const [searchType, setSearchType] = useState(actionData?.searchType || "");
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  //
  const [sortFilter, setSortFilter] = useState("rank,distance,mdate");
  const MonsterResumesList = useSelector(
    (state) => state.Resumes.monsterResumesList
  );
  const [country, setCountry] = useState(840);
  const [radius, setRadius] = useState(actionData?.radius || 30);
  const [selectedStates, setSelectedStates] = useState([]);
  const [optionsCityZip, setOptionCityZip] = useState([]);
  const [searchTerm, setSearchTerm] = useState(actionData?.searchTerm || "");
  const [selectCityZip, setSelectCityZip] = useState(
    actionData?.selectCityZip || ""
  );
  const [highEducation, setHighEducation] = useState(
    actionData?.highEducation || ""
  );
  const [stateMonsterOptions, setStateMonsterOptions] = useState([]);

  const [tsni, setTsni] = useState(actionData?.tsni || "");
  const [wa, setWa] = useState(actionData?.wa || "");
  const [tnsalmax, setTnsalmax] = useState(actionData?.tnsalmax || "");
  const [tnsalmin, setTnsalmin] = useState(actionData?.tnsalmin || "");
  //power Search
  const [powerSearchType, setPowerSearchType] = useState("Semantic");
  const [resumeUpdate, setResumeUpdate] = useState(
    actionData?.resumeUpdate || 0
  );
  const [minedulvid, setMinedulvid] = useState(actionData?.minedulvid || "");
  const [salaryRangeType, setSalaryRangeType] = useState(
    actionData?.salaryRangeType || "Per Year"
  );
  const [salaryRangeOptions, setSalaryRangeOptions] = useState(
    MonsterPowerSearchSalaryRangeOptions
  );
  const [salaryRange, setSalaryRange] = useState(actionData.salaryRange || "");
  const [edumjr, setEdumjr] = useState(actionData.edumjr || "");
  const [wtt, setWtt] = useState(actionData.wtt || "");
  const [ten, setTen] = useState(actionData.ten || "");
  const [gsc, setGsc] = useState(actionData.lstat || "");
  const [lstat, setLstat] = useState(actionData.lstat || "");
  const [relo, setRelo] = useState(actionData.relo || "");
  const [tjtid, setTjtid] = useState(actionData.tjtid || "");
  const [bkeyword, setBkeyword] = useState(actionData.bkeyword || "");
  const [experience, setExperience] = useState(actionData.experience || "");
  const [jt, setJt] = useState(actionData?.jt || [{ id: 1, value: "" }]);
  const [skills, setSkills] = useState(
    actionData.skills || [{ id: 1, value: "" }]
  );
  const [locationSearch, setLocationSearch] = useState(
    actionData.locationSearch || [{ id: 1, value: "" }]
  );
  const [locationRadius, setLocationRadius] = useState(
    actionData.locationRadius || [{ id: 1, value: 50 }]
  );
  const [selectCityState, setSelectCityState] = useState(
    actionData.selectCityState || [{ id: 1, value: "" }]
  );
  const [searchCityState, setSearchCityState] = useState(
    actionData.searchCityState || [{ id: 1, value: "" }]
  );
  //monsterSearch
  const [mSearchType, setMSearchType] = useState("Semantic Search");
  const [veteran, setVeteran] = useState("");
  const [comp, setComp] = useState([{ id: 1, value: "" }]);
  const [edumjrMonster, setEdumjrMonster] = useState([{ id: 1, value: "" }]);
  const [edumjrTypeMonster, setEdumjrTypeMonster] = useState([
    { id: 1, value: "" },
  ]);
  const [sch, setSch] = useState([{ id: 1, value: "" }]);
  const [cname, setCname] = useState("");
  const [clvid, setClvid] = useState("");
  const handleSelectCityZip = (value) => {
    setSearchTerm(value);
    setSelectCityZip(value);
  };
  const handleSelectCountry = async (value) => {
    setCountry(value);
    try {
      const response = await fetch(`${apiUrl}/countries/getStates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: "tektree", // Replace with the actual client value if dynamic
          countryCode: value, // Replace with the actual countryCode value if dynamic
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }

      const data = await response.json();
      setStateMonsterOptions(data);
      // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const CbResumesList = useSelector((state) => state.Resumes.cbResumesList);
  const InternalResumesList = useSelector(
    (state) => state.Resumes.internalResumesList
  );
  const internalDBCandidateList = Array.isArray(InternalResumesList)
    ? InternalResumesList.map((applicant, index) => ({
        id: index,
        ...applicant,
      }))
    : [];
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
  const templatesData = useSelector((state) => state.Search.templates) || [];
  //Monster
  // Function to calculate experience
  function calculateExperience(resume) {
    const experienceElements = resume.querySelectorAll("Experience");
    let totalExperienceYears = 0;
    let totalExperienceMonths = 0;

    experienceElements.forEach((experience) => {
      const experienceFromDate = experience.querySelector("DateFrom");
      const experienceToDate = experience.querySelector("DateTo");
      const experienceYears = experience.querySelector("Years");

      // If the Years element is present, use it directly
      if (experienceYears) {
        const years = parseFloat(experienceYears.textContent);
        totalExperienceYears += Math.floor(years);
        totalExperienceMonths += Math.round((years % 1) * 12);
      } else if (experienceFromDate && experienceToDate) {
        const yearFrom = parseInt(experienceFromDate.getAttribute("Year"), 10);
        const monthFrom = parseInt(
          experienceFromDate.getAttribute("Month"),
          10
        );
        const yearTo = parseInt(experienceToDate.getAttribute("Year"), 10);
        const monthTo = parseInt(experienceToDate.getAttribute("Month"), 10);

        let years = yearTo - yearFrom;
        let months = monthTo - monthFrom;

        if (months < 0) {
          years--;
          months += 12;
        }

        totalExperienceYears += years;
        totalExperienceMonths += months;
      }
    });

    // Normalize months to years
    if (totalExperienceMonths >= 12) {
      totalExperienceYears += Math.floor(totalExperienceMonths / 12);
      totalExperienceMonths = totalExperienceMonths % 12;
    }

    return { years: totalExperienceYears, months: totalExperienceMonths };
  }
  let matchedMonsterResult = 0;
  function parseMonsterCandidatedData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      MonsterResumesList,
      "application/xml"
    );
    const resumes = xmlDoc.getElementsByTagName("Resume");

    const candidates = Array.from(resumes).map((resume, index) => {
      const firstName = resume.querySelector("First")?.textContent || "";
      const lastName = resume.querySelector("Last")?.textContent || "";
      const middleName = resume.querySelector("Middle")?.textContent || "";
      const email =
        resume.querySelector("MD5-EmailAddress")?.textContent || "N/A";
      const board = resume.querySelector("Board");
      const city = resume.querySelector("City")?.textContent || "N/A";

      // Query Location inside each resume
      const locationElement = resume.querySelector("Location");
      let country = "N/A",
        state = "N/A",
        cityFromLocation = "N/A";
      if (locationElement) {
        const location = locationElement.textContent || "";
        [country, state, cityFromLocation] = location.split("-");
      }

      const relocation =
        resume.querySelector("Relocation")?.textContent || "N/A";
      const title = resume.querySelector("Title")?.textContent || "N/A";
      const description =
        resume.querySelector("Description")?.textContent || "N/A";
      const dateModified =
        resume.querySelector("DateModified")?.textContent || "N/A";
      const companyName =
        resume.querySelector("Company > Name")?.textContent || "N/A";

      // JobTypes
      const jobTypeElements = resume.getElementsByTagName("JobType");
      const jobTypes = Array.from(jobTypeElements).map(
        (jobType) => jobType.textContent
      );
      const desiredJobTypes = `${jobTypes.join(", ")}`;
      // Experience Calculation
      const experienceFromDate = resume.querySelector("Experience > DateFrom");
      const experienceToDate = resume.querySelector("Experience > DateTo");
      const educationElements = resume.getElementsByTagName("Education");
      const educationLevels = Array.from(educationElements)
        .map((education) => {
          const level = education.querySelector("Level");
          return level ? level.textContent : null;
        })
        .filter((level) => level !== null);
      const salaryElement = resume.querySelector("Salary");
      const minSalary = salaryElement?.querySelector("Min")?.textContent || "0";
      const maxSalary = salaryElement?.querySelector("Max")?.textContent || "0";
      const currency =
        salaryElement?.querySelector("Currency")?.textContent || "N/A";
      const type = salaryElement?.querySelector("Type")?.textContent || "N/A";

      const { years, months } = calculateExperience(resume);
      const isConfidential =
        resume.querySelector("Confidential")?.textContent === "1";
      const name = isConfidential
        ? "Confidential"
        : `${firstName || ""} ${middleName || ""} ${lastName || ""}`;
      const resumesNode = xmlDoc.getElementsByTagName("Resumes")[0];
      matchedMonsterResult = resumesNode.getAttribute("Found");
      // Extracting military experience data
      const militaryServiceFlag =
        resume.querySelector("MilitaryExperience > ServiceFlag")?.textContent ||
        "";
      const militaryInvolvement =
        resume.querySelector("MilitaryExperience > MilitaryInvolvement")
          ?.textContent || "";
      const militaryExperienceData = {
        ServiceFlag: militaryServiceFlag,
        MilitaryInvolvement: militaryInvolvement,
      };
      const resumeTitle =
        resume.querySelector("ResumeTitle")?.textContent || "";
      const experienceJobTitle =
        resume.querySelector("Experience Job Title")?.textContent || "";
      const experienceJobDescription =
        resume.querySelector("Experience Job Description")?.textContent || "";
      const experienceDateFrom =
        resume.querySelector("Experience DateFrom")?.getAttribute("Year") || "";
      const experienceDateTo =
        resume.querySelector("Experience DateTo")?.textContent || "";
      const experienceData = {
        Company: companyName,
        JobTitle: experienceJobTitle,
        JobDescription: experienceJobDescription,
        DateFrom: experienceDateFrom,
        DateTo: experienceDateTo,
      };
      // Extract Work Authorization
      const workAuths = resume.querySelectorAll("WorkAuths > WorkAuth");
      let authorization = "N/A";

      if (workAuths.length > 0) {
        Array.from(workAuths).forEach((workAuth) => {
          const countryID = workAuth
            .querySelector("Country")
            ?.getAttribute("ID");
          const authType = workAuth.querySelector("AuthType")?.textContent;
          if (countryID === "164" && authType) {
            authorization = `US - ${authType}`;
          }
        });

        // If no US authorization was found, fallback to the first available WorkAuth
        if (authorization === "N/A") {
          const firstWorkAuth = workAuths[0];
          const fallbackAuthType =
            firstWorkAuth.querySelector("AuthType")?.textContent || "N/A";
          authorization = `Other - ${fallbackAuthType}`;
        }
      } else {
        // Handle case where WorkAuths is empty
        authorization = "No work authorization data available";
      }
      const targetElement = resume.querySelector("Target");
      const targetJobTitle = targetElement
        ? targetElement.querySelector("JobTitle")?.textContent || "N/A"
        : "N/A";
      const dateModifiedElement = resume.querySelector("DateModified");
      let formattedDateModified = "N/A";

      if (dateModifiedElement) {
        const rawDate = dateModifiedElement.getAttribute("Date");
        if (rawDate) {
          const date = new Date(rawDate);
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const year = date.getFullYear().toString().slice(-2);
          formattedDateModified = `${month}/${day}/${year}`;
        }
      }
      const experiences = resume.querySelectorAll("Experiences > Experience");

      let recentExperience = null;
      let latestStartDate = new Date(0); // Initialize with the earliest possible date

      experiences.forEach((experience) => {
        const dateFromElement = experience.querySelector("DateFrom");
        const companyElement = experience.querySelector("Company > Name");
        const jobTitleElement = experience.querySelector("Job > Title");

        if (dateFromElement) {
          const year = parseInt(dateFromElement.getAttribute("Year"), 10);
          const month = parseInt(dateFromElement.getAttribute("Month"), 10) - 1; // JS months are zero-based
          const startDate = new Date(year, month);

          if (startDate > latestStartDate) {
            latestStartDate = startDate;
            recentExperience = {
              jobTitle: jobTitleElement?.textContent || "N/A",
              company: companyElement?.textContent || "N/A",
            };
          }
        }
      });
      // Extract skills
      const skills = Array.from(resume.querySelectorAll("Skill")).map(
        (skill) => {
          const name =
            skill.querySelector("Name")?.textContent || "Unknown Skill";
          const yrsUsed = parseFloat(
            skill.querySelector("YrsUsed")?.textContent || "0"
          );
          let lastUsed = skill.querySelector("LastUsed")?.textContent || "N/A";

          // Convert LastUsed to YYYY-MM-DD if it's in a valid format
          if (lastUsed !== "N/A") {
            const date = new Date(lastUsed);
            lastUsed = isNaN(date.getTime())
              ? "Invalid Date"
              : date.toISOString().split("T")[0];
          }
          return { name, yrsUsed, lastUsed };
        }
      );

      // Get the top 3 skills by years of experience
      const topSkills = skills
        .filter((skill) => skill.yrsUsed > 0) // Exclude skills with 0 experience
        .sort((a, b) => b.yrsUsed - a.yrsUsed) // Sort by years of experience descending
        .slice(0, 3); // Take top 3 skills
      const decendingSkills = skills
        .filter((skill) => skill.yrsUsed > 0) // Exclude skills with 0 experience
        .sort((a, b) => b.yrsUsed - a.yrsUsed);
      return {
        id: index,
        ResumeId: resume.getAttribute("SID") || "N/A",
        ResumeBoardId: board?.getAttribute("ID") || "N/A",
        Name: name,
        Source: board?.textContent || "N/A",
        Email: email,
        Location: {
          country: country || "N/A",
          state: state || "N/A",
          city: cityFromLocation || "N/A",
        },
        Relocation: relocation,
        JobTitle: targetJobTitle,
        RecentExperienceJobTitle: recentExperience?.jobTitle,
        Description: description,
        ResumeUpdated: dateModified,
        DesiredJobType: desiredJobTypes,
        HighestEducation:
          educationLevels.length > 0 ? educationLevels[0] : "N/A",
        DesiredSalary: `${minSalary}-${maxSalary} ${currency}/${type}`,
        ExperienceYears: years || 0,
        ExperienceMonths: months || 0,
        companyName: companyName,
        experienceData: experienceData,
        resumeTitle: resumeTitle,
        militaryExperienceData: militaryExperienceData,
        middleName: middleName,
        Authorization: authorization,
        RecentExperience: recentExperience
          ? `${recentExperience.jobTitle}, ${recentExperience.company}`
          : "",
        ResumeUpdated: formattedDateModified,
        TopSkills: topSkills,
        AllSkills: decendingSkills,
      };
    });

    return candidates;
  }

  // Call the function to parse candidate data
  const monsterCandidateList = parseMonsterCandidatedData();
  const [keyWords, setKeyWords] = useState(actionData.keyWords || "");
  const [errorKeyword, setErrorKeyword] = useState("");
  const [fromDate, setFromDate] = useState(actionData?.mdateminage || 0);
  const [toDate, setToDate] = useState(actionData?.mdatemaxage || 43200);
  const [cn, setcn] = useState(actionData?.qrcn || "");
  const [jobTitle, setJobTitle] = useState(
    actionData?.qrjt || actionData.jobTitle || ""
  );
  const [jobDescription, setJobDescription] = useState(actionData?.qrjd || "");
  const [schoolAttend, setSchoolAttend] = useState(actionData?.qsn || "");
  const handleSearchMonster = () => {
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }
    const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
      storeData,
      integrationsList,
      closeSnackbar
    );
    const action = {
      sort: sortFilter,
      radius: actionData?.radius || 30,
      query: keyWords,
      mdateminage: fromDate,
      mdatemaxage: toDate,
      country: actionData?.rpcr ? "" : actionData?.country,
      rlid: actionData?.rlid || 316,
      zipcode: actionData?.zipcode,
      rpcr: actionData?.rpcr || "",
      tsni: actionData?.tsni || "",
      twlid: actionData?.twlid || "",
      wa: actionData?.wa || "",
      qrcn: cn,
      qrjt: jobTitle,
      qrjd: jobDescription,
      qsn: schoolAttend,
      tnsalmin: actionData?.tnsalmin || "",
      tnsalmax: actionData?.tnsalmax || "",
      tsaltyp: actionData?.tsaltyp || "",
      tsalcur: actionData?.tsalcur || "",
      clv: actionData?.clv || "",
      edulv: actionData?.edulv || "",
      tjtft: actionData?.tjtft || "",
      tjtpt: actionData?.tjtpt || "",
      tjtpd: actionData?.tjtpd || "",
      tjtp: actionData?.tjtp || "",
      tjti: actionData?.tjti || "",
      tjttc: actionData?.tjttc || "",
      tjts: actionData?.tjts || "",
      Lskill: actionData?.Lskill || "",
      twindus: actionData?.twindus || "",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    setLoading(true);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "MonsterClassicSearch",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 1500);
  };
  ///powerSearch
  const combineData = (array1, array2) => {
    if (array1.length === array2.length) {
      return array1.map((item, index) => `${item}-${array2[index]}`);
    }
    if (array1.length > array2.length) {
      return array1.map((item, index) =>
        array2[index] ? `${item}-${array2[index]}` : item
      );
    }
    if (array2.length > array1.length) {
      return array2.map((item, index) =>
        array1[index] ? `${array1[index]}-${item}` : item
      );
    }
    return [...array1, ...array2];
  };

  //CarrerBuilder
  const [excludeViewedByMe, setExcludeViewedByMe] = useState("");
  const [yearofexpfrom, setYearofexpfrom] = useState("");
  const [yearofexpto, setYearofexpto] = useState("");
  const [resumeModifiedFrom, setResumeModifiedFrom] = useState("");
  const [resumeModifiedTo, setResumeModifiedTo] = useState("");
  const [contactInfo, setContactInfo] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);

  const handlePageChange = (event, value) => {
    if (searchType === "MonsterClassicSearch") {
      if (!keyWords) {
        return setErrorKeyword("please type any keyword");
      }
      const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      const action = {
        sort: sortFilter,
        radius: actionData.radius || 30,
        query: keyWords,
        mdateminage: fromDate,
        mdatemaxage: toDate,
        country: actionData?.rpcr ? "" : actionData?.country || 840,
        rlid: actionData?.rlid || 316,
        zipcode: actionData?.zipcode || "",
        rpcr: actionData?.rpcr || "",
        tsni: actionData?.tsni || "",
        twlid: actionData?.twlid || "",
        wa: actionData?.wa || "",
        qrcn: cn,
        qrjt: jobTitle,
        qrjd: jobDescription,
        qsn: schoolAttend,
        tnsalmin: actionData?.tnsalmin || "",
        tnsalmax: actionData?.tnsalmax || "",
        tsaltyp: actionData?.tsaltyp || "",
        tsalcur: actionData?.tsalcur || "",
        clv: actionData?.clv || "",
        edulv: actionData?.edulv || "",
        tjtft: actionData?.tjtft || "",
        tjtpt: actionData?.tjtpt || "",
        tjtpd: actionData?.tjtpd || "",
        tjtp: actionData?.tjtp || "",
        tjti: actionData?.tjti || "",
        tjttc: actionData?.tjttc || "",
        tjts: actionData?.tjts || "",
        Lskill: actionData?.Lskill || "",
        twindus: actionData?.twindus || "",
        page: value,
        pagesize: pageSize,
        client: client,
        monsterAccountId: monsterAccountId,
      };
      setPage(value);
      setLoading(true);
      dispatch(monsterAdvancedResumeSearchViaApi(action));
      setTimeout(() => {
        dispatch(getIntegrationsViaApi(action));
      }, 2000);
    } else if (searchType === "MonsterPowerSearch") {
      const { monsterAccountId } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      const jobTileExtract = jt?.map((item) => item.value);
      const skillsExtract = skills?.map((item) => item.value);
      const selectCityStateExtract = selectCityState
        ?.map((item) => item.value)
        .filter((value) => typeof value === "string" && value.trim() !== "");
      const locationRadiusExtract = locationRadius
        ?.map((item) => item.value)
        .filter((value) => typeof value === "string" && value.trim() !== "");
      const combinedData =
        selectCityStateExtract.length > 0
          ? combineData(selectCityStateExtract, locationRadiusExtract)
          : [];
      const matchedValues =
        tjtid &&
        tjtid.length > 0 &&
        optionsValues
          .filter((option) => tjtid.includes(option.label))
          .map((option) => option.value);
      const lstatMatches =
        lstat &&
        lstat.length > 0 &&
        optionValuescitizen
          .filter((option) => lstat.includes(option.label))
          .map((option) => option.value);

      const gscMatches =
        gsc &&
        gsc.length > 0 &&
        ClearanceLeveloptions.filter((option) =>
          gsc.includes(option.label)
        ).map((option) => option.value);
      const action = {
        bkeyword: bkeyword,
        sk: skillsExtract
          ? skillsExtract.map((skill) => `${skill} nth`).join(",")
          : "",
        jt: jobTileExtract ? jobTileExtract.join(",") : "",
        experience: experience,
        location:
          selectCityStateExtract.length > 0
            ? combinedData.join("+") === "-"
              ? ""
              : combinedData.join("+")
            : "",
        minedulvid: highEducation,
        edumjr: edumjr,
        tjtid: matchedValues.length > 0 ? matchedValues.join(",") : "",
        mdatemaxage: resumeUpdate === 0 ? 1576800 : 1440 * resumeUpdate,
        ten: ten || "",
        wtt: wtt ? wtt : 1,
        relo: relo ? true : "",
        lstat: lstatMatches.length > 0 ? lstatMatches.join(",") : "",
        tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
        gsc: gscMatches.length > 0 ? gscMatches.join(",") : "",
        country: "US",
        page: value,
        pagesize: pageSize,
        client: client,
        monsterAccountId: monsterAccountId,
      };
      setPage(value);
      setLoading(true);
      dispatch(monsterPowerSearchViaApi(action));
    } else if (searchType === "MonsterSearch") {
      const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      const tenExtract =
        ten &&
        MonsterPowerSearchTenureOptions.filter(
          (option) => ten === option.label
        ).map((option) => option.value);
      const waExtractt = workAuthorizeOptions
        ? workAuthorizeOptions
            .filter((option) => wa === option.label)
            .map((option) => option.value)
        : []; // Default to an empty array if workAuthorizeOptions is undefined
      const waData = Array.isArray(waExtractt) ? waExtractt.join(",") : "";
      const clvidMatches =
        clvid &&
        clvid.length > 0 &&
        careerLevelOptions
          .filter((option) => clvid.includes(option.label))
          .map((option) => option.value);
      const compExtract = comp
        ?.map((item) => item.value)
        .filter((value) => typeof value === "string" && value.trim() !== "");
      const schExtract = sch
        ?.map((item) => item.value)
        .filter((value) => typeof value === "string" && value.trim() !== "");
      const matchedValues =
        tjtid &&
        tjtid.length > 0 &&
        optionsValues
          .filter((option) => tjtid.includes(option.label))
          .map((option) => option.value);
      const lstatMatches =
        lstat &&
        lstat.length > 0 &&
        optionValuescitizen
          .filter((option) => lstat.includes(option.label))
          .map((option) => option.value);
      const gscMatches =
        gsc &&
        gsc.length > 0 &&
        ClearanceLeveloptions.filter((option) =>
          gsc.includes(option.label)
        ).map((option) => option.value);
      const wttMatches =
        wtt &&
        MonsterPowerSearchTravelRequirementsOptions.filter(
          (option) => wtt === option.label
        ).map((option) => option.value);
      const highEducationMatches =
        highEducation &&
        MonsterEducationLevel.filter(
          (option) => highEducation === option.label
        ).map((option) => option.value);
      const edumjrMonsterExtract = edumjrMonster
        ?.map((item) => item.value)
        .filter((value) => typeof value === "string" && value.trim() !== "");
      const action = {
        bkeyword: bkeyword,
        jt: jobTitle,
        sk: keyWords ? `${keyWords} nth`.replace(/\s/g, "+") : "",
        location: selectCityZip ? `${selectCityZip}-${radius}` : "",
        relo: relo ? true : "",
        veteran: veteran ? 1 : "",
        wa: waData ? `${164}-${waData}` : "",
        clvid: clvidMatches.length > 0 ? clvidMatches.join(",") : "",
        ten: tenExtract.length > 0 ? tenExtract.join(",") : "",
        experience: experience,
        comp: compExtract.length > 0 ? compExtract.join(",") : "",
        edumjr:
          edumjrMonsterExtract.length > 0 ? edumjrMonsterExtract.join(",") : "",
        sch: schExtract.length > 0 ? schExtract.join(",") : "",
        mdatemaxage: resumeUpdate === 0 ? 1576800 : 1440 * resumeUpdate,
        tjtid: matchedValues.length > 0 ? matchedValues.join(",") : "",
        lstat: lstatMatches.length > 0 ? lstatMatches.join(",") : "",
        gsc: gscMatches.length > 0 ? gscMatches.join(",") : "",
        wtt: wtt ? wttMatches.join(",") : "",
        tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
        minedulvid: highEducation ? highEducationMatches.join(",") : "",
        country: "US",
        page: value,
        pagesize: pageSize,
        client: client,
        monsterAccountId,
      };
      setPage(value);
      setLoading(true);
      dispatch(monsterPowerSearchViaApi(action));
    } else if (searchType === "CareerBuilder") {
      if (!keyWords) {
        return setErrorKeyword("please type any keyword");
      }
      const action = {
        Keywords: keyWords,
        State: actionData.State,
        City: actionData.City,
        ZipCode: actionData.ZipCode,
        Names: actionData.Names,
        JobTitle: actionData.JobTitle,
        Employer: actionData.Employer,
        ExcludeTerms: actionData.ExcludeTerms,
        Radius: actionData.Radius,
        PageNumber: value,
        RowsPerPage: pageSize,
        client: client,
      };
      dispatch(advancedResumeSearchViaApi(action));
      setPage(value);
      setLoading(true);
    } else if (searchType === "InternalDB") {
      if (!keyWords) {
        return setErrorKeyword("please type any keyword");
      }
      const action = {
        client: client,
        keyWords: keyWords,
        name: actionData.name,
        jobTitle: actionData.jobTitle,
        pageSize: pageSize,
        page: value,
      };
      setPage(value);
      dispatch(searchResumeInternalDbViaApi(action));
      setLoading(true);
    }
  };
  //CareerBuilder
  let matchedCBResult = 0;
  function parseCBCandidateData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      CbResumesList.V2_AdvancedResumeSearchResult,
      "application/xml"
    );
    const candidates = Array.from(
      xmlDoc.getElementsByTagName("ResumeResultItem_V3")
    );
    matchedCBResult = xmlDoc.getElementsByTagName("Hits")[0]?.textContent;
    return candidates.map((item, index) => {
      return {
        id: index, // Assuming id is simply the index + 1
        Name: item.getElementsByTagName("ContactName")[0].textContent || "N/A",
        Email:
          item.getElementsByTagName("ContactEmailMD5")[0].textContent || "N/A",
        phone: item.getElementsByTagName("RecentPay")[0].textContent || "N/A", // Replace with the actual phone field if available
        ResumeId: item.getElementsByTagName("ResumeID")[0].textContent || "N/A",
        ContactEmail:
          item.getElementsByTagName("ContactEmail")[0].textContent || "N/A",
        HomeLocation:
          item.getElementsByTagName("HomeLocation")[0].textContent || "N/A",
        UserDID: item.getElementsByTagName("UserDID")[0].textContent || "N/A",
        SFNmc: item.getElementsByTagName("SFNmc")[0].textContent || "N/A",
        source: "Career Builder",
        HasWordDoc:
          item.getElementsByTagName("HasWordDoc")[0].textContent || "N/A",
        title: item.getElementsByTagName("ResumeTitle")[0].textContent || "N/A",
        RecentJobTitle:
          item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        highestEducation:
          item.getElementsByTagName("HighestDegree")[0].textContent || "N/A",
        MonthsOfExperience:
          item.getElementsByTagName("MonthsOfExperience")[0].textContent ||
          "N/A",
        RecentEmployer:
          item.getElementsByTagName("RecentEmployer")[0].textContent || "N/A",
        lastUpdated:
          item.getElementsByTagName("LastUpdate")[0].textContent || "N/A",
        JobTitle: item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        resume: {
          LastActivity:
            item.getElementsByTagName("LastActivity")[0].textContent || "N/A",
          JobTitle:
            item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
          authorization: "US", // Assuming US as default
        },
        location: {
          city:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[2] || "N/A",
          state:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[1] || "N/A",
          country: "US", // Assuming US as default
        },
      };
    });
  }
  const cbCandidateList = parseCBCandidateData();

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [revertHtml, setRevertHtml] = useState(false);
  const [htmlResume, setHtmlResume] = useState("");
  const candidateList =
    searchType === "CareerBuilder" ? cbCandidateList : monsterCandidateList;

  const filterAlreadyMigratedProfiles = candidateList.filter((data) =>
    internalDBMatchedResumes.some(
      (dataMatch) => String(dataMatch.resumeId) === String(data.ResumeId)
    )
  );
  const filterSelectedCandidateMigrated = internalDBMatchedResumes.filter(
    (data) =>
      selectedRows.some(
        (dataMatch) => String(dataMatch.ResumeId) === String(data.resumeId)
      )
  );

  const handleCheckboxChange = (event, row, type) => {
    // Check if the type belongs to Monster or CareerBuilder
    if (
      type === "MonsterClassicSearch" ||
      type === "MonsterPowerSearch" ||
      type === "MonsterSearch" ||
      type === "CareerBuilder"
    ) {
      const filterPreviousData = selectedRows.filter((data) =>
        internalDBMatchedResumes.some(
          (dataMatch) => String(dataMatch.resumeId) === String(data.ResumeId)
        )
      );
      const findPresentData = internalDBMatchedResumes.find(
        (data) => String(data.resumeId) === String(row.ResumeId)
      );

      if (filterPreviousData.length === 0) {
        if (selectedRows.length === 0) {
          setSelectedRows([row]); // Set the selected rows state with the current row
        } else if (!findPresentData) {
          const newSelectedRows = event.target.checked
            ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Add if not already selected
              ? [...selectedRows, row]
              : selectedRows // Keep as is if already selected
            : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove if unchecked
          setSelectedRows(newSelectedRows); // Update the selected rows state
        }
      } else {
        if (!findPresentData) {
          setSelectedRows([row]); // Replace selected rows with the current row
        } else {
          const newSelectedRows = event.target.checked
            ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Add if not already selected
              ? [...selectedRows, row]
              : selectedRows // Keep as is if already selected
            : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove if unchecked
          setSelectedRows(newSelectedRows); // Update the selected rows state
        }
      }
    } else if (type === "InternalDB") {
      // Handle InternalDB logic
      const newSelectedRows = event.target.checked
        ? !selectedRows.some((r) => r.applicantId === row.applicantId) // Add if not already selected
          ? [...selectedRows, row]
          : selectedRows // Keep as is if already selected
        : selectedRows.filter((r) => r.applicantId !== row.applicantId); // Remove if unchecked
      setSelectedRows(newSelectedRows); // Update the selected rows state
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const data =
        searchType === "MonsterClassicSearch" ||
        searchType === "MonsterPowerSearch" ||
        searchType === "MonsterSearch"
          ? filterAlreadyMigratedProfiles
          : searchType === "CareerBuilder"
          ? filterAlreadyMigratedProfiles
          : internalDBCandidateList;
      if (data.length === 0) {
        return "";
      }
      setSelectedRows(data); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };
  const handleDeleteUserProfile = (index) => {
    // Filter out the item at the specified index
    const updatedRows = selectedRows.filter((_, i) => i !== index);

    // Check that at least one item remains
    if (updatedRows.length > 0) {
      setSelectedRows(updatedRows);
    } else {
      alert("At least one item must remain.");
    }
  };
  const [bulkActionMenu, setBulkActionMenu] = useState(null);
  //
  const [sortMenu, setSortMenu] = useState(null);

  const open = Boolean(bulkActionMenu);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [mailMergeModal, setMailMergeModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);

  const handleWarningModalClose = () => {
    setWarningModal(false);
    setWarningMessage(false);
  };
  const handleClickBulkAction = (event) => {
    setBulkActionMenu(event.currentTarget);
  };
  const handleCloseBulkAction = () => {
    setBulkActionMenu(null);
  };
  let toastShown = false; // Flag to track if toast message has been shown
  const handleSendEMail = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      if (!storeData.emailsIntegration) {
        setWarningModal(true);
        setWarningMessage(
          "Your email-ID is not updated, Please contact your Manager!"
        );
        return "";
      }
      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === storeData._id
          )
        : [];
      if (takenAssignedTemplate.length === 0) {
        setWarningModal(true);
        setWarningMessage(
          "No Email Template is available, kindly create one to send an email!"
        );
        return "";
      }
    }

    setSendMailModal(!sendMailModal);
  };
  const handleToggleSendMailModal = () => {
    setSendMailModal(!sendMailModal);
  };

  const [selectJobTag, setSelectJobTag] = useState("");
  const [jobId, setJobId] = useState("");
  const [optionsTemplate, setOptionsTemplate] = useState([]);
  const [template, setTemplate] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateSubjectType, setTemplateSubjectType] = useState("");
  const [templateBodyType, setTemplateBodyType] = useState("");
  const [jobDetails, setJobDeatils] = useState({});

  const handleToggleMailMerge = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      if (!storeData.emailsIntegration) {
        setWarningModal(true);
        setWarningMessage(
          "Your email-ID is not updated, Please contact your Manager!"
        );
        return "";
      }
      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === storeData._id
          )
        : [];
      if (takenAssignedTemplate.length === 0) {
        setWarningModal(true);
        setWarningMessage(
          "No Email Template is available, kindly create one to send an email!"
        );
        return "";
      }
    }
    setMailMergeModal(!mailMergeModal);
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === storeData?._id
          )
        : [];
      const takeTemplateOption = takenAssignedTemplate
        ? takenAssignedTemplate.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    } else {
      const takeTemplateOption = templatesData
        ? templatesData.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    }
  };
  const handleChangeTemplate = (value) => {
    setTemplate(value);

    const filtered = templatesData
      ? templatesData.filter((template) => template.templateTitle === value)
      : [];
    const { type, emailSubject, emailBody, _id } = filtered ? filtered[0] : [];
    setTemplateBodyType(emailBody.length > 0 ? emailBody : "");
    setTemplateSubjectType(emailSubject.length > 0 ? emailSubject : "");
    setTemplateId(_id || "");
  };
  const handleToggleCancelMailMerge = () => {
    setMailMergeModal(!mailMergeModal);
    setJobId("");
    setTemplateId("");
    setTemplate("");
    setSelectJobTag("");
    setJobDeatils({});
  };
  const handleJobSelect = (value) => {
    setSelectJobTag(value);
    if (value && value.value) {
      const takeJobPosting = Array.isArray(JobPosting) ? JobPosting : [];
      const findId = takeJobPosting.find(
        (job) => job.jobDetails.jobCode === value.value
      );
      setJobId(findId._id);
      setJobDeatils({
        jobTitle: findId?.jobDetails?.jobTitle || "",
        jobLocation: findId?.jobDetails?.location || "",
        jobDescription: findId?.organizationalInformation?.jobDescription || "",
      });
    }
  };
  const [openSendModal, setOpenSendModal] = useState(false);
  const handleShareEMail = () => {
    if (!jobId || !templateId) {
      enqueueSnackbar(`Please select fileds`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    setOpenSendModal(!openSendModal);
    setTemplateBodyType(
      jobPostingReplacePlaceholders(templateBodyType, jobDetails)
    );
    setTemplateSubjectType(
      jobPostingReplacePlaceholders(templateSubjectType, jobDetails)
    );
  };
  const handleShareEMailClose = () => {
    setOpenSendModal(!openSendModal);
    setJobId("");
    setTemplateId("");
    setTemplateBodyType("");
    setTemplateSubjectType("");
    setMailMergeModal(!mailMergeModal);
    setTemplate("");
    setSelectJobTag("");
  };

  const handleSelect = (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    setLoading(true);
    if (
      searchType === "MonsterClassicSearch" ||
      searchType === "MonsterPowerSearch" ||
      searchType === "MonsterSearch"
    ) {
      const resumes =
        internalDBMatchedResumes.find(
          (internal) =>
            internal.resumeId === monsterCandidateList[key]?.ResumeId
        )?.resumes || [];
      const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
      const serverLocation =
        resumes?.[resumes.length - 1]?.serverLocation || "";
      if (serverLocation) {
        const action = {
          key: serverLocation,
        };
        dispatch(awsResumeUrl(action));
      }
    } else if (searchType === "CareerBuilder") {
      const resumes =
        internalDBMatchedResumes.find(
          (internal) => internal.resumeId === cbCandidateList[key]?.ResumeId
        )?.resumes || [];
      const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
      const serverLocation =
        resumes?.[resumes.length - 1]?.serverLocation || "";

      if (serverLocation) {
        const action = {
          key: serverLocation,
        };
        dispatch(awsResumeUrl(action));
      }
    } else if (searchType === "InternalDB") {
      const resume =
        internalDBCandidateList[key]?.resumes?.[
          internalDBCandidateList[key]?.resumes.length - 1
        ];
      if (resume?.serverLocation) {
        const action = { key: resume.serverLocation };
        dispatch(awsResumeUrl(action));
      }
    }
    setSelectedCandidate(key);
    setDrawerOpen(true);
    setRevertHtml(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const closeDrawer = () => {
    setSelectedCandidate(null);
    setDrawerOpen(false);
    setRevertHtml(false);
  };

  const [openTabId, setOpenTabId] = useState("1");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
  };
  const handleGetResume = () => {
    if (
      searchType === "MonsterClassicSearch" ||
      searchType === "MonsterPowerSearch" ||
      searchType === "MonsterSearch"
    ) {
      const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      if (totalAvailable === 0) {
        enqueueSnackbar(`Your Monster Account Credits have reached zero.`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        return "";
      }
      const selectCandidateData = monsterCandidateList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData?.Name?.split(" ");
      const firstName = fullName[0] || selectCandidateData?.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData?.Name; // In case the last name has multiple parts
      const middlename =
        fullName.slice(2).join(" ") || selectCandidateData?.Name;
      const country = selectCandidateData.WorkAuths?.WorkAuth?.Country || "";
      const authType = selectCandidateData.WorkAuths?.WorkAuth?.AuthType || "";
      const workAuth = `${country} ${authType}`;
      const resumeTitle = selectCandidateData.ResumeTitle || "";
      const militaryExperience = {
        serviceFlag:
          selectCandidateData.PersonalData?.MilitaryExperience?.ServiceFlag ||
          "0",
        militaryInvolvement:
          selectCandidateData.PersonalData?.MilitaryExperience
            ?.MilitaryInvolvement || "0",
      };
      const experiences =
        selectCandidateData.Experiences?.Experience?.map((exp) => ({
          companyName: exp.Company?.Name || "",
          jobTitle: exp.Job?.Title || "",
          description: exp.Job?.Description || "",
          dateFrom: exp.DateFrom
            ? `${exp.DateFrom.Year}-${exp.DateFrom.Month || "01"}`
            : "",
          dateTo: exp.DateTo
            ? `${exp.DateTo.Year}-${exp.DateTo.Month || "01"}`
            : "Present",
        })) || [];
      const action = {
        client: client,
        resumeBoardId: selectCandidateData.ResumeBoardId,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        middleName: middlename,
        experienceMonths: selectCandidateData.ExperienceMonths, // Convert months to years
        experienceYears: selectCandidateData.ExperienceYears,
        jobTitle:
          selectCandidateData.JobTitle ||
          selectCandidateData.RecentExperienceJobTitle,
        source: selectCandidateData.Source,
        monsterAccountId: monsterAccountId,
        country: country,
        SFWillingToRelocate: selectCandidateData.Relocation,
        workHistory: selectCandidateData.experienceData,
        resumeTitle: selectCandidateData.resumeTitle,
        DesiredSalary: selectCandidateData.DesiredSalary,
        militaryExperience: selectCandidateData.militaryExperienceData,
      };
      dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
    } else if (searchType === "CareerBuilder") {
      const selectCandidateData = candidateList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData.Name.split(" ");
      const firstName = fullName[0] || selectCandidateData.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData.Name; // In case the last name has multiple parts
      const action = {
        client: client,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        experienceMonths: selectCandidateData.MonthsOfExperience, // Convert months to years
        jobTitle: selectCandidateData.JobTitle,
        source: "CareerBuilder",
        HomeLocation: selectCandidateData.location,
        LastUpdate: selectCandidateData.lastUpdated,
        ResumeTitle: selectCandidateData.title,
        RecentEmployer: selectCandidateData.RecentEmployer,
        RecentJobTitle: selectCandidateData.resume.JobTitle,
        HighestDegree: selectCandidateData.highestEducation,
        LastActivity: selectCandidateData.resume?.LastActivity,
        UserDID: selectCandidateData.UserDID,
        SFNmc: selectCandidateData.SFNmc,
      };
      dispatch(getResumeAndStoreInternalDbViaApi(action));
    }
  };
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));

    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };

  const handleDownloadResume = async (key, applicant) => {
    try {
      const parts = key.split(".");
      const firstName = applicant.firstName;
      const lastName = applicant.lastName;
      const fileName = `${firstName || ""} ${lastName || ""}.${parts.pop()}`;
      const payload = {
        key: key, // S3 key to be passed to the backend
        fileName: fileName, // File name passed from frontend
        fileType: parts.pop(),
      };
      const response = await fetch(apiUrl + "/applicants/downloadFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to download the file");
      }
      // Trigger file download from response
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error triggering download:", error.message);
    }
  };

  const [list, setList] = useState(false);
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);
  function extractKeywords(query) {
    const andClauses = query.replace(/[()]/g, "").split(/\s+and\s+/i);
    const keywords = [];
    andClauses.forEach((clause) => {
      clause.split(/\s+or\s+/i).forEach((keyword) => {
        // Remove any leading and trailing quotes from each keyword
        const cleanKeyword = keyword.trim().replace(/^["“”]|["“”]$/g, "");
        keywords.push(cleanKeyword);
      });
    });
    return keywords;
  }

  const extractedKeywords = keyWords ? extractKeywords(keyWords) : [];

  const handleClickRevertHtml = (data) => {
    const key =
      data?.resumes?.[data?.resumes?.length - 1]?.serverLocation || "";
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    if (key) {
      const action = { key };
      dispatch(awsResumeUrl(action));
    }
    setRevertHtml((prevState) => !prevState);
  };

  const { highlightedHtml, keywordCounts } = useMemo(() => {
    const resumes = getResumes({
      searchType,
      selectedCandidate,
      internalDBCandidateList,
      internalDBMatchedResumes,
      cbCandidateList,
      monsterCandidateList,
    });
    const latest = resumes?.[resumes.length - 1]?.htmlResume || "";
    const content = latest || HtmlResume || "";
    let updatedHtml = revertHtml ? HtmlResume : content;
    const counts = {};
    // Sort keywords by length in descending order to prioritize longer phrases
    const sortedKeywords = [...extractedKeywords].sort(
      (a, b) => b.length - a.length
    );
    sortedKeywords.forEach((keyword) => {
      const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      const regex = new RegExp(`(${escapedKeyword})`, "gi");
      counts[keyword] = (content.match(regex) || []).length;
      updatedHtml = updatedHtml.replace(
        regex,
        `<span style="background-color: yellow;">$1</span>`
      );
    });
    return { highlightedHtml: updatedHtml, keywordCounts: counts };
  }, [HtmlResume, extractedKeywords, resumesFile, htmlResume]);
  const handleClose = () => "";
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  const handleNewWindow = (value) => {
    const resumes = value.resumes || [];
    const serverLocation = resumes?.[resumes.length - 1]?.serverLocation || "";
    if (serverLocation) {
      const action = {
        client: client,
        key: serverLocation,
      };
      dispatch(awsResumeUrl(action));
    }
    // const url = "/viewResume";
    // window.open(url, "_blank", "noopener,noreferrer");
    // Pass serverLocation as a query parameter
    const url = `/viewResume?serverLocation=${encodeURIComponent(
      serverLocation
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [openQuickSubmitModal, setOpenQuickSubmitModal] = useState(false);
  const [selectCandidateQuickSubmit, setSelectCandidateQuickSubmit] = useState(
    []
  );
  const [selectJobQuickSubmit, setSelectJobQuickSubmit] = useState("");
  const [selectTagStatus, setSelectTagStatus] = useState("");
  const handleQuickClickSubmitModal = (applicant, tagStatus) => {
    setSelectTagStatus("");
    setSelectCandidateQuickSubmit("");
    setSelectJobQuickSubmit("");
    setSelectCandidateQuickSubmit([applicant]);
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
    setSelectTagStatus(tagStatus);
  };
  const handleCancelQuickSubmitModal = () => {
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    setSelectJobQuickSubmit("");
    setSelectCandidateQuickSubmit([]);
    setSelectTagStatus("");
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const [openModalNotAutharized, setOpenModalNotAutharized] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const handleQuickSubmit = () => {
    const actionData = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(actionData));
    if (selectCandidateQuickSubmit === 0) {
      return enqueueSnackbar("Please select at least one candidate!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (!selectJobQuickSubmit) {
      return enqueueSnackbar("Please select a job!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleCloseConfirmModalOpen = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };
  const handleQuickSubmitConfirm = () => {
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }

    const submission = selectCandidateQuickSubmit.map((select) => ({
      addedBy: storeData._id,
      addedOn: new Date(),
      applicantId: select._id,
      applicantStatus: selectTagStatus,
    }));

    const jobSubmissionAction = {
      id: findId ? findId._id : "",
      submissions: submission,
    };

    let tagAction = [];

    selectCandidateQuickSubmit.forEach((selectedRow) => {
      const tagData = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobPostId: findId ? findId._id : "",
        applicantStatus: selectTagStatus,
      };
      const existingTagJobs = Array.isArray(selectedRow.tagJobs)
        ? selectedRow.tagJobs.filter(
            (tag) => tag.jobPostId !== tagData.jobPostId
          )
        : [];
      const updatedTagJobs = [...existingTagJobs, tagData];
      tagAction.push({
        id: selectedRow._id,
        tagJobs: updatedTagJobs,
      });
    });

    const action = {
      jobSubmissionAction,
      tagAction,
      addedBy: storeData._id,
      client: client,
    };
    setLoading(true);
    dispatch(tagApplicantToJobViaApi(action));
    setSelectJobQuickSubmit("");
    handleCancelQuickSubmitModal();
    handleCloseConfirmModalOpen();
    setTimeout(() => {
      dispatch(getAllDegreesViaApi(client));
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 2000);
  };
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
    dispatch(getAllTemplatesViaApi(action));
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [note, setNote] = useState("");
  const [jobPostReference, setJobPostReference] = useState("");
  const [noteAction, setNoteAction] = useState("");
  const [clickNoteApplicant, setClickNoteApplicant] = useState("");
  const [notePriority, setNotePriory] = useState("");

  const [selectDestiny, setSelectDestiny] = useState("");
  const handleCloseNote = () => {
    setOpenNoteDrawer(false);
    setNote("");
    setClickNoteApplicant("");
    setJobPostReference("");
    setNoteAction("");
    setClickNoteApplicant("");
    setNotePriory("");
  };
  const handleClickNotes = (row, type, key = "") => {
    setClickNoteApplicant("");
    setClickNoteApplicant(row);
    setOpenNoteDrawer(!openNoteDrawer);
  };
  const handleNote = (value) => {
    setNote(value);
  };
  // Helper function to check for empty HTML content
  function isEmptyHtml(input) {
    // Remove all HTML tags and trim whitespace
    const strippedContent = input.replace(/<[^>]*>/g, "").trim();
    return strippedContent.length === 0;
  }
  const handleNoteSubmit = () => {
    if (!note || isEmptyHtml(note)) {
      return enqueueSnackbar("Please type note !", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (jobPostReference) {
      const takeJobCode = jobPostReference.value;
      const findId = JobPosting.find(
        (job) => job.jobDetails.jobCode === takeJobCode
      );
      const action = {
        client: client,
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
        notePriority: notePriority,
        noteAction: noteAction,
        jobPostReference: jobPostReference,
        applicantId: clickNoteApplicant?._id,
        jobPostId: findId?._id,
        source: "applicantReference",
      };
      dispatch(addApplicantJobPostReferenceViaApi(action));
      setLoading(true);
      setNote("");
      setTimeout(() => {
        setLoading(false);
        handleCloseNote();
      }, 3000);
    } else {
      const newNote = {
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
        notePriority: notePriority,
        noteAction: noteAction,
      };
      const existingNotes =
        searchType === "InternalDB"
          ? internalDBCandidateList.find(
              (internal) =>
                internal.applicantId === clickNoteApplicant?.applicantId
            )?.note || []
          : internalDBMatchedResumes.find(
              (internal) =>
                internal.applicantId === clickNoteApplicant?.applicantId
            )?.note || [];
      const updatedNotes = [...existingNotes, newNote];
      const action = {
        id: clickNoteApplicant?._id,
        note: updatedNotes,
        client: client,
      };
      dispatch(addApplicantNoteViaApi(action));
      setLoading(true);
      setNote("");
      setTimeout(() => {
        setLoading(false);
        handleCloseNote();
      }, 3000);
    }
  };
  //migration modal
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [totalResult, setTotalResult] = useState([]);
  const handleCloseMigrationModal = () => {
    setOpenMigrationModal(!openMigrationModal);
    setTotalResult([]);
  };

  const handleOpenMigrationModal = () => {
    if (selectedRows.length === 0) {
      const typeofData =
        searchType === "MonsterClassicSearch" ||
        searchType === "MonsterPowerSearch" ||
        searchType === "MonsterSearch"
          ? monsterCandidateList
          : searchType === "CareerBuilder"
          ? cbCandidateList
          : [];
      if (typeofData.length > 0) {
        const filterSelectedData = typeofData.filter(
          (select) =>
            !internalDBMatchedResumes.some(
              (internal) => internal.resumeId === select.ResumeId
            )
        );
        setTotalResult(filterSelectedData.slice(0, 10));
        setOpenMigrationModal((prevState) => !prevState); // Toggle modal state
        return "";
      } else {
        return;
      }
    }

    const filterSelectedData = selectedRows.filter(
      (select) =>
        !internalDBMatchedResumes.some(
          (internal) => internal.resumeId === select.ResumeId
        )
    );
    if (searchType === "CareerBuilder") {
      setLoading(true); // Set loading before processing
      filterSelectedData.forEach((item) => {
        const fullName = item.Name ? item.Name.split(" ") : []; // Safeguard against null/undefined
        const firstName = fullName[0] || item.Name; // Default to Name if firstName is missing
        const lastName = fullName.slice(1).join(" ") || ""; // Default to an empty string for lastName

        const action = {
          client: client,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.MonthsOfExperience, // Ensure this is properly handled
          jobTitle: item.JobTitle,
          source: "CareerBuilder",
        };
        console.log("action", action);
        // Dispatch the action for each item
        dispatch(getResumeAndStoreInternalDbViaApi(action));
      });
      // dispatch(getResumeAndStoreInternalDbViaApi(action));
    }
    if (
      searchType === "MonsterClassicSearch" ||
      searchType === "MonsterPowerSearch" ||
      searchType === "MonsterSearch"
    ) {
      const { monsterAccountId } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      setLoading(true);
      filterSelectedData.forEach((item) => {
        const fullName = item.Name.split(" ");
        const firstName = fullName[0] || item.Name;
        const lastName = fullName.slice(1).join(" ") || item.Name; // In case the last name has multiple parts
        const action = {
          client: client,
          resumeBoardId: item.ResumeBoardId,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.ExperienceMonths, // Convert months to years
          experienceYears: item.ExperienceYears,
          jobTitle: item.JobTitle,
          source: item.Source,
          monsterAccountId: monsterAccountId,
        };
        console.log("action", action);
        dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
      });
    }
  };

  const handleMigrationYES = () => {
    if (searchType === "CareerBuilder") {
      setLoading(true); // Set loading before processing
      totalResult.forEach((item) => {
        const fullName = item.Name ? item.Name.split(" ") : []; // Safeguard against null/undefined
        const firstName = fullName[0] || item.Name; // Default to Name if firstName is missing
        const lastName = fullName.slice(1).join(" ") || ""; // Default to an empty string for lastName
        const action = {
          client: client,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.MonthsOfExperience,
          jobTitle: item.JobTitle,
          source: "CareerBuilder",
        };
        console.log("Dispatching action:", action);
        dispatch(getResumeAndStoreInternalDbViaApi(action));
      });
      handleCloseMigrationModal(); // Close modal and clear results
    }
    if (searchType === "MonsterClassicSearch") {
      const { monsterAccountId } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      setLoading(true); // Set loading before processing
      totalResult.forEach((item) => {
        const fullName = item.Name.split(" ");
        const firstName = fullName[0] || item.Name;
        const lastName = fullName.slice(1).join(" ") || item.Name; // In case the last name has multiple parts
        const action = {
          client: client,
          resumeBoardId: item.ResumeBoardId,
          resumeId: item.ResumeId,
          addedBy: storeData._id,
          addedOn: new Date(),
          firstName: firstName,
          lastName: lastName,
          experienceMonths: item.ExperienceMonths, // Convert months to years
          experienceYears: item.ExperienceYears,
          jobTitle: item.JobTitle,
          source: item.Source,
          monsterAccountId: monsterAccountId,
        };
        dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
      });
      handleCloseMigrationModal(); // Close modal and clear results
    }
  };

  const removeHtmlTags = (htmlString) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    // Set the HTML content
    tempDiv.innerHTML = htmlString;
    // Return the text content of the element
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const handleFilterMonster = (value) => {
    setSortFilter(value);
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }
    if (searchType === "MonsterClassicSearch") {
      const { monsterAccountId } = GetMonsterAccountInfo(
        storeData,
        integrationsList,
        closeSnackbar
      );
      const action = {
        sort: value,
        radius: actionData.radius || 30,
        query: keyWords,
        mdateminage: fromDate,
        mdatemaxage: toDate,
        country: actionData?.rpcr ? "" : actionData?.country || 840,
        rlid: actionData?.rlid || 316,
        zipcode: actionData?.zipcode || "",
        rpcr: actionData?.rpcr || "",
        tsni: actionData?.tsni || "",
        twlid: actionData?.twlid || "",
        wa: actionData?.wa || "",
        qrcn: cn,
        qrjt: jobTitle,
        qrjd: jobDescription,
        qsn: schoolAttend,
        tnsalmin: actionData?.tnsalmin || "",
        tnsalmax: actionData?.tnsalmax || "",
        tsaltyp: actionData?.tsaltyp || "",
        tsalcur: actionData?.tsalcur || "",
        clv: actionData?.clv || "",
        edulv: actionData?.edulv || "",
        tjtft: actionData?.tjtft || "",
        tjtpt: actionData?.tjtpt || "",
        tjtpd: actionData?.tjtpd || "",
        tjtp: actionData?.tjtp || "",
        tjti: actionData?.tjti || "",
        tjttc: actionData?.tjttc || "",
        tjts: actionData?.tjts || "",
        Lskill: actionData?.Lskill || "",
        twindus: actionData?.twindus || "",
        page: page,
        pagesize: pageSize,
        client: client,
        monsterAccountId: monsterAccountId,
      };

      setLoading(true);
      dispatch(monsterAdvancedResumeSearchViaApi(action));
      setTimeout(() => {
        dispatch(getIntegrationsViaApi(action));
      }, 2000);
    }
  };
  const handlePowerSearch = () => {
    const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
      storeData,
      integrationsList,
      closeSnackbar
    );
    const jobTileExtract = jt?.map((item) => item.value);
    const skillsExtract = skills?.map((item) => item.value);
    const selectCityStateExtract = selectCityState
      ?.map((item) => item.value)
      .filter((value) => typeof value === "string" && value.trim() !== "");
    const locationRadiusExtract = locationRadius
      ?.map((item) => item.value)
      .filter((value) => typeof value === "string" && value.trim() !== "");
    const combinedData =
      selectCityStateExtract.length > 0
        ? combineData(selectCityStateExtract, locationRadiusExtract)
        : [];
    const matchedValues =
      tjtid &&
      tjtid.length > 0 &&
      optionsValues
        .filter((option) => tjtid.includes(option.label))
        .map((option) => option.value);
    const lstatMatches =
      lstat &&
      lstat.length > 0 &&
      optionValuescitizen
        .filter((option) => lstat.includes(option.label))
        .map((option) => option.value);

    const gscMatches =
      gsc &&
      gsc.length > 0 &&
      ClearanceLeveloptions.filter((option) => gsc.includes(option.label)).map(
        (option) => option.value
      );
    const action = {
      bkeyword: bkeyword,
      sk: skillsExtract
        ? skillsExtract.map((skill) => `${skill} nth`).join(",")
        : "",
      jt: jobTileExtract ? jobTileExtract.join(",") : "",
      experience: experience,
      location:
        selectCityStateExtract.length > 0
          ? combinedData.join("+") === "-"
            ? ""
            : combinedData.join("+")
          : "",
      minedulvid: highEducation,
      edumjr: edumjr,
      tjtid: matchedValues.length > 0 ? matchedValues.join(",") : "",
      mdatemaxage: resumeUpdate === 0 ? 1576800 : 1440 * resumeUpdate,
      ten: ten || "",
      wtt: wtt ? wtt : 1,
      relo: relo ? true : "",
      lstat: lstatMatches.length > 0 ? lstatMatches.join(",") : "",
      tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
      gsc: gscMatches.length > 0 ? gscMatches.join(",") : "",
      country: "US",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    const withoutAction = {
      bkeyword: bkeyword,
      jt: jt,
      skills: skills,
      experience: experience,
      selectCityState: selectCityState,
      locationRadius: locationRadius,
      highEducation: highEducation,
      edumjr: edumjr,
      tjtid: tjtid,
      resumeUpdate: resumeUpdate,
      ten: ten,
      wtt: wtt,
      tnsalmax: tnsalmax,
      tsni: tsni,
      gsc: gsc,
      relo: relo,
      lstat: lstat,
      country: "US",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    setLoading(true);
    dispatch(monsterPowerSearchViaApi(action));
    const historyAction = {
      query: withoutAction,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: searchType,
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
  };

  const handleMonsterSearch = () => {
    const { monsterAccountId, totalAvailable } = GetMonsterAccountInfo(
      storeData,
      integrationsList,
      closeSnackbar
    );
    const tenExtract =
      ten &&
      MonsterPowerSearchTenureOptions.filter(
        (option) => ten === option.label
      ).map((option) => option.value);

    const waExtractt = workAuthorizeOptions
      ? workAuthorizeOptions
          .filter((option) => wa === option.label)
          .map((option) => option.value)
      : []; // Default to an empty array if workAuthorizeOptions is undefined
    const waData = Array.isArray(waExtractt) ? waExtractt.join(",") : "";
    const clvidMatches =
      clvid &&
      clvid.length > 0 &&
      careerLevelOptions
        .filter((option) => clvid.includes(option.label))
        .map((option) => option.value);
    const compExtract = comp
      ?.map((item) => item.value)
      .filter((value) => typeof value === "string" && value.trim() !== "");
    const schExtract = sch
      ?.map((item) => item.value)
      .filter((value) => typeof value === "string" && value.trim() !== "");
    const matchedValues =
      tjtid &&
      tjtid.length > 0 &&
      optionsValues
        .filter((option) => tjtid.includes(option.label))
        .map((option) => option.value);
    const lstatMatches =
      lstat &&
      lstat.length > 0 &&
      optionValuescitizen
        .filter((option) => lstat.includes(option.label))
        .map((option) => option.value);
    const gscMatches =
      gsc &&
      gsc.length > 0 &&
      ClearanceLeveloptions.filter((option) => gsc.includes(option.label)).map(
        (option) => option.value
      );
    const wttMatches =
      wtt &&
      MonsterPowerSearchTravelRequirementsOptions.filter(
        (option) => wtt === option.label
      ).map((option) => option.value);
    const highEducationMatches =
      highEducation &&
      MonsterEducationLevel.filter(
        (option) => highEducation === option.label
      ).map((option) => option.value);
    const edumjrMonsterExtract = edumjrMonster
      ?.map((item) => item.value)
      .filter((value) => typeof value === "string" && value.trim() !== "");
    const action = {
      bkeyword: bkeyword,
      jt: jobTitle,
      sk: keyWords ? `${keyWords} nth`.replace(/\s/g, "+") : "",
      location: selectCityZip ? `${selectCityZip}-${radius}` : "",
      relo: relo ? true : "",
      veteran: veteran ? 1 : "",
      wa: waData ? `${164}-${waData}` : "",
      clvid: clvidMatches.length > 0 ? clvidMatches.join(",") : "",
      ten: tenExtract.length > 0 ? tenExtract.join(",") : "",
      experience: experience,
      comp: compExtract.length > 0 ? compExtract.join(",") : "",
      edumjr:
        edumjrMonsterExtract.length > 0 ? edumjrMonsterExtract.join(",") : "",
      sch: schExtract.length > 0 ? schExtract.join(",") : "",
      mdatemaxage: resumeUpdate === 0 ? 1576800 : 1440 * resumeUpdate,
      tjtid: matchedValues.length > 0 ? matchedValues.join(",") : "",
      lstat: lstatMatches.length > 0 ? lstatMatches.join(",") : "",
      gsc: gscMatches.length > 0 ? gscMatches.join(",") : "",
      wtt: wtt ? wttMatches.join(",") : "",
      tsni: tnsalmax || tnsalmin ? 0 : tsni ? 1 : "",
      minedulvid: highEducation ? highEducationMatches.join(",") : "",
      country: "US",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId,
    };
    const withoutAction = {
      bkeyword: bkeyword,
      jobTitle: jobTitle,
      keyWords: keyWords,
      selectCityZip: selectCityZip,
      radius: radius,
      relo: relo,
      veteran: veteran,
      waData: workAuthorizeOptions,
      clvid: clvid,
      ten: ten,
      experience: experience,
      comp: comp,
      edumjrMonster: edumjrMonster,
      sch: sch,
      resumeUpdate: resumeUpdate,
      tjtid: tjtid,
      lstat: lstat,
      gsc: gsc,
      wtt: wtt,
      tsni: tsni,
      highEducation: highEducation,
      country: "US",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId,
    };
    setLoading(true);
    dispatch(monsterPowerSearchViaApi(action));
    const historyAction = {
      query: withoutAction,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: searchType,
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
  };
  const [showAllSkills, setShowAllSkills] = useState([]);
  const handleShowAllSkills = (id) => {
    setShowAllSkills((prev) =>
      prev.includes(id)
        ? prev.filter((skillId) => skillId !== id)
        : [...prev, id]
    );
  };
  // const SelectedCandidateInfo = searchType === ("MonsterClassicSearch" ||  "MonsterPowerSearch" ||  "MonsterSearch") ? monsterCandidateList[selectedCandidate] || []
  // : searchType === "CareerBuilder" ? cbCandidateList[selectedCandidate] || [] : [];

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Boards Result"}>
          <Button
            size="small"
            color="error"
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{ textTransform: "inherit" }}
            onClick={() => {
              const state = {
                tabId:
                  searchType === "InternalDB"
                    ? "1"
                    : searchType === "CareerBuilder"
                    ? "2"
                    : "3",
                searchType: searchType,
              };
              navigate("/jobBoards", { state }); // Navigate to a specific route with state
            }}
          >
            Back
          </Button>
          <MainBackground>
            <Grid container spacing={4}>
              {grid && (
                <>
                  {searchType === "MonsterClassicSearch" && (
                    <Grid item xs={2.5}>
                      <Typography>Search Options</Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          gap: 1,
                          flexDirection: "column",
                        }}
                      >
                        <CustomFormField
                          title={"Keyword"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                required: true,
                                defaultValue: keyWords,
                                onChange: (value) => setKeyWords(value),
                                error: !!errorKeyword,
                                errorMessage: errorKeyword,
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Resume Updated"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "From",
                                options: optionsMonsterPostFrom,
                                value: fromDate,
                                onChange: (value) => setFromDate(value),
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "To",
                                options: optionsMonsterTo,
                                value: toDate,
                                onChange: (value) => setToDate(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Employer"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Employer",
                                defaultValue: cn,
                                onChange: (value) => setcn(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Job Title"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Job Title",
                                defaultValue: jobTitle,
                                onChange: (value) => setJobTitle(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Description"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Description",
                                defaultValue: jobDescription,
                                onChange: (value) => setJobDescription(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"School Attended"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "School Attended",
                                defaultValue: schoolAttend,
                                onChange: (value) => setSchoolAttend(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 1 }}
                          onClick={handleSearchMonster}
                        >
                          Apply
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  {/* {searchType !== "MonsterClassicSearch" &&
                    searchType !== "MonsterPowerSearch" &&
                    searchType !== "MonsterSearch" && searchType !== "CareerBuilder"&&(
                      <Grid item xs={2.5}>
                        <Typography>Filter Result</Typography>
                        <Box>
                          <CustomFormField
                            //colunm={true}
                            //title={"Gender"}
                            //required
                            components={[
                              {
                                type: "radio",

                                props: {
                                  title: "Select Gender",
                                  required: true,
                                  defaultValue: "All Results",
                                  options: [
                                    "All Results",
                                    "Resume Results",
                                    "Social Results",
                                  ],
                                },
                              },
                            ]}
                          />
                          <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                            Apply
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                          }}
                        >
                          <CustomFormField
                            title={"Exclude"}
                            components={[
                              {
                                type: "select",
                                props: {
                                  title: "Select",
                                  options: [
                                    "Option - 1",
                                    "Option - 2",
                                    "Option - 3",
                                  ],
                                },
                              },
                            ]}
                          />
                          <Divider />
                          <CustomFormField
                            title={"Experience"}
                            colunm={true}
                            components={[
                              {
                                type: "rtText",
                                props: {
                                  text: "from",
                                },
                              },
                              {
                                type: "select",
                                props: {
                                  title: "Select",
                                  options: [
                                    "Option - 1",
                                    "Option - 2",
                                    "Option - 3",
                                  ],
                                },
                              },
                              {
                                type: "rtText",
                                props: {
                                  text: "to",
                                },
                              },
                              {
                                type: "select",
                                props: {
                                  title: "Select",
                                  options: [
                                    "Option - 1",
                                    "Option - 2",
                                    "Option - 3",
                                  ],
                                },
                              },
                            ]}
                          />
                          <Divider />
                          <CustomFormField
                            title={"Last Modified"}
                            colunm={true}
                            components={[
                              {
                                type: "rtText",
                                props: {
                                  text: "from",
                                },
                              },
                              {
                                type: "select",
                                props: {
                                  title: "Select",
                                  options: [
                                    "Option - 1",
                                    "Option - 2",
                                    "Option - 3",
                                  ],
                                },
                              },
                              {
                                type: "rtText",
                                props: {
                                  text: "to",
                                },
                              },
                              {
                                type: "select",
                                props: {
                                  title: "Select",
                                  options: [
                                    "Option - 1",
                                    "Option - 2",
                                    "Option - 3",
                                  ],
                                },
                              },
                            ]}
                          />
                          <Divider />
                          <CustomFormField
                            title={"Conatact Info"}
                            components={[
                              {
                                type: "checkbox",

                                props: {
                                  title: "Select Gender",
                                  required: true,
                                  defaultValue: "",
                                  options: ["Email", "Phone"],
                                },
                              },
                            ]}
                          />
                          <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                            Apply
                          </Button>
                        </Box>
                      </Grid>
                    )} */}
                  {searchType === "MonsterPowerSearch" && (
                    <Grid item xs={2.5}>
                      <Box sx={{ maxHeight: "78vh", height: "100%" }}>
                        <ScrollView>
                          <JobBoardMonsterPowerSearch
                            powerSearchType={powerSearchType}
                            setPowerSearchType={setPowerSearchType}
                            country={country}
                            countryMonsterOptions={countryMonsterOptions}
                            handleSelectCountry={handleSelectCountry}
                            MonsterPowerSearchRadiusOptions={
                              MonsterPowerSearchRadiusOptions
                            }
                            radius={radius}
                            setRadius={setRadius}
                            MonsterPowerSearchSalaryRangeOptions={
                              MonsterPowerSearchSalaryRangeOptions
                            }
                            MonsterPowerSearchResumeUpdatedOptions={
                              MonsterPowerSearchResumeUpdatedOptions
                            }
                            MonsterPowerSearchHourlySalaryRangeOptions={
                              MonsterPowerSearchHourlySalaryRangeOptions
                            }
                            MonsterEducationLevel={MonsterEducationLevel}
                            MonsterPowerSearchTenureOptions={
                              MonsterPowerSearchTenureOptions
                            }
                            MonsterPowerSearchTravelRequirementsOptions={
                              MonsterPowerSearchTravelRequirementsOptions
                            }
                            resumeUpdate={resumeUpdate}
                            setResumeUpdate={setResumeUpdate}
                            minedulvid={minedulvid}
                            setMinedulvid={setMinedulvid}
                            salaryRangeOptions={salaryRangeOptions}
                            setSalaryRangeOptions={setSalaryRangeOptions}
                            salaryRange={salaryRange}
                            setSalaryRange={setSalaryRange}
                            salaryRangeType={salaryRangeType}
                            setSalaryRangeType={setSalaryRangeType}
                            tsni={tsni}
                            setTsni={setTsni}
                            highEducation={highEducation}
                            setHighEducation={setHighEducation}
                            edumjr={edumjr}
                            setEdumjr={setEdumjr}
                            setWtt={setWtt}
                            wtt={wtt}
                            ten={ten}
                            setTen={setTen}
                            gsc={gsc}
                            setGsc={setGsc}
                            setLstat={setLstat}
                            lstat={lstat}
                            relo={relo}
                            setRelo={setRelo}
                            tjtid={tjtid}
                            setTjtid={setTjtid}
                            bkeyword={bkeyword}
                            setBkeyword={setBkeyword}
                            experience={experience}
                            setExperience={setExperience}
                            jt={jt}
                            setJt={setJt}
                            skills={skills}
                            setSkills={setSkills}
                            locationSearch={locationSearch}
                            setLocationSearch={setLocationSearch}
                            locationRadius={locationRadius}
                            setLocationRadius={setLocationRadius}
                            handlePowerSearch={handlePowerSearch}
                            selectCityState={selectCityState}
                            setSelectCityState={setSelectCityState}
                            searchCityState={searchCityState}
                            setSearchCityState={setSearchCityState}
                            apiUrl={apiUrl}
                            filter={true}
                          />
                        </ScrollView>
                      </Box>
                    </Grid>
                  )}
                  {searchType === "MonsterSearch" && (
                    <Grid item xs={2.5}>
                      <Box sx={{ maxHeight: "78vh", height: "100%" }}>
                        <ScrollView>
                          <JobBoardMonsterSearchMonster
                            mSearchType={mSearchType}
                            setMSearchType={setMSearchType}
                            MonsterPowerSearchRadiusOptions={
                              MonsterPowerSearchRadiusOptions
                            }
                            radius={radius}
                            setRadius={setRadius}
                            jobTitle={jobTitle}
                            setJobTitle={setJobTitle}
                            bkeyword={bkeyword}
                            setBkeyword={setBkeyword}
                            keyWords={keyWords}
                            setKeyWords={setKeyWords}
                            relo={relo}
                            setRelo={setRelo}
                            setVeteran={setVeteran}
                            veteran={veteran}
                            MonsterPowerSearchTenureOptions={
                              MonsterPowerSearchTenureOptions
                            }
                            ten={ten}
                            setTen={setTen}
                            experience={experience}
                            setExperience={setExperience}
                            comp={comp}
                            setComp={setComp}
                            edumjrMonster={edumjrMonster}
                            setEdumjrMonster={setEdumjrMonster}
                            edumjrTypeMonster={edumjrTypeMonster}
                            setEdumjrTypeMonster={setEdumjrTypeMonster}
                            sch={sch}
                            setSch={setSch}
                            cname={cname}
                            setCname={setCname}
                            wa={wa}
                            setWa={setWa}
                            clvid={clvid}
                            setClvid={setClvid}
                            handleMonsterSearch={handleMonsterSearch}
                            country={country}
                            setSelectCityZip={setSelectCityZip}
                            setSearchTerm={setSearchTerm}
                            setOptionCityZip={setOptionCityZip}
                            searchTerm={searchTerm}
                            selectCityZip={selectCityZip}
                            handleSelectCityZip={handleSelectCityZip}
                            apiUrl={apiUrl}
                            optionsCityZip={optionsCityZip}
                            MonsterSearchResumeUpdatedOptions={
                              MonsterSearchResumeUpdatedOptions
                            }
                            resumeUpdate={resumeUpdate}
                            setResumeUpdate={setResumeUpdate}
                            setTjtid={setTjtid}
                            tjtid={tjtid}
                            lstat={lstat}
                            setLstat={setLstat}
                            setGsc={setGsc}
                            gsc={gsc}
                            setMinedulvid={setMinedulvid}
                            setSalaryRangeType={setSalaryRangeType}
                            setSalaryRangeOptions={setSalaryRangeOptions}
                            MonsterPowerSearchHourlySalaryRangeOptions={
                              MonsterPowerSearchHourlySalaryRangeOptions
                            }
                            MonsterPowerSearchSalaryRangeOptions={
                              MonsterPowerSearchSalaryRangeOptions
                            }
                            salaryRangeType={salaryRangeType}
                            setSalaryRange={setSalaryRange}
                            salaryRange={salaryRange}
                            salaryRangeOptions={salaryRangeOptions}
                            tsni={tsni}
                            setTsni={setTsni}
                            wtt={wtt}
                            setWtt={setWtt}
                            highEducation={highEducation}
                            setHighEducation={setHighEducation}
                            filter={true}
                          />
                        </ScrollView>
                      </Box>
                    </Grid>
                  )}
                  {searchType === "CareerBuilder" && (
                    <Grid item xs={2.5}>
                      <Typography>Filter Result</Typography>
                      <Box>
                        <CustomFormField
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Select Gender",
                                required: true,
                                defaultValue: "All Results",
                                options: [
                                  "All Results",
                                  "Resume Results",
                                  "Social Results",
                                ],
                              },
                            },
                          ]}
                        />
                        <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                          Apply
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          gap: 1,
                          flexDirection: "column",
                        }}
                      >
                        <CustomFormField
                          title={"Exclude"}
                          components={[
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select",
                                defaultValue: excludeViewedByMe,
                                options: excludeViewedByMeOptionsCareerBuilder,
                                onChange: (value) =>
                                  setExcludeViewedByMe(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Experience"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select",
                                defaultValue: yearofexpfrom,
                                options: yearsOfExperienceOptionsCareerBuilder,
                                onChange: (value) => setYearofexpfrom(value),
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select",
                                defaultValue: yearofexpto,
                                options: yearsOfExperienceOptionsCareerBuilder,
                                onChange: (value) => setYearofexpto(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Last Modified"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select",
                                defaultValue: resumeModifiedFrom,
                                options: resumeModifiedFromOptionsCarrerBuilder,
                                onChange: (value) =>
                                  setResumeModifiedFrom(value),
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "Select",
                                defaultValue: resumeModifiedTo,
                                options: resumeModifiedToOptionsCareerBuilder,
                                onChange: (value) => setResumeModifiedTo(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Conatact Info"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                title: "Select Gender",
                                required: true,
                                defaultValue: contactInfo,
                                options: ["Email", "Phone"],
                                multiple: true,
                                onChange: (value) => setContactInfo(value),
                              },
                            },
                          ]}
                        />
                        <CustomFormField
                          title={"Work Status"}
                          components={[
                            {
                              type: "checkbox",
                              props: {
                                title: "Select Gender",
                                required: true,
                                defaultValue: workStatus,
                                multiple: true,
                                options: [
                                  "Can work for any employer",
                                  "US Citizen",
                                  "H1 Visa",
                                  "Green Card Holder",
                                  "Need H1 Visa Sponsor",
                                  "TN Permit Holder",
                                  "Employment Authorization Document",
                                  "Not specified",
                                ],
                                onChange: (value) => setWorkStatus(value),
                              },
                            },
                          ]}
                        />
                        <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                          Apply
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={grid ? 9.5 : 12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    gap: 0.5,
                    color: theme.palette.grey[500],
                    mb: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 30, lineHeight: 1 }}>
                    {searchType === "MonsterClassicSearch" ||
                    searchType === "MonsterPowerSearch" ||
                    searchType === "MonsterSearch"
                      ? matchedMonsterResult
                      : searchType === "CareerBuilder"
                      ? matchedCBResult
                      : ""}
                  </Typography>
                  <Typography>results</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        searchType ===
                        ("MonsterClassicSearch" ||
                          "MonsterPowerSearch" ||
                          "MonsterSearch")
                          ? selectedRows.length ===
                              filterAlreadyMigratedProfiles.length &&
                            selectedRows.length > 0
                          : searchType === "CareerBuilder"
                          ? selectedRows.length ===
                              filterAlreadyMigratedProfiles.length &&
                            selectedRows.length > 0
                          : selectedRows.length ===
                              internalDBCandidateList.length &&
                            selectedRows.length > 0
                      } // Check if all rows are selected
                      onChange={handleHeaderCheckboxChange}
                    />
                    Select Migrated Profile
                    {searchType !== "InternalDB" && (
                      <Button
                        variant="text"
                        startIcon={<SwapHorizIcon />}
                        size="small"
                        onClick={handleOpenMigrationModal}
                      >
                        Migrate Profiles
                      </Button>
                    )}
                    {(searchType === "InternalDB" ||
                      filterSelectedCandidateMigrated.length > 0) && (
                      <Box ml={1}>
                        <Button
                          size="small"
                          onClick={handleClickBulkAction}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Bulk Actions
                        </Button>
                        <Menu
                          anchorEl={bulkActionMenu}
                          open={Boolean(bulkActionMenu)}
                          onClose={handleCloseBulkAction}
                          onClick={handleCloseBulkAction}
                          hideBackdrop
                        >
                          <MenuItem onClick={handleSendEMail}>
                            Send Email
                          </MenuItem>
                          <MenuItem onClick={handleCloseBulkAction}>
                            Share Job
                          </MenuItem>
                          <MenuItem onClick={handleCloseBulkAction}>
                            Add to Group
                          </MenuItem>
                          <MenuItem onClick={handleToggleMailMerge}>
                            Mail Merge
                          </MenuItem>
                        </Menu>
                      </Box>
                    )}
                    {/* add sort options */}
                    {searchType === "MonsterClassicSearch" && (
                      <Box ml={1}>
                        <Button
                          size="small"
                          onClick={({ currentTarget }) =>
                            setSortMenu(currentTarget)
                          }
                          endIcon={
                            Boolean(sortMenu) ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )
                          }
                        >
                          Sort By -{" "}
                          {
                            monsterSort.find(
                              (label) => label.value === sortFilter
                            )?.label
                          }
                        </Button>
                        <Menu
                          anchorEl={sortMenu}
                          open={Boolean(sortMenu)}
                          onClose={() => setSortMenu(null)}
                          onClick={() => setSortMenu(null)}
                          hideBackdrop
                        >
                          {monsterSort.map((label, index) => (
                            <MenuItem
                              key={index}
                              selected={label.value === sortFilter}
                              onClick={() => handleFilterMonster(label.value)}
                            >
                              {label.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    )}
                    <IconButton
                      size="small"
                      onClick={() => {
                        setList(false);
                        setGrid(true);
                        setIsGrid(false);
                      }}
                      color={isGrid ? "default" : "primary"}
                    >
                      <GridViewOutlinedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setList(true);
                        setGrid(false);
                        setIsGrid(true);
                      }}
                      color={isGrid ? "primary" : "default"}
                    >
                      <FormatListBulletedIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Pagination
                      count={
                        searchType === "MonsterClassicSearch" ||
                        searchType === "MonsterPowerSearch" ||
                        searchType === "MonsterSearch"
                          ? matchedMonsterResult
                          : searchType === "CareerBuilder"
                          ? matchedCBResult
                          : pageSize
                      }
                      page={page} // Set the current page
                      onChange={handlePageChange} // Handle page change
                      size="small"
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 1, bgcolor: theme.palette.grey[50], p: 1 }}>
                  <Typography>{keyWords}</Typography>
                </Box>
                {grid && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    {(searchType === "MonsterClassicSearch" ||
                      searchType === "MonsterPowerSearch" ||
                      searchType === "MonsterSearch") && (
                      <>
                        {monsterCandidateList.map((item, key) => {
                          const itemSelected = selectedCandidate === key;
                          return (
                            <Box
                              key={key}
                              sx={{
                                display: "flex",
                                gap: 2,
                                bgcolor: theme.palette.grey[50],
                                border: `1px solid ${theme.palette.grey[300]}`,
                                borderRadius: 1,
                                p: 2,
                                // cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              {internalDBMatchedResumes.length > 0 &&
                                internalDBMatchedResumes.map((applicant) => {
                                  if (applicant.resumeId === item.ResumeId) {
                                    return <CustomBadge />;
                                  }
                                })}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0.5,
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton size="small">
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.some(
                                      (r) => r.ResumeId === item.ResumeId
                                    )} // Check if the row is selected
                                    onChange={(e) =>
                                      handleCheckboxChange(e, item, searchType)
                                    } // Handle checkbox toggle
                                  />
                                </IconButton>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      flex: 1,
                                      display: "flex",
                                      gap: 1.5,
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Box>
                                      <Box display={"flex"}>
                                        {internalDBMatchedResumes.length > 0 &&
                                          internalDBMatchedResumes.map(
                                            (applicant) => {
                                              if (
                                                applicant.resumeId ===
                                                item.ResumeId
                                              ) {
                                                const { note } = applicant;
                                                return (
                                                  note?.length > 0 && (
                                                    <IconButton
                                                      size="small"
                                                      key={applicant.resumeId} // Assign unique key here
                                                      onClick={() =>
                                                        handleClickNotes(
                                                          applicant,
                                                          "Monster",
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <CommentIcon
                                                        fontSize="inherit"
                                                        color="warning"
                                                      />
                                                    </IconButton>
                                                  )
                                                );
                                              }
                                              return ""; // Ensure no rendering for unmatched cases
                                            }
                                          )}
                                        <Typography
                                          sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.Name?.trim() === ""
                                            ? "CONFIDENTIAL"
                                            : item.Name}
                                        </Typography>
                                        <IconButton
                                          size="xsmall"
                                          color="secondary"
                                          onClick={() => handleSelect(key)}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: 12,
                                          color: theme.palette.grey[600],
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 0.5,
                                        }}
                                      >
                                        <FmdGoodIcon fontSize="inherit" />
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            color: theme.palette.grey[600],
                                          }}
                                        >
                                          {item.Location?.country} -{" "}
                                          {item.Location?.state} -{" "}
                                          {item.Location?.city}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          fontSize: 12,
                                          color: theme.palette.grey[600],
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 0.5,
                                        }}
                                      >
                                        {/* <FmdGoodIcon fontSize="inherit" /> */}
                                        {searchType !== "MonsterPowerSearch" &&
                                          searchType !== "MonsterSearch" && (
                                            <Typography fontSize={"inherit"}>
                                              {/* {item?.Title} */}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={extractedKeywords}
                                                autoEscape={true}
                                                textToHighlight={
                                                  item?.RecentExperienceJobTitle
                                                }
                                              />
                                              {""} {item?.companyName}
                                            </Typography>
                                          )}
                                        {(searchType === "MonsterPowerSearch" ||
                                          searchType === "MonsterSearch") && (
                                          <Typography fontSize={"inherit"}>
                                            {/* {item?.Title} */}
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={extractedKeywords}
                                              autoEscape={true}
                                              textToHighlight={
                                                item?.RecentExperience
                                              }
                                            />
                                            {""} {item?.companyName}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box
                                    flex={1}
                                    bgcolor={theme.palette.grey[100]}
                                    px={1.5}
                                    py={1}
                                  >
                                    {(searchType === "MonsterPowerSearch" ||
                                      searchType === "MonsterSearch") && (
                                      // <th>Top Skills</th>
                                      // <th>Experience (Years)</th>
                                      <>
                                        <Box sx={{ display: "flex", gap: 0.5 }}>
                                          <Typography
                                            sx={{
                                              fontSize: 14,
                                              color:
                                                theme.palette.secondary.main,
                                              fontWeight: "600",
                                            }}
                                          >
                                            Skills
                                          </Typography>
                                          <IconButton
                                            size="xsmall"
                                            color="secondary"
                                          >
                                            {showAllSkills.includes(item.id) ? (
                                              <VisibilityOffIcon
                                                onClick={() =>
                                                  handleShowAllSkills(item.id)
                                                }
                                              />
                                            ) : (
                                              <VisibilityIcon
                                                onClick={() =>
                                                  handleShowAllSkills(item.id)
                                                }
                                              />
                                            )}
                                          </IconButton>
                                        </Box>
                                      </>
                                    )}

                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {searchType ===
                                        "MonsterClassicSearch" && (
                                        <Typography
                                          sx={{
                                            fontSize: 12,
                                            color: theme.palette.grey[600],
                                          }}
                                        >
                                          {item?.Description}
                                          <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={extractedKeywords}
                                            autoEscape={true}
                                            textToHighlight={item?.Description}
                                          />
                                        </Typography>
                                      )}
                                      {item?.TopSkills.map((skill, index) => (
                                        <KeyData
                                          key={index}
                                          fullContent
                                          value={`${skill.name}`}
                                          title={`${skill.yrsUsed} yrs`}
                                          reverse
                                        />
                                      ))}
                                    </Box>
                                  </Box>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Resume Updated"}
                                      fullContent
                                      value={item?.ResumeUpdated}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Highest Education"}
                                      fullContent
                                      value={item?.HighestEducation}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Target Job Title"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.JobTitle}
                                        />
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Desired Salary"}
                                      fullContent
                                      value={item?.DesiredSalary}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Relocation"}
                                      fullContent
                                      value={item?.Relocation}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Authorization"}
                                      fullContent
                                      value={item?.Authorization}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <KeyData
                                      title={"Desired Job Type"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.DesiredJobType}
                                        />
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                {searchType !== "MonsterClassicSearch" && (
                                  <>
                                    {showAllSkills.includes(item.id) ? (
                                      <Box
                                        bgcolor={theme.palette.grey[100]}
                                        p={3}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: 14,
                                            color: theme.palette.secondary.main,
                                            fontWeight: "600",
                                            mb: 2,
                                          }}
                                        >
                                          Skills
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {item?.AllSkills.map(
                                            (skill, index) => (
                                              <Grid item xs={2} key={index}>
                                                <KeyData
                                                  // title={`${skill.name}`}
                                                  // value={`${skill.yrsUsed} | ${skill.lastUsed}`}
                                                  value={`${skill.name}`}
                                                  title={`${skill.yrsUsed} yrs`}
                                                  reverse
                                                />
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    )}
                    {searchType === "CareerBuilder" && (
                      <>
                        {cbCandidateList.map((item, key) => {
                          const itemSelected = selectedCandidate === key;
                          return (
                            <Box
                              key={key}
                              sx={{
                                display: "flex",
                                gap: 2,
                                bgcolor: theme.palette.grey[50],
                                border: `1px solid ${theme.palette.grey[300]}`,
                                borderRadius: 1,
                                p: 2,
                                // cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              {internalDBMatchedResumes.length > 0 &&
                                internalDBMatchedResumes.map((applicant) => {
                                  if (applicant.resumeId === item.ResumeId) {
                                    return <CustomBadge />;
                                  }
                                })}
                              <Box  sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0.5,
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}>
                                  <IconButton size="small">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some(
                                    (r) => r.ResumeId === item.ResumeId
                                  )} // Check if the row is selected
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      item,
                                      "CareerBuilder"
                                    )
                                  } // Handle checkbox toggle
                                />
                                </IconButton>
                              </Box>
                              <Box
                                // onClick={() => handleSelect(key)}
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <Box>
                                  <Box display={"flex"}>
                                  {internalDBMatchedResumes.length > 0 &&
                                          internalDBMatchedResumes.map(
                                            (applicant) => {
                                              if (
                                                applicant.resumeId ===
                                                item.ResumeId
                                              ) {
                                                const { note } = applicant;
                                                return (
                                                  note?.length > 0 && (
                                                    <IconButton
                                                      size="small"
                                                      key={applicant.resumeId} // Assign unique key here
                                                      onClick={() =>
                                                        handleClickNotes(
                                                          applicant,
                                                          "CareerBuilder",
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <CommentIcon
                                                        fontSize="inherit"
                                                        color="warning"
                                                      />
                                                    </IconButton>
                                                  )
                                                );
                                              }
                                              return ""; // Ensure no rendering for unmatched cases
                                            }
                                          )}
                                  <Typography
                                    sx={{ fontSize: 16, fontWeight: 600 }}
                                  >
                                    {item.Name}
                                  </Typography>
                                  <IconButton
                                          size="xsmall"
                                          color="secondary"
                                          onClick={() => handleSelect(key)}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                        </Box>
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      color: theme.palette.grey[600],
                                    }}
                                  >
                                    {/* {item?.JobTitle} */}
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={item?.JobTitle}
                                    />
                                  </Typography>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      color: theme.palette.grey[600],
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 0.5,
                                    }}
                                  >
                                    <FmdGoodIcon fontSize="inherit" />
                                    <Typography fontSize={"inherit"}>
                                      {item.location.city} -{" "}
                                      {item.location.state} -{" "}
                                      {item.location.country}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Recent Position"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.RecentJobTitle}
                                        />
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Recent Education"}
                                      fullContent
                                      value={
                                        item?.highestEducation
                                          ? item?.highestEducation
                                          : "n/a"
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Experience"}
                                      fullContent
                                      value={(
                                        item?.MonthsOfExperience / 12
                                      ).toFixed(1)}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    )}
                    {searchType === "InternalDB" && (
                      <>
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {internalDBCandidateList.map((item, key) => {
                            const itemSelected = selectedCandidate === key;
                            return (
                              <Box
                                key={key}
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  bgcolor: theme.palette.grey[50],
                                  border: `1px solid ${theme.palette.grey[300]}`,
                                  borderRadius: 1,
                                  p: 2,
                                  // cursor: "pointer",
                                }}
                              >
                                <Box>
                                  <IconButton size="small">
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.some(
                                        (r) =>
                                          r.applicantId === item.applicantId
                                      )} // Check if the row is selected
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item,
                                          "InternalDB"
                                        )
                                      } // Handle checkbox toggle
                                    />
                                  </IconButton>
                                </Box>
                                <Box
                                  // onClick={() => handleSelect(key)}
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                  }}
                                >
                                  <Box>
                                    <Box display={"flex"}>
                                    {item?.note?.length>0&&(
              <IconButton size="small">
              <CommentIcon
                fontSize="inherit"
                color="warning"
                onClick={() => handleClickNotes(item, "InternalDB", "")}
              />
            </IconButton>
            )}
                                    <Typography
                                      sx={{ fontSize: 16, fontWeight: 600 }}
                                    >
                                      {item?.firstName} {item?.lastName}
                                    </Typography>
                                    <IconButton
                                          size="xsmall"
                                          color="secondary"
                                          onClick={() => handleSelect(key)}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                      }}
                                    >
                                      <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={extractedKeywords}
                                        autoEscape={true}
                                        textToHighlight={item?.jobTitle}
                                      />
                                    </Typography>
                                    <Box
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <FmdGoodIcon fontSize="inherit" />
                                      <Typography fontSize={"inherit"}>
                                        {item?.city} - {item?.state} -{" "}
                                        {item?.country}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <KeyData
                                        title={"Recent Position"}
                                        fullContent
                                        value={
                                          <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={extractedKeywords}
                                            autoEscape={true}
                                            textToHighlight={item?.jobTitle}
                                          />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      {Array.isArray(item?.educationDetails) &&
                                        item?.educationDetails.map(
                                          (degree, index) => (
                                            <KeyData
                                              key={index}
                                              title={`schoolName${degree.schoolName}`}
                                              value={degree.degree}
                                            />
                                          )
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <KeyData
                                        title={"Experience"}
                                        fullContent
                                        value={item?.experienceYears}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {list && (
                  <Box>
                    <JobBoardResultTable
                      rows={
                        searchType === "MonsterClassicSearch" ||
                        searchType === "MonsterPowerSearch" ||
                        searchType === "MonsterSearch"
                          ? monsterCandidateList
                          : searchType === "CareerBuilder"
                          ? cbCandidateList
                          : internalDBCandidateList
                      }
                      searchType={searchType}
                      handleSelect={handleSelect}
                      selectedRows={selectedRows}
                      handleCheckboxChange={handleCheckboxChange}
                      handleClickNotes={handleClickNotes}
                      resumesFile={resumesFile}
                      internalDBMatchedResumes={internalDBMatchedResumes}
                      allUsers={allUsers}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </MainBackground>

          {/* Drawer */}
          <Drawer open={drawerOpen} anchor="right" onClose={closeDrawer}>
            {(searchType === "MonsterClassicSearch" ||
              searchType === "MonsterPowerSearch" ||
              searchType === "MonsterSearch" ||
              searchType === "CareerBuilder") && (
              <>
                <Box
                  sx={{
                    width: 700,
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {loading ? (
                    <>
                      {" "}
                      <ShimmerText line={5} gap={10} />
                    </>
                  ) : (
                    <>
                      {selectedCandidate !== null &&
                      selectedCandidate !== undefined ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ maxWidth: 350 }}>
                              {!internalDBMatchedResumes.some(
                                (applicant) =>
                                  String(applicant.resumeId) ===
                                  String(
                                    candidateList[selectedCandidate]?.ResumeId
                                  )
                              ) ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: 18,
                                      fontWeight: 700,
                                      color: theme.palette.primary.dark,
                                      textDecoration: "none",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    {candidateList[selectedCandidate]?.Name ||
                                      ""}
                                  </Typography>
                                  <Typography
                                    noWrap
                                    sx={{
                                      fontSize: 11,
                                      color: theme.palette.grey[700],
                                    }}
                                  >
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={
                                        candidateList[selectedCandidate]
                                          ?.JobTitle || "N/A"
                                      }
                                    />
                                  </Typography>
                                </>
                              ) : (
                                // <Typography
                                //   component={Link}
                                //   variant="h6"
                                //   sx={{
                                //     textDecoration: "none",
                                //     "&:hover": { textDecoration: "underline" },

                                //   }}
                                //   to={`/applicantDetails/${
                                //     internalDBMatchedResumes.find(
                                //       (applicant) =>
                                //         String(applicant?.resumeId) ===
                                //         String(candidateList[selectedCandidate]?.ResumeId)
                                //     )?.applicantId || ""
                                //   }`}// Assuming this is the intended link
                                //   state={{
                                //     applicant: candidateList[selectedCandidate],
                                //   }} // Passing the candidate object in state
                                // >
                                //   {internalDBMatchedResumes.find(
                                //     (applicant) =>
                                //       String(applicant.resumeId) ===
                                //       String(
                                //         candidateList[selectedCandidate]
                                //           ?.ResumeId
                                //       )
                                //   )?.applicantId || ""}
                                //   {" - "}
                                //   {candidateList[selectedCandidate]?.Name || ""}
                                //   {" - "}
                                //   <Highlighter
                                //     highlightClassName="YourHighlightClass"
                                //     searchWords={extractedKeywords}
                                //     autoEscape={true}
                                //     textToHighlight={
                                //       candidateList[selectedCandidate]
                                //         ?.JobTitle || "N/A"
                                //     }
                                //   />
                                // </Typography>
                                <>
                                  <Typography
                                    component={Link}
                                    to={`/applicantDetails/${
                                      internalDBMatchedResumes.find(
                                        (applicant) =>
                                          String(applicant?.resumeId) ===
                                          String(
                                            candidateList[selectedCandidate]
                                              ?.ResumeId
                                          )
                                      )?.applicantId || ""
                                    }`} // Assuming this is the intended link
                                    state={{
                                      applicant:
                                        candidateList[selectedCandidate],
                                    }} // Passing the candidate object in state
                                    sx={{
                                      fontSize: 18,
                                      fontWeight: 700,
                                      color: theme.palette.primary.dark,
                                      textDecoration: "none",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    {internalDBMatchedResumes.find(
                                      (applicant) =>
                                        String(applicant.resumeId) ===
                                        String(
                                          candidateList[selectedCandidate]
                                            ?.ResumeId
                                        )
                                    )?.applicantId || ""}
                                    {" - "}
                                    {candidateList[selectedCandidate]?.Name ||
                                      ""}
                                  </Typography>
                                  <Typography
                                    noWrap
                                    sx={{
                                      fontSize: 11,
                                      color: theme.palette.grey[700],
                                    }}
                                  >
                                    {" "}
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={
                                        candidateList[selectedCandidate]
                                          ?.JobTitle || "N/A"
                                      }
                                    />{" "}
                                  </Typography>
                                </>
                              )}
                            </Box>

                            <Box>
                              <ButtonGroup>
                                {internalDBMatchedResumes.some(
                                  (applicant) =>
                                    applicant.resumeId ===
                                    (searchType === "CareerBuilder"
                                      ? cbCandidateList
                                      : monsterCandidateList)[selectedCandidate]
                                      ?.ResumeId
                                ) &&
                                  internalDBMatchedResumes.map((applicant) =>
                                    applicant.resumeId ===
                                    candidateList[selectedCandidate]
                                      ?.ResumeId ? (
                                      <IconButton
                                        size="small"
                                        key={applicant.resumeId}
                                      >
                                        <Tooltip title="Download">
                                          <FileDownloadIcon
                                            onClick={() =>
                                              handleDownloadResume(
                                                applicant?.resumes?.[
                                                  applicant.resumes.length - 1
                                                ]?.serverLocation,
                                                applicant
                                              )
                                            }
                                            sx={{ fontSize: "17px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    ) : (
                                      ""
                                    )
                                  )}

                                <IconButton size="small">
                                  <Tooltip title="Similar Applicants">
                                    <SupervisorAccountIcon
                                      sx={{ fontSize: "14px" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId && (
                                      <IconButton size="small">
                                        <Tooltip
                                          title="Open in New Tab"
                                          onClick={() =>
                                            handleNewWindow(applicant)
                                          }
                                        >
                                          <OpenInNewIcon
                                            sx={{ fontSize: "14px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    )
                                )}
                                {internalDBMatchedResumes.some(
                                  (applicant) =>
                                    applicant.resumeId ===
                                    candidateList[selectedCandidate]?.ResumeId
                                ) ? (
                                  internalDBMatchedResumes.map((applicant) =>
                                    applicant.resumeId ===
                                    candidateList[selectedCandidate]
                                      ?.ResumeId ? (
                                      <IconButton
                                        size="small"
                                        key={applicant.resumeId}
                                      >
                                        <Tooltip title="View the Resume">
                                          <VisibilityIcon
                                            onClick={() =>
                                              handleAxiosLink(
                                                applicant?.resumes?.[
                                                  applicant.resumes.length - 1
                                                ]?.serverLocation
                                              )
                                            }
                                            sx={{ fontSize: "17px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    ) : (
                                      ""
                                    )
                                  )
                                ) : (
                                  <IconButton size="small">
                                    <Tooltip title="Merge to Internal Database">
                                      <AddIcon
                                        onClick={handleGetResume}
                                        sx={{ fontSize: "17px" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                )}

                                <IconButton size="small">
                                  <Tooltip title="Email">
                                    <MailOutlineIcon
                                      sx={{ fontSize: "17px" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId && (
                                      <IconButton size="small">
                                        <Tooltip
                                          title="Add Notes"
                                          onClick={() =>
                                            handleClickNotes(
                                              applicant,
                                              "MonsterCB",
                                              ""
                                            )
                                          }
                                        >
                                          <NoteAddOutlinedIcon
                                            sx={{ fontSize: "17px" }}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    )
                                )}
                                <IconButton size="small">
                                  <Tooltip title="Share the Profile">
                                    <ShortcutIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip title="Add to Group">
                                    <GroupsIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip title="Share Job">
                                    <ShareIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>

                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId && (
                                      <>
                                        <IconButton size="small">
                                          <Tooltip
                                            title="Tag to Job"
                                            onClick={() =>
                                              handleQuickClickSubmitModal(
                                                applicant,
                                                "TagJob"
                                              )
                                            }
                                          >
                                            <LabelIcon
                                              sx={{ fontSize: "17px" }}
                                            />
                                          </Tooltip>
                                        </IconButton>

                                        <IconButton size="small">
                                          <Tooltip
                                            title="Quick Submit to Job"
                                            onClick={() =>
                                              handleQuickClickSubmitModal(
                                                applicant,
                                                "SubmitJob"
                                              )
                                            }
                                          >
                                            <PersonAddAlt1Icon
                                              sx={{ fontSize: "17px" }}
                                            />
                                          </Tooltip>
                                        </IconButton>
                                      </>
                                    )
                                )}
                              </ButtonGroup>
                            </Box>
                          </Box>

                          <Box
                            bgcolor={theme.palette.grey[100]}
                            padding={2}
                            marginY={2}
                          >
                            <Grid container spacing={1}>
                              {/* <Grid item xs={4}>
                                <KeyData
                                  title={"Job Title"}
                                  value={
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={
                                        candidateList[selectedCandidate]
                                          ?.JobTitle || ""
                                      }
                                    />
                                  }
                                />
                              </Grid> */}
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Location"}
                                  value={
                                    candidateList[selectedCandidate]
                                      ?.HomeLocation ||
                                    candidateList[selectedCandidate]?.Location
                                      ?.country +
                                      " - " +
                                      candidateList[selectedCandidate]?.Location
                                        ?.state +
                                      " - " +
                                      candidateList[selectedCandidate]?.Location
                                        ?.city ||
                                    ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Recent Education"}
                                  value={
                                    candidateList[selectedCandidate]
                                      ?.HighestEducation ||
                                    candidateList[selectedCandidate]
                                      ?.highestEducation ||
                                    ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Experience"}
                                  value={
                                    getYearsfromMonths(
                                      candidateList[selectedCandidate]
                                        ?.MonthsOfExperience
                                    ) ||
                                    `${
                                      candidateList[selectedCandidate]
                                        ?.ExperienceYears || ""
                                    }${"-"}${
                                      candidateList[selectedCandidate]
                                        ?.ExperienceMonths || ""
                                    }` ||
                                    "-"
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId &&
                                    applicant?.mobileNumbers?.map(
                                      (mob, index) => (
                                        <KeyData
                                          key={index}
                                          title={`${mob.type} Mobile Number`}
                                          value={mob.number || ""}
                                        />
                                      )
                                    )
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                {internalDBMatchedResumes.map(
                                  (applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId &&
                                    applicant?.emailAddress?.map(
                                      (mob, index) => (
                                        <>
                                          {mob?.email && (
                                            <>
                                              <KeyData
                                                key={index}
                                                title={`${mob.type} Email`}
                                                value={mob.email || ""}
                                              />
                                              <CopyToClipboardButton
                                                text={mob.email}
                                              />
                                            </>
                                          )}
                                        </>
                                      )
                                    )
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Work Authorization"}
                                  value={
                                    candidateList[selectedCandidate]
                                      ?.Authorization || "-"
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            flex={1}
                          >
                            <TabContext value={openTabId}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <TabList
                                  onChange={handleChange}
                                  aria-label="Job Boards tabs"
                                  size="small"
                                  variant="scrollable"
                                >
                                  <Tab label="Resume" value="1" />
                                  <Tab label="Personal Details" value="2" />
                                  <Tab label="Job Applied" value="3" />
                                  <Tab label="Notes" value="4" />
                                  <Tab label="Work Experience" value="5" />
                                  <Tab label="Email Merges" value="6" />
                                </TabList>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TabPanel
                                  value="1"
                                  sx={{
                                    p: 0,
                                    flex: 1,
                                  }}
                                >
                                  {internalDBMatchedResumes.some(
                                    (applicant) =>
                                      applicant.resumeId ===
                                      candidateList[selectedCandidate]?.ResumeId
                                  ) ? (
                                    <>
                                      <Box
                                        sx={{
                                          p: 2,
                                          display: "flex",
                                          gap: 1,
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {Object.entries(keywordCounts).map(
                                          ([keyword, count]) => (
                                            <Chip
                                              key={keyword}
                                              size="small"
                                              label={`${keyword} - ${count} `}
                                              variant="outlined"
                                            />
                                          )
                                        )}
                                      </Box>

                                      <Box>
                                        {(() => {
                                          const matchedApplicant =
                                            internalDBMatchedResumes.find(
                                              (applicant) =>
                                                applicant.resumeId ===
                                                candidateList[selectedCandidate]
                                                  ?.ResumeId
                                            );
                                          if (matchedApplicant) {
                                            const user = allUsers.find(
                                              (user) =>
                                                user._id ===
                                                matchedApplicant.addedBy
                                            );
                                            const userName =
                                              user?.userSudoFirstName ||
                                              "Unknown";
                                            const addedOnDate =
                                              matchedApplicant.addedOn
                                                ? new Date(
                                                    matchedApplicant.addedOn
                                                  ).toLocaleString(undefined, {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    hourCycle: "h23",
                                                  })
                                                : "Unknown Date";
                                            return (
                                              <>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 0.5,
                                                    py: 1,
                                                  }}
                                                >
                                                  <Typography>
                                                    Migrated by
                                                  </Typography>
                                                  <Chip
                                                    label={userName}
                                                    size="small"
                                                    icon={
                                                      <SentimentVerySatisfiedIcon />
                                                    }
                                                    variant="outlined"
                                                    color="primary"
                                                  />
                                                  <Typography>from</Typography>
                                                  <Chip
                                                    label={
                                                      matchedApplicant?.source ||
                                                      "Unknown source"
                                                    }
                                                    size="small"
                                                    icon={<CloudDownloadIcon />}
                                                    variant="outlined"
                                                    color="secondary"
                                                  />
                                                  <Typography>on</Typography>
                                                  <Chip
                                                    label={addedOnDate}
                                                    size="small"
                                                    icon={<CalendarMonthIcon />}
                                                    variant="outlined"
                                                    color="info"
                                                  />
                                                </Box>
                                                <Box>
                                                  Trouble viewing resume?
                                                  <Button
                                                    onClick={() =>
                                                      handleClickRevertHtml(
                                                        matchedApplicant
                                                      )
                                                    }
                                                  >
                                                    {" "}
                                                    Click Here
                                                  </Button>
                                                </Box>
                                              </>
                                            );
                                          }
                                          return "";
                                        })()}
                                      </Box>
                                      <div
                                        // dangerouslySetInnerHTML={{
                                        //   __html: highlightedHtml,
                                        // }}

                                        dangerouslySetInnerHTML={{
                                          __html: highlightedHtml,
                                        }}
                                        style={{
                                          padding: "20px",
                                          backgroundColor: "#f9f9f9",
                                          borderRadius: "8px",
                                          boxShadow:
                                            "0 2px 4px rgba(0,0,0,0.1)",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: 2,
                                          px: 10,
                                        }}
                                      >
                                        <Typography
                                          variant="h5"
                                          textAlign="center"
                                        >
                                          By clicking here, credits will be
                                          used, and you will unlock the resume.
                                        </Typography>
                                        <Button
                                          variant="outlined"
                                          sx={{ textTransform: "inherit" }}
                                          onClick={() => handleGetResume()}
                                        >
                                          Get the resume
                                        </Button>
                                      </Box>
                                    </Box>
                                  )}
                                </TabPanel>
                                <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                                  {internalDBMatchedResumes.map(
                                    (applicant) =>
                                      applicant.resumeId ===
                                        candidateList[selectedCandidate]
                                          ?.ResumeId && (
                                        <Box
                                          bgcolor={theme.palette.grey[100]}
                                          padding={2}
                                          marginY={2}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"FirstName"}
                                                value={
                                                  applicant?.firstName || ""
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"Middle Name"}
                                                value={
                                                  applicant?.middleName || ""
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"Last Name"}
                                                value={
                                                  applicant?.lastName || ""
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"Gender"}
                                                value={applicant?.gender || ""}
                                              />
                                            </Grid>

                                            <Grid item xs={4}>
                                              {applicant?.mobileNumbers?.map(
                                                (mob, index) => (
                                                  <KeyData
                                                    key={index}
                                                    title={`${mob.type} Mobile Number`}
                                                    value={mob.number || ""}
                                                  />
                                                )
                                              )}
                                            </Grid>
                                            <Grid item xs={4}>
                                              {applicant?.emailAddress?.map(
                                                (mob, index) => (
                                                  <>
                                                    {mob.email && (
                                                      <>
                                                        <KeyData
                                                          key={index}
                                                          title={`${mob.type} Email Address`}
                                                          value={
                                                            mob.email || ""
                                                          }
                                                        />
                                                        <CopyToClipboardButton
                                                          text={mob.email}
                                                        />
                                                      </>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"State"}
                                                value={applicant?.state || ""}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"City"}
                                                value={applicant?.city || ""}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <KeyData
                                                title={"ZipCode"}
                                                value={applicant?.zipCode || ""}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )
                                  )}
                                </TabPanel>
                                <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                                  Job Applied
                                </TabPanel>
                                <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                                  Notes
                                  {internalDBMatchedResumes.map((applicant) =>
                                    applicant.resumeId ===
                                      candidateList[selectedCandidate]
                                        ?.ResumeId &&
                                    applicant?.note?.length > 0
                                      ? applicant.note.map(
                                          (noteItem, index) => (
                                            <MainBackground key={index}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: 1,
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontSize: 13,
                                                    letterSpacing: 0.2,
                                                    color:
                                                      theme.palette.grey[800],
                                                  }}
                                                >
                                                  Note:{" "}
                                                  {/* {noteItem.note &&
                                                  typeof noteItem.note ===
                                                    "string" ? (
                                                    // Render as plain text if it's a string
                                                    <span>{noteItem.note}</span>
                                                  ) : (
                                                    // Render as HTML if it's an HTML string
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: noteItem.note,
                                                      }}
                                                    />
                                                  )} */}
                                                  {removeHtmlTags(
                                                    noteItem.note
                                                  )}
                                                </Typography>
                                                <Divider sx={{ py: 0.5 }} />
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    gap: 0.5,
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: 11,
                                                      letterSpacing: 0.2,
                                                      color:
                                                        theme.palette.grey[500],
                                                    }}
                                                  >
                                                    addedBy -{" "}
                                                    {allUsers.find(
                                                      (user) =>
                                                        user._id ===
                                                        noteItem.addedBy
                                                    )?.userSudoFirstName ||
                                                      "Unknown"}
                                                  </Typography>

                                                  <Typography
                                                    sx={{
                                                      fontSize: 11,
                                                      letterSpacing: 0.2,
                                                      color:
                                                        theme.palette.grey[500],
                                                    }}
                                                  >
                                                    {FormatDateTime(
                                                      noteItem.addedOn,
                                                      "full"
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </MainBackground>
                                          )
                                        )
                                      : ""
                                  )}
                                </TabPanel>
                                <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                                  Work Experience
                                </TabPanel>
                                <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                                  Email Merges
                                </TabPanel>
                              </Box>
                            </TabContext>
                          </Box>
                        </>
                      ) : (
                        <Typography variant="body1">
                          No details available {selectedCandidate}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
            {searchType === "InternalDB" && (
              <>
                <Box
                  sx={{
                    width: 700,
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {selectedCandidate !== null &&
                  selectedCandidate !== undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ maxWidth: 350 }}>
                          <Typography
                            component={Link}
                            to={`/applicantDetails/${
                              internalDBCandidateList[selectedCandidate]
                                ?.applicantId || ""
                            }`}
                            // Assuming this is the intended link
                            state={{
                              applicant:
                                internalDBCandidateList[selectedCandidate],
                            }} // Passing the candidate object in state
                            sx={{
                              fontSize: 18,
                              fontWeight: 700,
                              color: theme.palette.primary.dark,
                              textDecoration: "none",
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            {internalDBCandidateList[selectedCandidate]
                              ?.applicantId || ""}
                            {" - "}
                            {internalDBCandidateList[selectedCandidate]
                              ?.firstName || ""}{" "}
                            {internalDBCandidateList[selectedCandidate]
                              ?.lastName || ""}
                          </Typography>
                          <Typography
                            noWrap
                            sx={{
                              fontSize: 11,
                              color: theme.palette.grey[700],
                            }}
                          >
                            {" "}
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={extractedKeywords}
                              autoEscape={true}
                              textToHighlight={
                                internalDBCandidateList[selectedCandidate]
                                  ?.jobTitle || ""
                              }
                            />{" "}
                          </Typography>
                        </Box>
                        <Box></Box>

                        <Box>
                          <ButtonGroup size="small">
                            {/* <IconButton size="small">
                              <Tooltip title="Download">
                                <FileDownloadIcon sx={{ fontSize: "18px" }} onClick={}/>
                              </Tooltip>
                            </IconButton> */}

                            {internalDBCandidateList[selectedCandidate]
                              .resumes &&
                              internalDBCandidateList[selectedCandidate].resumes
                                .length > 0 && (
                                <IconButton size="small">
                                  <Tooltip title="Download">
                                    <FileDownloadIcon
                                      onClick={() =>
                                        handleDownloadResume(
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.resumes?.[
                                            internalDBCandidateList[
                                              selectedCandidate
                                            ]?.resumes?.length - 1
                                          ]?.serverLocation,
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            <IconButton size="small">
                              <Tooltip title="Similar Applicants">
                                <SupervisorAccountIcon
                                  sx={{ fontSize: "14px" }}
                                />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Open in New Tab"
                                onClick={() =>
                                  handleNewWindow(
                                    internalDBCandidateList[selectedCandidate]
                                  )
                                }
                              >
                                <OpenInNewIcon sx={{ fontSize: "14px" }} />
                              </Tooltip>
                            </IconButton>

                            {internalDBCandidateList[selectedCandidate]
                              .resumes &&
                              internalDBCandidateList[selectedCandidate].resumes
                                .length > 0 && (
                                <IconButton size="small">
                                  <Tooltip title="View the Resume">
                                    <VisibilityIcon
                                      onClick={() =>
                                        handleAxiosLink(
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.resumes?.[
                                            internalDBCandidateList[
                                              selectedCandidate
                                            ]?.resumes?.length - 1
                                          ]?.serverLocation
                                        )
                                      }
                                      title="View the resume"
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            <IconButton size="small">
                              <Tooltip title="Email">
                                <MailOutlineIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Add Notes"
                                onClick={() =>
                                  handleClickNotes(
                                    internalDBCandidateList[selectedCandidate],
                                    "InternalDB",
                                    ""
                                  )
                                }
                              >
                                <NoteAddOutlinedIcon
                                  sx={{ fontSize: "17px" }}
                                />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Share the Profile">
                                <ShortcutIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Add to Group">
                                <GroupsIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Share Job">
                                <ShareIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Tag to Job"
                                onClick={() =>
                                  handleQuickClickSubmitModal(
                                    internalDBCandidateList[selectedCandidate],
                                    "TagJob"
                                  )
                                }
                              >
                                <LabelIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Quick Submit to Job"
                                onClick={() =>
                                  handleQuickClickSubmitModal(
                                    internalDBCandidateList[selectedCandidate],
                                    "SubmitJob"
                                  )
                                }
                              >
                                <PersonAddAlt1Icon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                          </ButtonGroup>
                        </Box>
                      </Box>

                      <Box
                        bgcolor={theme.palette.grey[100]}
                        padding={2}
                        marginY={2}
                      >
                        <Grid container spacing={1}>
                          {internalDBCandidateList[
                            selectedCandidate
                          ].mobileNumbers.map((number, index) => (
                            <Grid item xs={4} key={index}>
                              <KeyData
                                title={`mobile${number.type}`}
                                value={number.number}
                              />
                            </Grid>
                          ))}

                          <Grid item xs={4}>
                            <KeyData
                              title={"Job Title"}
                              value={
                                internalDBCandidateList[selectedCandidate]
                                  ?.jobTitle || ""
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Location"}
                              value={`${
                                internalDBCandidateList[selectedCandidate]
                                  ?.city || ""
                              } - ${
                                internalDBCandidateList[selectedCandidate]
                                  ?.state || ""
                              } - ${
                                internalDBCandidateList[selectedCandidate]
                                  ?.country || ""
                              }`}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            {Array.isArray(
                              internalDBCandidateList[selectedCandidate]
                                ?.educationDetails
                            ) &&
                              internalDBCandidateList[
                                selectedCandidate
                              ].educationDetails.map((degree, index) => (
                                <KeyData
                                  key={index}
                                  title={`schoolName${degree.schoolName}`}
                                  value={degree.degree}
                                />
                              ))}
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Experience(years)"}
                              value={
                                internalDBCandidateList[selectedCandidate]
                                  .experienceYears || ""
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Email"}
                              value={
                                internalDBCandidateList[
                                  selectedCandidate
                                ].emailAddress?.find(
                                  (emails) => emails.type === "primary"
                                ).email || "-"
                              }
                            />
                            <CopyToClipboardButton
                              text={
                                internalDBCandidateList[
                                  selectedCandidate
                                ].emailAddress?.find(
                                  (emails) => emails.type === "primary"
                                ).email || "-"
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Work Authorization"}
                              value={
                                internalDBCandidateList[selectedCandidate]
                                  .workAuthorization || "-"
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box display={"flex"} flexDirection={"column"} flex={1}>
                        <TabContext value={openTabId}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChange}
                              aria-label="ob Boards tabs"
                              size="small"
                              variant="scrollable"
                            >
                              <Tab label="Resume" value="1" />
                              <Tab label="Personal Details" value="2" />
                              <Tab label="Job Applied" value="3" />
                              <Tab label="Notes" value="4" />
                              <Tab label="Skills" value={"5"} />
                              <Tab label="Work Experience" value="6" />
                              <Tab label="Email Merges" value="7" />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            sx={{
                              p: 0,
                              flex: 1,

                              width: "100%",
                              height: "100vh",
                            }}
                          >
                            {/* {AwsResumeUrl ? (
                      <ResumeText AwsResumeUrl={AwsResumeUrl} />
                    ) : (
                      <></>
                    )} */}
                            {HtmlResume ? (
                              <>
                                <Box
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    gap: 1,
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {Object.entries(keywordCounts).map(
                                    ([keyword, count]) => (
                                      <Chip
                                        size="small"
                                        label={`${keyword} - ${count} `}
                                        variant="outlined"
                                      />
                                    )
                                  )}
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                    py: 1,
                                  }}
                                >
                                  <Typography>Migrated by</Typography>
                                  <Chip
                                    label={`${
                                      allUsers.find(
                                        (user) =>
                                          user._id ===
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.addedBy
                                      )?.userSudoFirstName || "Unknown"
                                    }`}
                                    size="small"
                                    icon={<SentimentVerySatisfiedIcon />}
                                    variant="outlined"
                                    color="primary"
                                  />
                                  <Typography>from</Typography>
                                  <Chip
                                    label={`${
                                      internalDBCandidateList[selectedCandidate]
                                        ?.source || "Unknown source"
                                    }`}
                                    size="small"
                                    icon={<CloudDownloadIcon />}
                                    variant="outlined"
                                    color="secondary"
                                  />
                                  <Typography>on</Typography>
                                  <Chip
                                    label={`${
                                      internalDBCandidateList[selectedCandidate]
                                        ?.addedOn
                                        ? new Date(
                                            internalDBCandidateList[
                                              selectedCandidate
                                            ].addedOn
                                          ).toLocaleString(undefined, {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hourCycle: "h23",
                                          })
                                        : "Unknown date"
                                    }`}
                                    size="small"
                                    icon={<CalendarMonthIcon />}
                                    variant="outlined"
                                    color="info"
                                  />
                                </Box>
                                <Box>
                                  Trouble viewing resume?
                                  <Button
                                    onClick={() =>
                                      handleClickRevertHtml(
                                        internalDBCandidateList[
                                          selectedCandidate
                                        ]
                                      )
                                    }
                                  >
                                    {" "}
                                    Click Here
                                  </Button>
                                </Box>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: highlightedHtml,
                                  }}
                                  style={{
                                    padding: "20px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </TabPanel>
                          <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                            <Box
                              bgcolor={theme.palette.grey[100]}
                              padding={2}
                              marginY={2}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"FirstName"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.firstName || ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Middle Name"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.middleName || ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Last Name"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.lastName || ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Gender"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.gender || ""
                                    }
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  {internalDBCandidateList[
                                    selectedCandidate
                                  ].emailAddress.map((email, index) => (
                                    <>
                                      {email.email && (
                                        <>
                                          <KeyData
                                            title={`email${email.type}`}
                                            value={email.email}
                                          />
                                          <CopyToClipboardButton
                                            text={email.email}
                                          />
                                        </>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"State"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        .state || ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"City"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        .city || ""
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"ZipCode"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.zipCode || ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </TabPanel>
                          <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                            Job Applied
                          </TabPanel>
                          <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                            Notes
                            {internalDBCandidateList[
                              selectedCandidate
                            ]?.note?.map((noteItem, index) => (
                              <MainBackground key={index}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 13,
                                      letterSpacing: 0.2,
                                      color: theme.palette.grey[800],
                                    }}
                                  >
                                    Note:
                                    {removeHtmlTags(noteItem.note)}
                                  </Typography>
                                  <Divider sx={{ py: 0.5 }} />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 0.5,
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      User Name -{" "}
                                      {allUsers.find(
                                        (user) => user._id === noteItem.addedBy
                                      )?.userSudoFirstName || "Unknown"}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      {FormatDateTime(noteItem.addedOn, "full")}
                                    </Typography>
                                  </Box>
                                </Box>
                              </MainBackground>
                            ))}
                          </TabPanel>
                          <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                            <Box
                              bgcolor={theme.palette.grey[100]}
                              padding={2}
                              marginY={2}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    {Array.isArray(
                                      internalDBCandidateList[selectedCandidate]
                                        ?.skills
                                    ) &&
                                      internalDBCandidateList[
                                        selectedCandidate
                                      ]?.skills.map((skill, index) => (
                                        <Grid item xs={3} key={index}>
                                          {" "}
                                          {/* Adjust xs to control width */}
                                          <KeyData
                                            value={
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={extractedKeywords}
                                                autoEscape={true}
                                                textToHighlight={skill || ""}
                                              />
                                            }
                                          />
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </TabPanel>
                          <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                            Work Experience
                          </TabPanel>
                          <TabPanel value="7" sx={{ p: 0, pt: 5 }}>
                            Email Merges
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body1">
                      No details available {selectedCandidate}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Drawer>
          <Modal
            open={openResumeModal}
            onClose={handleCloseResumeModal}
            aria-labelledby="resume-modal-title"
            aria-describedby="resume-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={handleCloseResumeModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* Conditional rendering based on file type */}
              {AwsResumeUrl ? (
                <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
              ) : (
                <Typography variant="h6" textAlign="center">
                  No resume available.
                </Typography>
              )}
            </Box>
          </Modal>
          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={internalDBLoadingRedux || loading}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" size="3rem" />
          </Backdrop>
          <SnackbarComponent />
        </LayoutSidebarMenu>
        {/* ///InternalDB */}
        <Modal
          open={sendMailModal}
          onClose={handleToggleSendMailModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidateSendMail
              close={handleToggleSendMailModal}
              applicantsList={
                searchType === "InternalDB"
                  ? selectedRows
                  : filterSelectedCandidateMigrated
              }
              jobId={""}
            />
          </Box>
        </Modal>
        <AlertModal
          open={warningModal}
          message={warningMessage}
          onClose={handleWarningModalClose}
        />

        <Modal
          open={mailMergeModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 1000,
              // height: "80%",
              // maxHeight: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              //overflow: "hidden",
            }}
          >
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item xs={7}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SectionTitle title="Email Merge" />
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.grey[500] }}
                    >
                      No of Recipients: {selectedRows.length}
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select job"}
                      components={[
                        {
                          type: "autocomplete",
                          props: {
                            placeholder: `select the job`,
                            options: jobPostingOptions,
                            defaultValue: selectJobTag,
                            onChange: (value) => handleJobSelect(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select Template"}
                      required
                      components={[
                        {
                          type: "select",
                          props: {
                            required: true,
                            placeholder: "Select",
                            defaultValue: template,
                            options: optionsTemplate,
                            onChange: (value) => handleChangeTemplate(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item sx={12}>
                    <Button
                      color="inherit"
                      onClick={() => handleToggleCancelMailMerge()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleShareEMail()}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <ScrollView>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: 1,
                    }}
                  >
                    {(searchType === "InternalDB"
                      ? selectedRows
                      : filterSelectedCandidateMigrated
                    ).map((item, key) => {
                      return (
                        <Box
                          key={key}
                          sx={{
                            borderRadius: 2,
                            p: 1,
                            mx: 0.5,
                            background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                            border: `1px solid ${theme.palette.grey[200]}`,
                          }}
                        >
                          <Box>
                            <Grid container spacing={0.2}>
                              <Grid item xs={9}>
                                <Typography sx={{ fontWeight: "bold" }} noWrap>
                                  {item?.firstName || ""}
                                  {item?.middleName || ""}
                                  {item?.lastName || ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={{ fontSize: 11 }}>
                                  Profile used
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Email"}
                                  value={
                                    item?.emailAddress
                                      ? item.emailAddress
                                          .filter((item1) => item1.email) // Filter out objects without an email
                                          .map((item1) => item1.email)
                                          .join(", ")
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Experience"}
                                  value={item.experienceYears || ""}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Job Title"}
                                  value={
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={item?.jobTitle || ""}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Notice Period"}
                                  value={item?.availability}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={10}>
                                {" "}
                                <KeyData
                                  title={" Pay rate"}
                                  value={`${
                                    item?.payRateSalary?.currency || ""
                                  }/${item?.payRateSalary?.minAmount || ""}
                                /${item?.payRateSalary?.maxAmount || ""}/${
                                    item?.payRateSalary?.paySchedule || ""
                                  }/${item?.payRateSalary?.taxTerm || ""}`}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <IconButton size="xsmall" color="error">
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="inherit"
                                    onClick={() => handleDeleteUserProfile(key)}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </ScrollView>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={openSendModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
          onClose={handleShareEMailClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidatesMailMerge
              close={handleShareEMailClose}
              applicantsList={
                searchType === "InternalDB"
                  ? selectedRows
                  : filterSelectedCandidateMigrated
              }
              jobId={jobId}
              optionsTemplate={optionsTemplate}
              templateSubjectType={templateSubjectType}
              setTemplateSubjectType={setTemplateSubjectType}
              templateBodyType={templateBodyType}
              setTemplateBodyType={setTemplateBodyType}
              template={template}
              setTemplate={setTemplate}
              templateId={templateId}
            />
          </Box>
        </Modal>
        <JobBoardsQuickSubmitSelectJob
          open={openQuickSubmitModal}
          close={handleCancelQuickSubmitModal}
          submit={handleQuickSubmit}
          setSelectJobQuickSubmit={setSelectJobQuickSubmit}
          selectJobQuickSubmit={selectJobQuickSubmit}
          jobPostingOptions={jobPostingOptions}
          selectTagStatus={selectTagStatus}
        />
        <JobBoardsQuickSubmitCheckAutharize
          open={openModalNotAutharized}
          close={setOpenModalNotAutharized}
        />
        <JobBoardsQuickSubmitConfirmModal
          open={isConfirmModalOpen}
          close={handleCloseConfirmModalOpen}
          cofirm={handleQuickSubmitConfirm}
          jobId={selectJobQuickSubmit?.label}
          applicantName={`${
            selectCandidateQuickSubmit[0]?.firstName || ""
          }${" "}${selectCandidateQuickSubmit[0]?.middleName || ""}${" "}${
            selectCandidateQuickSubmit[0]?.lastName || ""
          }`}
          selectTagStatus={selectTagStatus}
        />
        <JobBoardsAddNotes
          open={openNoteDrawer}
          close={handleCloseNote}
          allUsers={allUsers}
          notes={
            searchType === "InternalDB"
              ? (
                  internalDBCandidateList.find(
                    (internal) =>
                      internal.applicantId === clickNoteApplicant?.applicantId
                  )?.note || []
                ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
              : (
                  internalDBMatchedResumes.find(
                    (internal) =>
                      internal.applicantId === clickNoteApplicant?.applicantId
                  )?.note || []
                ).sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
          }
          noteActionTexts={noteActionTexts}
          note={note}
          handleNote={handleNote}
          setNote={setNote}
          handleNoteSubmit={handleNoteSubmit}
          jobPostingOptions={jobPostingOptions}
          jobPostReference={jobPostReference}
          setJobPostReference={setJobPostReference}
          noteAction={noteAction}
          setNoteAction={setNoteAction}
          notePriority={notePriority}
          setNotePriory={setNotePriory}
        />
        <JobBoardMigrationModal
          open={openMigrationModal}
          close={handleCloseMigrationModal}
          totalResult={totalResult}
          yes={handleMigrationYES}
        />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default JobBoardsResult;
