import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Drawer,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Tab,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import theme from "../../Theme";
import ScrollView from "../Helper/ScrollView";
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import BusinessIcon from '@mui/icons-material/Business';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import WorkIcon from '@mui/icons-material/Work';
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicantsSearchListViaApi,
  getjobPostingsSearchListViaApi,
  globalSearchResultViaApi,
  stopLoadingRedux,
} from "../../store/actions";
import CommanFilters from "../../utils/CommanFilters";
const ViewContainer = ({
  type = "default", //default, Applicants, Job Postings, Placements
  title = "Lorem ipsum dolor sit",
  title1='',
  title2='',
  desc = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, odio!",

  email='',
  phone='',
  jobCode='',
  companyName='',
  city=''
}) => {
  
  let borderColor, titleTag, descTag;
  // Define properties based on the type
  let icon1=''
  let icon2=''
  let icon3=''
  const styleIcon = {
    fontSize: 14,
    lineHeight: 1,
    color: theme.palette.grey[500],
  };
  
  switch (type) {
    case "Applicants":
      borderColor = theme.palette.secondary.dark;
      titleTag = "Name:";
      descTag = "Application Details:";
      icon1=<PersonIcon sx={ styleIcon}/>;
      icon2=<LocalPhoneIcon sx={ styleIcon}/>;
      icon3=<MailIcon sx={ styleIcon}/>;
      break;
    case "Job Postings":
      borderColor = theme.palette.success.dark;
      icon1=<WorkIcon sx={ styleIcon}/>;
      icon2=<BusinessIcon sx={ styleIcon}/>;
      icon3=<FmdGoodIcon sx={ styleIcon}/>;
      break;
    case "Placements":
      borderColor = theme.palette.info.dark;
      titleTag = "Client Name:";
      descTag = "Email:";
      break;
    default:
      borderColor = theme.palette.grey[400];
      titleTag = "Title:";
      descTag = "Description:";
  }

  return (
    <Paper
      sx={{
        p: 1.5,
        borderTop: "2px solid",
        borderTopColor: borderColor,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {/* {type==='Applicants'&&(
          <> */}
          <Box
          sx={{
            display: "flex",
            gap: 0.4,
            alignItems:"center"
          }}
        >{icon1}
          <Typography
            noWrap
            sx={{ fontSize: 12, fontWeight: 600, lineHeight: 1 }}
          >
            {title}
          </Typography>
          
        </Box>
          
            <Box sx={{ display: "flex", gap: 0.4, alignItems:"center" }}>
            
            {icon2}
          <Typography
            noWrap
            sx={{ fontSize: 12, fontWeight: 400, lineHeight: 1 }}
          >
            {title1}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.4, alignItems:"center" }}>
        {icon3}
          <Typography
            noWrap
            sx={{ fontSize: 12, fontWeight: 400, lineHeight: 1 }}
          >
            {title2}
          </Typography>
        </Box>

       
      </Box>
    </Paper>
  );
};
const customTabLabel = (text, count, countColor) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{text}</Typography>
    <Typography sx={{ fontWeight: 400, fontSize: 12, color: countColor }}>
      {count}
    </Typography>
  </Box>
);

const CustomTabPanel = ({ value, panelValue, dataCount, type,getDisplayString,limitCount,handleClickMore,handleNavigate }) => {
  if (value !== panelValue) return null;
  return (
    <TabPanel value={panelValue} sx={{ flex: 1, height: "100%", p: 0 }}>
      <Box sx={{ display: "flex", flex: 1, height: "100%" }}>
        <ScrollView>
          <Box p={2}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              {Array.isArray(dataCount) &&
                dataCount.map((data, index) => (
                  <Grid item xs={2} key={data?.applicantId || index}  onClick={()=>handleNavigate(data,panelValue)}
                  style={{ cursor: "pointer" }} // Add pointer cursor
                  >
                    <ViewContainer
                      type={type || "default"}
                      title={panelValue==='Applicants'?`${data?.applicantId||''}${'-'}${data?.firstName||''}${' '}${data?.lastName||''}`:panelValue==='Job Postings'?`${getDisplayString(data?.jobDetails?.jobCode)}${' '}${data?.jobDetails?.jobTitle}`:''}
                      title1={panelValue==='Applicants'?data?.emailAddress
                        ?.filter(item => typeof item.email === "string" && item.email.trim() !== "") 
                        .map(item => item.email) 
                        .join(", ") || "":panelValue==='Job Postings'?data?.jobDetails?.client :''}

                        title2={panelValue==='Applicants'?data?.mobileNumbers
                          ?.filter(item => typeof item.number === "string" && item.number.trim() !== "")
                          .map(item => item.number) 
                          .join(", ") || "" :panelValue==='Job Postings'?data?.jobDetails?.location:''}
                      email={
                        ''
                      }
                      phone={
                        ''
                      }
                      jobCode={''}
                        companyName={''}
                        city={''}
                    />
                  </Grid>
                ))}
            </Grid>
            <Box p={2} display={"flex"} justifyContent={"center"}>
              {limitCount>dataCount.length&&(
              <Button onClick={()=>handleClickMore(panelValue)}>Load more...!{panelValue}</Button>
              )}
           
              </Box> 
          </Box>
        </ScrollView>
       
      </Box>
    </TabPanel>
  );
};

const GlobalSearchDrawer = ({ open, onClose, client,value,setValue,selectSearchType,setSelectSearchType }) => {
  const {getDisplayString}=CommanFilters
  const dispatch = useDispatch();
  const ApplicantsList =
    useSelector((state) => state.JobBoards?.applicantsList) || [];
 const Applicants = useSelector((state) => state.Recruiters?.applicants);
 console.log('Applicants',Applicants);
  const JobPostingsList =
    useSelector((state) => state.JobBoards?.jobPostingsList) || [];
  const CountAllGlobalSearchList =
    useSelector((state) => state.JobBoards?.countAllGlobalSearchList) || {};
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [loading, setLoading] = useState(false);

  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [page,setPage]=useState(0)
  const [pageSize, setPageSize] = useState(10);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue==='Placements'){
      return ''
    }
    if (searchKeyWord?.trim()) {
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:newValue,
        client: client,
      };
      setLoading(true);
      dispatch(globalSearchResultViaApi(action));
    }
  };
  const handleSelectChangeSearchType = (e) => {
    setSelectSearchType(e.target.value);
    setValue((e.target.value === "All")?value:e.target.value);
    if(e.target.value==='Placements'){
      return ''
    }
    if (!searchKeyWord?.trim()) {
        return ''
    }
    setLoading(true);
    if(e.target.value==="All"){
        const action = {
          searchKeyWord: searchKeyWord,
          moduleType:
            (e.target.value === "All") ?'Applicants' :e.target.value,
          client: client,
        };
        setValue('Applicants')
        dispatch(globalSearchResultViaApi(action));
    }else if(e.target.value==="Applicants"){
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:
          (e.target.value === "All") ?'Applicants' :e.target.value,
        client: client,
        page:0,
        pageSize:30,
      };
      dispatch(getApplicantsSearchListViaApi(action))
    }else if(e.target.value==="Job Postings"){
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:e.target.value,
        client: client,
        page:0,
        pageSize:30,
      };
      dispatch(getjobPostingsSearchListViaApi(action))
    }
  };
  const handleGlobalSearch = () => {
    if(selectSearchType==='Placements'){
      return ''
    }
    if (!searchKeyWord?.trim()) {
      return ''
  }
  setLoading(true);
    if (selectSearchType==="All") {
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:
          (selectSearchType === "All"&&value==='Applicants') ? "Applicants" : (selectSearchType==="All"&&value!=='Applicants'?value:selectSearchType),
        client: client,
      };
      setValue('Applicants')
      dispatch(globalSearchResultViaApi(action));
    }
    else if(selectSearchType==="Applicants"){
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:selectSearchType,
        client: client,
        page:0,
        pageSize:30,
      };
      dispatch(getApplicantsSearchListViaApi(action))
    }else if(selectSearchType==="Job Postings"){
      const action = {
        searchKeyWord: searchKeyWord,
        moduleType:selectSearchType,
        client: client,
        page:0,
        pageSize:30,
      };
      dispatch(getjobPostingsSearchListViaApi(action))
    }
  };
const handleClickMore=(value)=>{
  setLoading(true)
  if(value==='Applicants'){
    const action = {
      searchKeyWord: searchKeyWord,
      moduleType:value,
      client: client,
      page:page+1,
      pageSize:30,
    };
    dispatch(getApplicantsSearchListViaApi(action))
  }else if(value==='Job Postings'){
    const action = {
      searchKeyWord: searchKeyWord,
      moduleType:value,
      client: client,
      page:page+1,
      pageSize:30,
    };
    dispatch(getjobPostingsSearchListViaApi(action))
  }
  setPage(page+1)
}

const handleNavigate=(data,type)=>{
  if(type==='Applicants'){
    localStorage.setItem('ApplicantsList', JSON.stringify([data]));
    const url = `/applicantDetails/${encodeURIComponent(
      data?.applicantId
    )}`;
    window.open(url, "_blank", "noopener,noreferrer");
    
  }else if(type==='Job Postings'){
    const url = `/jobPostDetails?bdmCompanyId=${encodeURIComponent(data?.bdmCompanyId)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  }
}




  const handleClose = () => "";
  
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { height: "90%" }, // Apply height to the Paper component
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: 800,
            }}
          >
            {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                <MenuIcon />
              </IconButton> */}
            <Select
              value={selectSearchType}
              size="small"
              sx={{
                width: 170,
                "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // Remove default border
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                }, // Remove hover border
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                }, // Remove focus border
                "& .MuiSelect-select": {
                  padding: "8px", // Adjust padding for visual balance if needed
                },
              }}
              onChange={handleSelectChangeSearchType}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Job Postings"}>Job Postings</MenuItem>
              <MenuItem value={"Applicants"}>Applicants</MenuItem>
              {/* <MenuItem value={"Placements"}>Placements</MenuItem> */}
            </Select>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Global Search"
              inputProps={{ "aria-label": "global search" }}
              onChange={(e) => setSearchKeyWord(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleGlobalSearch(); // Call the function when Enter is pressed
                }
              }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => handleGlobalSearch()}
            >
              <ManageSearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box flex={1}>
          <TabContext value={value}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {CountAllGlobalSearchList?.moduleType&& selectSearchType==="All"&&(
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={customTabLabel(
                      "Applicants",
                      CountAllGlobalSearchList?.applicantsCount,
                      `${theme.palette.secondary.dark}`
                    )}
                    value="Applicants"
                  />
                  <Tab
                    label={customTabLabel(
                      "Job Postings",
                      CountAllGlobalSearchList?.jobPostingsCount,
                      `${theme.palette.success.dark}`
                    )}
                    value="Job Postings"
                  />
                  {/* <Tab
                    label={customTabLabel(
                      "Placements",
                      CountAllGlobalSearchList?.placementsCount,
                      `${theme.palette.info.dark}`
                    )}
                    value="Placements"
                  /> */}
                </TabList>
              </Box>
              )}
              
              <Box sx={{ flex: 1 }}>
                <CustomTabPanel
                  value={value}
                  panelValue="Applicants"
                  dataCount={ApplicantsList}
                  type="Applicants"
                  getDisplayString={getDisplayString}
                  limitCount={CountAllGlobalSearchList?.applicantsCount}
                  handleClickMore={handleClickMore}
                  handleNavigate={handleNavigate}
                />
                <CustomTabPanel
                  value={value}
                  panelValue="Job Postings"
                  dataCount={JobPostingsList}
                  type="Job Postings"
                  getDisplayString={getDisplayString}
                  limitCount={CountAllGlobalSearchList?.jobPostingsCount}
                  handleClickMore={handleClickMore}
                  handleNavigate={handleNavigate}
                />
                {/* <CustomTabPanel
                  value={value}
                  panelValue="Placements"
                  dataCount={0}
                  type="Placements"
                  getDisplayString={getDisplayString}
                  limitCount={CountAllGlobalSearchList?.placementsCount}
                  handleClickMore={handleClickMore}
                  handleNavigate={handleNavigate}
                /> */}

              </Box>
            </Box>
          </TabContext>
        </Box>
      </Box>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
    </Drawer>
  );
};

export default GlobalSearchDrawer;
