import React, { useState } from "react";
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CandidateSendMail from "../Modals/CandidateSendMail";
import SectionTitle from "../Helper/SectionTitle";
import theme from "../../Theme";
import CustomFormField from "../Common/CustomFormField";
import ScrollView from "../Helper/ScrollView";
import KeyData from "../Helper/KeyData";
import Highlighter from "react-highlight-words";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CandidatesMailMerge from "../Modals/CandidatesMailMerge";
import { KeyboardArrowUp } from "@mui/icons-material";
import { monsterSort } from "../../Data/JobPostingData";
import AlertModal from "../Modals/AlertModal";
import CommanFilters from "../../utils/CommanFilters";
const JobBoardMigration = 
({selectedRows,setSelectedRows,searchType,page,
  matchedMonsterResult,matchedCBResult,pageSize,
  handlePageChange,isGrid,setIsGrid,dataList=[],searchTypeGrid='',handleOpenMigrationModal
  ,sortFilter,setSortFilter,handleFilterMonster,filterAlreadyMigratedProfiles=[],filterSelectedCandidateMigrated=[]
}) => {

  const { jobPostingReplacePlaceholders } = CommanFilters;
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const templatesData = useSelector((state) => state.Search.templates) || [];
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
  // Event Handlers
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataList); // Select all rows
      const data =
      searchType === "Monster"
        ? filterAlreadyMigratedProfiles
        : searchType === "CareerBuilder"
        ? filterAlreadyMigratedProfiles
        : dataList;
 if(data.length===0){
  return ''
 }
    setSelectedRows(data); // Select all rows
  
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };

 

  const [bulkActionMenu, setBulkActionMenu] = useState(null);

  const [sortMenu, setSortMenu] = useState(null);

  const open = Boolean(bulkActionMenu);
  
  const [sendMailModal, setSendMailModal] = useState(false);
  const [mailMergeModal, setMailMergeModal] = useState(false);
  const [warningModal,setWarningModal]=useState(false)
  const [warningMessage,setWarningMessage]=useState(false);
  const handleWarningModalClose=()=>{
    setWarningModal(false)
    setWarningMessage(false)
  }
  const handleClickBulkAction = (event) => {
    setBulkActionMenu(event.currentTarget);
  };
  const handleCloseBulkAction = () => {
    setBulkActionMenu(null);
  };
  let toastShown = false; // Flag to track if toast message has been shown
  const handleSendEMail = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      if (!storeData.emailsIntegration) {
        setWarningModal(true)
        setWarningMessage('Your email-ID is not updated, Please contact your Manager!')
        return ''
      }
      const takenAssignedTemplate = templatesData
        ? templatesData.filter((data) => data.templateAssignToId === storeData._id)
        : [];
        if(takenAssignedTemplate.length===0){
          setWarningModal(true)
        setWarningMessage('No Email Template is available, kindly create one to send an email!')
          return ''
        }
    }
    setSendMailModal(!sendMailModal);
  };
  const handleToggleSendMailModal = () => {
    setSendMailModal(!sendMailModal);
  };

  const [selectJobTag, setSelectJobTag] = useState("");
  const [jobId, setJobId] = useState("");
  const [optionsTemplate, setOptionsTemplate] = useState([]);
  const [template, setTemplate] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateSubjectType, setTemplateSubjectType] = useState("");
  const [templateBodyType, setTemplateBodyType] = useState("");
  const [jobDetails,setJobDeatils]=useState({});
  const handleToggleMailMerge = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      if (!storeData.emailsIntegration) {
        setWarningModal(true)
        setWarningMessage('Your email-ID is not updated, Please contact your Manager!')
        return ''
      }
      const takenAssignedTemplate = templatesData
        ? templatesData.filter((data) => data.templateAssignToId === storeData._id)
        : [];
        if(takenAssignedTemplate.length===0){
          setWarningModal(true)
        setWarningMessage('No Email Template is available, kindly create one to send an email!')
          return ''
        }
    }
    setMailMergeModal(!mailMergeModal);
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === storeData?._id
          )
        : [];
      const takeTemplateOption = takenAssignedTemplate
        ? takenAssignedTemplate.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    } else {
      const takeTemplateOption = templatesData
        ? templatesData.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    }
  };
  const handleChangeTemplate = (value) => {
    setTemplate(value);

    const filtered = templatesData
      ? templatesData.filter((template) => template.templateTitle === value)
      : [];
    const { type, emailSubject, emailBody, _id } = filtered ? filtered[0] : [];
    setTemplateBodyType(emailBody.length > 0 ? emailBody : "");
    setTemplateSubjectType(emailSubject.length > 0 ? emailSubject : "");
    setTemplateId(_id || "");
  };
  const handleToggleCancelMailMerge = () => {
    setMailMergeModal(!mailMergeModal);
    setJobId("");
    setTemplateId("");
    setTemplate("");
    setSelectJobTag("");
    setJobDeatils({})
  };
  const handleJobSelect = (value) => {
    setSelectJobTag(value);
    if (value && value.value) {
      const takeJobPosting = Array.isArray(JobPosting) ? JobPosting : [];
      const findId = takeJobPosting.find(
        (job) => job.jobDetails.jobCode === value.value
      );
      setJobId(findId._id);
      setJobDeatils({
        jobTitle:findId?.jobDetails?.jobTitle||'',
        jobLocation:findId?.jobDetails?.location||'',
        jobDescription:findId?.organizationalInformation?.jobDescription||''
      })
    }
  };
  const [openSendModal, setOpenSendModal] = useState(false);
  const handleShareEMail = () => {
    if (!jobId || !templateId) {
      enqueueSnackbar(`Please select fileds`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    setOpenSendModal(!openSendModal);
    setTemplateBodyType(jobPostingReplacePlaceholders(templateBodyType,jobDetails))
    setTemplateSubjectType(jobPostingReplacePlaceholders(templateSubjectType,jobDetails))
  };
  const handleShareEMailClose = () => {
    setOpenSendModal(!openSendModal);
    setJobId("");
    setTemplateId("");
    setTemplateBodyType("");
    setTemplateSubjectType("");
    setMailMergeModal(!mailMergeModal);
    setTemplate("");
    setSelectJobTag("");
    setJobDeatils({})
  };
  const handleDeleteUserProfile = (index) => {
    // Filter out the item at the specified index
    const updatedRows = selectedRows.filter((_, i) => i !== index);

    // Check that at least one item remains
    if (updatedRows.length > 0) {
      setSelectedRows(updatedRows);
    } else {
      alert("At least one item must remain.");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          checked={
            selectedRows.length === (searchType==="InternalDB"?dataList:filterAlreadyMigratedProfiles).length && selectedRows.length > 0
          }
          onChange={handleHeaderCheckboxChange}
        />
        Select Migrated Profile
        {searchType !== "InternalDB"&&(
        <Button variant="text" startIcon={<SwapHorizIcon />} size="small" onClick={handleOpenMigrationModal}>
          Migrate Profiles
        </Button>
        )}
          {(searchType === "InternalDB"||filterSelectedCandidateMigrated.length>0) && (
        <Box ml={1}>
        
            <Button
              size="small"
              onClick={handleClickBulkAction}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Bulk Actions
            </Button>
         

          <Menu
            anchorEl={bulkActionMenu}
            open={Boolean(bulkActionMenu)}
            onClose={handleCloseBulkAction}
            onClick={handleCloseBulkAction}
            hideBackdrop
          >
            <MenuItem onClick={handleSendEMail}>Send Email</MenuItem>
            <MenuItem onClick={handleCloseBulkAction}>Share Job</MenuItem>
            <MenuItem onClick={handleCloseBulkAction}>Add to Group</MenuItem>
            <MenuItem onClick={handleToggleMailMerge}>Mail Merge</MenuItem>
          </Menu>
        </Box>
         )}
         {/* add sort options */}
         {searchType === "Monster" && (
                      <Box ml={1}>
                      <Button
                          size="small"
                          onClick={({currentTarget}) => setSortMenu(currentTarget)}
                          endIcon={Boolean(sortMenu) ? <KeyboardArrowUp/> :<KeyboardArrowDownIcon />}
                        >
                         Sort By - {monsterSort.find((label)=>label.value===sortFilter)?.label}
                        </Button>
                      {/* <Menu
                        anchorEl={sortMenu}
                        open={Boolean(sortMenu)}
                        onClose={() => setSortMenu(null)}
                        onClick={() => setSortMenu(null)}
                        hideBackdrop
                      >
                        {['Job Title','Resume Updated','Location'].map((label, index) => 
                        <MenuItem key={index} selected={label === sortFilter} onClick={() => setSortFilter(label)}>{label}</MenuItem>)}
                      </Menu> */}
                      <Menu
                          anchorEl={sortMenu}
                          open={Boolean(sortMenu)}
                          onClose={() => setSortMenu(null)}
                          onClick={() => setSortMenu(null)}
                          hideBackdrop
                        >
                          {
                            monsterSort.map((label,index)=>(
                                <MenuItem
                                key={index}
                                selected={label.value === sortFilter}
                                onClick={() =>handleFilterMonster(label.value)  }
                                >
                                  {label.label}
                                </MenuItem>
                              ))
                         
                          }
                        </Menu>
                    </Box>)}
        {!searchTypeGrid&&(
          <>
          <IconButton
          size="small"
          onClick={() => {
            setIsGrid(false);
          }}
          color={isGrid ? "default" : "primary"}
        >
          <GridViewOutlinedIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setIsGrid(true);
          }}
          color={isGrid ? "primary" : "default"}
        >
          <FormatListBulletedIcon fontSize="inherit" />
        </IconButton>
        </>
        )}
        
      </Box>
      <Box>
        <Pagination
          count={
            searchType === "Monster"
              ? matchedMonsterResult
              : searchType === "CareerBuilder"
              ? matchedCBResult
              : pageSize
          }
          page={page}
          onChange={handlePageChange}
          size="small"
        />
      </Box>
      <Modal
          open={sendMailModal}
          onClose={handleToggleSendMailModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidateSendMail
              close={handleToggleSendMailModal}
              applicantsList={searchType==="InternalDB"?selectedRows:filterSelectedCandidateMigrated}
              jobId={""}
            />
          </Box>
        </Modal>
        <Modal
          open={mailMergeModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 1000,
              // height: "80%",
              // maxHeight: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              //overflow: "hidden",
            }}
          >
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item xs={7}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SectionTitle title="Email Merge" />
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.grey[500] }}
                    >
                      No of Recipients: {selectedRows.length}
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select job"}
                      components={[
                        {
                          type: "autocomplete",
                          props: {
                            placeholder: `select the job`,
                            options: jobPostingOptions,
                            defaultValue: selectJobTag,
                            onChange: (value) => handleJobSelect(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select Template"}
                      required
                      components={[
                        {
                          type: "select",
                          props: {
                            required: true,
                            placeholder: "Select",
                            defaultValue: template,
                            options: optionsTemplate,
                            onChange: (value) => handleChangeTemplate(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item sx={12}>
                    <Button
                      color="inherit"
                      onClick={() => handleToggleCancelMailMerge()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleShareEMail()}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <ScrollView>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: 1,
                    }}
                  >
                    {(searchType==="InternalDB"?selectedRows:filterSelectedCandidateMigrated).map((item, key) => {
                      return (
                        <Box
                          key={key}
                          sx={{
                            borderRadius: 2,
                            p: 1,
                            mx: 0.5,
                            background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                            border: `1px solid ${theme.palette.grey[200]}`,
                          }}
                        >
                          <Box>
                            <Grid container spacing={0.2}>
                              <Grid item xs={9}>
                                <Typography sx={{ fontWeight: "bold" }} noWrap>
                                  {item?.firstName || ""}
                                  {item?.middleName || ""}
                                  {item?.lastName || ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={{ fontSize: 11 }}>
                                  Profile used
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Email"}
                                  value={
                                    item?.emailAddress
                                      ? item.emailAddress
                                          .filter((item1) => item1.email) // Filter out objects without an email
                                          .map((item1) => item1.email)
                                          .join(", ")
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Experience"}
                                  value={item.experienceYears || ""}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Job Title"}
                                  value={
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={[]}
                                      autoEscape={true}
                                      textToHighlight={item?.jobTitle || ""}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Notice Period"}
                                  value={item?.availability}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={10}>
                                {" "}
                                <KeyData
                                  title={" Pay rate"}
                                  value={`${
                                    item?.payRateSalary?.currency || ""
                                  }/${item?.payRateSalary?.minAmount || ""}
                                /${item?.payRateSalary?.maxAmount || ""}/${
                                    item?.payRateSalary?.paySchedule || ""
                                  }/${item?.payRateSalary?.taxTerm || ""}`}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <IconButton size="xsmall" color="error">
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="inherit"
                                    onClick={() => handleDeleteUserProfile(key)}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </ScrollView>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <AlertModal 
  open={warningModal} 
  message={warningMessage} 
  onClose={handleWarningModalClose} 
/>
        <Modal
          open={openSendModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
          onClose={handleShareEMailClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidatesMailMerge
              close={handleShareEMailClose}
              applicantsList={searchType==="InternalDB"?selectedRows:filterSelectedCandidateMigrated}
              jobId={jobId}
              optionsTemplate={optionsTemplate}
              templateSubjectType={templateSubjectType}
              setTemplateSubjectType={setTemplateSubjectType}
              templateBodyType={templateBodyType}
              setTemplateBodyType={setTemplateBodyType}
              template={template}
              setTemplate={setTemplate}
              templateId={templateId}
            />
          </Box>
        </Modal>
    </Box>
  );
};

export default JobBoardMigration;
