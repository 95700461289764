import { 
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  TextField,
  Tooltip,
  Typography,

} from "@mui/material";
import React from "react";
// import theme from "../../Theme";
import theme from "../../Theme";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("Text copied to clipboard!");
        console.log('copied');
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  
  const CopyToClipboardButton = ({ text }) => {
    return (
      <Tooltip title="Copy">
        <Link
          variant="body2"
          sx={{ ml: 0.5 }}
          href="javascript:void(0)"
          onClick={(e) => {
            e.preventDefault();
            copyToClipboard(text);
          }}
          color={theme.palette.grey[500]}
        >
          <ContentCopyIcon fontSize="inherit" />
        </Link>
      </Tooltip>
    );
  };
  export default CopyToClipboardButton;