import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomFormField, MainBackground, SectionTitle } from "..";
import theme from "../../Theme";
import InternalDB from "../JobBoardsResult/InternalDB";
import { defaults } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addJobBoardsHistoryViaApi,
  clearSearchResults,
  deleteJobBoardsHistoryViaApi,
  getJobBoardsHistoryViaApi,
  getjobBoardsStatusHistoryViaApi,
  internalDBLoading,
  saveJobBoardsHistoryViaApi,
  searchResumeInternalDbViaApi,
  stopLoadingRedux,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { degreesOptions, DisabilityOptions, DomainOptions, EthnicityOptions, GenderOptions, industryOptions, MonsterstateData, noticePeriodOptions, payScheduleTypes, VeteranStatusOptions, workAuthorizationOptions } from "../../Data/JobPostingData";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import moment from "moment-timezone";
import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";

let additionalDataConfig={
  workAuthorization: '',
  industry: '',
selectEducationDetail: '',
selectVeteranStatus: '',
selectGender: '',
selectDomain: '',
selectEthinicOption: '',
selectDisabilityOption: '',
selectNoticePeriodOption: '',
relocateOption: '',
clearanceOption: '',
currentCtcOption: '',
currentCtcMinOption: '',
currentCtcMaxOption: '',
expectedCtcOption: '',
expectedCtcMinOption: '',
expectedCtcMaxOption: '',
preferredLocation: '',
employer: '',
fromDate: '',
toDate: '',
functionOption: '',
gpa: '',
min: '',
max: '',
minMonth: '' ,
maxMonth: '' ,
  } 

const JobBoardInternalDb = () => {
  let navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const JobBoardHistory = useSelector(
    (state) => state.JobBoards.jobBoardsHistory
  );
  const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
    ? JobBoardHistory.filter(
        (job) => job.searchType === "InternalDB" && job.status === "recent"
      )
    : [];
  const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
    ? JobBoardHistory.filter(
        (job) => job.searchType === "InternalDB" && job.status === "saved"
      )
    : [];

  const InternalResumesList = useSelector(
    (state) => state.Resumes.internalResumesList
  );
  const [loading, setLoading] = useState(false);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const [keyWords, setKeyWords] = useState();
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [internalDataStates , setInternalDataStates] = useState()
  const [internalDbCities , setInternalDbCities] = useState()
  const [additionalData, setAdditionalData] = useState(additionalDataConfig)

  const [keyWordError, setKeyWordError] = useState();
  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  useEffect(() => {
    // Dynamically set the height of the content when options are toggled
    if (contentRef.current) {
      setHeight(showOptions ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [showOptions]);
  const [limitPage, setLimtPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchInternalDB = () => {
    setKeyWordError("");

    if (!keyWords && !name && !jobTitle) {
      setKeyWordError("This filed is required.");
      return; // This will stop further execution and prevent API call
    }
    setLoading(true);
    const action = {
      client: client,
      keyWords: keyWords,
      name: name,
      jobTitle: jobTitle,
       country: country,
      state: state,
      city: city ?? "",
      workAuthorization: additionalData.workAuthorization ?? "",
      education: additionalData.selectEducationDetail ?? "",
      veteranStatus: additionalData.selectVeteranStatus ?? "",
      industry: additionalData.industry ?? "",
      gender: additionalData.selectGender ?? "",
      domain: additionalData.selectDomain ?? "",
      relocate: additionalData.relocateOption ?? "",
      clearance: additionalData.clearanceOption ?? "",
      notice: additionalData.selectNoticePeriodOption ?? "",
      disability: additionalData.selectDisabilityOption ?? "",
      EthinicOption: additionalData.selectEthinicOption ?? "",
      minCurrentCtc: additionalData.currentCtcMinOption ?? "",
      maxCurrentCtc: additionalData.currentCtcMaxOption ?? "",
      minExpectedCtc: additionalData.expectedCtcMinOption ?? "",
      maxExpectedCtc: additionalData.expectedCtcMaxOption ?? "",
      currentCtcOption:additionalData.currentCtcOption ?? "",
      expectedCtcOption: additionalData.expectedCtcOption ?? "",
      preferredLocation : additionalData.preferredLocation ?? "",
      employer: additionalData.employer ?? "",
      fromDateaddedon: additionalData.fromDate ?? "",
      toDateaddedOn: additionalData.toDate ?? "",
      minExperience: additionalData.min ?? "",
      maxExperience: additionalData.max ?? "",
      pageSize: limitPage,
      page: currentPage,
    };
    dispatch(clearSearchResults());
    
    dispatch(searchResumeInternalDbViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "InternalDB",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    dispatch(internalDBLoading(true))
    setTimeout(() => {
      navigate("/jobBoardsResult", {
        state: { ...action, keyWords: keyWords, searchType: "InternalDB", },
      });
    }, 2000);
  };
  
  const handleResetSearch = () => {
    setKeyWords("");
    setName("");
    setJobTitle("");
    setKeyWordError("");
    setAdditionalData(additionalDataConfig);
  };
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleClose = () => "";
  const [openTabId, setOpenTabId] = useState("recentHistory");
  const handleChange = (event, newValue) => {
    handleResetSearch();
    setOpenTabId(newValue);
    if (newValue === "recentHistory") {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "recent",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    } else {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }
  };
  const handleClickEditHistory = (searchData) => {
    const QueryData = searchData?.query;
    handleResetSearch();
    const updateStates = { jobTitle: setJobTitle, keyWords: setKeyWords, name: setName, country : setCountry, state: setState, city: setCity};
    const updateAdditionalStates = {minExperience: 'min', maxExperience: 'max', maxCurrentCtc:'currentCtcMaxOption',maxExpectedCtc: 'expectedCtcMaxOption',minCurrentCtc:'currentCtcMinOption',
      minExpectedCtc:'expectedCtcMinOption', workAuthorization: 'workAuthorization',education:'selectEducationDetail',veteranStatus: 'selectVeteranStatus',industry:'industry',
      gender:'selectGender',domain:'selectDomain',relocate:'relocateOption',clearance:'clearanceOption',notice:'selectNoticePeriodOption',disability:'selectDisabilityOption',
      EthinicOption: 'selectEthinicOption', currentCtcOption:'currentCtcOption',expectedCtcOption:'expectedCtcOption',preferredLocation:'preferredLocation',employer:'employer',
      fromDateaddedon:'fromDate',toDateaddedOn:'toDate'};
      Object.entries(updateStates).forEach(([key, setter]) => { setter(QueryData[key]) });
      Object.entries(updateAdditionalStates).forEach(([key, setter]) => { setAdditionalData(prev => ({...prev, [setter]: QueryData[key]})) });
    };
  const handleClickHistory = (value) => {
    const action = value.query;
    setLoading(true);
    dispatch(searchResumeInternalDbViaApi(action));
    const historyAction = {
      id: value._id,
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: value.status,
      searchType: "InternalDB",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getJobBoardsHistoryViaApi(historyAction));
      dispatch(internalDBLoading(true))
      navigate("/jobBoardsResult", {
        state: {
          ...action,
          keyWords: action.keyWords,
          searchType: "InternalDB",
        },
      });
    }, 2000);
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const handleClickSaveHistory = (value) => {
    setHistoryTitle("");
    setErrTitle("");
    setSelectHistory("");
    if (value) {
      setAddNoteModal(!addNoteModal);
      setSelectHistory(value);
    }
  };
  const handleClickDeleteHistory = (value) => {
    if (value) {
      setSelectHistory(value);
      setDeleteHistoryModal(!deleteHistoryModal);
    }
  };
  const handleConfirmHistorySave = () => {
    setErrTitle("");
    if (!selectHistory && !historyTitle) {
      setErrTitle("please type title");
      return "";
    }
    const action = {
      id: selectHistory._id,
      client: client,
      title: historyTitle,
    };
    dispatch(saveJobBoardsHistoryViaApi(action));
    setLoading(true);
    setAddNoteModal(!addNoteModal);
    setTimeout(() => {
      const action = {
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "recent",
        client: client,
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }, 2000);
  };
  const handleConfirmHistoryDelete = () => {
    const action = {
      id: selectHistory._id,
      client: client,
    };
    dispatch(deleteJobBoardsHistoryViaApi(action));
    setLoading(true);
    setDeleteHistoryModal(!deleteHistoryModal);
    setTimeout(() => {
      const fetchAction = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
    }, 2000);
  };

  let USallStates = MonsterstateData.map(state => state.label);
  useEffect(()=>{
    if(country === "United States"){
      setInternalDataStates(USallStates);
    }else{
      setInternalDataStates([])
    }
  },[country])
  const getCitiesFromStates = async (value) => {
    setState(value); // Fixed typo
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}/countries/getInternalDbCities`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: client || "tektree", // Update this if the client is dynamic
          state: value, // The selected state name
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setInternalDbCities(data); // Update the internal state with the fetched data
      // Optionally, you can set other state variables here if needed
    } catch (error) {
      console.error("Error fetching cities:", error); // Updated error message for accuracy
    }
  };


  return (
    <Box>
      <MainBackground p={0}>
        <Grid container>
          <Grid item xs={8}>
            <Box
              sx={{
                p: 2,
                bgcolor: theme.palette.primary[100],
              }}
            >
              <Box sx={{ mb: 3 }}>
                <SectionTitle
                  title="Applicant Search"
                  size="sm"
                  color="secondary"
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Keyword"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "skills",
                          required: true,
                          defaultValue: keyWords,
                          onChange: (value) => setKeyWords(value),
                          error: !!keyWordError,
                          errorMessage: keyWordError,
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Name"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          required: true,
                          defaultValue: name,
                          onChange: (value) => setName(value),
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Job Title"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          required: true,
                          defaultValue: jobTitle,
                          onChange: (value) => setJobTitle(value),
                          // error:!!errorKeyword,
                          // errorMessage:errorKeyword
                        },
                      },
                    ]}
                  />
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="Recent Job Title"
                      componentsProps={{
                        typography: { sx: { fontSize: "12px" } },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} display={"none"}>
                  <CustomFormField
                    components={[
                      {
                        type: "radio",
                        props: {
                          title: "",

                          options: [
                            "Search with in Selected Location",
                            "Search with in Radius",
                          ],
                          defaultValue: "Search with in Selected Location",
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Country"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Country",
                          defaultValue: country,
                          options: ["United States", "Canada"],
                          onChange: (value) => setCountry(value),
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"State"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "State",
                          defaultValue: state,
                          options:   internalDataStates,
                          onChange: (value) => getCitiesFromStates(value),
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"City"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "City",
                          defaultValue: city,
                          options: internalDbCities,
                          onChange: (value) => setCity(value),
                        },
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={toggleOptions}
                  >
                    Advanced Options
                  </Button>

                  <Box
                    sx={{
                      height,
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",

                      mt: 2,
                    }}
                    ref={contentRef}
                  >
                    <Box sx={{ mt: 5, mb: 2 }}>
                      <SectionTitle
                        title="Advanced Options"
                        size="sm"
                        color="secondary"
                      />
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Total Experience"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min Yrs",
                                type: "number",
                                defaultValue: additionalData.min,
                                onChange: (value) => setAdditionalData(prev => ({...prev, min: value}))
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "months",
                                type: "number",
                                defaultValue: additionalData.minMonth,
                                onChange: (value) => setAdditionalData(prev => ({...prev, minMonth: value}))
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max Yrs",
                                type: "number",
                                defaultValue: additionalData.max,
                                onChange: (value) => setAdditionalData(prev => ({...prev, max: value}))
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "months",
                                type: "number",
                                defaultValue: additionalData.maxMonth,
                                onChange: (value) => setAdditionalData(prev => ({...prev, maxMonth: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Current CTC"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min",
                                type: "number",
                                defaultValue: additionalData.currentCtcMinOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, currentCtcMinOption: value}))
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max",
                                type: "number",
                                defaultValue: additionalData.currentCtcMaxOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, currentCtcMaxOption: value}))

                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: additionalData.currentCtcOption,
                                options: payScheduleTypes,
                                onChange: (value) => setAdditionalData(prev => ({...prev, currentCtcOption: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Expected CTC"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min",
                                type: "number",
                                defaultValue: additionalData.expectedCtcMinOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, expectedCtcMinOption: value}))
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max",
                                type: "number",
                                defaultValue: additionalData.expectedCtcMaxOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, expectedCtcMaxOption: value}))
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: additionalData.expectedCtcOption,
                                options: payScheduleTypes,
                                onChange: (value) => setAdditionalData(prev => ({...prev, expectedCtcOption: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Educational Details"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: additionalData.selectEducationDetail,
                                options: degreesOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectEducationDetail: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Preferred Location"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Preferred Location",
                                defaultValue: additionalData.preferredLocation,
                                onChange: (value) => setAdditionalData(prev => ({...prev, preferredLocation: value}))

                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Box>
                            <CustomFormField
                              title={"Notice Period"}
                              required
                              components={[
                                {
                                  type: "select",
                                  props: {
                                    title: "Slect",
                                    defaultValue: additionalData.selectNoticePeriodOption,
                                    options: noticePeriodOptions,
                                    onChange: (value) => setAdditionalData(prev => ({...prev, selectNoticePeriodOption: value}))
                                  },
                                },
                              ]}
                            />
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <CustomFormField
                              components={[
                                {
                                  type: "datepicker",
                                  props: {
                                    required: true,
                                    placeholder: "From Date",
                                    //defaultValue: details.yearCompleted,
                                    defaultValue: additionalData.fromDate,
                                onChange: (value) => setAdditionalData(prev => ({...prev, fromDate: value}))
                                  },
                                },
                                {
                                  type: "datepicker",
                                  props: {
                                    required: true,
                                    placeholder: "To Date",
                                    //defaultValue: details.yearCompleted,
                                    defaultValue: additionalData.toDate,
                                onChange: (value) => setAdditionalData(prev => ({...prev, toDate: value}))

                                  },
                                },
                              ]}
                            />
                            <Button sx={{ mt: 1 }}>Clear</Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Function"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Function",
                                defaultValue: additionalData.functionOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, functionOption: value}))

                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Industry"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Industry",
                                defaultValue: additionalData.industry,
                                options: industryOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, industry: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Work Authorization"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Work Authorization",
                                defaultValue: additionalData.workAuthorization,
                                options: workAuthorizationOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, workAuthorization: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Employer"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Employer",
                                defaultValue: additionalData.employer,
                                onChange: (value) => setAdditionalData(prev => ({...prev, employer: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Willing to Relocate"}
                          required
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Willing to Relocate",
                                required: true,
                                options: ["Yes", "No"],
                                // defaultValue: "no",
                                defaultValue: additionalData.relocateOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, relocateOption: value}))

                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Clearance"}
                          required
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Clearance",
                                required: true,
                                options: ["Yes", "No"],
                                // defaultValue: "no",
                                defaultValue: additionalData.clearanceOption,
                                onChange: (value) => setAdditionalData(prev => ({...prev, clearanceOption: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"GPA"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "GPA",
                                defaultValue: additionalData.gpa,
                                onChange: (value) => setAdditionalData(prev => ({...prev, gpa: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Domain"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Domain",
                                defaultValue: additionalData.selectDomain,
                                options: DomainOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectDomain: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SectionTitle size="sm" title="EEO Details" />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Gender"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Gender",
                                defaultValue: additionalData.selectGender,
                                options: GenderOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectGender: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Race/Ethnicity"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Race/Ethnicity",
                                defaultValue: additionalData.selectEthinicOption,
                                options: EthnicityOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectEthinicOption: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Disability"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Disability",
                                defaultValue: additionalData.selectDisabilityOption,
                                options: DisabilityOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectDisabilityOption: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Veteran Status"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Veteran Status",
                                defaultValue: additionalData.selectVeteranStatus,
                                options: VeteranStatusOptions,
                                onChange: (value) => setAdditionalData(prev => ({...prev, selectVeteranStatus: value}))
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button>Save</Button>
                        <Button onClick={toggleOptions}>Cancel</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} alignContent={"center"}>
                  <Box display={"flex"} justifyContent={"center"} gap={2}>
                    <Button
                      variant="contained"
                      onClick={() => handleSearchInternalDB()}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleResetSearch()}
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: theme.palette.grey[50],
                px: 3,
                py: 1,
                height: "100%",
              }}
            >
              <TabContext value={openTabId}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="ob Boards tabs">
                    <Tab label="Recent History" value="recentHistory" />
                    <Tab label="Saved History" value="savedHistory" />
                  </TabList>
                </Box>

                <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistoryRecent.map((search) => (
                    <SearchHistorySection
                      key={search._id}
                      data={search.query.keyWords}
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickSave={() => handleClickSaveHistory(search)}
                      status="recent"
                      onClickEdit={()=> handleClickEditHistory(search)}
                    />
                  ))}
                </TabPanel>
                <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistorySaved.map((search) => (
                    <SearchHistorySection
                      key={search._id}
                      data={search.query.keyWords}
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickDelete={() => handleClickDeleteHistory(search)}
                      title={search.title}
                      status="saved"
                      onClickEdit={()=> handleClickEditHistory(search)}
                    />
                  ))}
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </MainBackground>
      <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
              {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
            </Typography>
            {/* <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography> */}
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
    </Box>
  );
};

export default JobBoardInternalDb;
