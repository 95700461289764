import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
} from "redux-saga/effects";
import {
  ADVANCED_RESUME_SEARCH_VIA_API,
  APPLICANT_SEARCH_INTERNAL_DB_VIA_API,
  ASSIGNED_TO_MONSTER_ACCESS_VIA_API,
  DISTRIBUTE_MONSTER_CREDITS_VIA_API,
  EMAILS_SEND_TO_APPLICANTS_VIA_API,
  GET_INTEGRATIONS_VIA_API,
  GET_MONSTER_CREDITS_VIA_API,
  GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API,
  GET_RESUME_AND_STORE_INTERNALDB_VIA_API,
  INTEGRATE_INTERNAL_DB_SEARCH_VIA_API,
  INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API,
  INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API,
  INTEGRATE_SEARCH_INTERNAL_DB_VIA_API,
  INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API,
  INTERNAL_DB_SEARCH_VIA_API,
  MONSTER_ADVANCED_RESUME_SEARCH_VIA_API,
  MONSTER_POWER_SEARCH_VIA_API,
  SEARCH_RESUME_INTERNAL_DB_VIA_API,
  SEARCH_RESUMEID_INTERNALDB_VIA_API,
} from "./actionType";
import {
  getIntegrationsViaApi,
  successAdvancedResumeSearch,
  successInegrations,
  successIntegrateCBAdvancedResumesSearch,
  successIntegrateInternalDBSearch,
  successIntegrateMonsterAvancedResumesSearch,
  successInternalDBNoteUpdate,
  successInternalDbSearch,
  successMonsterAdvancedResumeSearch,
  successSearchResumeIdInternalDb,
  successSearchResumeInternalDb,
} from "./action";
import { setSnackbarMessage } from "../search/action";
import {
  awsResumeUrl,
  internalDBLoading,
  internalDBMatchedResumesViaApi,
  loadingViaRedux,
  successAddApplicant,
  successAddMigratedAppliants,
  successGetAllReferencesNotesViaApi,
  successInternalDBMatchedResumes,
} from "../actions";
const apiUrl = process.env.REACT_APP_API_URL;
const emailApiUrl=process.env.REACT_APP_API_URL_EMAILS;
function advancedResumeSearchViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/cbSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((errorData) => {
        throw new Error(
          errorData.error || "Error occurred while fetching data"
        );
      });
    }
    return response.json();
  });
}
function searchResumeIdInternalDbViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/resumeIdSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getResumeAndStoreInternalDbViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/getResumeSIDB", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function monsterAdvancedResumeSearchViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/monsterClassicSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function getMonsterResumeAndStoreInternalDbViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/getMonsterResumeSIDB", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function searchResumeInternalDbViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/searchResumeInternalDB", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function emailsSendToApplicantsViaApiSaga(payload) {
  return fetch(emailApiUrl + "/applicantsEmailLogs/sendEmailToApplicants", {
    method: "POST",
    body: payload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function integrateSearchInternalDbViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/integrateSearchInternalDB", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getMonsterCreditsViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);

  return fetch(apiUrl + "/applicants/getMonsterCredits", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getIntegrationsViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/integrations/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function integrationNewMonsterAccountViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/integrations/addNewAccount", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function assignedToMonsterAccessViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/integrations/addAccessUser", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function distributeMonsterCreditsViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/integrations/distributeMonsterCredit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function monsterPowerSearchViaApiSaga(payload) {
  return fetch(apiUrl + "/applicants/monsterPowerSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

//---------------------------------------------------------------------------------------
// Fetch Advanced Resume Search
function* advancedResumeSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(advancedResumeSearchViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      // Extract XML string from JSON
      const xmlString = response.V2_AdvancedResumeSearchResult;

      // Parse the XML string using DOMParser
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "application/xml");

      // Check for parsing errors
      const errorNode = xmlDoc.querySelector("parsererror");
      if (errorNode) {
        yield put(successAdvancedResumeSearch(response));
        return;
      }
      // Extract Resume IDs
      const resumes = xmlDoc.getElementsByTagName("ResumeResultItem_V3");
      const resumeIds = Array.from(resumes)
        .map(
          (resume) => resume.getElementsByTagName("ResumeID")[0]?.textContent
        )
        .filter(Boolean);
      const client = action.payload.client;
      if (resumeIds.length > 0) {
        const actionData = {
          client: client,
          resumeIds: resumeIds,
        };
        yield put(internalDBMatchedResumesViaApi(actionData));
      }
      yield put(successAdvancedResumeSearch(response));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}

function* searchResumeIdInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(searchResumeIdInternalDbViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });
    if (response) {
      if (response.error) {
        yield put(loadingViaRedux(true));
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      yield delay(1000);
      if (response) {
        const action = {
          key: response?.[0]?.resumes?.[response[0].resumes.length - 1]
            ?.serverLocation,
        };
        yield put(awsResumeUrl(action));
      }
      yield put(successInternalDBNoteUpdate([response]));
      yield put(successInternalDBMatchedResumes([response]));
      yield delay(3000);
      yield put(loadingViaRedux(true));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
function* getResumeAndStoreInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getResumeAndStoreInternalDbViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      if (response.error) {
        yield put(loadingViaRedux(true));

        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      yield delay(1000);
      if (response.data) {
        const resumeUrlAction = {
          key: response.data.resumes?.[response.data.resumes.length - 1]
            ?.serverLocation,
        };
        yield put(awsResumeUrl(resumeUrlAction));
      }
      yield put(successInternalDBNoteUpdate([response.data]));
      yield put(successInternalDBMatchedResumes([response.data]));
      yield put(successAddMigratedAppliants([response.data]))
      yield delay(3000);
      yield put(loadingViaRedux(true));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
function* monsterAdvancedResumeSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(monsterAdvancedResumeSearchViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      // Parse XML using DOMParser
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "application/xml");
      const parserError = xmlDoc.getElementsByTagName("parsererror");
      if (parserError.length > 0) {
        yield put(successMonsterAdvancedResumeSearch(response.data));
        return null;
      }
      // Extract Resume SIDs
      const resumeElements = xmlDoc.getElementsByTagName("Resume");
      const resumeSIDs = Array.from(resumeElements).map((resume) =>
        resume.getAttribute("SID")
      );
      const client = action.payload.client;
      if (resumeSIDs.length > 0) {
        const actionData = {
          client: client,
          resumeIds: resumeSIDs,
        };
        yield put(internalDBMatchedResumesViaApi(actionData));
      }
      yield put(successMonsterAdvancedResumeSearch(response.data));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}

function* getMonsterResumeAndStoreInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(
        getMonsterResumeAndStoreInternalDbViaApiSaga,
        action.payload
      ),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      yield delay(1000);
      if (response.data) {
        const resumeUrlAction = {
          key: response.data.resumes?.[response.data.resumes.length - 1]
            ?.serverLocation,
        };
        yield put(awsResumeUrl(resumeUrlAction));
      }
      yield put(successInternalDBNoteUpdate([response.data]));
      yield put(successInternalDBMatchedResumes([response.data]));
      yield put(successAddMigratedAppliants([response.data]))
      yield delay(4000);
      // Trigger loading state after the delay
      yield put(loadingViaRedux(true));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
function* searchResumeInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      // response: call(searchResumeInternalDbViaApiSaga, action.payload),
      response: call(integrateSearchInternalDbViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });
    if (response) {
      if (response.error) {
        yield put(loadingViaRedux(true));
        yield put(internalDBLoading(false));
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      if (response.length > 0) {
        const filterIds = response.map((applicant) => applicant._id);
        
        const actionNote = {
          client: action.payload.client,
          applicantIds: filterIds,
        };
        yield put(successGetAllReferencesNotesViaApi(actionNote));
        yield put(successAddMigratedAppliants(response))
      }
      yield put(successSearchResumeInternalDb(response));
      yield put(loadingViaRedux(true));
      yield put(internalDBLoading(false));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}

function* emailsSendToApplicantsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(emailsSendToApplicantsViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* integrateSearchInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(integrateSearchInternalDbViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* applicantSearchInternalDbViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(integrateSearchInternalDbViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true));

      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      yield put(successAddApplicant(response));
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* internalDbSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      // response: call(searchResumeInternalDbViaApiSaga, action.payload),
      response: call(integrateSearchInternalDbViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      yield put(successInternalDbSearch(response));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}

function* getMonsterCreditsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getMonsterCreditsViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getIntegrationsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getIntegrationsViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      yield put(successInegrations(response));
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* integrationNewMonsterAccountViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(integrationNewMonsterAccountViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* assignedToMonsterAccessViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(assignedToMonsterAccessViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* distributeMonsterCreditsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(distributeMonsterCreditsViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }

      // yield put(loadingViaRedux(true))
      // yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* integrateMonsterAdvancedResumesSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(monsterAdvancedResumeSearchViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      
// Parse XML using DOMParser
const parser = new DOMParser();
const xmlDoc = parser.parseFromString(response.data, "application/xml");
const parserError = xmlDoc.getElementsByTagName("parsererror");
if (parserError.length > 0) {
  yield put(successMonsterAdvancedResumeSearch(response.data));
  return null;
}
// Extract Resume SIDs
const resumeElements = xmlDoc.getElementsByTagName("Resume");
const resumeSIDs = Array.from(resumeElements).map((resume) =>
  resume.getAttribute("SID")
);
const client = action.payload.client;
if (resumeSIDs.length > 0) {
  const actionData = {
    client: client,
    resumeIds: resumeSIDs,
  };
  yield put(internalDBMatchedResumesViaApi(actionData));
}
      yield put(successIntegrateMonsterAvancedResumesSearch(response.data));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
function* integrateCBAdvancedResumesSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(advancedResumeSearchViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
       // Extract XML string from JSON
       const xmlString = response.V2_AdvancedResumeSearchResult;
       // Parse the XML string using DOMParser
       const parser = new DOMParser();
       const xmlDoc = parser.parseFromString(xmlString, "application/xml");
 
       // Check for parsing errors
       const errorNode = xmlDoc.querySelector("parsererror");
       if (errorNode) {
         yield put(successAdvancedResumeSearch(response));
         return;
       }
       // Extract Resume IDs
       const resumes = xmlDoc.getElementsByTagName("ResumeResultItem_V3");
       const resumeIds = Array.from(resumes)
         .map(
           (resume) => resume.getElementsByTagName("ResumeID")[0]?.textContent
         )
         .filter(Boolean);
       const client = action.payload.client;
       if (resumeIds.length > 0) {
         const actionData = {
           client: client,
           resumeIds: resumeIds,
         };
         yield put(internalDBMatchedResumesViaApi(actionData));
       }
      yield put(successIntegrateCBAdvancedResumesSearch(response));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
function* integrateInternalDBSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      // response: call(searchResumeInternalDbViaApiSaga, action.payload),
      response: call(integrateSearchInternalDbViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      }
      if (response.length > 0) {
        const filterIds = response.map((applicant) => applicant._id);
        const actionNote = {
          client: action.payload.client,
          applicantIds: filterIds,
        };
        
        yield put(successGetAllReferencesNotesViaApi(actionNote));
      }
      yield put(successIntegrateInternalDBSearch(response));
      yield put(successAddMigratedAppliants(response))
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}

function* monsterPowerSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(monsterPowerSearchViaApiSaga, action.payload),
      timeout: delay(60000), // 1-minute timeout
    });

    if (response) {
      yield put(loadingViaRedux(true));
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      // Parse XML using DOMParser
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "application/xml");
      const parserError = xmlDoc.getElementsByTagName("parsererror");
      if (parserError.length > 0) {
        yield put(successMonsterAdvancedResumeSearch(response.data));
        return null;
      }
      // Extract Resume SIDs
      const resumeElements = xmlDoc.getElementsByTagName("Resume");
      const resumeSIDs = Array.from(resumeElements).map((resume) =>
        resume.getAttribute("SID")
      );
      const client = action.payload.client;
      if (resumeSIDs.length > 0) {
        const actionData = {
          client: client,
          resumeIds: resumeSIDs,
        };
        yield put(internalDBMatchedResumesViaApi(actionData));
      }
      yield put(successMonsterAdvancedResumeSearch(response.data));
    }
  } catch (error) {
    yield put(
      setSnackbarMessage("An error occurred: " + error.message, "error")
    );
  }
}
// Watcher saga
function* watchSettleItems() {
  yield takeEvery(ADVANCED_RESUME_SEARCH_VIA_API, advancedResumeSearchViaSaga);
  yield takeEvery(
    SEARCH_RESUMEID_INTERNALDB_VIA_API,
    searchResumeIdInternalDbViaSaga
  );
  yield takeEvery(
    GET_RESUME_AND_STORE_INTERNALDB_VIA_API,
    getResumeAndStoreInternalDbViaSaga
  );
  yield takeEvery(
    MONSTER_ADVANCED_RESUME_SEARCH_VIA_API,
    monsterAdvancedResumeSearchViaSaga
  );
  yield takeEvery(
    GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API,
    getMonsterResumeAndStoreInternalDbViaSaga
  );
  yield takeEvery(
    SEARCH_RESUME_INTERNAL_DB_VIA_API,
    searchResumeInternalDbViaSaga
  );
  yield takeEvery(
    EMAILS_SEND_TO_APPLICANTS_VIA_API,
    emailsSendToApplicantsViaSaga
  );
  yield takeEvery(
    INTEGRATE_SEARCH_INTERNAL_DB_VIA_API,
    integrateSearchInternalDbViaSaga
  );
  yield takeEvery(
    APPLICANT_SEARCH_INTERNAL_DB_VIA_API,
    applicantSearchInternalDbViaSaga
  );
  yield takeEvery(INTERNAL_DB_SEARCH_VIA_API, internalDbSearchViaSaga);
  yield takeEvery(GET_MONSTER_CREDITS_VIA_API, getMonsterCreditsViaSaga);
  yield takeEvery(GET_INTEGRATIONS_VIA_API, getIntegrationsViaSaga);
  yield takeEvery(
    INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API,
    integrationNewMonsterAccountViaSaga
  );
  yield takeEvery(
    ASSIGNED_TO_MONSTER_ACCESS_VIA_API,
    assignedToMonsterAccessViaSaga
  );
  yield takeEvery(
    DISTRIBUTE_MONSTER_CREDITS_VIA_API,
    distributeMonsterCreditsViaSaga
  );
  yield takeEvery(
    INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API,
    integrateMonsterAdvancedResumesSearchViaSaga
  );
  yield takeEvery(
    INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API,
    integrateCBAdvancedResumesSearchViaSaga
  );
  yield takeEvery(
    INTEGRATE_INTERNAL_DB_SEARCH_VIA_API,
    integrateInternalDBSearchViaSaga
  );
  yield takeEvery(
    MONSTER_POWER_SEARCH_VIA_API,
    monsterPowerSearchViaSaga
  );
}

// Root saga
export default function* Resumes() {
  yield all([fork(watchSettleItems)]);
}
